import { TemplateRef } from '@angular/core';

export interface HeaderProperties {
  headerTitle: string;
  navigateBackUrl?: string;
  createUrl?: string;
  moduleName?: string;
  entityName?: string; // Used for setting entity's name in form pages after 'Create'/'Edit' title
  headerTemplate?: TemplateRef<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}
