import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Is module local module only, server doesn't know about it
   */

  public isAdmin = false;
  public isCore = true;
  /**
   * Module name
   */
  public moduleName = SharedTranslateKeys.Documents;

  /**
   * Short name
   */
  public moduleNameShort = 'dmm';

  /**
   * Module description
   */
  public moduleDescription = 'Manage all types of documents and files throughout the platform';

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.DOCUMENTS;
  /**
   *  Module icon
   */
  public moduleIcon = 'pi pi-images';
  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#87a4f4';

  /**
   *  Module darker color
   */
  public moduleLogoDarkerColor = '#549df0';

  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.DOCUMENTS;

  /**
   * Sidebar permissions
   */

  documentManagementPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.DMM_RUN],
    operation: Operations.OR,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems = [
    {
      label: SharedTranslateKeys.Documents,
      icon: 'pi pi-images',
      routerLink: '/dmm/documents',
      permissions: this.documentManagementPermissions,
      items: [
        {
          label: SharedTranslateKeys.Files,
          icon: 'pi pi-images',
          routerLink: '/dmm/documents',
          permissions: this.documentManagementPermissions,
        },
      ],
    },
  ];

  public modulePermissions = ModulePermissions;
}
