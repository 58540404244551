import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogStorageService {
  public dialogs: { id: string; ref: DynamicDialogRef }[] = [];
  public isFormDiscarded = false;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  saveDialogRef(id: string, ref: DynamicDialogRef) {
    this.dialogs.push({ id, ref });

    ref.onClose.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.dialogs = this.dialogs.filter((dialog) => dialog.id !== id);
    });
  }

  closeDialog(id: string, value?: any) {
    const dialog = this.dialogs.find((d) => d.id === id);
    if (!dialog) {
      throw new Error('Dialog does not exist');
    }
    dialog.ref.close(value);
  }
}
