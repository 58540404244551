/**
 * Class with metadata for module
 */
import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type FiyuMenuItem,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Is module local module only, server doesn't know about it
   */
  public isCore = true;
  public isAdmin = false;
  /**
   * Module name
   */
  public moduleName = SharedTranslateKeys.Notifications;

  /**
   * Module Icon
   */
  public moduleIcon = 'pi pi-fw pi-bell';

  /**
   * Short name
   */
  public moduleNameShort = 'NMM';

  /**
   * Module description
   */
  public moduleDescription = SharedTranslateKeys.NotificationsModuleDescription;

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.NOTIFICATIONS;
  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#3c3c3c';

  /**
   *  Module darker color
   */
  public moduleLogoDarkerColor = '#000';

  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.NOTIFICATIONS;

  /**
   * All possible permissions for this module
   */
  public modulePermissions = ModulePermissions;

  /**
   * Sidebar permissions
   */
  notificationsPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.NMM_RUN],
    operation: Operations.OR,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems: FiyuMenuItem[] = [
    {
      label: SharedTranslateKeys.Notifications,
      icon: 'pi pi-fw pi-bell-o',
      routerLink: '/nmm/notifications',
      permissions: this.notificationsPermissions,
      items: [
        {
          label: SharedTranslateKeys.Notifications,
          icon: 'pi pi-fw pi-bell',
          routerLink: '/nmm/notifications',
          permissions: this.notificationsPermissions,
        },
        /*   {
          label: SharedTranslateKeys.About,
          routerLink: '/nmm/about',
          icon: 'pi pi-fw pi-info-circle',
          permissions: this.notificationsPermissions,
        }, */
      ],
    },
  ];
}
