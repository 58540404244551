import { HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CacheRepository } from './state/cache.repository';

/**
 * Used to retrieve saved response of a particular request.
 * For any new outgoing request, used to save the request url and its response.
 * @export
 * @class HttpCacheService
 */
@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  // A key is an HttpRequest url and its corresponding value is an HttpResponse instance.
  private cacheRepository = inject(CacheRepository);
  private cacheMap: Map<string, HttpResponse<any>> = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    return this.cacheMap.get(req.urlWithParams) ?? undefined;
  }

  set(req: HttpRequest<any>, response: HttpResponse<any>): void {
    this.cacheMap.set(req.urlWithParams, response);
    this.cacheRepository.updateCacheMap(this.cacheMap);
  }

  // Invalidate a particular request response
  delete(req: HttpRequest<any>): void {
    this.cacheMap.delete(req.urlWithParams);
    this.cacheRepository.updateCacheMap(this.cacheMap);
  }

  // Invalidate the entire cache
  invalidateCache(): void {
    const cacheMap = new Map();
    this.cacheRepository.updateCacheMap(cacheMap);
  }
}
