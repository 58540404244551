import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, PresentationEntity } from '@fiyu/core';
import { ModulePermissions } from './../module-permissions';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.Organization,
  pluralName: SharedTranslateKeys.Organizations,
  singularUrlName: 'organization',
  pluralUrlName: 'organizations',
  contentType: 'application/organization.admin.v1+json',
  permissionPrefix: ModulePermissions.ORGANIZATION,
})
export class OrganizationModel extends AbstractEntity {
  @PresentationEntity({})
  name: string;
  active: boolean;
  identificationNumber: string;
  registrationNumber: string;
  verified: boolean;
  override deleted: Date = null;
}
