import {
  MODULE_METADATA_TOKEN,
  Operations,
  RequiredModulesConfig,
  SharedTranslateKeys,
  VALIDATION_ERRORS_TOKEN,
} from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const OrganizationsRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('./organization-layout/organization-layout.component')).OrganizationLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'organization/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Organization}`,
        loadComponent: async () =>
          (await import('./organizations/organization-form/organization-form.component')).OrganizationFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.ORGANIZATION_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'organization',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Organization}`,
        loadComponent: async () =>
          (await import('./organizations/organization-form/organization-form.component')).OrganizationFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.ORGANIZATION_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'organizations',
        title: SharedTranslateKeys.Organizations,
        loadComponent: async () =>
          (await import('./organizations/organization-list/organization-list.component')).OrganizationListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.ORGANIZATION_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'employee/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Employee}`,
        loadComponent: async () =>
          (await import('./employees/employee-form/employee-form.component')).EmployeeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.EMPLOYEE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'employee',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Employee}`,
        loadComponent: async () =>
          (await import('./employees/employee-form/employee-form.component')).EmployeeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.EMPLOYEE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'employees',
        title: SharedTranslateKeys.Employees,
        loadComponent: async () =>
          (await import('./employees/employee-list/employee-list.component')).EmployeeListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.EMPLOYEE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'module/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Module}`,
        loadComponent: async () => (await import('./modules/module-form/module-form.component')).ModuleFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
      },
      {
        path: 'module',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Module}`,
        loadComponent: async () => (await import('./modules/module-form/module-form.component')).ModuleFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
      },
      {
        path: 'modules',
        title: SharedTranslateKeys.Modules,
        loadComponent: async () => (await import('./modules/module-list/module-list.component')).ModuleListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
      },
      {
        path: 'preferences',
        title: SharedTranslateKeys.Preferences,
        loadComponent: async () =>
          (
            await import(
              './organization-preferences/organization-preferences-form/organization-preferences-form.component'
            )
          ).OrganizationPreferencesFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [
          ModulePermissions.ORGANIZATION_RUN,
          ModulePermissions.ORGANIZATION_PREFERENCE_VIEW,
          ModulePermissions.ORGANIZATION_PREFERENCE_EDIT,
        ],
        operation: Operations.AND,
      },
      {
        path: 'landing',
        title: SharedTranslateKeys.Organizations,
        loadComponent: async () =>
          (await import('./organization-landing/organization-landing.component')).OrganizationLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.ORGANIZATION,
        requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
