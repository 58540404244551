import 'reflect-metadata';
import { DecoratorKeysEnum } from './decorator-keys.enum';
import type { FormOptions } from './form-options.interface';

/**
 * Decorator for entity form property
 *
 * Insert entity and form options
 *
 * @param options
 */
export function FormEntity(options?: FormOptions) {
  return (target: any, key: string) => {
    const t = Reflect.getMetadata('design:type', target, key);

    const classConstructor = target.constructor;
    const decoratorKey = DecoratorKeysEnum.Form + classConstructor.name;

    let presentationMap = Reflect.getMetadata(decoratorKey, classConstructor);

    if (!presentationMap) {
      presentationMap = new Map<string, FormOptions>();
    }

    const _options = options || {};
    _options.type = t.name;

    presentationMap.set(key, _options);

    Reflect.defineMetadata(decoratorKey, presentationMap, classConstructor);
  };
}
