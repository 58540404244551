import type { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { retry, type RetryConfig } from 'rxjs';

/**
 * Retry interceptor with configurable retry count and delay
 * @usage
 * provideHttpClient(
      withInterceptors([ retryInterceptor({ count: 5, delay: 1000 }) ])
    ),
 * @param config RetryConfig
 * @returns HttpInterceptorFn
 */
export const retryInterceptor = (config: RetryConfig) => {
  const interceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    return next(req).pipe(retry(config));
  };
  return interceptor;
};
