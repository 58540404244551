import type { ModuleMetadataInterface, Preference } from '@fiyu/api';
import type { Observable } from 'rxjs';
import type { AbstractEntity } from '../entity';

export interface ModuleMetadataServiceInterface {
  getModuleMetadata: () => ModuleMetadataInterface;
  isAdminModule: () => boolean;
  isCoreModule: () => boolean;
  isLocalModule: () => boolean;
  getLogoClass: () => string;
  getLogoColor: () => string;
  getLogoDarkerColor: () => string;
  getModulePrefix: () => string;
  getModuleAlias: () => string;
  getModuleNameShort: () => string;
  getModuleName?: () => string;
  getModulePreferences?: () => Preference[];
  getModuleSettings?: () => AbstractEntity;
  getModuleVersion?: () => Observable<string>;
}
