import { inject } from '@angular/core';
import type { ModuleMetadataInterface, Preference } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { VersionInfoService } from '../core/version/version-info.service';
import type { ModuleMetadataServiceInterface } from './module-metadata-service.interface';

/**
 * Abstract class for module Metadata service
 */
export class AbstractModuleMetadataService implements ModuleMetadataServiceInterface {
  /**
   * ModuleMetadata class instance
   */
  protected moduleMetadata: ModuleMetadataInterface;
  protected readonly versionInfoService: VersionInfoService = inject(VersionInfoService);
  protected readonly gitInfo: string | null = null;

  constructor(m: ModuleMetadataInterface) {
    this.moduleMetadata = m;
  }

  getModuleMetadata(): ModuleMetadataInterface {
    return this.moduleMetadata;
  }

  isAdminModule(): boolean {
    return this.moduleMetadata.isAdmin || false;
  }

  isCoreModule(): boolean {
    return this.moduleMetadata.isCore || false;
  }

  isLocalModule(): boolean {
    return this.moduleMetadata.isLocal || false;
  }

  getLogoClass(): string {
    return this.moduleMetadata.moduleIcon;
  }

  getLogoColor(): string {
    return this.moduleMetadata.moduleLogoColor;
  }

  getLogoDarkerColor(): string {
    return this.moduleMetadata.moduleLogoDarkerColor;
  }

  getModuleNameShort(): string {
    return this.moduleMetadata.moduleNameShort;
  }

  getModuleName(): string {
    return this.moduleMetadata.moduleName;
  }

  getModulePrefix(): string {
    return this.moduleMetadata.modulePrefix;
  }

  getModuleAlias(): string {
    return this.moduleMetadata.moduleAlias as any;
  }

  getModulePreferences(): Preference[] {
    return this.moduleMetadata.preferences as any;
  }

  // getModuleSettings(): AbstractEntity {
  //     return this.moduleMetadata.settings;
  // }

  getModuleVersion(): Observable<string> {
    return this.versionInfoService.getVersionInfo(this.moduleMetadata, this.gitInfo);
  }
}
