import { Injectable } from '@angular/core';
import { AbstractCRUDService, AbstractEntity } from '@fiyu/core';
import { Observable } from 'rxjs';
import { ModuleMetadata } from '../module-metadata';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends AbstractCRUDService {
  constructor() {
    super('/documents', ModuleMetadata.getInstance());
  }

  override create<Document extends AbstractEntity>(entity: Document, contentType: string): Observable<Document> {
    contentType = 'application/document.v1+json';
    (<any>entity)['docId'] = entity.id;
    // const { id, ...entityWithoutId } = entity;
    return this.httpClient.post<Document>(`${this.apiUrl}/edit`, entity, this.getHttpOptions(contentType));
  }
}
