import { FiyuError } from '../fiyu-error';
import { ValidationError } from './validation-error';

export class FiyuValidationError extends FiyuError {
  validationErrors?: ValidationError[] = [];

  constructor(error: FiyuValidationError) {
    super(error);
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FiyuValidationError.prototype);
  }
}
