import { formatInTimeZone } from 'date-fns-tz';
import { differenceInDays } from 'date-fns/differenceInDays';
import { format } from 'date-fns/format';
import { formatISO } from 'date-fns/formatISO';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { isValid } from 'date-fns/isValid';
import { enUS } from 'date-fns/locale/en-US';
import { parseISO } from 'date-fns/parseISO';
import { parseJSON } from 'date-fns/parseJSON';

export class DateUtility {
  static getDateTimeWithTimezone(date: Date): string {
    return `${format(date, 'yyyy-MM-DDTHH:mm:ss.SSS')}Z[${Intl.DateTimeFormat().resolvedOptions().timeZone}]`;
  }

  /**
   *
   * @static
   * @param {Date} [date=new Date()]
   * @param {string} [format='yyyy-MM-dd HH:mm:ss zzz'] // 2014-10-25 10:46:20 GMT+2
   * @return {*}  {string}
   * @memberof DateUtility
   */
  static formatInTimezone(date: Date = new Date(), format: string = 'yyyy-MM-dd HH:mm:ss zzz'): string {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const locale = navigator.languages[0] || 'en-US';
    return formatInTimeZone(date, timezone, format, { locale: enUS });
  }

  static compareDate(date1: Date, date2: Date, operator: string): boolean {
    if (operator === 'lt') {
      return this.isBefore(date1, date2);
    }

    if (operator === 'gt') {
      return this.isAfter(date1, date2);
    }

    if (operator === 'gtOrEqual') {
      return this.isSameOrAfter(date1, date2);
    }

    if (operator === 'ltOrEqual') {
      return this.isSameOrBefore(date1, date2);
    }

    throw new Error('Compare operator missing or Invalid!');
  }

  static isValidDate(date: string | Date): boolean {
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    const isValidDate = isValid(parsedDate);
    return isValidDate;
  }

  static getFormattedDate(date: Date, dateFormat: string): string {
    if (!date) date = new Date();
    if (typeof date === 'string') {
      date = this.parseIsoStringToDate(date);
    }

    return format(date, dateFormat);
  }

  static isBefore(date1: Date, date2: Date): boolean {
    return isBefore(date1, date2);
  }

  static isAfter(date1: Date, date2: Date): boolean {
    return isAfter(date1, date2);
  }

  static isSameOrBefore(date1: Date, date2: Date): boolean {
    return differenceInDays(date1, date2) <= 0;
  }

  static isSameOrAfter(date1: Date, date2: Date): boolean {
    return differenceInDays(date1, date2) >= 0;
  }

  static formatDateToIsoString(date: Date): string {
    if (typeof date === 'string') {
      date = this.parseIsoStringToDate(date);
    }
    return formatISO(date);
  }

  static parseIsoStringToDate(datestring: string): Date {
    return parseISO(datestring);
  }

  static parseJSON(string: string): Date | string {
    const val = String(string);
    if (!this.isValidDate(val)) {
      return val;
    }
    return parseJSON(val);
  }
}
