export const LC_STORAGE_TYPE = 'storage_type';
export const LC_USER = 'user';
export const LC_ACTIVE_ORGANIZATION_ID = 'active_organization_id';
export const LC_ORGANIZATIONS = 'organizations';
export const LC_ACTIVE_MODULE = 'activeModule';
export const LC_ACCESS_TOKEN = 'access_token';
export const LC_REFRESH_TOKEN = 'refresh_token';
export const LC_LANG = 'lang';
export const LC_THEME = 'theme';
export const LC_TOUR_SEEN = 'tour_seen';
