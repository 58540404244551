import {
  MODULE_METADATA_TOKEN,
  Operations,
  RequiredModulesConfig,
  SharedTranslateKeys,
  VALIDATION_ERRORS_TOKEN,
} from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const PlatformRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () => (await import('./platform-layout/platform-layout.component')).PlatformLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'user/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.User}`,
        loadComponent: async () => (await import('./users/user-form/user-form.component')).UserFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.USER_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'user',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.User}`,
        loadComponent: async () => (await import('./users/user-form/user-form.component')).UserFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.USER_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'users',
        title: SharedTranslateKeys.Users,
        loadComponent: async () => (await import('./users/user-list/user-list.component')).UserListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.USER_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'organization/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Organization}`,
        loadComponent: async () =>
          (await import('./organization/organization-form/organization-form.component')).OrganizationFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'organization',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Organization}`,
        loadComponent: async () =>
          (await import('./organization/organization-form/organization-form.component')).OrganizationFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'organizations',
        title: SharedTranslateKeys.Organizations,
        loadComponent: async () =>
          (await import('./organization/organization-list/organization-list.component')).OrganizationListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'organization-type/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.OrganizationType}`,
        loadComponent: async () =>
          (await import('./organization-type/organization-type-form/organization-type-form.component'))
            .OrganizationTypeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_TYPE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'organization-type',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.OrganizationType}`,
        loadComponent: async () =>
          (await import('./organization-type/organization-type-form/organization-type-form.component'))
            .OrganizationTypeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_TYPE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'organization-types',
        title: SharedTranslateKeys.OrganizationTypes,
        loadComponent: async () =>
          (await import('./organization-type/organization-type-list/organization-type-list.component'))
            .OrganizationTypeListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_TYPE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'module/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Module}`,
        loadComponent: async () => (await import('./modules/module-form/module-form.component')).ModuleFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.MODULE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'modules',
        title: SharedTranslateKeys.Modules,
        loadComponent: async () => (await import('./modules/module-list/module-list.component')).ModuleListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.MODULE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'role-template/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.RoleTemplate}`,
        loadComponent: async () =>
          (await import('./role-templates/role-template-form/role-template-form.component')).RoleTemplateFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ROLE_TEMPLATE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'role-template',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.RoleTemplate}`,
        loadComponent: async () =>
          (await import('./role-templates/role-template-form/role-template-form.component')).RoleTemplateFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ROLE_TEMPLATE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'role-templates',
        title: SharedTranslateKeys.RoleTemplates,
        loadComponent: async () =>
          (await import('./role-templates/role-template-list/role-template-list.component')).RoleTemplateListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ROLE_TEMPLATE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'landing',
        title: SharedTranslateKeys.Platform,
        loadComponent: async () =>
          (await import('./platform-landing/platform-landing.component')).PlatformLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.PLATFORM,
        requiredPermissions: [ModulePermissions.PLATFORM_RUN],
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
