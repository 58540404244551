import type { Type } from '@angular/core';
import type { Permissions } from '@fiyu/api';
import type { AbstractCRUD } from '../crud/abstract-crud.interface';
import type { AbstractEntity } from '../entity/abstract-entity.model';

export interface PresentationOptions {
  primary?: boolean;
  displayName?: string;
  tableDisplay?: boolean;
  quickViewDisplay?: boolean;
  quickViewPanelSectionDisplay?: boolean;
  /**
   * Factor used for configuring table column width
   */
  columnWidthPercentage?: number;

  /** Entity type option automatically filled using reflect metadata. */
  type?: string;
  hidden?: boolean;
  renderer?: string;
  useCustomComponent?: boolean;
  componentRenderType?: string;
  /**
   * Define what type is value provided top renderer function,
   * it can be row value (this property) or record, by default it's property value
   */
  rendererValueRecord?: boolean;
  rendererFunction?: () => void;
  rendererAsyncFunction?: () => void;

  /** Flag for disabling filter. Currently used to disable filtering by complex types*/
  disableFilter?: boolean;

  /** Flag for disabling sort. Currently used to disable sorting by complex types*/
  disableSort?: boolean;

  /** Used for displaying enum options table filter.
   * Used for rendering enum values
   * This property should be of same type as entity property. */
  enumModel?: any;

  /**
   * Used for columns with custom templating
   */
  useCustomTemplate?: boolean;

  /**
   * Marks property as a flag used to determine if row actions for that entity should be enabled
   */
  enableRowActionsProperty?: boolean;

  /**
   * Property is being used for autocomplete suggestions.
   * More than one property can be used for searching.
   * Used only for models that are defined as lookupModel with form type 'autocomplete'
   */
  suggestionSearch?: boolean;

  /**
   * Required permissions to see this property in table list
   */
  requiredPermissions?: string | Permissions;

  /**
   * model and service used for fetching from external service
   */

  lookupModel?: Type<AbstractEntity>;
  lookupService?: Type<AbstractCRUD>;
  customDateFormat?: string;
  criterionField?: string;
  isColumnFrozen?: boolean;
  columnFrozenAlignment?: 'left' | 'right';
}
