import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Module name
   */
  public moduleName = SharedTranslateKeys.Organization;

  /**
   * Module Icon
   */
  public moduleIcon = 'pi pi-fw pi-briefcase';
  /**
   * Core module flag
   */
  public isCore = true;

  /**
   * Short name
   */
  public moduleNameShort = 'Org';

  /**
   * Module description
   */
  public moduleDescription: string = SharedTranslateKeys.OrganizationModuleDescription;

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.ORGANIZATION;

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#D69451';
  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoDarkerColor = '#bf7a35';
  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.ORGANIZATION;

  /**
   * All possible permissions for this module
   */
  public modulePermissions = ModulePermissions;

  /**
   * Sidebar permissions
   */
  organizationPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.ORGANIZATION_VIEW],
    operation: Operations.AND,
  };
  employeesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.EMPLOYEE_VIEW],
    operation: Operations.AND,
  };
  preferencesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.ORGANIZATION_RUN, ModulePermissions.ORGANIZATION_PREFERENCE_VIEW],
    operation: Operations.AND,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems = [
    {
      label: SharedTranslateKeys.OrganizationModuleLabel,
      icon: 'pi pi-fw pi-briefcase',
      routerLink: '/organizations/organization',
      permissions: this.organizationPermissions,
      items: [
        {
          label: SharedTranslateKeys.Organization,
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/organization/organization',
          permissions: this.organizationPermissions,
        },
        {
          label: SharedTranslateKeys.Employees,
          icon: 'pi pi-fw pi-users',
          routerLink: '/organization/employees',
          permissions: this.employeesPermissions,
        },
        {
          label: SharedTranslateKeys.Preferences,
          icon: 'pi pi-fw pi-cog',
          routerLink: '/organization/preferences',
          permissions: this.preferencesPermissions,
        },
      ],
    },
    // {
    // 	label: 'Modules',
    // 	icon: 'fa fa-fw fa-rocket',
    // 	routerLink: '/organization/modules'
    // },
  ];
}
