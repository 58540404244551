import { registerLocaleData } from '@angular/common';
import type { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeHr from '@angular/common/locales/hr';
import localeIt from '@angular/common/locales/it';
import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import type { SelectItem } from 'primeng/api';
import { LC_LANG } from './../../models/storage/local-storage-keys';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  readonly #languageOptions: SelectItem[] = [
    { label: 'en', value: 'en', icon: 'gb' },
    { label: 'hr', value: 'hr', icon: 'hr' },
    { label: 'de', value: 'de', icon: 'de' },
  ];
  #locale: string;

  set locale(value: string) {
    this.setUserLocale(value);
    this.#locale = value;
  }
  get locale(): string {
    this.#locale = this.getUserLocale();
    return this.#locale;
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }
    this.locale = culture;

    // Register locale data since only the en-US locale data comes with Angular
    switch (culture) {
      case 'hr': {
        registerLocaleData(localeHr);
        break;
      }
      case 'de': {
        registerLocaleData(localeDe);
        break;
      }
      case 'fr': {
        registerLocaleData(localeFr);
        break;
      }
      case 'it': {
        registerLocaleData(localeIt);
        break;
      }
      default: {
        // registerLocaleData(localeEn);
        break;
      }
    }
  }

  getUserLocale(): string {
    return localStorage.getItem(LC_LANG) ?? 'en';
  }

  setUserLocale(locale: string): void {
    localStorage.setItem(LC_LANG, locale);
  }

  getLanguageOptions(): SelectItem[] {
    return this.#languageOptions;
  }

  getLanguageOptionByValue(value: string): SelectItem {
    return this.#languageOptions.find((option) => option.value === value);
  }
}

/**
 * Factory for creating new JSON file translation loader object
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/core/', '.json');
}
