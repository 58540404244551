import { HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ModulePrefixes, SKIP_CACHE_HEADER } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractCRUDService } from './../crud/abstract-crud.service';
import { SearchCriteria } from './../crud/search/search-criteria.type';
import type { SearchResult } from './../crud/search/search-result.type';
import { EnvironmentService } from './../environment/environment.service';
import { SecurityService } from './../security/security.service';
import type { Nullable } from './../types/nullable-type';
import type { CoreEmployee } from './core-employee.model';

@Injectable({
  providedIn: 'root',
})
export class CoreEmployeeService extends AbstractCRUDService {
  private readonly securityService: SecurityService = inject(SecurityService);
  constructor(envService: EnvironmentService) {
    super(`${envService.getModuleApiURL(ModulePrefixes.ORGANIZATION)}/general`);
  }

  override get<CoreEmployee>(searchCriteria: SearchCriteria): Observable<CoreEmployee[]> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/employees/active`, searchCriteria, this.getHttpOptions(SearchCriteria.contentType))
      .pipe(map((response: SearchResult<CoreEmployee>) => response.results as CoreEmployee[]));
  }

  /**
   * Get all active and inactive employees
   * @returns CoreEmployee array
   */
  getAllEmployees(): Observable<CoreEmployee[]> {
    return this.httpClient.get<CoreEmployee[]>(`${this.apiUrl}/employees`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  /**
   * Get all active employees
   * @returns CoreEmployee array
   */
  getAllActiveEmployees(): Observable<CoreEmployee[]> {
    return this.httpClient.get<CoreEmployee[]>(`${this.apiUrl}/employees/active`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  /**
   * Get all inactive employees
   * @returns CoreEmployee array
   */
  getAllInactiveEmployees(): Observable<CoreEmployee[]> {
    return this.httpClient.get<CoreEmployee[]>(`${this.apiUrl}/employees/inactive`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  /**
   * Get one employee by employee id
   * @param employeeId
   * @returns CoreEmployee object
   */
  getByEmployeeId(employeeId: string): Observable<CoreEmployee> {
    return this.httpClient.get<CoreEmployee>(`${this.apiUrl}/employee/${employeeId}`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  /**
   * Get one employee by userId
   * @param userId
   * @returns
   */
  getByUserId(userId: string): Observable<CoreEmployee> {
    return this.httpClient.get<CoreEmployee>(`${this.apiUrl}/employee/user/${userId}`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  /**
   * Determines if user is INTERNAL or EXTERNAL - userTypeClassification
   * @param employeeId
   * @returns Observable string
   */
  getEmployeeClassification(employeeId?: string): Observable<Nullable<string>> {
    if (!employeeId) {
      employeeId = this.securityService.getDecodedAccessToken().id;
    }
    return this.getByEmployeeId(employeeId).pipe(
      map((result: CoreEmployee) => {
        return result.userTypeClassification;
      }),
    );
  }
}
