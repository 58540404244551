import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Admin module flag
   */
  public isAdmin = true;
  /**
   * Core module flag
   */
  public isCore = true;

  /**
   * Module name
   */
  public moduleName = SharedTranslateKeys.Platform;

  /**
   * Short name
   */
  public moduleNameShort = SharedTranslateKeys.Platform;

  /**
   * Module description
   */
  public moduleDescription = SharedTranslateKeys.PlatformModuleDescription;

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.PLATFORM;

  /**
   * Module Icon
   */
  public moduleIcon = 'pi pi-fw pi-cog';

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#D65151';

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoDarkerColor = '#b13c3c';

  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.PLATFORM;

  /**
   * Sidebar permissions
   */
  userManagementPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.USER_VIEW],
    operation: Operations.AND,
  };
  organizationPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.ORGANIZATION_VIEW, ModulePermissions.PLATFORM_RUN],
    operation: Operations.AND,
  };
  organizationsPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_VIEW],
    operation: Operations.AND,
  };
  organizationTypesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ORGANIZATION_TYPE_VIEW],
    operation: Operations.AND,
  };
  roleTemplatesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.ROLE_TEMPLATE_VIEW],
    operation: Operations.AND,
  };
  modulesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.PLATFORM_RUN, ModulePermissions.MODULE_VIEW],
    operation: Operations.AND,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems = [
    {
      id: 'platform-management',
      label: SharedTranslateKeys.PlatformModuleLabel,
      icon: 'pi pi-fw pi-cog',
      routerLink: '/platform/users',
      permissions: this.userManagementPermissions,
      items: [
        {
          id: 'users',
          label: SharedTranslateKeys.Users,
          icon: 'pi pi-fw pi-users',
          routerLink: '/platform/users',
          permissions: this.userManagementPermissions,
        },
        {
          id: 'platform-organizations',
          label: SharedTranslateKeys.Organizations,
          icon: 'pi pi-fw pi-sitemap',
          permissions: this.organizationPermissions,
          items: [
            {
              id: 'organizations',
              label: SharedTranslateKeys.Organizations,
              icon: 'pi pi-fw pi-sitemap',
              routerLink: '/platform/organizations',
              permissions: this.organizationsPermissions,
            },
            {
              id: 'organization-types',
              label: SharedTranslateKeys.OrganizationTypes,
              icon: 'pi pi-fw pi-cog',
              routerLink: '/platform/organization-types',
              permissions: this.organizationTypesPermissions,
            },
          ],
        },
        {
          id: 'role-templates',
          label: SharedTranslateKeys.RoleTemplates,
          icon: 'pi pi-fw pi-plus-circle',
          routerLink: '/platform/role-templates',
          permissions: this.roleTemplatesPermissions,
        },
        {
          id: 'modules',
          label: SharedTranslateKeys.Modules,
          icon: 'pi pi-fw pi-cog',
          routerLink: '/platform/modules',
          permissions: this.modulesPermissions,
        },
      ],
    },
  ];

  /**
   * Module permissions
   */
  public modulePermissions = ModulePermissions;
}
