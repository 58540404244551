import {
  HttpErrorResponse,
  type HttpEvent,
  type HttpHandlerFn,
  type HttpInterceptorFn,
  type HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError, type Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHeaders } from './../../errors/error-headers.enum';
import { FiyuException } from './../../errors/fiyu-exception';
import { FiyuValidationError } from './../../errors/validation/fiyu-validation-error';
import type { ValidationError } from './../../errors/validation/validation-error';
import { LogService } from './../../logging/log-service';

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const fiyuException: FiyuException = inject(FiyuException);
  const logService: LogService = inject(LogService);
  return next(req).pipe(
    catchError((error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        if (error.headers.get(ErrorHeaders.Name)) {
          const fiyuError = fiyuException.getByErrorName(
            error.headers.get(ErrorHeaders.Name),
            error.headers.get(ErrorHeaders.Message),
          );
          fiyuError.parameters = extractParams(error);

          if (fiyuError instanceof FiyuValidationError) {
            fiyuError.validationErrors = fiyuException.getValidationErrors(extractValidationErrors(error));
          }
          const parsedFiyuError: FiyuValidationError = JSON.parse(JSON.stringify(fiyuError));
          parsedFiyuError.message = error.headers.get(ErrorHeaders.Message) || error.message;
          parsedFiyuError.status = error.status;
          parsedFiyuError.validationErrors =
            fiyuError instanceof FiyuValidationError
              ? fiyuException.getValidationErrors(extractValidationErrors(error))
              : null;
          logService.logError(parsedFiyuError.message);
          // throw fiyuError;
          return throwError(() => parsedFiyuError);
        }
      }
      return throwError(() => error);
    }),
  );
};

const extractParams = (error: HttpErrorResponse): Object => {
  const params = error.headers.get(ErrorHeaders.Parameters) as any;
  return JSON.parse(params);
};

const extractValidationErrors = (error: HttpErrorResponse): ValidationError[] => {
  const info = error.headers.get(ErrorHeaders.Info) as any;
  return JSON.parse(info);
};
