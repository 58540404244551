import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { SKIP_CACHE_HEADER, type ModuleMetadataInterface } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { SuggestionSearchParameters } from '../component-types/suggestion-search-parameters.type';
import type { AbstractEntity } from '../entity/abstract-entity.model';
import type { PresentationOptions } from '../entity/presentation-options.interface';
import { EnvironmentService } from '../environment/environment.service';
import { ObjectUtility } from '../utilities/object.utility';
import { SearchCriteriaUtility } from '../utilities/search-criteria.utility';
import type { AbstractCRUD } from './abstract-crud.interface';
import { ExportCriteria } from './export-import/export-criteria';
import { CompositionType } from './search/composition-type.enum';
import { CriterionType } from './search/criterion-type.enum';
import type { Criterion } from './search/criterion.type';
import { SearchCriteria } from './search/search-criteria.type';
import type { SearchResult } from './search/search-result.type';
import { SearchType } from './search/search-type.enum';
export abstract class AbstractCRUDService implements AbstractCRUD {
  private suggestionSearchCriteria: SearchCriteria = {};
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environmentService: EnvironmentService = inject(EnvironmentService);

  constructor(
    public apiUrl: string,
    protected moduleMetadata?: ModuleMetadataInterface,
    protected baseUrl?: string,
  ) {
    if (moduleMetadata) {
      this.baseUrl = this.environmentService?.getModuleApiURL(moduleMetadata.modulePrefix);
      this.apiUrl = `${this.baseUrl}${this.apiUrl}`;
    }
  }

  get<T extends AbstractEntity>(searchCriteria: SearchCriteria): Observable<T[]> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/list`, searchCriteria, this.getHttpOptions(SearchCriteria.contentType))
      .pipe(map((response: SearchResult<T>) => response.results as T[]));
  }

  /**
   * Accepts params as object and returns results
   *
   * @param {SearchCriteria} searchCriteria
   * @param {Record<string, any>} filterParams
   * @returs Observable<SearchResult<T>>
   */
  getWithParams<T extends AbstractEntity>(
    searchCriteria: SearchCriteria,
    filterParams: Record<string, any>,
  ): Observable<SearchResult<T>> {
    //  filterParams = JSON.parse(JSON.stringify(filterParams));
    const params = new HttpParams({ fromObject: filterParams });
    const { headers } = this.getHttpOptions(SearchCriteria.contentType);
    return this.httpClient.post<any>(`${this.apiUrl}/list`, searchCriteria, { params, headers });
  }

  /**
   * Returns search result containing requested entities and their total number.
   *
   * @param searchCriteria
   */
  getPage<T extends AbstractEntity>(searchCriteria: SearchCriteria): Observable<SearchResult<T>> {
    return this.httpClient.post<any>(
      `${this.apiUrl}/list`,
      searchCriteria,
      this.getHttpOptions(SearchCriteria.contentType),
    );
  }

  fullExportData(searchCriteria: SearchCriteria): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', SearchCriteria.contentType);
    return this.httpClient.post(`${this.apiUrl}/download`, searchCriteria, {
      headers: headers,
      observe: 'response' as 'body',
      responseType: 'blob',
    });
  }

  getAll<T extends AbstractEntity>(): Observable<T[]> {
    return this.get<T>(new SearchCriteria());
  }

  getOne<T extends AbstractEntity>(id: string, acceptType?: string): Observable<T> {
    return this.httpClient.get<T>(`${this.apiUrl}/${id}`, this.getHttpOptions('', acceptType));
  }

  create<T extends AbstractEntity>(entity: T, contentType: string): Observable<T> {
    return this.httpClient.post<T>(this.apiUrl, entity, this.getHttpOptions(contentType));
  }

  update<T extends AbstractEntity>(entity: T, contentType: string): Observable<T> {
    return this.httpClient.put<T>(this.apiUrl, entity, this.getHttpOptions(contentType));
  }

  /* @ts-ignore */
  delete<T extends AbstractEntity>(id: string): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }

  getMy<T extends AbstractEntity>(): Observable<T[]> {
    return this.httpClient
      .get<any>(`${this.apiUrl}/my`)
      .pipe(map((response: SearchResult<T>) => response.results as T[]));
  }

  /**
   * Export import methods
   */
  getExportConfiguration(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/export`);
  }

  exportTableData(exportFields: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', ExportCriteria.contentType);
    return this.httpClient.post(`${this.apiUrl}/export`, exportFields, {
      headers: headers,
      responseType: 'blob',
    });
  }

  getImportTemplate(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/import`, {
      responseType: 'blob',
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  importTableData(files: any) {
    const formData: FormData = new FormData();
    formData.append('file', files[0], files[0].name);

    return this.httpClient.post(`${this.apiUrl}/import`, formData);
  }

  /**
   * Get suggestions SelectItem[], search based on entity
   * @deprecated
   * @param entityType
   * @param searchParameters
   * @param skipMappingToSelectItem optional
   * @param additionalCriterion optional
   */
  getSuggestions<T extends AbstractEntity>(
    entityType: new () => T,
    searchParameters: SuggestionSearchParameters,
    _skipMappingToSelectItem?: boolean,
    additionalCriterion?: Criterion,
  ): Observable<SearchResult<any>> {
    if (!this.suggestionSearchCriteria.criterion) {
      this.createSuggestionsSearchCriteria(entityType);
    }

    this.setSuggestionSearchParameters(searchParameters);

    let searchCriteria = this.suggestionSearchCriteria;

    if (additionalCriterion) {
      searchCriteria = ObjectUtility.copy<SearchCriteria>(this.suggestionSearchCriteria) as SearchCriteria;
      SearchCriteriaUtility.addCriterion(additionalCriterion, searchCriteria);
    }

    return this.httpClient.post<any>(
      `${this.apiUrl}/suggestions`,
      searchCriteria,
      this.getHttpOptions(SearchCriteria.contentType),
    );
  }

  /**
   * Get suggestions SelectItem[], search based on entity
   *
   * @param entityType
   * @param searchParameters
   * @param skipMappingToSelectItem optional
   * @param additionalCriterion optional
   */
  /* @ts-ignore */
  getSuggestionsByList<T extends AbstractEntity>(
    entityType: any,
    searchParameters: SuggestionSearchParameters,
    skipMappingToSelectItem?: boolean,
    additionalCriterion?: Criterion,
  ): Observable<SearchResult<any>> {
    if (!this.suggestionSearchCriteria.criterion) {
      this.createSuggestionsSearchCriteria(entityType);
    }

    this.setSuggestionSearchParameters(searchParameters);

    let searchCriteria = this.suggestionSearchCriteria;

    if (additionalCriterion) {
      searchCriteria = ObjectUtility.copy<SearchCriteria>(this.suggestionSearchCriteria) as SearchCriteria;
      SearchCriteriaUtility.addCriterion(additionalCriterion, searchCriteria);
    }

    return this.httpClient
      .post<any>(`${this.apiUrl}/list`, searchCriteria, this.getHttpOptions(SearchCriteria.contentType))
      .pipe(
        map((searchResult) => {
          if (!skipMappingToSelectItem) {
            searchResult.results = searchResult.results.map((item: any) => new entityType(item).getSelectItem());
          }
          return searchResult;
        }),
      );
  }

  /**
   * Creates search criteria for searching by multiple entity fields or a single entity field
   */
  private createSuggestionsSearchCriteria<T extends AbstractEntity>(entityType: new () => T) {
    const entity = new entityType();

    this.suggestionSearchCriteria.criterion = {
      type: SearchType.Composite,
      compositionType: CompositionType.Or,
      criterions: [],
    };

    entity.getPresentationConfiguration().forEach((propertyOptions: PresentationOptions, propertyName: string) => {
      if (propertyOptions.suggestionSearch) {
        this.suggestionSearchCriteria?.criterion?.criterions?.push({
          type: SearchType.Simple,
          propertyPath: propertyName,
          criterionType: CriterionType.IBegins,
        });
      }
    });

    if (!this.suggestionSearchCriteria?.criterion?.criterions?.length) {
      this.suggestionSearchCriteria = {
        criterion: {
          type: SearchType.Simple,
          propertyPath: entity.getLabelField(),
          criterionType: CriterionType.IBegins,
        },
      };
    }

    this.setSorting(entity);
  }

  /**
   * Sets value that search criteria uses to search by
   */
  private setSuggestionSearchParameters(searchParameters: SuggestionSearchParameters) {
    if (searchParameters.searchQuery) {
      this.setSearchQuery(searchParameters.searchQuery);
    } else {
      delete this.suggestionSearchCriteria.criterion;
    }

    if (searchParameters.suggestionPageTotalRows) {
      this.suggestionSearchCriteria.window = {
        firstResult: searchParameters.suggestionPageStartingRow as number,
        maxResults: searchParameters.suggestionPageTotalRows,
      };
    }
  }

  private setSearchQuery(searchQuery: string) {
    const { criterion } = this.suggestionSearchCriteria;

    if (criterion?.criterions) {
      criterion.criterions.forEach((crit: Criterion) => (crit.propertyValue = searchQuery));
    } else {
      criterion.propertyValue = searchQuery;
    }
  }

  private setSorting<T extends AbstractEntity>(entity: T) {
    const sortFieldName: string = entity.getSortField();
    const sortOrder: string = entity.getSortOrder();

    if (sortFieldName) {
      this.suggestionSearchCriteria.sortProperties = {
        [sortFieldName]: sortOrder,
      };
    }
  }

  /**
   * append all params
   * @param args {unknown[]}
   * @returns {HttpParams}
   */
  appendAllParams(...args: unknown[]): HttpParams {
    let params = new HttpParams();
    args.forEach((param: any) => {
      Object.keys(param).forEach((key: string): void => {
        params = params.append(key, param[key]);
      });
    });
    return params;
  }

  getBaseUrl(): any {
    return this.baseUrl;
  }

  protected getHttpOptions(contentType?: string, acceptType?: string) {
    const headers: Record<string, any> = {};

    if (contentType) {
      headers['Content-type'] = contentType;
    }

    if (acceptType) {
      headers['Accept'] = acceptType;
    }

    if (contentType || acceptType) {
      return { headers: new HttpHeaders(headers), context: new HttpContext().set(SKIP_CACHE_HEADER, true) };
    } else {
      return { context: new HttpContext().set(SKIP_CACHE_HEADER, true) };
    }
  }
}
