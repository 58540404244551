import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';
import { OrganizationUserType } from '../organization-user-types/organization-user-type.model';
import { OrganizationUserTypeService } from '../organization-user-types/organization-user-type.service';
import { UserService } from '../user.service';
import { EmployeeListItem } from './employee-list.model';
import { EmployeeService } from './employee.service';

export class UserAdminNestedOrganization extends AbstractEntity {
  @FormEntity()
  name: string;
  @FormEntity()
  active: boolean;
  @FormEntity()
  organizationUserId: string;
  @FormEntity({
    arrayValueType: String,
  })
  roles: any[] = [];
}

export class UserAdmin extends AbstractEntity {
  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.FirstName,
    required: true,
  })
  firstName: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.LastName,
    required: true,
  })
  lastName: string;

  @PresentationEntity()
  fullName: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Username,
    required: true,
  })
  userName: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Email,
    required: true,
    validators: {
      email: true,
    },
  })
  email: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.IsSuperadmin,
  })
  superAdmin: boolean;

  verified: boolean;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.ActiveOnPlatform,
    required: true,
  })
  enabled: boolean;

  @FormEntity({
    arrayValueType: UserAdminNestedOrganization,
    label: SharedTranslateKeys.ListOfOrganizations,
    render: false,
  })
  organizations: UserAdminNestedOrganization[] = [];

  @FormEntity({
    render: false,
  })
  override deleted: Date = new Date();
}

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.OrganizationEmployee,
  pluralName: SharedTranslateKeys.OrganizationEmployees,
  singularUrlName: 'employee',
  pluralUrlName: 'employees',
  contentType: 'application/user.organization.v1+json',
  labelField: 'fullName',
  defaultSort: { field: 'fullName', order: SortDirection.Ascending, type: 'string' },
  permissionPrefix: ModulePermissions.ORGANIZATION,
})
export class Employee extends AbstractEntity {
  @FormEntity({
    label: SharedTranslateKeys.FullName,
    // transient: true,
    required: false,
    readonly: true,
    renderOnCreate: false,
  })
  fullName: string;

  @FormEntity({
    label: SharedTranslateKeys.UserInPlatform,
    formType: FormControlType.DropdownFilter,
    placeholder: SharedTranslateKeys.StartTyping,
    lookupModel: EmployeeListItem,
    lookupService: UserService,
    required: true,
    renderOnEdit: false,
    lookupEmpty: false,
  })
  userId: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    label: SharedTranslateKeys.JobPosition,
    placeholder: SharedTranslateKeys.ChooseUserType,
    lookupEmpty: false,
    lookupModel: OrganizationUserType,
    lookupService: OrganizationUserTypeService,
    required: true,
  })
  userTypeId: number;

  @FormEntity({
    label: SharedTranslateKeys.Superior,
    formType: FormControlType.DropdownFilter,
    placeholder: SharedTranslateKeys.StartTyping,
    required: false,
    lookupEmpty: false,
    lookupService: EmployeeService,
    lookupModel: EmployeeListItem,
  })
  superior: string;

  @FormEntity({
    required: true,
    label: SharedTranslateKeys.Email,
    validators: {
      email: true,
    },
  })
  email: string;

  @FormEntity({
    label: SharedTranslateKeys.PhoneNumber,
    placeholder: SharedTranslateKeys.EnterPhoneNumber,
    validators: {
      phoneNumber: true,
    },
  })
  phoneNumber: string;

  @FormEntity({
    label: SharedTranslateKeys.InternalUsername,
    placeholder: SharedTranslateKeys.EnterInternalUsername,
    required: true,
  })
  internalUsername: string;

  @FormEntity({
    label: SharedTranslateKeys.InternalCode,
    placeholder: SharedTranslateKeys.EnterInternalCode,
  })
  internalCode: string;

  @FormEntity({
    formType: FormControlType.DateTime,
    label: SharedTranslateKeys.EmploymentStartsFrom,
    required: true,
  })
  validFrom: Date;

  @FormEntity({
    formType: FormControlType.DateTime,
    label: SharedTranslateKeys.EmploymentEndsOn,
  })
  validTo: Date;

  @FormEntity({
    label: SharedTranslateKeys.IsActiveEmployment,
  })
  active: boolean;
}
