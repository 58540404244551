export class ObjectUtility {
  /**
   * Recursively deep copies object with any type
   * @param obj
   */
  static copy<T>(obj: T[] | T): T[] | T {
    if (obj === null) {
      return obj;
    }
    const emptyArray: T[] = [];
    const emptyObject: T = {} as T;
    const arrayCopy: T[] | T = Array.isArray(obj) ? emptyArray : emptyObject;
    for (const key of Object.keys(obj)) {
      const value: any = (<any>obj)[key];
      (<any>arrayCopy)[key] = typeof value === 'object' && value !== null ? this.copy(value) : value;
    }
    return arrayCopy;
  }

  /**
   * Check is parameter object
   * @param obj
   */
  static isObject<T>(obj: T): boolean {
    return obj === Object(obj);
  }

  static removeProp(obj: any, removePropKey: string): Record<any, any> {
    const { [removePropKey]: remove, ...rest } = obj;
    return rest;
  }
}
