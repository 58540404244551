import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { ModuleMetadataInterface } from '@fiyu/api';
import { of, type Observable } from 'rxjs';
import { EnvironmentService } from '../../environment';
import type { GitRepositoryState } from './git-repository-state';

/**
 * Responsible for returning version info.
 * Version info have frontend info with date when is created
 * and backed info with date when is build.
 *
 * Frontend info is read from git-version.json (generated using npm run version)
 * From more info see package.json file version script
 *
 * Backend info is fetched from endpoint [moduleName]/module/[apiUrlPostfix]
 * where default value for apiUrlPostfix is info.
 * Note if you change endpoint it must be update on backend also
 *
 * By default version info is displayed on landing page using AbstractModuleMetadataService
 * If you want version info somewhere else there are two options.
 *      1. Use predefined fiyu-version component
 *      2. Create you own component and fetch data using this service
 *
 *
 */
@Injectable({
  providedIn: 'root',
})
export class VersionInfoService {
  gitRepositoryState: GitRepositoryState = {} as GitRepositoryState;
  protected readonly environmentService: EnvironmentService = inject(EnvironmentService);
  protected readonly httpClient: HttpClient = inject(HttpClient);

  /**
   * Responsible for returning version info.
   * Version info have frontend info with date when is created
   * and backed info with date when is build.
   *
   * Frontend info is read from git-version.json (generated using npm run version)
   * Version is read from filed version if not set then then is set to ''
   *
   * Backend info is fetched from endpoint [moduleName]/module/[apiUrlPostfix]
   * where default value for apiUrlPostfix is info.
   * Version is read from filed tags if not set then closestTagName if not set then is set to ''
   *
   * @param moduleMetadata: ModuleMetadataInterface, must be not null, used to set moduleName in endpoint
   * @param gitInfo path to git-version.json file. File is generated using npm run version, If null method will not
   * return frontend version info
   * @param apiUrlPostfix default value is info. If you change default value backend must be also changed
   * @returns Observable<string> format is FE [major].[minor].[patch] [created] BE [major].[minor].[patch] [buildTime]
   * @throws error Version not available
   */
  public getVersionInfo(
    _moduleMetadata: ModuleMetadataInterface,
    _gitInfo?: any,
    _apiUrlPostfix = 'info',
  ): Observable<string> {
    /*   // get FE version
    let fullVersion = '';
    if (gitInfo && gitInfo.semver && gitInfo.semver.version && gitInfo.created) {
      const feVersionInfo = `FE: ${gitInfo.semver.version}  ${gitInfo.created} `;
      fullVersion = feVersionInfo;
    }

    // get BE version
    const baseUrl = this.environmentService?.getModuleApiURL(moduleMetadata.modulePrefix);
    const apiUrl = `${baseUrl}/module/${apiUrlPostfix}`;

    const versionInfo = new Observable<string>((observer) => {
      this.httpClient
        .get<GitRepositoryState>(apiUrl)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (res) => {
            this.gitRepositoryState = res;

            if (this.gitRepositoryState.tags) {
              fullVersion = fullVersion + `BE: ${this.gitRepositoryState.tags} `;
            } else if (this.gitRepositoryState.closestTagName) {
              fullVersion = fullVersion + `BE: ${this.gitRepositoryState.closestTagName} `;
            }

            if (this.gitRepositoryState.buildTime && fullVersion.indexOf('BE:') !== -1) {
              fullVersion += this.gitRepositoryState.buildTime.toString().substring(0, 10);
            }

            observer.next(fullVersion);
          },
          error: (error: unknown) => {
            observer.error(`Version not available ${error}`);
          },
        });

      return {
        unsubscribe() {
          fullVersion = '';
        },
      };
    }); */

    const versionInfo = '';

    return of(versionInfo);
  }
}
