export enum ToastKeys {
  /**
   * bottom center toast key
   */
  FiyuErrorKey = 'fiyuErrorKey',
  /**
   * top right toast key
   */
  FiyuKey = 'fiyuKey',
}
