import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BYPASS_ORGANIZATION_HEADER, LC_ACCESS_TOKEN, LC_REFRESH_TOKEN, ModulePrefixes, WINDOW } from '@fiyu/api';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, ReplaySubject, Subject, throwError, type Observable } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { StorageService } from '../core/storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { DateUtility } from './../utilities/date.utility';
import type { AccessToken } from './access-token.model';
import { EncryptService } from './encrypt.service';
import type { ForgotPassword, ResetPassword } from './forgot-password.model';
import type { RefreshToken } from './refresh-token.model';
import type { SecurityToken } from './security-token.model';

// const FMS_MODULE_PREFIX = 'fsm';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly envService: EnvironmentService = inject(EnvironmentService);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly router: Router = inject(Router);
  private readonly encryptService: EncryptService = inject(EncryptService);
  private readonly window: Window = inject(WINDOW);
  public loginUrl = '/oauth/token';
  public azureLoginUrl = '/azure/oauth2/token';
  public logoutUrl = '/logout';
  public publicKeyUrl = '/public-key';
  public forgotPasswordUrl = '/profile/forgotPassword';
  public resetPasswordUrl = '/profile/password';
  private readonly accessToken$: BehaviorSubject<AccessToken | null> = new BehaviorSubject(
    this.getDecodedAccessTokenOrNull(),
  );
  private readonly purge$: Subject<void> = new Subject();

  /**
   * Authenticate user on the platform
   *
   * @param username
   * @param password
   * @param rememberMe
   */
  login(username: string, password: string, rememberMe: boolean): Observable<SecurityToken> {
    // set property where security data should be stored, in local or session storage
    this.storageService.setLocal(rememberMe);

    const headers = new HttpHeaders({
      Authorization: `Basic ${this.window.btoa(`${this.envService.jwtClientId}:${this.envService.jwtClientSecret}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    /**
     * Get public key for password encryption and post login data
     */
    return this.getPublicKey().pipe(
      switchMap((key) => {
        const body = `username=${username}&password=${encodeURIComponent(
          this.encryptService.encrypt(password, key),
        )}&grant_type=password`;

        return this.postLoginData(body, headers);
      }),
    );
  }

  /**
   * Post login data to be
   * @param body
   * @param headers
   */
  private postLoginData(body: string, headers: HttpHeaders): Observable<SecurityToken> {
    return this.httpClient
      .post<SecurityToken>(`${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.loginUrl}`, body, {
        headers,
      })
      .pipe(
        map((res) => {
          const decodedToken = this.decodeToken(res.access_token as any) as AccessToken;

          if (!this.hasOrganization(decodedToken)) {
            console.error('Insufficient permissions!');
          }
          // set tokens to local storage
          this.setLocalData(res);
          return res;
        }),
        share({
          connector: () => new ReplaySubject(3),
          resetOnComplete: false,
          resetOnError: false,
          resetOnRefCountZero: false,
        }),
      );
  }

  private hasOrganization(decodedToken: AccessToken): boolean {
    return Object.keys(decodedToken.organizations)?.length > 0;
  }
  /**
   * Get public key for password encryption
   */
  getPublicKey(): Observable<string> {
    return this.httpClient.get(`${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.publicKeyUrl}`, {
      responseType: 'text',
      context: new HttpContext().set(BYPASS_ORGANIZATION_HEADER, true),
    });
  }

  // OBSOLETE
  /*   deactivateFCMToken(): any {
    const fcmToken = sessionStorage.getItem('FCMToken');
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/registrationToken.v1+json',
      }),
      body: {
        token: fcmToken,
      },
    };

    return this.httpClient
      .delete(this.envService.getModuleApiURL(FMS_MODULE_PREFIX) + '/registrationTokens', options)
      .pipe(map((response: any) => response.json()));
  } */

  /**
   * Make user log out from platform
   */
  logout() {
    return this.httpClient.get(`${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.logoutUrl}`).pipe(
      map((res) => {
        this.removeLoginDataFromStorage();
        return res;
      }),
      catchError(async (error: unknown) => {
        this.removeLoginDataFromStorage();
        await this.router.navigateByUrl('/login');
        return throwError(() => error);
      }),
    );
  }

  /**
   * Get access token behavior observable
   */
  public getAccessTokenSource(): Observable<AccessToken | null> {
    return this.accessToken$;
  }

  /**
   * Get decoded access token observable
   */
  public getDecodedAccessTokenSource(): Observable<AccessToken | null> {
    // return this.accessToken$.map(token => this.decodeToken(token));
    return this.accessToken$;
  }

  /**
   * Get purge called source
   */
  public getPurgeSource(): Observable<void> {
    return this.purge$;
  }
  /**
   * Get access token as encoded string
   *
   *@returns string
   */
  public getAccessToken(): string | null {
    if (this.storageService.getItem(LC_ACCESS_TOKEN)) {
      return this.storageService.getItem(LC_ACCESS_TOKEN);
    } else {
      throw new Error('Access token does not exist.');
    }
  }

  /**
   * Get refresh token as encoded string
   *
   *@returns string
   */
  public getRefreshToken(): string | null {
    if (this.storageService.getItem(LC_REFRESH_TOKEN)) {
      return this.storageService.getItem(LC_REFRESH_TOKEN);
    } else {
      throw new Error('Refresh token does not exist.');
    }
  }

  /**
   * Get decoded access token
   */
  public getDecodedAccessToken(): AccessToken {
    const decoded = this.decodeToken(this.getAccessToken()) as AccessToken;
    // USER
    decoded.id = decoded.userId as string;
    return decoded;
  }

  /**
   * Get decoded access token. Doesn't throw
   */
  public getDecodedAccessTokenOrNull(): AccessToken | null {
    try {
      const decoded = this.decodeToken(this.getAccessToken()) as AccessToken;
      // USER
      decoded.id = decoded.userId as string;
      return decoded;
    } catch {
      return null;
    }
  }
  /**
   * Get decoded refresh token
   */
  public getDecodedRefreshToken(): RefreshToken {
    const decoded = this.decodeToken(this.getRefreshToken() as any);
    return decoded as RefreshToken;
  }

  /**
   * Check if user is logged in, does access token exist
   * and is refresh token expiration before now
   */
  public isLoggedIn() {
    try {
      return DateUtility.isBefore(new Date(), this.getRefreshExpiration());
    } catch (error) {
      return false;
    }
  }

  /**
   *  Remove local tokens, to ensure that next request
   *  should be forced to log in again
   */
  public removeLoginDataFromStorage() {
    this.storageService.removeItem(LC_ACCESS_TOKEN);
    this.storageService.removeItem(LC_REFRESH_TOKEN);
    this.purge$.next();
    this.accessToken$.next(null);
  }

  /**
   *
   */
  public doRefreshToken(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${this.window.btoa(`${this.envService.jwtClientId}:${this.envService.jwtClientSecret}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = `grant_type=refresh_token&refresh_token=${this.getRefreshToken()}`;

    return this.httpClient
      .post<SecurityToken>(`${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.loginUrl}`, body, {
        headers,
      })
      .pipe(
        map((res) => {
          this.setLocalData(res);
          return this.getAccessToken();
        }),
      );
  }

  /**
   * NOT IN USE
   * @returns Date
   */
  /*   private getAccessExpiration(): Date {
    const expiration = this.getDecodedAccessToken().exp;
    if (!expiration) {
      return;
    }
    const expiresAt = expiration * 1000;

    return new Date(expiresAt);
  } */

  /**
   * @returns Date
   */
  private getRefreshExpiration(): Date {
    const expiration = this.getDecodedRefreshToken().exp;
    const expiresAt = expiration * 1000;
    return new Date(expiresAt);
  }

  /**
   * Decode JWT token
   *
   * @param token
   */
  /* @ts-ignore */
  private decodeToken(token: string): Object {
    if (token) {
      try {
        return jwtDecode(token);
      } catch (Error) {
        console.error('Token is corrupted, it can not be decoded.');
      }
    } else {
      console.error('Token does not exist.');
    }
  }

  /**
   * Set access and refresh tokens to local storage
   *
   * @param securityToken
   */
  public setLocalData(securityToken: SecurityToken) {
    this.storageService.setItem(LC_ACCESS_TOKEN, securityToken.access_token);
    this.storageService.setItem(LC_REFRESH_TOKEN, securityToken.refresh_token);
    this.accessToken$.next(this.decodeToken(securityToken.access_token as string) as AccessToken);
  }

  forgotPassword(body: ForgotPassword): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/password.forgot.v1+json',
    });
    return this.httpClient.post(
      `${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.forgotPasswordUrl}`,
      body,
      { headers, context: new HttpContext().set(BYPASS_ORGANIZATION_HEADER, true) },
    );
    //{ params: new HttpParams().set('email', email) }
  }

  resetPassword(body: ResetPassword): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/password.update.v1+json',
    });
    return this.httpClient.put(
      `${this.envService.getModuleApiURL(ModulePrefixes.USER)}${this.resetPasswordUrl}`,
      body,
      { headers, context: new HttpContext().set(BYPASS_ORGANIZATION_HEADER, true) },
    );
  }
}
