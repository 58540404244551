export enum ModulePermissions {
  PLATFORM_RUN = 'PLATFORM_RUN',
  USER_VIEW = 'USER_VIEW',
  USER_EDIT = 'USER_EDIT',
  USER_CREATE = 'USER_CREATE',
  USER_DELETE = 'USER_DELETE',
  ORGANIZATION_USER_VIEW = 'ORGANIZATION_USER_VIEW',
  ORGANIZATION_USER_EDIT = 'ORGANIZATION_USER_EDIT',
  ORGANIZATION_CREATE = 'ORGANIZATION_CREATE',
  ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
  ORGANIZATION_DELETE = 'ORGANIZATION_DELETE',
  ROLE_TEMPLATE_VIEW = 'ROLE_TEMPLATE_VIEW',
  ROLE_TEMPLATE_EDIT = 'ROLE_TEMPLATE_EDIT',
  ORGANIZATION_TYPE_CREATE = 'ORGANIZATION_TYPE_CREATE',
  ORGANIZATION_TYPE_EDIT = 'ORGANIZATION_TYPE_EDIT',
  ORGANIZATION_TYPE_VIEW = 'ORGANIZATION_TYPE_VIEW',
  ORGANIZATION_TYPE_DELETE = 'ORGANIZATION_TYPE_DELETE',
  ORGANIZATION_SETTING_CREATE = 'ORGANIZATION_SETTING_CREATE',
  ORGANIZATION_SETTING_VIEW = 'ORGANIZATION_SETTING_VIEW',
  ORGANIZATION_SETTING_EDIT = 'ORGANIZATION_SETTING_EDIT',
  ORGANIZATION_SETTING_DELETE = 'ORGANIZATION_SETTING_DELETE',
  MODULE_CREATE = 'MODULE_CREATE',
  MODULE_EDIT = 'MODULE_EDIT',
  MODULE_VIEW = 'MODULE_VIEW',
  MODULE_DELETE = 'MODULE_DELETE',
  ROLE_TEMPLATE_CREATE = 'ROLE_TEMPLATE_CREATE',
  ROLE_TEMPLATE_DELETE = 'ROLE_TEMPLATE_DELETE',

  // Permission prefixes
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_USER = 'ORGANIZATION_USER',
  ORGANIZATION_TYPE = 'ORGANIZATION_TYPE',
  ROLE_TEMPLATE = 'ROLE_TEMPLATE',
  MODULE = 'MODULE',
}
