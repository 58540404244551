import { AbstractControl } from '@angular/forms';
import { StrictFormGroup } from '@fiyu/api';

export class FormUtility {
  /**
   * Removes form controls from form and saves them within a map.
   *
   * @param controlNameToFormControl map containing removed controls
   *  - key is control's name
   *  - value is the form control
   * @param formGroup form group containing all form controls
   * @param controlNames names of controls which should be removed
   */
  static removeControlsFromForm(
    controlNameToFormControl: Map<string, AbstractControl>,
    formGroup: StrictFormGroup<unknown>,
    controlNames: string[]
  ) {
    controlNames.forEach((controlName: string) => {
      const formControl: AbstractControl | null = formGroup.get(controlName);

      if (!formControl) {
        return;
      }

      if (!controlNameToFormControl.has(controlName)) {
        controlNameToFormControl.set(controlName, formControl);
      }

      formGroup.removeControl(controlName);
    });
  }

  /**
   * Retrieves removed form controls from map and adds them to form.
   *
   * @param controlNameToFormControl map containing removed controls
   *  - key is control's name
   *  - value is the form control
   * @param formGroup form group containing all form controls
   * @param controlNames names of controls which should be added
   */
  static addControlsToForm(
    controlNameToFormControl: Map<string, AbstractControl>,
    formGroup: StrictFormGroup<unknown>,
    controlNames: string[]
  ) {
    controlNames.forEach((controlName: string) => {
      if (formGroup.get(controlName)) {
        return;
      }

      const formControl: AbstractControl | undefined = controlNameToFormControl.get(controlName);

      if (!formControl) {
        return;
      }

      formGroup.setControl(controlName, formControl);
    });
  }

  /**
   * Modifies form group by adding missing and removing excessive form controls.
   *
   * @param allFormControls map containing all possible form controls that can be displayed on a page
   * @param formGroup main form group
   * @param desiredFormControlNames names of form controls that should be present on page
   */
  static setFormControls(
    allFormControls: Map<string, AbstractControl>,
    formGroup: StrictFormGroup<unknown>,
    desiredFormControlNames: string[]
  ) {
    const currentFormControlNames: string[] = Object.keys(formGroup.controls);
    const formControlNamesToAdd = this.getFormControlNamesToAdd(currentFormControlNames, desiredFormControlNames);
    const formControlNamesToRemove = this.getFormControlNamesToRemove(currentFormControlNames, desiredFormControlNames);

    this.addControlsToForm(allFormControls, formGroup, formControlNamesToAdd);
    this.removeControlsFromForm(allFormControls, formGroup, formControlNamesToRemove);
  }

  /**
   * Returns form control names that should be added to main form group.
   *
   * @param currentFormControlNames names of form controls that are currently present on page
   * @param desiredFormControlNames names of form controls that should be present on page
   */
  private static getFormControlNamesToAdd(
    currentFormControlNames: string[],
    desiredFormControlNames: string[]
  ): string[] {
    return desiredFormControlNames.filter((name) => {
      return !currentFormControlNames.includes(name);
    });
  }

  /**
   * Returns form control names that should be removed from main form group.
   *
   * @param currentFormControlNames names of form controls that are currently present on page
   * @param desiredFormControlNames names of form controls that should be present on page
   */
  private static getFormControlNamesToRemove(
    currentFormControlNames: string[],
    desiredFormControlNames: string[]
  ): string[] {
    return currentFormControlNames.filter((name) => {
      return !desiredFormControlNames.includes(name);
    });
  }
}
