import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { NotificationsModuleMetadataService } from './notifications-module-metadata.service';

export function provideNotificationsModuleProviders(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: 'NmmModuleMetadataService',
      useClass: NotificationsModuleMetadataService,
    },
  ]);
}
