import { sharedValidationErrors, ValidationError } from '@fiyu/core';

export const validationErrors: ValidationError[] = [
  ...sharedValidationErrors,
  {
    error: {
      key: 'ROLE_NAME_EXISTS',
    },
    message: 'Role name already exists!',
  },
];
