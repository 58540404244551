/**
 * Strongly typed version of simpleChanges for use in ngOnChanges lifecycle method.
 * @usageNotes
 * ```typescript
 * ngOnChanges(changes: NgChanges<AlertComponent>) {
 *  changes.type.currentValue.codePointAt;
 *  changes.disabled.currentValue
 * }
 * ```
 */
import { Subject } from 'rxjs';
export type NgChanges<Component extends object, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};
type MarkFunctionPropertyNames<Component> = {
  [Key in keyof Component]: Component[Key] extends Function | Subject<any> ? never : Key;
};
type ExcludeFunctionPropertyNames<T extends object> = MarkFunctionPropertyNames<T>[keyof T];
type ExcludeFunctions<T extends object> = Pick<T, ExcludeFunctionPropertyNames<T>>;
