import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_CACHE_HEADER } from '@fiyu/api';
import { AbstractCRUDService } from '@fiyu/core';
import { ModuleMetadata } from '../module-metadata';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends AbstractCRUDService {
  constructor() {
    super('/modules', ModuleMetadata.getInstance());
  }

  updateModule(moduleAlias: string) {
    return this.httpClient.get<Object>(`${this.environmentService.getModuleApiURL(moduleAlias)}/module/update`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  installModule(moduleAlias: string) {
    return this.httpClient.get<Object>(`${this.environmentService.getModuleApiURL(moduleAlias)}/module/install/start`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }
}
