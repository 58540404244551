export enum ModuleAliases {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  PLATFORM = 'PLATFORM',
  DOCUMENTS = 'DMM',
  NOTIFICATIONS = 'NMM',
  CRM = 'CRM',
  WORKSPACE = 'WORKSPACE',
}

export enum RequiredModulesConfig {
  USER = 'user',
  ORGANIZATION = 'organization',
  PLATFORM = 'platform',
  DOCUMENTS = 'dmm',
  NOTIFICATIONS = 'nmm',
  CRM = 'crm',
}

export enum ModulePrefixes {
  USER = 'user',
  ORGANIZATION = 'organization',
  NOTIFICATIONS = 'nmm',
  DOCUMENTS = 'dmm',
  PLATFORM = 'platform',
  CRM = 'crm',
}
