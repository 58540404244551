import { APP_INITIALIZER, inject, makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { FeatureFlagsFacadeService } from './../services/feature-flags/feature-flags-facade.service';
import type { FeatureFlags } from './../types/feature-flags';

function initializeFeatureFlags(): () => Promise<FeatureFlags> {
  const featureFlagsFacade: FeatureFlagsFacadeService = inject(FeatureFlagsFacadeService);

  return async (): Promise<FeatureFlags> => featureFlagsFacade.loadFeatureFlags();
}

export function provideFeatureFlags(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeFeatureFlags(),
      deps: [FeatureFlagsFacadeService],
      multi: true,
    },
  ]);
}
