import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import { SecurityService } from '../security/security.service';
import { CoreService } from './../core/core.service';
import type { LogFields } from './log-fields';
import { LogType } from './log-type';
import { Logger } from './logger';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private logger: Logger | null = null;
  private readonly url: string;
  private environmentType = '';
  /**
   *
   * core service is injected in order to obtain
   * essential user and session data (userID, username, active module)
   * environmentService - environment service is injected in order to
   * acquire environment data (url of the logstash server and information whether
   * the current environment is production or not..)
   */
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly environmentService: EnvironmentService = inject(EnvironmentService);
  private readonly securityService: SecurityService = inject(SecurityService);
  private readonly coreService: CoreService = inject(CoreService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.url = location.href;
    // Since logger can be called really early in the application initialization phase it is necessary
    // to check if the environment is loaded properly (environment is loaded by external method and
    // can be slower than some other components). Following lines of code ensure that environment
    // variables will be loaded only when the behavior subject indicates that it is done with
    // environment initialization.
    this.isEnvironmentReady()
      .pipe(takeUntilDestroyed())
      .subscribe((ready) => {
        if (ready) {
          this.environmentType = this.environmentService.environment;
          // Initializes Logger class with the application name and destination of the loki api url
          this.logger = new Logger(
            'Fiyu Application',
            this.environmentService.lokiLogUrl,
            this.httpClient,
            this.destroyRef,
          );
        }
      });
  }

  /**
   * Generates log message with type: WARNING
   * @param warningMessage - log message (string)
   */
  public logWarning(warningMessage: string) {
    if (!this.environmentService.logToLoki) {
      return;
    }
    const logFields: LogFields = this.setLogFields();
    this.logger?.log(LogType.WARNING, warningMessage, logFields);
  }

  /**
   * Generates log message with type: ERROR
   * @param errorMessage - log message (string)
   */
  public logError(errorMessage: string) {
    if (!this.environmentService.logToLoki) {
      return;
    }
    const logFields: LogFields = this.setLogFields();
    this.logger?.log(LogType.ERROR, errorMessage, logFields);
  }

  /**
   * Generates log message with type: INFO
   * @param info - log message (string)
   */
  public logInfo(info: string) {
    if (!this.environmentService.logToLoki) {
      return;
    }
    const logFields: LogFields = this.setLogFields();
    this.logger?.log(LogType.INFO, info, logFields);
  }

  /**
   * Provides a BehaviorSubject that is used to check whether the environment initialization is done.
   */
  private isEnvironmentReady(): BehaviorSubject<boolean> {
    return this.environmentService.readyObservable;
  }

  /**
   * Checks if user is logged in, and fills in data accordingly.
   */
  private setLogFields(): LogFields {
    let userId = 'guest';
    let userName = 'guest';
    let moduleName: any = 'Not logged in.';
    const date = new Date(Date.now());
    if (this.securityService.isLoggedIn()) {
      try {
        userId = this.coreService.getUserId();
      } catch (e) {
        this.logger?.log(LogType.ERROR, 'User id could not be fetched.', {
          userId: '',
          userName,
          moduleName,
          url: this.url,
          environmentType: this.environmentType,
          date,
        });
      }
      try {
        userName = this.coreService.getUser().userName;
      } catch (e) {
        this.logger?.log(LogType.ERROR, 'User name could not be fetched from local storage.', {
          userId,
          userName,
          moduleName,
          url: this.url,
          environmentType: this.environmentType,
          date,
        });
      }
      try {
        moduleName = this.coreService.getActiveModule();
      } catch (e) {
        this.logger?.log(LogType.ERROR, 'Active module could not be fetched.', {
          userId,
          userName,
          moduleName,
          url: this.url,
          environmentType: this.environmentType,
          date,
        });
      }
    }
    return {
      userId,
      userName,
      moduleName,
      url: this.url,
      environmentType: this.environmentType,
      date,
    };
  }
}
