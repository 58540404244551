import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SharedTranslateKeys } from '@fiyu/api';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import autoTable, { type CellHookData } from 'jspdf-autotable';
import { catchError, firstValueFrom, map, throwError, type Observable } from 'rxjs';
import { TranslateKeys } from './../module-translate-keys';
import type { Invoice } from './summary-list-item.model';

@Injectable({
  providedIn: 'root',
})
export class SummaryInvoicePDFService {
  document: jsPDF;
  textY = 8;
  lineWidth = 0;
  public translateKeys = TranslateKeys;
  public sharedTranslateKeys = SharedTranslateKeys;
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly httpClient: HttpClient = inject(HttpClient);
  // private readonly destroyRef: DestroyRef = inject(DestroyRef);

  async generateSummaryInvoice(data: Invoice) {
    this.document = new jsPDF();
    await this.addFonts();
    this.textY = 8;
    this.createHeader(data);
    this.createBody(data);
    this.createFooter(data);
    this.document.save(`Serengeti-Invoice-${data.invoiceInfo.id}.pdf`);
  }

  async addFonts() {
    const fontUrl = 'assets/fonts/CalibriRegular.ttf';
    const boldFontUrl = 'assets/fonts/CalibriBold.ttf';
    const font$ = this.loadFontAsBinaryString(fontUrl);
    const font = await firstValueFrom(font$);
    this.document.addFileToVFS('CalibriRegular.ttf', font);
    this.document.addFont('CalibriRegular.ttf', 'Calibri', 'normal');
    this.document.setFont('Calibri', 'normal');
    const boldFont$ = this.loadFontAsBinaryString(boldFontUrl);
    const boldFont = await firstValueFrom(boldFont$);
    this.document.addFileToVFS('CalibriBold.ttf', boldFont);
    this.document.addFont('CalibriBold.ttf', 'Calibri', 'bold');
  }

  loadFontAsBinaryString(fontUrl: string): Observable<string> {
    return this.httpClient.get(fontUrl, { responseType: 'arraybuffer' }).pipe(
      map((response: ArrayBuffer) => {
        const binaryArray = new Uint8Array(response);
        let binaryString = '';
        for (let i = 0; i < binaryArray.length; i++) {
          binaryString += String.fromCharCode(binaryArray[i]);
        }
        return binaryString;
      }),
      catchError((error: unknown) => {
        console.error('Error loading font', error);
        return throwError(() => error);
      }),
    );
  }

  createHeader(data: Invoice) {
    const logoImage = 'assets/images/serengeti-tech-logo.png';
    // Set header properties
    const headerFontSize = 9;
    const logoWidth = 60;
    const logoHeight = 15;
    // Add logo to the header
    const logoX = 10;
    const logoY = 10;
    this.document.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
    // Add company information
    const pageWidth: number = this.document.internal.pageSize.getWidth();
    const textX = pageWidth - 55;
    this.document.setFontSize(headerFontSize);
    Object.values(data.companyInfo).forEach((val: string) => {
      this.document.text(val, textX, this.textY);
      this.textY += 4;
    });
  }

  addCustomerInformation(data: Invoice) {
    const customerLabel: string = this.translateService.instant(this.translateKeys.Customer);
    this.textY += 5;
    // Set line properties
    this.lineWidth = this.document.internal.pageSize.getWidth(); // Line width equal to document width
    const lineHeight = 0.5; // Line height in points
    // Add the line
    this.document.setFontSize(8);
    this.document.text(customerLabel, 10, this.textY);
    this.document.setLineWidth(lineHeight);
    this.document.line(10, 50, this.lineWidth - 10, 50);
    // Customer's information
    this.textY += 5;
    this.document.setFontSize(10);
    this.document.text(data.customerInfo.name, 10, this.textY);
    this.document.text(data.customerInfo.OIB, 10, this.textY + 5);
    this.document.text(data.customerInfo.customerAddress, 10, this.textY + 10);
    this.document.text(data.customerInfo.postalCode, 10, this.textY + 15);
    this.document.text(data.customerInfo.municipality, 35, this.textY + 15);

    // ************************details********************************************************
    this.textY += 25;
    const dateOfPayment = `${this.translateService.instant(this.translateKeys.DateOfPayment)}:`;
    const dateOfCurrency = `${this.translateService.instant(this.translateKeys.DateOfCurrency)}:`;
    const placeAndDate = `${this.translateService.instant(this.translateKeys.PlaceAndDate)}:`;
    const contract = `${this.translateService.instant(this.translateKeys.Contract)}:`;
    const orderForm = `${this.translateService.instant(this.translateKeys.OrderForm)}:`;
    const timeOfCreation = `${this.translateService.instant(this.translateKeys.CreatedAt)}:`;
    const internalNumber = `${this.translateService.instant(this.translateKeys.InternalNumber)}:`;
    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY,
      margin: {
        left: 9,
      },
      styles: {
        textColor: 'black',
        fontSize: 9,
        font: 'Calibri',
      },
      body: [
        [dateOfPayment, data.invoiceInfo.dateOfPayment],
        [dateOfCurrency, data.invoiceInfo.dateOfCurrency],
        [placeAndDate, data.invoiceInfo.placeAndDate],
      ],
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 35, fontStyle: 'bold' },
        1: { cellWidth: 'auto', fontSize: 10 },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 5;
      },
    });

    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY,
      margin: {
        left: 80,
      },
      styles: {
        textColor: 'black',
        fontSize: 9,
        font: 'Calibri',
      },
      body: [
        [contract, data.invoiceInfo.contract],
        [orderForm, data.invoiceInfo.orderForm],
      ],
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
      },
      columnStyles: {
        0: { cellWidth: 25, fontStyle: 'bold' },
        1: { cellWidth: 'auto', fontSize: 10 },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 5;
      },
    });
    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY,
      margin: {
        left: 140,
      },
      styles: {
        textColor: 'black',
        fontSize: 9,
        font: 'Calibri',
      },
      body: [
        [timeOfCreation, data.invoiceInfo.timeOfCreation],
        [internalNumber, data.invoiceInfo.internalNumber],
      ],
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 'auto', fontStyle: 'bold' },
        1: { cellWidth: 'auto', fontSize: 10 },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 5;
      },
    });
  }

  createInvoiceTitle(data: Invoice) {
    const invoiceTitle: string = this.translateService.instant(this.translateKeys.Invoice);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.textY = (this.document as any).lastAutoTable.finalY + 15;
    let lineHeight: number = 0.5; // Line height in points
    this.document.setFontSize(20);
    this.document.setFont('Calibri', 'bold');
    this.document.text(invoiceTitle, 10, this.textY);
    this.document.setFont('Calibri', 'normal');
    this.document.text(data.invoiceInfo.id, 40, this.textY);
    let startLineY: number = this.textY + 1;
    this.document.line(10, startLineY, this.lineWidth - 10, startLineY);
    this.document.setLineWidth(lineHeight);
    lineHeight = 1.5;
    startLineY++;
    this.document.setLineWidth(lineHeight);
    this.document.line(10, startLineY, 75, startLineY);
    lineHeight = 0.5;
    this.document.setLineWidth(lineHeight);
  }

  createInvoiceTable(data: Invoice) {
    // Define table headers and data
    const id: string = this.translateService.instant(this.translateKeys.Identification);
    const name: string = this.translateService.instant(this.translateKeys.ProductName);
    const quantity: string = this.translateService.instant(this.translateKeys.Quantity);
    const unit: string = this.translateService.instant(this.translateKeys.Unit);
    const price: string = this.translateService.instant(this.sharedTranslateKeys.Price);
    const rebate = `${this.translateService.instant(this.translateKeys.Rebate)} %`;
    const VAT = `${this.translateService.instant(this.translateKeys.VAT)} %`;
    const valueWithoutTax: string = this.translateService.instant(this.translateKeys.ValueNoVATAdded);
    const headers: unknown[][] = [[id, name, quantity, unit, price, rebate, VAT, valueWithoutTax]];
    const tableData: string[][] = [
      [
        data.invoiceInfo.identificationNumber,
        data.invoiceInfo.productName,
        data.invoiceInfo.quantity,
        data.invoiceInfo.measurementUnit,
        data.invoiceInfo.price,
        data.invoiceInfo.rebate,
        data.invoiceInfo.tax,
        data.invoiceInfo.valueNoTax,
      ],
    ];

    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY + 15,
      margin: {
        left: 10,
      },
      styles: {
        textColor: 'black',
        fontSize: 8,
        font: 'Calibri',
      },
      head: headers,
      body: tableData,
      headStyles: {
        halign: 'center',
        valign: 'middle',
        fillColor: [221, 222, 224],
        textColor: 'black',
        lineWidth: { top: 0.5, bottom: 0.5, right: 0.5 },
        lineColor: 'black',
        fontStyle: 'bold',
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        fillColor: [255, 255, 255],
        lineWidth: { top: 0.5, bottom: 0.5 },
        lineColor: 'black',
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 60 },
        2: { cellWidth: 20 },
        3: { cellWidth: 10 },
        4: { cellWidth: 15 },
        5: { cellWidth: 15 },
        6: { cellWidth: 15 },
        7: { cellWidth: 45, halign: 'right' },
      },
      willDrawCell: (value: CellHookData) => {
        if (value.section === 'head' && value.column.index === tableData[0].length - 1) {
          value.cell.styles.lineWidth = { top: 0.5, bottom: 0.5, right: 0 };
          value.cell.styles.halign = 'right';
        }

        if (value.column.index === 1) {
          value.cell.styles.halign = 'left';
        }
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.textY = (this.document as any).lastAutoTable.finalY - 15;
    const taxCode = `${this.translateService.instant(this.translateKeys.TaxNumber)}:`;
    const total = `${this.translateService.instant(this.translateKeys.Total)}:`;
    const PDV = `${this.translateService.instant(this.translateKeys.VAT)}:`;
    const totalToPay = `${this.translateService.instant(this.translateKeys.TotalToPay)}:`;
    const OIB = `${this.translateService.instant(this.translateKeys.OIB)}:`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.textY = (this.document as any).lastAutoTable.finalY + 1;
    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY,
      margin: {
        left: 9,
      },
      styles: {
        textColor: 'black',
        fontSize: 9,
        font: 'Calibri',
      },
      body: [
        [taxCode, data.invoiceInfo.taxCode],
        [OIB, data.invoiceInfo.VAT_value],
      ],
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
        fillColor: [255, 255, 255],
      },
      columnStyles: {
        0: { cellWidth: 25, fontStyle: 'bold' },
        1: { cellWidth: 25, fontSize: 10 },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 6;
      },
    });

    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY,
      margin: {
        left: 150,
      },
      styles: {
        textColor: 'black',
        fontSize: 9,
        font: 'Calibri',
      },
      body: [
        [total, data.invoiceInfo.total],
        [PDV, data.invoiceInfo.VAT_value],
        [totalToPay, data.invoiceInfo.totalToPay],
      ],
      bodyStyles: {
        halign: 'left',
        valign: 'middle',
        fillColor: [255, 255, 255],
        lineWidth: { top: 0.5, bottom: 0.5 },
        lineColor: 'black',
      },
      columnStyles: {
        0: { cellWidth: 25, fontStyle: 'bold' },
        1: { cellWidth: 25, fontSize: 10, halign: 'right' },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 6;
        if (value.row.index !== 2) {
          value.cell.styles.lineWidth = 0;
        }
      },
    });
  }

  createTaxInfoTable(data: Invoice) {
    const taxRates: string = this.translateService.instant(this.translateKeys.TaxRates);
    const base: string = this.translateService.instant(this.translateKeys.Base);
    const VAT: string = this.translateService.instant(this.translateKeys.VAT);
    const value: string = this.translateService.instant(this.translateKeys.Value);
    const headers: unknown[][] = [[taxRates, base, VAT, value]];
    const tableData: string[][] = [
      [data.invoiceInfo.productName, data.invoiceInfo.price, data.invoiceInfo.VAT_value, data.invoiceInfo.totalToPay],
    ];

    // Set tax table properties
    autoTable(this.document, {
      theme: 'plain',
      startY: 170,
      margin: {
        left: 10,
      },
      styles: {
        textColor: 'black',
        fontSize: 8,
        font: 'Calibri',
        fillColor: 'white',
      },
      head: headers,
      body: tableData,
      headStyles: {
        valign: 'middle',
        fillColor: [221, 222, 224],
        textColor: 'black',
        lineWidth: { top: 0.5, bottom: 0.5 },
        lineColor: 'black',
        fontStyle: 'bold',
      },
      bodyStyles: {
        valign: 'middle',
        fillColor: [255, 255, 255],
        lineWidth: { top: 0.5, bottom: 0.5 },
        lineColor: 'black',
      },
      columnStyles: {
        0: { cellWidth: 75 },
        1: { cellWidth: 20 },
        2: { cellWidth: 25 },
        3: { cellWidth: 70, halign: 'right' },
      },
      willDrawCell: (value: CellHookData) => {
        if (value.column.index === 3) {
          value.cell.styles.halign = 'right';
        }
      },
    });

    // *********************************invoice details********************************

    const methodOfPayment = `${this.translateService.instant(this.sharedTranslateKeys.PaymentMethod)}:`;
    const operatorCode = `${this.translateService.instant(this.translateKeys.OperatorCode)}:`;
    const responsiblePersonForInvoice = `${this.translateService.instant(
      this.translateKeys.ResponsiblePersonForInvoice,
    )}:`;
    const vatNumber = `${this.translateService.instant(this.translateKeys.VATNumber)}:`;
    const paymentInfoMessage: string = this.translateService.instant(this.translateKeys.PaymentInfoMessage);
    const signature = `${this.translateService.instant(this.translateKeys.Signature)}:`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.textY = (this.document as any).lastAutoTable.finalY;

    autoTable(this.document, {
      theme: 'plain',
      startY: this.textY + 1,
      margin: {
        left: 10,
      },
      styles: {
        textColor: 'black',
        fontSize: 7,
        font: 'Calibri',
      },

      bodyStyles: {
        fillColor: 255,
        halign: 'left',
        valign: 'middle',
      },
      body: [
        [methodOfPayment, data.invoiceInfo.paymentMethod],
        [operatorCode, data.invoiceInfo.operatorCode],
      ],
      columnStyles: {
        0: { cellWidth: 20, cellPadding: { top: 0, bottom: 0 } },
        1: { cellWidth: 'auto', cellPadding: { top: 0, bottom: 0 } },
      },
      willDrawCell: (value: CellHookData) => {
        value.row.height = 4;
      },
    });

    this.textY++;
    this.document.setFontSize(7);
    this.document.text(responsiblePersonForInvoice, 10, this.textY + 10);
    this.document.text(data.invoiceInfo.responsiblePerson, 55, this.textY + 10);

    this.document.setFontSize(8);
    this.document.text(paymentInfoMessage, 10, this.textY + 20);
    this.document.text(vatNumber, 10, this.textY + 25);

    // Add line for signature
    this.document.line(135, this.textY + 40, this.lineWidth - 10, this.textY + 40);
    this.document.setFontSize(7);
    this.document.text(signature, 135, this.textY + 43);
  }

  createFooter(data: Invoice) {
    const footerText: string = this.translateService.instant(this.translateKeys.InvoiceFooterMessage);
    const footerCompanyRegistrationText: string = this.translateService.instant(this.translateKeys.TaxFooterMessage);
    const footerCompanyRegistrationLogo = 'assets/images/tax-logo.png';
    const footerTextBoardMember = `${this.translateService.instant(this.translateKeys.BoardMember)}: ${
      data.invoiceInfo.memberOfBoard
    }`;
    const footerY: number = this.document.internal.pageSize.getHeight() - 13;

    this.document.setFontSize(8);
    this.document.line(10, footerY, this.lineWidth - 10, footerY);
    this.document.text(footerText, 20, footerY + 5);
    this.document.text(footerTextBoardMember, 20, footerY + 8);

    const x = 5; // X-coordinate of the starting point
    const y: number = this.document.internal.pageSize.height - 25; // Y-coordinate of the starting point
    const rotationAngle = 90; // Rotation angle in degrees
    this.document.setFontSize(7);
    this.document.addImage(footerCompanyRegistrationLogo, 'PNG', x + 2, y, 6, 6, null, null, 90);
    this.document.text(footerCompanyRegistrationText, x, y, { angle: rotationAngle });
  }

  createBody(data: Invoice) {
    this.addCustomerInformation(data);
    this.createInvoiceTitle(data);
    this.createInvoiceTable(data);
    this.createTaxInfoTable(data);
  }
}
