import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
  private readonly translateService = inject(TranslateService);
  private readonly title: Title = inject(Title);
  constructor() {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    // set title
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      const translatedTitle = this.hasMultipleTranslateKeys(title)
        ? this.translateMultipleKeys(title)
        : this.translateService.instant(title);
      this.title.setTitle(`FIYU - full code RAD platform | ${translatedTitle}`);
    }
  }

  private hasMultipleTranslateKeys = (str: string) => {
    const hasDots = str.match(/\./g);
    return hasDots && hasDots.length > 1;
  };

  private getMultipleTranslateKeys = (str: string) => {
    let translateKeysList: string[] = [];
    str = str.replace(/\s/g, ',');
    translateKeysList = str.split(',');
    return translateKeysList.map((item: string) => item.trim());
  };

  private translateMultipleKeys = (str: string) => {
    const translateKeysList = this.getMultipleTranslateKeys(str);
    return translateKeysList.map((item: string) => this.translateService.instant(item)).join(' ');
  };
}
