import { inject, Injectable } from '@angular/core';
import { hmac, pki, util } from 'node-forge';
import { EnvironmentService } from './../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  private readonly envService: EnvironmentService = inject(EnvironmentService);

  encrypt(text: string, key: string): string {
    const shouldEncrypt = this.envService.getShouldEncrypt();
    if (!shouldEncrypt) {
      return text;
    }
    // load public key from PEM-formatted string
    const publicKey = pki.publicKeyFromPem(key);

    // convert string to UTF-8 encoded bytes
    const buffer = util.createBuffer(text, 'utf8');
    const bytes = buffer.getBytes();

    // encrypt data with a public key using RSAES PKCS#1 v1.5
    const encrypted = publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5');

    // base64-encode encrypted data to send to server
    const b64Encoded = util.encode64(encrypted);

    return b64Encoded;
  }
  /**
   * @description encrypt text with key using HmacSHA256
   * @example this.encryptService.createHmacSHA256Signature(text, key)
   * @param text
   * @param key
   * @returns string // output: 6e286e47aade83ad3740a8bc83ef183ca69feb23e702ebe64add250760b30a62
   */
  createHmacSHA256Signature(text: string, key: string): string {
    // encrypt text with key using HmacSHA256
    const h = hmac.create();
    h.start('sha256', key);
    h.update(text);
    const byteBuffer = h.digest();
    // if needed to convert to base64
    // const byteString = byteBuffer.bytes();
    // const base64 = util.encode64(byteString); // 'bihuR6reg603QKi8g+8YPKaf6yPnAuvmSt0lB2CzCmI='

    // "hex" is the "plain text" value
    const hex = byteBuffer.toHex();
    return hex;
  }
}
