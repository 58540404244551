import { inject, Injectable, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  LC_ACTIVE_MODULE,
  LC_ACTIVE_ORGANIZATION_ID,
  LC_ORGANIZATIONS,
  LC_USER,
  ModuleAliases,
  PrimeNgThemeEnum,
  ThemeService,
  type ModuleMetadataInterface,
  type Profile,
  type ProfileOrganization,
} from '@fiyu/api';
import { BehaviorSubject, combineLatest, throwError, type Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import type { ModuleMetadataServiceInterface } from './../module/module-metadata-service.interface';
import type { TokenModule, TokenOrganization } from './../security/access-token.model';
import { SecurityService } from './../security/security.service';
import type { UserPreferenceDTO } from './../user-preference/user-preference-model';
import { UserPreferenceService } from './../user-preference/user-preference.service';
import { StringUtility } from './../utilities/string.utility';
import { LogoService } from './logo.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class CoreService {
  private readonly storage: StorageService = inject(StorageService);
  private readonly themeService: ThemeService = inject(ThemeService);
  private readonly logoService: LogoService = inject(LogoService);
  /**
   * Private BehaviorSubject fields holding core service values
   */
  private readonly user$: BehaviorSubject<Profile | undefined> = new BehaviorSubject<Profile | undefined>(
    this.getUser(),
  );

  /*   private userPermissions$: BehaviorSubject<string[] | undefined> = new BehaviorSubject<string[] | undefined>(
    undefined
  );
  private userModules$: BehaviorSubject<ModuleMetadataInterface | null> =
    new BehaviorSubject<ModuleMetadataInterface | null>(null); */

  private readonly userOrganizations$: BehaviorSubject<ProfileOrganization[]> = new BehaviorSubject<
    ProfileOrganization[]
  >(this.getUserOrganizations());
  private readonly activeModule$: BehaviorSubject<string> = new BehaviorSubject<string>(this.getActiveModule() as any);
  private readonly currentOrganization$: BehaviorSubject<ProfileOrganization | null | undefined> = new BehaviorSubject<
    ProfileOrganization | null | undefined
  >(this.getUserOrganization(this.storage.getItem(LC_ACTIVE_ORGANIZATION_ID)));
  private readonly currentOrganizationId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    this.storage.getItem(LC_ACTIVE_ORGANIZATION_ID),
  );
  private readonly injector: Injector = inject(Injector);
  // private readonly coreEmployeeService: CoreEmployeeService = inject(CoreEmployeeService);
  private readonly securityService: SecurityService = inject(SecurityService);
  private readonly userPreferenceService: UserPreferenceService = inject(UserPreferenceService);

  constructor() {
    this.securityService
      .getPurgeSource()
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.purgeSavedData();
      });
  }

  /**
   * Returns CoreUser object from local storage,
   * it is subset properties of User object from Auth module,
   * Notice: ID is user ID
   * @returns CoreUser
   */
  public getUser(): Profile {
    const userRaw: any = this.storage.getItem(LC_USER);
    return JSON.parse(userRaw) as Profile;
  }

  public getUserSource(): Observable<Profile> {
    return this.user$ as any;
  }

  /**
   * Get User ID (UUID) from token
   */
  public getUserId() {
    return this.securityService.getDecodedAccessToken().id;
  }

  /**
   * Save user object to local storage and emit changed value
   */
  public setUser(user: Profile): void {
    this.user$.next(user);
    this.storage.setItem(LC_USER, JSON.stringify(user));
  }

  /**
   * Remove user object form local storage
   */
  public removeUser(): void {
    this.user$.next(undefined);
    this.storage.removeItem(LC_USER);
  }

  /**
   * Get user permissions for active organization
   */
  public getUserPermissions(moduleName?: string): string[] {
    moduleName = moduleName ? moduleName.toUpperCase() : moduleName;

    if (moduleName === ModuleAliases.WORKSPACE.toUpperCase()) {
      return [];
    }

    const organizations = this.getAllUserPermissions();
    const organizationId: any = this.storage.getItem(LC_ACTIVE_ORGANIZATION_ID);

    if (organizations) {
      const organization = organizations?.find((org: TokenOrganization) => org.organizationId === organizationId);
      if (organization) {
        if (moduleName) {
          const orgModule = organization.modules.find((module: TokenModule) => module.alias === moduleName);
          if (orgModule) {
            return orgModule.permissions;
          } else {
            throw new Error(
              `Permissions with organization id: ${organizationId} and for module: ${moduleName},
							don't exist in list of user permissions.`,
            );
          }
        } else {
          return Object.values(organization.modules).map((module: TokenModule) => module.alias);
        }
      } else {
        throw new Error(
          `Permissions with organization id: ${organizationId}, don't exist in list of user permissions.`,
        );
      }
    } else {
      throw new Error(`User permissions not found.`);
    }
  }

  public getUserPermissionsSource(moduleName: string): Observable<string[]> | undefined {
    moduleName = moduleName ? moduleName.toUpperCase() : moduleName;
    if (moduleName === ModuleAliases.WORKSPACE.toUpperCase()) {
      /*@ts-ignore */
      return;
    }

    const orgId$ = this.getCurrentOrganizationIdSource();
    const orgs$ = this.getAllUserPermissionsSource();

    return combineLatest([orgId$, orgs$]).pipe(
      map((combined) => {
        const orgId = combined[0] as string;
        const organizations: TokenOrganization[] = combined[1];
        const organization = organizations?.find((org: TokenOrganization) => org.organizationId === orgId);
        if (organization) {
          const orgModule = organization.modules.find((module: TokenModule) => module.alias === moduleName);
          return orgModule.permissions;
        } else return null;
      }),
    );
  }

  /**
   * Get user modules for all organizations
   */
  public getUserOrganizationsWithModulesSource(): Observable<Map<string, ModuleMetadataInterface[]>> {
    return this.securityService.getDecodedAccessTokenSource().pipe(
      filter((token) => token != null),
      map((token) => {
        if (!token || !token.organizations) {
          return new Map<string, ModuleMetadataInterface[]>();
        }
        return this.mapOrganizationsAndModules(token.organizations);
      }),
    );
  }

  /**
   * Get user modules for organization
   */
  public getUserModules(organizationId: string): ModuleMetadataInterface[] {
    const permissionModules = Object.values(
      this.securityService
        .getDecodedAccessToken()
        .organizations?.find((org: TokenOrganization) => org.organizationId === organizationId)?.modules,
    ).map((module: TokenModule) => module.alias);

    return this.mapModules(permissionModules);
  }

  public getUserModulesSource(organizationId: string): Observable<ModuleMetadataInterface[]> {
    return this.securityService.getDecodedAccessTokenSource().pipe(
      filter((token) => token != null),
      map((token) => {
        if (
          !token ||
          !token.organizations ||
          !token.organizations?.find((org: TokenOrganization) => org.organizationId === organizationId)
        ) {
          return [];
        }
        const permissionModules = Object.values(
          token.organizations?.find((org: TokenOrganization) => org.organizationId === organizationId)?.modules,
        ).map((module: TokenModule) => module.alias);

        return this.mapModules(permissionModules);
      }),
    );
  }

  /**
   * Get organization user id
   */
  public getOrganizationUserId(organizationId: string): any {
    return this.securityService
      .getDecodedAccessToken()
      .organizations?.find((org: TokenOrganization) => org.organizationId === organizationId)?.organizationUserId;
  }

  /**
   * Get ModuleMetadataInterface object based on module prefix
   */
  public getModuleInfoByName(modulePrefix: string): ModuleMetadataInterface {
    modulePrefix = StringUtility.toFirstLetterUpper(modulePrefix.toLowerCase());
    const serviceName = `${modulePrefix}ModuleMetadataService`;
    if (modulePrefix !== 'Emm') {
      try {
        const moduleService = this.injector.get<ModuleMetadataServiceInterface>(serviceName as any);
        return moduleService.getModuleMetadata();
      } catch (e) {
        console.warn(`Module with name: ${modulePrefix}, doesn't exist in application scope.`);
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Get user permissions for every organization
   */
  public getAllUserPermissions(): TokenOrganization[] {
    return this.securityService.getDecodedAccessToken().organizations;
  }

  public getAllUserPermissionsSource(): Observable<TokenOrganization[] | null> {
    return this.securityService
      .getDecodedAccessTokenSource()
      .pipe(map((token) => (token ? token.organizations : null)));
  }

  /**
   * Get list of all or specific user organization object from local storage
   */
  public getUserOrganization(organizationId: string): ProfileOrganization | null {
    if (organizationId) {
      const organizations: ProfileOrganization[] = this.getUserOrganizations();
      const organization: ProfileOrganization = organizations
        ? organizations.find((org: ProfileOrganization) => org.id === organizationId)
        : null;
      return organization ? organization : null;
    }
    return null;
  }

  public getUserOrganizationSource(organizationId: string): Observable<ProfileOrganization | undefined> {
    return this.userOrganizations$.pipe(
      map((organizations) => organizations.find((organization) => organization.id === organizationId)),
    );
  }

  /**
   * Set user organizations to local storage
   */
  public setUserOrganizations(organizations: ProfileOrganization[]): void {
    this.storage.setItem(LC_ORGANIZATIONS, JSON.stringify(organizations));
    this.userOrganizations$.next(organizations);
  }

  /**
   * Get list of all or specific user organization object from local storage
   */
  public getUserOrganizations(): ProfileOrganization[] {
    const item: any = this.storage.getItem(LC_ORGANIZATIONS);
    return JSON.parse(item);
  }

  public getUserOrganizationsSource(): Observable<ProfileOrganization[]> {
    return this.userOrganizations$;
  }

  /**
   * Get ID of current selected organization
   */
  public getCurrentOrganizationId(): string {
    const id = this.storage.getItem(LC_ACTIVE_ORGANIZATION_ID);
    return id ? id : '';
  }

  public getCurrentOrganizationIdSource(): Observable<string> {
    return this.currentOrganizationId$;
  }

  /**
   * Set selected organization
   */
  public setCurrentOrganization(organizationId: string): void {
    this.storage.setItem(LC_ACTIVE_ORGANIZATION_ID, organizationId);
    this.currentOrganizationId$.next(organizationId);
    const currentOrganization: ProfileOrganization = this.getUserOrganization(organizationId);
    this.currentOrganization$.next(currentOrganization);
    this.logoService.setUpLogo(currentOrganization.imageId);
    this.themeService.setTheme(currentOrganization.theme ?? PrimeNgThemeEnum.LIGHT_TEAL);
  }

  /**
   * Get current selected organization object from local storage
   */
  public getCurrentOrganization(): ProfileOrganization {
    const id = this.storage.getItem(LC_ACTIVE_ORGANIZATION_ID);
    if (id) {
      return this.getUserOrganization(id) as ProfileOrganization;
    } else {
      throw new Error(`User doesn't have any selected organization.`);
    }
  }

  public getCurrentOrganizationSource(): Observable<ProfileOrganization> {
    return this.currentOrganization$ as any;
  }

  /**
   * Get currently active HH module from storage
   */
  public getActiveModule(): string | null {
    return this.storage.getItem(LC_ACTIVE_MODULE);
  }

  public getActiveModuleSource(): Observable<string> {
    return this.activeModule$;
  }

  /**
   * Set active HH module
   */
  public setActiveModule(moduleName: string): void {
    this.storage.setItem(LC_ACTIVE_MODULE, moduleName.toUpperCase());
    this.activeModule$.next(moduleName.toUpperCase());
  }

  public isActiveModule(moduleName: string): boolean {
    const activeModule = this.getActiveModule();
    return activeModule === moduleName;
  }

  public isActiveModuleSource(moduleName: string): Observable<boolean> {
    return this.activeModule$.pipe(map((module) => module === moduleName));
  }

  /**
   * Check if the logged user has the permission (for active module)
   * If module name isn't specified, use active module
   */
  public hasPermission(permissionName: string, moduleName?: any): boolean {
    moduleName = moduleName || this.getActiveModule();
    const permissions: string[] = this.getUserPermissions(moduleName);
    return permissions.findIndex((element) => element === permissionName) >= 0;
  }

  /**
   * Check if the logged user is admin
   */
  public isSuperAdmin(): boolean {
    return this.securityService.getDecodedAccessToken().superAdmin;
  }

  public isSuperAdminSource(): Observable<boolean> {
    return this.securityService.getDecodedAccessTokenSource().pipe(map((token: any) => token.superAdmin));
  }

  /**
   * Get user preference value by preference name
   * Organization and module IDs are optional
   * If omitted, active module and organization IDs are used
   * Third, boolean, optional parameter isGeneral,
   * differentiates between organization preferences and general/global preferences
   * Defaults to general preferences
   */
  public getUserPreferenceValue(
    preferenceName: string,
    organizationId: string = this.getCurrentOrganizationId(),
    isGeneral = true,
    moduleAlias: any = this.getActiveModule(),
  ): Observable<string> | Observable<any> {
    if (isGeneral) {
      return this.userPreferenceService.getUserPreference().pipe(
        map((prefs) => prefs?.globalPreferences?.find((pref) => pref.name === preferenceName)?.value),
        catchError((_error: unknown) => throwError(() => `Global preference ${preferenceName} not found.`)),
      );
    } else {
      return this.userPreferenceService.getUserPreference().pipe(
        map(
          (prefs: UserPreferenceDTO) =>
            prefs?.organizations
              ?.find((org: any) => org.id === organizationId)
              .modules.find((module: any) => module.alias === moduleAlias)
              .preferences.find((pref: any) => pref.name === preferenceName).value,
        ),
        catchError((_error: unknown) =>
          throwError(
            () => `Preference ${preferenceName} in organization ${organizationId} and module ${moduleAlias} not found.`,
          ),
        ),
      );
    }
  }

  /**
   * Callback for security service data purge call
   */
  public purgeSavedData(): void {
    this.storage.removeItem(LC_USER);
    this.storage.removeItem(LC_ORGANIZATIONS);
  }

  private mapOrganizationsAndModules(organizations: TokenOrganization[]): Map<string, ModuleMetadataInterface[]> {
    let modules: ModuleMetadataInterface[] = [];
    const organizationModules = new Map<string, ModuleMetadataInterface[]>();
    organizations.forEach((organization: TokenOrganization) => {
      modules = [];
      const permissionModules = Object.values(organization.modules).map((module: TokenModule) => module.alias);
      permissionModules.forEach((mod) => {
        let modulePrefix = mod.toLowerCase();
        modulePrefix = StringUtility.toFirstLetterUpper(modulePrefix);

        const serviceName = `${modulePrefix}ModuleMetadataService`;
        if (modulePrefix !== 'Emm') {
          try {
            const moduleService = this.injector.get<ModuleMetadataServiceInterface>(serviceName as any);
            modules.push(moduleService.getModuleMetadata());
          } catch (e) {
            console.warn(e);
            // throw new Error(`Module with name: ${modulePrefix}, doesn't exist in list of user modules.`);
          }
        }
        organizationModules.set(organization.organizationId, modules);
      });
    });
    return organizationModules;
  }

  /**
   * Utility method for mapping modules from access token to module metadata
   */
  private mapModules(permissionModules: string[]): ModuleMetadataInterface[] {
    const modules: ModuleMetadataInterface[] = [];

    for (let i = 0; i < permissionModules.length; i++) {
      let modulePrefix = permissionModules[i].toLowerCase();
      modulePrefix = StringUtility.toFirstLetterUpper(modulePrefix);
      const serviceName = `${modulePrefix}ModuleMetadataService`;
      //console.log('Mapping permissions', permissionModules, serviceName, modulePrefix);
      if (modulePrefix !== 'Emm') {
        try {
          const moduleService = this.injector.get<ModuleMetadataServiceInterface>(serviceName as any);
          modules.push(moduleService.getModuleMetadata());
        } catch (e) {
          console.warn(e);
          // throw new Error(`Module with name: ${modulePrefix}, doesn't exist in list of user modules.`);
        }
      }
    }
    return modules;
  }
}
