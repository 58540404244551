import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationChangeService {
  public organizationChangeSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public organizationChange$: Observable<any> = this.organizationChangeSubject.asObservable();

  triggerOrganizationChange(value: any) {
    this.organizationChangeSubject.next(value);
  }
}
