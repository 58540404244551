import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import type { StepOptions } from './../../models/step-options.model';
import { SharedTranslateKeys } from './../../models/translation/translate-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  public confirmCancel: boolean = false;
  public currentStepIndex: number = 0;
  public defaultStepOptions: StepOptions = {};
  public isModal: boolean = true;
  public router: Router = inject(Router);
  public sharedTranslateKeys = SharedTranslateKeys;
  public steps: StepOptions[] = [];

  public readonly shepherdService: ShepherdService = inject(ShepherdService);
  // public readonly storageService: StorageService = inject(StorageService);
  public readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    let prevUrl = '';
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(() => {
          return location.pathname === prevUrl;
        }),
        tap(() => (prevUrl = location.pathname)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (this.shepherdService.isActive) {
          this.continueTourOnPageChange();
        }
      });
  }

  startTour() {
    this.currentStepIndex = 0;
    this.shepherdService.defaultStepOptions = this.defaultStepOptions;
    this.shepherdService.modal = this.isModal;
    this.shepherdService.confirmCancel = this.confirmCancel;
    this.shepherdService.addSteps(this.steps);
    this.shepherdService.start();
  }

  private continueTourOnPageChange(): void {
    if (this.currentStepIndex < this.shepherdService.tourObject.steps.length) {
      const currentStep = this.shepherdService.tourObject.getCurrentStep().id;
      const currentStepId = this.steps.findIndex((item) => item.id === currentStep);
      this.currentStepIndex = currentStepId + 1;
      this.shepherdService.show(this.shepherdService.tourObject.steps[this.currentStepIndex].id);
    } else {
      this.shepherdService.complete();
    }
  }

  isTourSeen(tourKey: string) {
    return localStorage.getItem(`${tourKey}_seen`);
  }

  setCurrentStep(stepId: string) {
    this.shepherdService.show(stepId);
  }
}
