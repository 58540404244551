import { inject } from '@angular/core';
import { Router, type CanActivateFn, type RouterStateSnapshot } from '@angular/router';
import type { Observable } from 'rxjs';
import type { FiyuActivatedRouteSnapshot } from './../routing/fiyu-activated-route-snapshot';
import { PermissionsService } from './permissions.service';

/**
 * Route guard based on user permissions
 * @deprecated use PermissionsMatchGuard instead
 * @usageNotes  { path: 'home', component: HomeComponent, canActivate: [PermissionsGuard]},
 * @param _route
 * @param _state
 * @returns boolean | Observable<boolean>
 */
export const PermissionsGuard: CanActivateFn = (
  route: FiyuActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): boolean | Observable<boolean> => {
  const permissionsService = inject(PermissionsService);
  const router = inject(Router);
  let hasPermission = true;
  /**
   * If the route requires a module check user permissions for the module
   */
  if (route.routeConfig?.requiredModule) {
    const module: any = route.routeConfig.requiredModule;
    hasPermission = permissionsService.checkModulePermission([module]);
    /**
     * If the route requires permissions for the child route,
     * check user permissions for child and for that module
     */
  } else if (route.routeConfig?.requiredPermissions) {
    const permissions = route.routeConfig?.requiredPermissions;
    let parent = route.parent as FiyuActivatedRouteSnapshot;

    while (!parent.routeConfig?.requiredModule) {
      parent = parent.parent as FiyuActivatedRouteSnapshot;
    }

    const module = route.routeConfig.requiredModule;
    hasPermission = permissionsService.checkPermission(permissions, undefined, module);
  }

  if (!hasPermission) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.navigateByUrl('/404');
  }
  return hasPermission;
};
