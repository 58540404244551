import { MODULE_METADATA_TOKEN, RequiredModulesConfig, SharedTranslateKeys, VALIDATION_ERRORS_TOKEN } from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const NotificationsRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () =>
      (await import('./notifications-layout/notifications-layout.component')).NotificationsLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'notifications',
        title: SharedTranslateKeys.Notifications,
        loadComponent: async () =>
          (await import('./notification-messages/notification-messages-list/notification-messages-list.component'))
            .NotificationMessagesListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.NOTIFICATIONS,
        requiredPermissions: [ModulePermissions.NMM_RUN],
      },
      {
        path: 'landing',
        title: SharedTranslateKeys.Notifications,
        loadComponent: async () =>
          (await import('./notifications-landing/notifications-landing.component')).NotificationsLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.NOTIFICATIONS,
        requiredPermissions: [ModulePermissions.NMM_RUN],
      },
      {
        path: 'about',
        title: SharedTranslateKeys.About,
        loadComponent: async () => (await import('./about/about-landing.component')).AboutLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.NOTIFICATIONS,
        requiredPermissions: [ModulePermissions.NMM_RUN],
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
