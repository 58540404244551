import {
  Validators as NgValidators,
  type AbstractControl,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';
import { DateUtility, TimeUtility } from '../utilities';
import type { Range } from './model/range.type';
import type { ValidatorScope } from './model/validator-scope.model';
import { ValidationMessage } from './validation-message';

/**
 * Custom FIYU validators and wrapper for internal Angular validators
 */
export class FiyuValidators {
  /**
   * Validator that requires the control's value to be greater than or equal to the provided number.
   * @param min
   * @returns ValidatorFn
   */
  static min(min: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.min(min);
      if (v(control)) {
        return new ValidationMessage('The minimum value of this field must be ' + min + '.', v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to be less than or equal to the provided number.
   * @param max
   * @returns ValidatorFn
   */
  static max(max: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.max(max);
      if (v(control)) {
        return new ValidationMessage('The maximum value of this field must be ' + max + '.', v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to be in the phone number format.
   * @param value
   * @returns ValidatorFn
   */
  static phoneNumber(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.pattern(new RegExp('^\\+?\\d+$'));
        if (v(control)) {
          return new ValidationMessage('Phone number is not valid.', v);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control have a non-empty value.
   * @param value
   * @returns ValidatorFn
   */
  static required(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.required(control);
        if (v) {
          return new ValidationMessage('This field is required.', v);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value be true. This validator is commonly
   * used for required checkboxes.
   * @param value
   * @returns ValidatorFn
   */
  static requiredTrue(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.requiredTrue(control);
        if (v) {
          return new ValidationMessage('This field is required and value must be true.', v);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a valid email pattern
   * @param value
   * @returns ValidatorFn
   */
  static email(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (!control.value) {
        return null;
      }

      if (value) {
        const v = NgValidators.email(control);
        if (v) {
          return new ValidationMessage('Email is not valid.', v);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the length of the control's value to be greater than or equal
   * to the provided minimum length.
   * @param minLength
   * @returns ValidatorFn
   */
  static minLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.minLength(minLength);
      if (v(control)) {
        return new ValidationMessage('The minimal length of this field must be ' + minLength + ' characters.', v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the length of the control's value to be less than or equal
   * to the provided maximum length.
   * @param maxLength
   * @returns ValidatorFn
   */
  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.maxLength(maxLength);

      if (v(control)) {
        return new ValidationMessage('The maximal length of this field must be ' + maxLength + ' characters.', v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a regex pattern.
   * @param pattern
   * @returns ValidatorFn
   */
  static pattern(pattern: string | RegExp): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      const v = NgValidators.pattern(pattern);
      if (v(control)) {
        return new ValidationMessage('The value of this field did not match the pattern ([A-Za-z0-9_])', v);
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to match a predefined password pattern.
   * @param value
   * @returns ValidatorFn
   */
  static password(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#$%^&*])'));

        if (v(control)) {
          return new ValidationMessage(
            'Password must contain at least 1 uppercase alphabetical character, 1 numeric character and 1 special character.',
            v,
          );
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to not be only spaces
   * @param value
   * @returns ValidatorFn
   */
  static notOnlySpaces(value: boolean): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.pattern(new RegExp('[A-Za-z0-9]+s*'));

        if (v(control)) {
          return new ValidationMessage('Input must contain alphanumerics', v);
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to not contain whitespace
   * @returns ValidatorFn
   */
  static noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // test the value of the control against the regexp supplied
      const isWhitespace = (control.value || '').match(/\s/g);
      const isValid = !isWhitespace;
      return isValid ? null : { hasSpace: true };
    };
  }

  /**
   * Validator that requires the control's value to be equal to other form field provided as a value parameter.
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static equalFields(value: string, scope: ValidatorScope): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.required;

        if (scope.formGroup && scope.entity) {
          if (
            (<any>scope.formGroup.controls)[value].value &&
            control.value !== (<any>scope.formGroup.controls)[value].value
          ) {
            return new ValidationMessage('This field must match field: ' + scope.entity.getFormOption(value).label, v);
          }
        }
      }
      return null;
    };
  }

  /**
   * Validator function that requires date control to be less than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static dateLessThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareDate(value, scope, 'lt');
  }

  /**
   * Validator function that requires date control to be greater than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static dateGreaterThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareDate(value, scope, 'gt');
  }

  /**
   * Validator function that requires date control to be less or equal than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static dateLessOrEqualThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareDate(value, scope, 'ltOrEqual');
  }

  /**
   * Validator function that requires date control to be greater or equal than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static dateGreaterOrEqualThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareDate(value, scope, 'gtOrEqual');
  }

  /**
   * Function used by date validators compares two dates depending on operator.
   * The name of the control to compare to is available as "value".
   * @param value
   * @param scope
   * @param operator
   * @returns ValidatorFn
   */
  private static compareDate(
    value: any,
    scope: ValidatorScope,
    operator: 'lt' | 'gt' | 'gtOrEqual' | 'ltOrEqual',
  ): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.required;

        if (scope.formGroup && scope.entity) {
          if ('undefined' !== typeof value && (<any>scope.formGroup.controls)[value].value) {
            const date = new Date(control.value);
            const compareTo = new Date((<any>scope.formGroup.controls)[value].value);
            const valid: boolean = DateUtility.compareDate(date, compareTo, operator);

            if (!valid) {
              const label: string = scope.translateService.instant(scope.entity.getFormOption(value).label);
              const mustBeText = {
                lt: 'less than',
                gt: 'greater than',
                gtOrEqual: 'greater or equal than',
                ltOrEqual: 'less or equal than',
              };
              const message = `This field must be ${mustBeText[operator]} ${label}`;
              return new ValidationMessage(message, v);
            }
          }
        }
      }
      return null;
    };
  }

  /**
   * Validator function that requires control to be less than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static timeLessThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareTime(value, scope, 'lt');
  }

  /**
   * Validator function that requires control to be greater than other form control provided in value
   * @param value
   * @param scope
   * @returns ValidatorFn
   */
  static timeGreaterThan(value: any, scope: ValidatorScope): ValidatorFn {
    return this.compareTime(value, scope, 'gt');
  }

  /**
   * Function used by time validators that compares two times in "HH:MM" depending on operator.
   * The name of the control to compare to is available as "value".
   * @param value
   * @param scope
   * @param operator
   * @returns ValidatorFn
   */
  private static compareTime(value: any, scope: ValidatorScope, operator: string) {
    return (control: AbstractControl): null | ValidationMessage => {
      if (value) {
        const v = NgValidators.required;
        if (scope.formGroup && scope.entity) {
          if ((<any>scope.formGroup.controls)[value].value) {
            const compareTo: Date = (<any>scope.formGroup.controls)[value].value;
            const valid: boolean = TimeUtility.compareTime(control.value, compareTo, operator);

            if (!valid) {
              const label: string = scope.entity.getFormOption(value).label;
              const mustBeText = operator === 'lt' ? 'less than' : 'greater than';
              const message = `This field must be ${mustBeText} ${label}`;

              return new ValidationMessage(message, v);
            }
          }
        }
      }
      return null;
    };
  }

  /**
   * Validator that requires the control's value to in the given range, or in one of the provided
   * ranges if multiple ranges are provided.
   * @param ranges
   * @param includeStart
   * @param includeEnd
   * @returns ValidatorFn
   */
  static ranges(ranges: Range[], includeStart?: boolean, includeEnd?: boolean) {
    return (control: AbstractControl): null | ValidationMessage => {
      const controlValue: any = control.value;

      if (controlValue && ranges) {
        let valid = false;

        for (const range of ranges) {
          const startConditionValid = includeStart ? controlValue >= range.start : controlValue > range.start;
          const endConditionValid = includeEnd ? controlValue <= range.end : controlValue < range.end;

          if (startConditionValid && endConditionValid) {
            valid = true;
            break;
          }
        }

        if (!valid) {
          return new ValidationMessage(this.getRangesValidationErrorMessage(ranges), {
            valueNotInRange: { value: controlValue },
          });
        }
      }
      return null;
    };
  }

  /**
   * RangesValidation message function. Returns appropriate message depending
   * whether there are multiple or only one range control must comply.
   * @param ranges
   * @returns string
   */
  private static getRangesValidationErrorMessage(ranges: Range[]): string {
    if (ranges.length === 1) {
      return `Value of this field must be between ${ranges[0].start} and ${ranges[0].end}`;
    } else {
      let validationMessage = 'Value of this field must be within one of ranges:';
      ranges.forEach((range: Range): void => {
        validationMessage += ` ${range.start} - ${range.end},`;
      });

      return validationMessage.substring(0, validationMessage.length - 1);
    }
  }

  /**
   * Validates a string to match HH:MM format
   * Clock false accepts any number of hours
   * With config set to 24, it accepts 23:59 max
   * Similarly 12 accepts 11:59
   * @param config
   * @returns ValidatorFn
   */
  static hoursMinutes(config: { clock: 12 | 24 | false }): ValidatorFn {
    return (control: AbstractControl): null | ValidationMessage => {
      if (config) {
        const v: ValidatorFn = !config.clock
          ? NgValidators.pattern(new RegExp('^(([0]{1,2})|([0-9][0-9]*)):[0-5][0-9]$'))
          : config.clock === 24
            ? NgValidators.pattern(new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$'))
            : NgValidators.pattern(new RegExp('^([0-9]|0[0-9]|1[0-1]):[0-5][0-9]$'));
        if (v(control)) {
          const additionalMessage: string = !config.clock ? '' : ` (${config.clock}:00 clock)`;
          return new ValidationMessage(`Input must be in HH:MM format${additionalMessage}.`, v);
        }
      }
      return null;
    };
  }

  /**
   *
   * @param regex
   * @param error
   * @returns ValidatorFn
   */
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  /**
   *
   * @param regex
   * @returns ValidatorFn
   */
  static forbiddenStringValidator(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden: boolean = regex.test(control.value);
      return forbidden ? { forbiddenString: { value: control.value } } : null;
    };
  }

  /**
   *
   * @param formgroup
   * @returns void
   */
  static passwordMatchValidator(formgroup: AbstractControl): void {
    const password: AbstractControl = formgroup.get('newPassword'); // get password from our password form control
    const confirmPassword: AbstractControl = formgroup.get('confirmPassword'); // get password from our confirmPassword form control
    // compare is the password math
    if (password?.value !== confirmPassword?.value) {
      // if they don't match, set an error in our confirmPassword form control
      confirmPassword.setErrors({ noPasswordMatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  }
}
