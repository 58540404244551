import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.Module,
  pluralName: SharedTranslateKeys.Modules,
  singularUrlName: 'module',
  pluralUrlName: 'modules',
  contentType: 'application/module.admin.v1+json',
  permissionPrefix: ModulePermissions.MODULE,
  disabledStateProp: 'defaultModule',
})
export class ModuleAdmin extends AbstractEntity {
  @FormEntity({
    label: SharedTranslateKeys.Name,
    required: true,
  })
  name: string;

  @FormEntity({
    label: SharedTranslateKeys.Description,
    required: true,
    formType: FormControlType.TextArea,
  })
  description: string;

  @FormEntity({
    label: SharedTranslateKeys.Enabled,
  })
  enabled: boolean;
}
