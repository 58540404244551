import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, EnumUtility, FormEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';
import { TranslateKeys } from '../module-translate-keys';

export enum PaymentMethodTypeEnum {
  DIRECT_BANK_TRANSFER = 'Direct bank transfer',
  CREDIT_CARD = 'Credit card',
}

@Entity({
  singularName: TranslateKeys.PaymentMethod,
  pluralName: TranslateKeys.PaymentMethods,
  singularUrlName: 'payment-method',
  pluralUrlName: 'payment-methods',
  contentType: 'application/payment-method.v1+json',
  permissionPrefix: ModulePermissions.CRM_PAYMENT_METHOD,
  quickExportEnabled: true,
  exportEnabled: false,
})
export class PaymentMethod extends AbstractEntity {
  @FormEntity({
    required: true,
    label: SharedTranslateKeys.Name,
  })
  name: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    label: SharedTranslateKeys.PaymentMethodType,
    required: true,
    lookupEmpty: false,
    lookupOptions: EnumUtility.mapEnumToSelectItems(PaymentMethodTypeEnum),
  })
  paymentMethodType: string;
}
