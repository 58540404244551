import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  public feedbackBarSubject: BehaviorSubject<any> = new BehaviorSubject({
    detail: null,
  });
  public feedbackValidationSubject: BehaviorSubject<any> = new BehaviorSubject({ detail: null });
  public feedbackToastSubject: BehaviorSubject<any> = new BehaviorSubject({ detail: null });
  public feedbackSnackBarSubject: BehaviorSubject<any> = new BehaviorSubject({
    detail: null,
  });

  public feedbackSnackBar$: Observable<any> = this.feedbackSnackBarSubject.asObservable();
  public feedbackBar$: Observable<any> = this.feedbackBarSubject.asObservable();
  public feedbackValidation$: Observable<any> = this.feedbackValidationSubject.asObservable();
  public feedbackToast$: Observable<any> = this.feedbackToastSubject.asObservable();

  /* Feedback Topbar */
  /**
   * - successBarMessage() method for generating success feedback topbar message
   * - this method passes data through "feedbackBarObservable" to feedback topbar component
   * - "message" parameter is mandatory while "title" parameter is optional
   * - Bold text (provided with title parameter) will always show in front of normal text
   *   provided by "message" parameter. That is due PrimeNg.
   * - example of usage: this.feedbackService.successBarMessage('Example Success Text');
   *                     this.feedbackService.successBarMessage('Example Success Text', 'Example Success Bold Text');
   * - To close feedback topBar just send empty string in parameter this.feedbackService.successBarMessage('');
   * @param message - mandatory parameter that provides normal text
   * @param action - this param is boolean, if you plan to pass a function set it to true to set
   * @param title - optional parameter that provides bold text
   */
  successBarMessage(message: string, action?: boolean, title?: string) {
    this.feedbackBarSubject.next({
      severity: 'success',
      summary: title,
      detail: message,
      passedFunction: action,
    });
  }

  /**
   * - infoBarMessage() method for generating info feedback topbar message
   * - this method passes data through "feedbackBarObservable" to feedback topbar component
   * - "message" parameter is mandatory while "title" parameter is optional
   * - Bold text (provided with title parameter) will always show in front of normal text
   *   provided by "message" parameter. That is due PrimeNg.
   * - example of usage: this.feedbackService.infoBarMessage('Example Info Text');
   *                     this.feedbackService.infoBarMessage('Example Info Text', 'Example Info Bold Text');
   * - To close feedback topBar just send empty string in parameter this.feedbackService.infoBarMessage('');
   * @param message - mandatory parameter that provides normal text
   * @param title - optional parameter that provides bold text
   * @param action - this param is boolean, if you plan to pass a function set it to true to set
   */
  infoBarMessage(message: string, action?: boolean, title?: string) {
    this.feedbackBarSubject.next({
      severity: 'info',
      summary: title,
      detail: message,
      passedFunction: action,
    });
  }

  /**
   * - warningBarMessage() method for generating warning feedback topbar message
   * - this method passes data through "feedbackBarObservable" to feedback topbar component
   * - "message" parameter is mandatory while "title" parameter is optional
   * - Bold text (provided with title parameter) will always show in front of normal text
   *   provided by "message" parameter. That is due PrimeNg.
   * - example of usage: this.feedbackService.warningBarMessage('Example Warning Text');
   *                     this.feedbackService.warningBarMessage('Example Warning Text', 'Example Warning Bold Text');
   * - To close feedback topBar just send empty string in parameter this.feedbackService.warningBarMessage('');
   * @param message - mandatory parameter that provides normal text
   * @param action - this param is boolean, if you plan to pass a function set it to true to set
   * @param title - optional parameter that provides bold text
   */
  warningBarMessage(message: string, action?: boolean, title?: string) {
    this.feedbackBarSubject.next({
      severity: 'warn',
      summary: title,
      detail: message,
      passedFunction: action,
    });
  }

  /**
   * - errorBarMessage() method for generating error feedback topbar message
   * - this method passes data through "feedbackBarObservable" to feedback topbar component
   * - "message" parameter is mandatory while "title" parameter is optional
   * - Bold text (provided with title parameter) will always show in front of normal text
   *   provided by "message" parameter. That is due PrimeNg.
   * - example of usage: this.feedbackService.errorBarMessage('Example Error Text');
   *                     this.feedbackService.errorBarMessage('Example Error Text', 'Example Error Bold Text');
   * - To close feedback topBar just sent empty string in parameter this.feedbackService.errorValidationMessage('');
   * @param message - mandatory parameter that provides normal text
   * @param action - this param is boolean, if you plan to pass a function set it to true to set
   * @param title - optional parameter that provides bold text
   */
  errorBarMessage(message: string, action?: boolean, title?: string) {
    this.feedbackBarSubject.next({
      severity: 'error',
      summary: title,
      detail: message,
      passedFunction: action,
    });
  }
  /* /Feedback Topbar */

  /* Feedback Top Validation form message */
  /**
   * Method for triggering success validation message that will show on top of the form
   * - example of usage: this.feedbackService.successValidationMessage('Success Text');
   *                     this.feedbackService.successValidationMessage('Success Text', 'Success Bold Text');
   * - To close feedback topBar just sent empty string in parameter this.feedbackService.successValidationMessage('');
   * @param message - sets message content
   * @param title - sets message title
   */
  successValidationMessage(message: string, title?: string) {
    this.feedbackValidationSubject.next({
      severity: 'success',
      summary: title,
      detail: message,
    });
  }

  /**
   * Method for triggering info validation message that will show on top of the form
   * - example of usage: this.feedbackService.infoValidationMessage('Example Info Text');
   *                     this.feedbackService.infoValidationMessage('Example Info Text', 'Example Info Bold Text');
   * - To close feedback topBar just sent empty string in parameter this.feedbackService.infoValidationMessage('');
   * @param message - sets message content
   * @param title - sets message title
   */
  infoValidationMessage(message: string, title?: string) {
    this.feedbackValidationSubject.next({
      severity: 'info',
      summary: title,
      detail: message,
    });
  }

  /**
   * Method for triggering warning validation message that will show on top of the form
   * - example of usage: this.feedbackService.warningValidationMessage('Warning Text');
   *                     this.feedbackService.warningValidationMessage('Warning Text', 'Warning Bold Text');
   * - To close feedback topBar just sent empty string in parameter this.feedbackService.warningValidationMessage('');
   * @param message - sets message content
   * @param title - sets message title
   */
  warningValidationMessage(message: string, title?: string) {
    this.feedbackValidationSubject.next({
      severity: 'warn',
      summary: title,
      detail: message,
    });
  }

  /**
   * Method for triggering error validation message that will show on top of the form
   * - example of usage: this.feedbackService.errorValidationMessage('Example Error Text');
   *                     this.feedbackService.errorValidationMessage('Example Error Text', 'Example Error Bold Text');
   * - To close feedback topBar just sent empty string in parameter this.feedbackService.errorValidationMessage('');
   * @param message - sets message content
   * @param title - sets message title
   */
  errorValidationMessage(message: string, title?: string) {
    this.feedbackValidationSubject.next({
      severity: 'error',
      summary: title,
      detail: message,
    });
  }

  /* /Feedback Top Validation form message */

  /* Feedback Toast */
  /**
   * - Method for triggering success toast message that will show on top of the form
   * - first and second parameter are mandatory
   * - example of usage: this.feedbackService.successToast('Success Toast');
   *                     this.feedbackService.successToast('Success Toast', 'Success Toast bold txt');
   * @param message - sets message content
   * @param title - sets message title
   * @param position - sets toast position. Available positions are: top-right,
   * top-left, bottom-right, bottom-left, top-center, bottom-center, center
   */
  successToast(message: string, title?: string, position?: string) {
    this.feedbackToastSubject.next({
      severity: 'success',
      summary: title,
      detail: message,
      toastPosition: position ? position : 'bottom-right',
    });
  }
  /**
   * - Method for triggering info toast message that will show on top of the form
   * - first and second parameter are mandatory
   * - example of usage: this.feedbackService.infoToast('Info Toast');
   *                     this.feedbackService.infoToast('Info Toast', Info Toast Bold Txt);
   * @param message - sets message content
   * @param title - sets message title
   * @param position - sets toast position. Available positions are: top-right, top-left,
   * bottom-right, bottom-left, top-center, bottom-center, center
   */
  infoToast(message: string, title?: string, position?: string) {
    this.feedbackToastSubject.next({
      severity: 'info',
      summary: title,
      detail: message,
      toastPosition: position ? position : 'bottom-right',
    });
  }
  /**
   * - Method for triggering warning toast message that will show on top of the form
   * - first and second parameter are mandatory
   * - example of usage: this.feedbackService.warningToast('warning Toast');
   *                     this.feedbackService.warningToast('warning Toast', warning Toast Bold Txt);
   * @param message - sets message content
   * @param title - sets message title
   * @param position - sets toast position. Available positions
   * are: top-right, top-left, bottom-right, bottom-left, top-center, bottom-center, center
   */
  warningToast(message: string, title?: string, position?: string) {
    this.feedbackToastSubject.next({
      severity: 'warn',
      summary: title,
      detail: message,
      toastPosition: position ? position : 'bottom-right',
    });
  }
  /**
   * - Method for triggering error toast message that will show on top of the form
   * - first and second parameter are mandatory
   * - example of usage: this.feedbackService.errorToast('error Toast');
   *                     this.feedbackService.errorToast('error Toast', error Toast Bold Txt);
   * @param message - sets message content
   * @param title - sets message title
   * @param position - sets toast position. Available positions
   *  are: top-right, top-left, bottom-right, bottom-left, top-center, bottom-center, center
   */
  errorToast(message: string, title?: string, position?: string) {
    this.feedbackToastSubject.next({
      severity: 'error',
      summary: title,
      detail: message,
      toastPosition: position ? position : 'bottom-right',
    });
  }
  /* /Feedback Toast */

  /* Feedback SnackBar */
  /**
   * - Method for triggering snackBar
   * - example of usage: this.feedbackService.triggerSnackBar('Testing snackBar');
   * @param message - Text that will show inside snackBar
   * @param severity - Severity of snackBar. Available severities are: success, info, warn, error
   */
  triggerSnackBar(message: string, severity?: string) {
    this.feedbackSnackBarSubject.next({
      severity: severity ?? 'info',
      detail: message,
    });
  }
  /* /Feedback SnackBar */
}
