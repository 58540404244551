import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@fiyu/core';
import { ModuleMetadata } from '../module-metadata';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUserTypeService extends AbstractCRUDService {
  constructor() {
    super('/organizationUserTypes', ModuleMetadata.getInstance());
  }
}
