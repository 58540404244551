import {
  MODULE_METADATA_TOKEN,
  Operations,
  RequiredModulesConfig,
  SharedTranslateKeys,
  VALIDATION_ERRORS_TOKEN,
} from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const UsersRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () => (await import('./users/user-layout/user-layout.component')).UserLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'organization-user-type/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.UserType}`,
        loadComponent: async () =>
          (
            await import(
              './users/organization-user-types/organization-user-type-form/organization-user-type-form.component'
            )
          ).OrganizationUserTypeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ORGANIZATION_USER_TYPE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'organization-user-type',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.UserType}`,
        loadComponent: async () =>
          (
            await import(
              './users/organization-user-types/organization-user-type-form/organization-user-type-form.component'
            )
          ).OrganizationUserTypeFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ORGANIZATION_USER_TYPE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'organization-user-types',
        title: SharedTranslateKeys.UserTypes,
        loadComponent: async () =>
          (
            await import(
              './users/organization-user-types/organization-user-type-list/organization-user-type-list.component'
            )
          ).OrganizationUserTypeListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ORGANIZATION_USER_TYPE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'role/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Role}`,
        loadComponent: async () => (await import('./users/roles/role-form/role-form.component')).RoleFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ROLE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'role',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Role}`,
        loadComponent: async () => (await import('./users/roles/role-form/role-form.component')).RoleFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ROLE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'roles',
        title: SharedTranslateKeys.Roles,
        loadComponent: async () => (await import('./users/roles/role-list/role-list.component')).RoleListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ROLE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'user/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.User}`,
        loadComponent: async () => (await import('./users/users/user-form/user-form.component')).UserFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'user',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.User}`,
        loadComponent: async () => (await import('./users/users/user-form/user-form.component')).UserFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'users',
        title: SharedTranslateKeys.Users,
        loadComponent: async () => (await import('./users/users/user-list/user-list.component')).UserListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'profile-settings',
        title: SharedTranslateKeys.ProfileSettings,
        loadComponent: async () =>
          (await import('./users/profile-settings/profile-settings-form/profile-settings.component'))
            .ProfileSettingsComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'preferences',
        title: SharedTranslateKeys.Preferences,
        loadComponent: async () =>
          (await import('./users/user-preferences/user-preference-form/user-preference-form.component'))
            .UserPreferenceFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ORGANIZATION_SETTING_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'user-password',
        title: SharedTranslateKeys.ChangePassword,
        loadComponent: async () =>
          (await import('./users/user-password/user-password-form/user-password-form.component'))
            .UserPasswordFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'landing',
        title: SharedTranslateKeys.Users,
        loadComponent: async () => (await import('./users/user-landing/user-landing.component')).UserLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.USER,
        requiredPermissions: [ModulePermissions.USER_RUN],
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
