import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { FeatureFlags } from './../../types/feature-flags';

/**
 * Service to fetch list of feature flags for application
 * check if feature flag is enabled in manifest file(feature-flags.manifest.json) in assets folder
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsDataService {
  readonly #httpClient: HttpClient = inject(HttpClient);

  getFeatureFlags(): Observable<FeatureFlags> {
    return this.#httpClient.get<FeatureFlags>('/assets/feature-flags.manifest.json');
  }
}
