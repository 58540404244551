import { Injectable } from '@angular/core';

import { AbstractAclCRUDService } from '../crud/abstract-acl-crud.service';

@Injectable({
  providedIn: 'root',
})
export class AclService extends AbstractAclCRUDService {
  constructor() {
    super('/acl');
  }
}
