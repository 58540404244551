import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpCacheTimerService {
  private cacheTimeInMinutes = 30;
  private isTimerStarted = false;
  public dDay = new Date();
  public timeDifference: any;

  // milliSecondsInASecond = 1000;
  // hoursInADay = 24;
  // minutesInAnHour = 60;
  // secondsInAMinute = 60;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
  }

  startTimer() {
    if (!this.isTimerStarted) {
      this.dDay.setMinutes(this.dDay.getMinutes() + this.cacheTimeInMinutes);
      this.getTimeDifference();
      this.isTimerStarted = true;
    }
  }

  resetTimer() {
    this.isTimerStarted = false;
    this.getTimeDifference();
  }

  getRemainingTime(): number {
    this.getTimeDifference();
    return this.timeDifference;
  }
}
