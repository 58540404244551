/**
 * List of available decorator keys
 *
 */
export enum DecoratorKeysEnum {
  Presentation = 'presentationPropertyMap',
  Form = 'formPropertyMap',
  Entity = 'entityPropertyMap',
  EntityOriginal = 'entityPropertyMapOriginal',
}
