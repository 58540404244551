import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DestroyRef, Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { of, Subject, type Observable } from 'rxjs';
import { catchError, debounceTime, filter, switchMap } from 'rxjs/operators';
import type { LogEntry } from './log-entry';
import type { LogFields } from './log-fields';
import type { LokiLogJson, LokiLogStream } from './log-json-array';
import { LogType } from './log-type';
import { LoggerEvents } from './logger-events';

/**
 * Logger has a buffer that will be used as a means to aggregate messages and send them in batches
 * instead of immediately, or in other words, that buffer prevents rapid firing of events.
 * Message will be sent to the server 5 seconds from the moment of revival. If another log message
 * is received in that time period, timer will reset to 5 seconds and the process continues.
 */
@Directive() // Needed only for Angular v9+ strict mode that enforce decorator to enable Component inheritance
export class Logger {
  private readonly MODULE_FIELD = 'Module';
  private readonly ENV_FIELD = 'Environment';
  private readonly USER_NAME_FIELD = 'UserName';
  private readonly USER_ID_FIELD = 'UserID';
  private readonly URL_FIELD = 'Url';
  private readonly DATE_FIELD = 'Date';
  private readonly buffer: LogEntry[] = [];
  private readonly flush = new Subject<LoggerEvents>();

  constructor(
    public appName: string,
    private readonly logEndpoint: string,
    private readonly httpClient: HttpClient,
    private readonly destroyRef: DestroyRef,
  ) {
    this.destroyRef.onDestroy(() => {
      this.flush.complete();
    });
    this.flush
      .pipe(
        debounceTime(5000),
        filter((event) => event === LoggerEvents.FLUSH),
        switchMap((_value: any) => this.flushBuffer()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (_res: any) => ({}),
        error: (error: unknown) => console.warn('logging error', error),
      });
    //  console.log('logging', this.appName);
  }

  /**
   * method that is called by the Log Service to process the message and send it to the loki log server
   * @param type - Type of the log event
   * @param message - log message
   * @param data - additional data like userID, url of the page that sent log request, active module name...
   */
  public log(type: LogType, message: string, data: LogFields) {
    this.buffer.push({
      type,
      message,
      data,
    });
    this.flush.next(LoggerEvents.FLUSH);
  }

  /**
   * Triggered by the debounce function. Sends logs in the queue
   * to the loki log server and writes them in the console.
   */
  private flushBuffer(): Observable<any> {
    const data: LogEntry[] = this.buffer.filter((entry) => entry.type !== LogType.INFO);

    // Each log entry in the data array is sent to buildLokiLogStream function
    const lokiLogStreams: LokiLogJson = {
      streams: [],
    };
    if (data) {
      lokiLogStreams.streams.push(this.buildLokiLogStream(data));
    }

    /*     if (this.getEnvironment(data) === EnvironmentType.Local) {
        console.log('logger', output);
    } */

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .post(this.logEndpoint, lokiLogStreams, {
        headers,
      })
      .pipe(catchError((_error: unknown) => of(null)));
  }

  getEnvironment(data: LogEntry[]): string {
    return data[0].data.environmentType;
  }

  /**
   * Log entry is additionally processed and structured to be ready to send to the loki log server
   * @param logData - log entry list
   */
  private buildLokiLogStream(logData: LogEntry[]): LokiLogStream {
    const lokiLogObject: LokiLogStream = {
      stream: { namespace: '', app: '' },
      values: [],
    };
    logData.forEach((entry: LogEntry) => {
      const { type, message, data } = entry;
      const additionalData = this.getAdditionalData(data);
      // for loki logs unix timestamp needs to be in nanoseconds
      const unixTimestamp = Math.floor(additionalData.Date.getTime() * 1000000).toString();
      const decoratedMessage = `${additionalData.Environment} - ${additionalData.Module} - ${additionalData.Url} - ${additionalData.UserName} - ${additionalData.UserID} - ${type} msg: ${message}`;
      // fill out stream labels
      lokiLogObject.stream.namespace = 'fiyu';
      lokiLogObject.stream.app = 'frontend';
      // fill out log entries
      lokiLogObject.values.push([unixTimestamp, decoratedMessage]);
    });
    return lokiLogObject;
  }

  private getAdditionalData(data: LogFields) {
    return {
      [this.MODULE_FIELD]: data.moduleName,
      [this.ENV_FIELD]: data.environmentType,
      [this.USER_NAME_FIELD]: data.userName,
      [this.USER_ID_FIELD]: data.userId,
      [this.URL_FIELD]: data.url,
      [this.DATE_FIELD]: data.date,
    };
  }
}
