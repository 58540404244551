import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ModulePrefixes, SKIP_CACHE_HEADER, type ProfileOrganization as Organization, type Profile } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { EnvironmentService } from './../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly environmentService: EnvironmentService = inject(EnvironmentService);

  getUserProfile(): Observable<Profile> {
    return this.httpClient.get<Profile>(`${this.environmentService.getModuleApiURL(ModulePrefixes.USER)}/profile`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }

  getUserOrganizations(): Observable<Organization[]> {
    return this.httpClient.get<Organization[]>(
      `${this.environmentService.getModuleApiURL(ModulePrefixes.USER)}/profile/organizations`,
      {
        context: new HttpContext().set(SKIP_CACHE_HEADER, true),
      },
    );
  }
}
