import { AbstractEntity } from './../entity/abstract-entity.model';
import { Entity } from './../entity/entity.decorator';
import { FormEntity } from './../entity/form-entity.decorator';
import type { Nullable } from './../types/nullable-type';

export interface TokenOrganization {
  organizationId: string;
  organizationUserId: string;
  modules: TokenModule[];
}

export interface TokenModule {
  alias: string;
  permissions: string[];
}

@Entity()
export class AccessToken extends AbstractEntity {
  @FormEntity() exp: Nullable<number> = null;
  @FormEntity() user_name: Nullable<string> = null;
  @FormEntity() organizations: Nullable<TokenOrganization[]> = null;
  @FormEntity() jti: Nullable<string> = null;
  @FormEntity() client_id: Nullable<string> = null;
  @FormEntity() scope: string[] = [];
  @FormEntity() superAdmin = false;
  @FormEntity() userId: Nullable<string> = null;
  @FormEntity() userTypes: Record<string, string>;
}
