import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { StepOptionsButton } from './../../models/step-options.model';
import { LC_TOUR_SEEN } from './../../models/storage/local-storage-keys';
import { TourService } from './../tour/tour.service';

export type TourButton = {
  [key: string]: StepOptionsButton;
};

@Injectable({ providedIn: 'root' })
export class FiyuTourService extends TourService {
  private readonly translateService: TranslateService = inject(TranslateService);
  constructor() {
    super();

    const buttons: TourButton = {
      next: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          this.shepherdService.next();
        },
      },

      cancel: {
        classes: 'cancel-button',
        secondary: true,
        text: this.translateService.instant(this.sharedTranslateKeys.Exit),
        action: () => {
          this.shepherdService.complete();
          localStorage.setItem(LC_TOUR_SEEN, 'true');
        },
      },

      finish: {
        classes: 'cancel-button',
        secondary: true,
        text: this.translateService.instant(this.sharedTranslateKeys.Finish),
        action: () => {
          this.shepherdService.complete();
          localStorage.setItem(LC_TOUR_SEEN, 'true');
        },
      },

      navigateToPlatformModule: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('platform/landing');
        },
      },

      navigateToUsers: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('platform/users');
        },
      },

      navigateToUserForm: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('platform/user');
        },
      },

      openQuickView: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          const elementToClick: HTMLElement = document.querySelector('.table-column');
          if (elementToClick) {
            elementToClick.click();
            setTimeout(() => {
              this.shepherdService.next();
            }, 300);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigateByUrl('/');
            const nextStepId = this.shepherdService.tourObject.steps[this.steps.length - 1].id;
            this.setCurrentStep(nextStepId);
          }
        },
      },
      returnToHomePage: {
        classes: 'tour-button',
        text: this.translateService.instant(this.sharedTranslateKeys.Next),
        action: () => {
          this.currentStepIndex = this.steps.length - 2;
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('/');
        },
      },
    };

    this.steps = [
      {
        scrollTo: true,
        buttons: [buttons.cancel, buttons.next],
        id: 'intro',
        title: this.translateService.instant(this.sharedTranslateKeys.WelcomeToTour),
        text: this.translateService.instant(this.sharedTranslateKeys.TourIntroMessage),
      },
      {
        attachTo: {
          element: '#platform',
          on: 'right',
        },
        scrollTo: true,
        buttons: [buttons.cancel, buttons.navigateToPlatformModule],
        id: 'platformModule',
        title: this.translateService.instant(this.sharedTranslateKeys.NavigateToPlatformModule),
        text: this.translateService.instant(this.sharedTranslateKeys.NavigationToPlatformModule),
      },
      {
        buttons: [buttons.cancel, buttons.next],
        id: 'platformLanding',
        title: this.translateService.instant(this.sharedTranslateKeys.PlatformModule),
        text: this.translateService.instant(this.sharedTranslateKeys.PlatformModuleIntro),
      },
      {
        attachTo: {
          element: '#users',
          on: 'right',
        },
        scrollTo: true,
        buttons: [buttons.cancel, buttons.navigateToUsers],
        id: 'users',
        title: this.translateService.instant(this.sharedTranslateKeys.NavigateToUsers),
        text: this.translateService.instant(this.sharedTranslateKeys.UsersNavigationIntro),
      },
      {
        buttons: [buttons.cancel, buttons.next],
        id: 'usersModule',
        title: this.translateService.instant(this.sharedTranslateKeys.UsersModule),
        text: this.translateService.instant(this.sharedTranslateKeys.UsersModuleIntroduction),
      },
      {
        attachTo: {
          element: '#addNewUser',
          on: 'bottom',
        },
        buttons: [buttons.cancel, buttons.navigateToUserForm],
        id: 'addNewUser',
        title: this.translateService.instant(this.sharedTranslateKeys.AddNewUser),
        text: this.translateService.instant(this.sharedTranslateKeys.AddNewUserMessage),
      },
      {
        attachTo: {
          element: '#fiyu-form-block',
          on: 'right',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'fiyu-form-block',
        title: this.translateService.instant(this.sharedTranslateKeys.UserInformation),
        text: this.translateService.instant(this.sharedTranslateKeys.UserForm),
      },
      {
        attachTo: {
          element: '#autocomplete',
          on: 'bottom',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'autocomplete',
        title: this.translateService.instant(this.sharedTranslateKeys.OrganizationAndRoles),
        text: this.translateService.instant(this.sharedTranslateKeys.OrganizationAndRolesStep),
      },
      {
        attachTo: {
          element: '#submitForm',
          on: 'left',
        },
        scrollTo: true,
        buttons: [buttons.cancel, buttons.navigateToUsers],
        id: 'submitForm',
        text: this.translateService.instant(this.sharedTranslateKeys.SubmitForm),
      },
      {
        buttons: [buttons.cancel, buttons.next],
        id: 'usersTable',
        title: this.translateService.instant(this.sharedTranslateKeys.UsersPage),
        text: this.translateService.instant(this.sharedTranslateKeys.UsersPageMessage),
      },
      {
        attachTo: {
          element: '#quickExport',
          on: 'bottom',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'quickExport',
        title: this.translateService.instant(this.sharedTranslateKeys.TableQuickExport),
        text: this.translateService.instant(this.sharedTranslateKeys.QuickExportDesc),
      },
      {
        attachTo: {
          element: '#filterForm',
          on: 'bottom',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'filterForm',
        title: this.translateService.instant(this.sharedTranslateKeys.FiyuTableFilters),
        text: this.translateService.instant(this.sharedTranslateKeys.FiyuTableFiltersDesc),
      },
      {
        attachTo: {
          element: '#addNewFilter',
          on: 'bottom',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'moreFilters',
        title: this.translateService.instant(this.sharedTranslateKeys.AddFilters),
        text: this.translateService.instant(this.sharedTranslateKeys.AddFiltersDesc),
      },
      {
        attachTo: {
          element: '#sortForm',
          on: 'top',
        },
        buttons: [buttons.cancel, buttons.next],
        id: 'usage',
        title: this.translateService.instant(this.sharedTranslateKeys.FiyuTableSorting),
        text: this.translateService.instant(this.sharedTranslateKeys.FiyuTableSortingDesc),
      },
      {
        attachTo: {
          element: '.p-selectable-row',
          on: 'top',
        },
        classes: 'mid-modal-box',
        buttons: [buttons.cancel, buttons.openQuickView],
        id: 'quickView',
        title: this.translateService.instant(this.sharedTranslateKeys.TableQuickView),
        text: this.translateService.instant(this.sharedTranslateKeys.QuickViewDesc),
      },
      {
        attachTo: {
          element: '#aside-quick-view',
          on: 'left',
        },
        buttons: [buttons.cancel, buttons.returnToHomePage],
        id: 'quickViewOpened',
        title: this.translateService.instant(this.sharedTranslateKeys.TableQuickView),
        text: this.translateService.instant(this.sharedTranslateKeys.TableQuickViewOpened),
      },
      {
        attachTo: {
          element: '#gitlab',
          on: 'right',
        },
        scrollTo: true,
        buttons: [buttons.finish],
        id: 'gitlab',
        title: this.translateService.instant(this.sharedTranslateKeys.GetCode),
        text: this.translateService.instant(this.sharedTranslateKeys.GetCodeGitlab),
      },
    ];

    this.defaultStepOptions = {
      cancelIcon: {
        enabled: true,
      },
      when: {
        hide: () => {
          localStorage.setItem(LC_TOUR_SEEN, 'true');
        },
        cancel: () => {
          localStorage.setItem(LC_TOUR_SEEN, 'true');
        },
      },
      beforeShowPromise: async () => {
        return new Promise<void>(function (resolve) {
          setTimeout(() => {
            resolve();
          }, 100);
        });
      },
    };
  }

  startFIYUTour() {
    const isTourFinished = this.isTourSeen('tour');
    if (!isTourFinished) {
      this.startTour();
    }
  }
}
