import type { Route } from '@angular/router';
import type { Operations } from '@fiyu/api';

export type FiyuRoutes = FiyuRoute[];
export interface FiyuRoute extends Route {
  requiredPermissions?: string[];
  requiredModule?: string;
  operation?: Operations;
  children?: FiyuRoute[];
}
