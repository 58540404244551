export enum ModulePermissions {
  USER_VIEW = 'USER_VIEW',
  USER_EDIT = 'USER_EDIT',
  ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
  NMM_RUN = 'NMM_RUN',
  NMM_SEND = 'NMM_SEND',

  // Permission prefixes
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
}
