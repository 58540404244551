export class NumberUtility {
  /**
   *
   *
   * @static
   * @param {number} value
   * @param {number} [precision=2]
   * @param {string} [locale=undefined]
   * @param {boolean} [rounded=false]
   * @return {*}  {string}
   * @memberof NumberUtility
   */
  static formatNumber(value: number, precision = 2, locale: string = undefined, rounded = false): string {
    if (!value) {
      value = 0;
      precision = 0;
    }
    const numberFormatOptions: Intl.NumberFormatOptions = {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      //@ts-ignore
      trailingZeroDisplay: 'auto', // trailingZeroDisplay: 'lessPrecision',
    };
    if (Number.isInteger(value)) {
      return value.toLocaleString(locale, numberFormatOptions).replace(locale === undefined ? '.00' : ',00', '');
    } else {
      if (rounded) {
        // if you want to round up numbers;
        numberFormatOptions.maximumSignificantDigits = Math.trunc(Math.abs(value)).toFixed().length;
      }
      return value.toLocaleString(locale, numberFormatOptions).replace(locale === undefined ? '.00' : ',00', '');
    }
  }
}
