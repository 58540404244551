import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import type { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { ModulePrefixes, type Profile, type ProfileOrganization } from '@fiyu/api';
import { of, ReplaySubject } from 'rxjs';
import { concatMap, map, share } from 'rxjs/operators';
import { CoreService } from './../core/core.service';
import { ProfileService } from './../core/profile.service';
import { EnvironmentService } from './../environment/environment.service';
import type { SecurityToken } from './security-token.model';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class AzureService {
  public azureLoginUrl = '/azure/oauth2/token';
  public loggedInAzureAccount: AccountInfo;
  public isLoggedInAzure = false;
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly envService: EnvironmentService = inject(EnvironmentService);
  private readonly coreService: CoreService = inject(CoreService);
  private readonly securityService: SecurityService = inject(SecurityService);
  private readonly router: Router = inject(Router);
  private readonly profileService: ProfileService = inject(ProfileService);
  private readonly azureAuthService: MsalService = inject(MsalService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  /**
   * Authenticate user on the platform with Azure AD
   *
   * @param idToken
   */
  loginAzure(idToken: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.httpClient
      .post<SecurityToken>(
        this.envService.getModuleApiURL(ModulePrefixes.USER) + this.azureLoginUrl,
        { idToken },
        {
          headers: headers,
        },
      )
      .pipe(
        map((res) => {
          // set tokens to local storage
          this.securityService.setLocalData(res);
          return res;
        }),
        share({
          connector: () => new ReplaySubject(3),
          resetOnComplete: false,
          resetOnError: false,
          resetOnRefCountZero: false,
        }),
      );
  }

  /**
   * Start azure login redirect
   */
  public startAzureRedirect(): void {
    const request = {
      scopes: ['openid'],
      prompt: 'select_account', // remove if you wanna automatically login without popup
    };

    this.azureAuthService.loginRedirect(request);
  }

  /**
   * Handle azure login redirect callback
   */
  public handleAzureRedirectCallback(): void {
    this.azureAuthService
      .handleRedirectObservable()
      .pipe(
        concatMap((result: AuthenticationResult) => {
          this.getAzureAccount(result.account);
          return this.loginAzure(result.idToken).pipe(
            concatMap((_token: SecurityToken) => {
              return this.profileService.getUserProfile().pipe(
                concatMap((profile: Profile) => {
                  if (!profile) {
                    this.isLoggedInAzure = false;
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.router.navigateByUrl('login');
                  } else {
                    this.coreService.setUser(profile);
                    this.isLoggedInAzure = true;
                  }
                  return this.profileService.getUserOrganizations().pipe(
                    // eslint-disable-next-line max-nested-callbacks
                    concatMap((organizations: ProfileOrganization[]) => {
                      this.coreService.setUserOrganizations(organizations);
                      this.coreService.setCurrentOrganization(organizations[0] ? organizations[0].id : null);
                      return of(organizations);
                    }),
                  );
                }),
              );
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        error: (error: unknown) =>
          console.warn(
            `Login with Microsoft failed. Something bad happened; please try again later. Original Error: ${JSON.stringify(
              error,
            )}`,
          ),
        complete: () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.router.navigateByUrl('');
        },
      });
  }

  /**
   * Logout Azure account
   */

  public logoutAzure() {
    if (!!this.loggedInAzureAccount) {
      this.azureAuthService.logoutRedirect({ account: this.loggedInAzureAccount });
    }
  }

  /**
   * Get Azure account
   */
  private getAzureAccount(account: AccountInfo) {
    this.loggedInAzureAccount = this.azureAuthService.instance.getAccountByUsername(account.username);
  }
}
