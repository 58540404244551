import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentSettings } from './environment-settings.model';
import { EnvironmentType } from './environment-type.enum';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService extends EnvironmentSettings {
  /**
   * used for getting generic moduleApiUrl
   */
  [key: `${string}ModuleApiUrl`]: string;
  public environment: EnvironmentType | string = EnvironmentType.Development;
  /**
   * Use this observable to notify app that config is loaded successfully and ready for usage
   */
  public readyObservable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   *
   * @param moduleAlias string
   */
  getModuleApiURL(moduleAlias: string): string {
    // moduleAliases: ['DMM','EMM','NMM','ORGANIZATION','PLATFORM','USER']
    if ((this as any)[`${moduleAlias}ModuleApiUrl`]) {
      return (this as any)[`${moduleAlias}ModuleApiUrl`];
    } else {
      throw new Error(`${moduleAlias}ModuleApiUrl as environment variable is undefined.`);
    }
  }

  /**
   * Return shouldEncrypt flag
   * Dev and local envs don't encrypt passwords
   */
  getShouldEncrypt(): boolean {
    return this.shouldEncrypt;
  }

  isDemo(): boolean {
    return this.appHostUrl.includes('demo');
  }
}
