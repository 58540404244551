import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@fiyu/core';
import { ModuleMetadata } from '../module-metadata';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends AbstractCRUDService {
  constructor() {
    super('/users', ModuleMetadata.getInstance());
  }
}
