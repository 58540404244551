import { Observable } from 'rxjs';

import { Acl } from '../acl/acl-user-privilege.model';

import { HttpContext } from '@angular/common/http';
import { SKIP_CACHE_HEADER } from '@fiyu/api';
import { EntityOptionsEnum } from '../entity/entity-options.enum';
import { AbstractCRUDService } from './abstract-crud.service';

/**
 * Abstract class with ACL support methods
 */
export abstract class AbstractAclCRUDService extends AbstractCRUDService {
  /**
   * Create ACL for an object
   *
   * @param entity
   * @returns Observable<Acl>
   */
  public createAcl(entity: Acl): Observable<Acl> {
    return this.httpClient.post<Acl>(
      `${this.apiUrl}/acl`,
      entity,
      this.getHttpOptions(entity.getEntityOption(EntityOptionsEnum.ContentType))
    );
  }

  /**
   * Update ACL for an object
   *
   * @param entity
   */
  public updateAcl(entity: Acl) {
    return this.httpClient.put<Acl>(
      `${this.apiUrl}/acl`,
      entity,
      this.getHttpOptions(entity.getEntityOption(EntityOptionsEnum.ContentType))
    );
  }

  /**
   * Get ACL object with list of users permissions
   *
   * @param id
   */
  public getAcl(id: string): Observable<Acl> {
    return this.httpClient.get<Acl>(`${this.apiUrl}/acl/${id}`, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    });
  }
}
