export enum FormControlType {
  Autocomplete = 'autocomplete',
  Checkbox = 'checkbox',
  ColorPicker = 'colorpicker',
  Date = 'date',
  DateTime = 'datetime',
  Dropdown = 'dropdown',
  DropdownFilter = 'dropdown-filter',
  InputCurrency = 'currency',
  InputFile = 'file',
  InputMask = 'input-mask',
  InputNumber = 'number',
  InputPassword = 'password',
  InputRating = 'rating',
  InputSwitch = 'input-switch',
  InputText = 'input',
  MultipleDate = 'multiple-date',
  MultiSelect = 'multi-select',
  RadioButtonGroup = 'radio-button-group',
  SelectButton = 'select-button',
  Spinner = 'spinner',
  TextArea = 'textarea',
  Template = 'template',
  Time = 'time',
  Upload = 'upload',
}
