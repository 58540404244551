
  // This file was autogenerated by dynamically running nx run showcase:generate-env-files --envTarget=dev and using dotenv for managing API key secrecy
  export const environment = {
      name: 'dev',
      production: false,
      shouldEncrypt: false,
      appHostUrl: 'https://fiyu-dev.getihub.com',
      userModuleApiUrl: 'https://fiyu-dev-user.getihub.com/user',
      organizationModuleApiUrl: 'https://fiyu-dev-organization.getihub.com/organization',
      platformModuleApiUrl: 'https://fiyu-dev-platform.getihub.com/platform',
      crmModuleApiUrl: 'https://fiyu-dev-crm.getihub.com/crm',
      nmmModuleApiUrl: 'https://fiyu-dev-nmm.getihub.com/nmm',
      dmmModuleApiUrl: 'https://fiyu-dev-document.getihub.com/dmm',
      emmModuleApiUrl: 'https://fiyu-dev-email.getihub.com/emm',
      jwtClientId: 'getihub_ui',
      jwtClientSecret: '217YlCPsF7O9OCNK3pLfESdtf6jKONYoXc2Xx8TJTCi2WEZs49',
      azureAuthEnabled: false,
      azureAuthClientId: '8cf9379b-4262-4f96-9826-8a299c65b4d2',
      azureAuthTenantId: '03f3fe41-af79-4720-bd8c-d01a63b1a919',
      firebase: {
        apiKey: 'AIzaSyBuRhvyAxmQ84VtGLm9zIfwiRavvHuIRUg',
        authDomain: 'serengeti3-5d2bd.firebaseapp.com',
        databaseURL: 'https://serengeti3-5d2bd-default-rtdb.europe-west1.firebasedatabase.app/',
        projectId: 'serengeti3-5d2bd',
        storageBucket: 'serengeti3-5d2bd.appspot.com',
        messagingSenderId: '35713874850',
        appId: '1:35713874850:web:1cd046b745ea18604110fb',
        measurementId: 'G-WWVJX66LNT',
      },
      firebaseVapidKey: 'BNNJTJI5edaCF5QDYCfubI7ZGCCuhvR2C-jIf08Bq58plRyGmK-9nSYwLcaLGecKIZ6p9MaHv9WCzjc2XgDBeOY',
      firebaseRecaptchaSiteKey: '6LcLqfgkAAAAAF2MasjrpBtQeO0lcyc-y9AX71rq',
      firebaseRecaptchaSecretKey: '6LcLqfgkAAAAAOMjMNMILrpd8Q2wrURoZCG2nkjJ',
      googleTagManagerTrackingId: 'GTM-W7JJT9V',
      clarityProjectId: 'i48wy67r6v',
      logToLoki: false,
      logstashHostUrl: 'https://localhost:33333',
      lokiLogUrl: 'http://localhost:31000/loki/api/v1/push',
      corvusPayStoreId: 25648,
      corvusPaySecretKey: 'ecYFiFMG7BVdqDZcMbEuKeGBt',
      corvusPayPaymentGatewayUrl: 'https://test-wallet.corvuspay.com/checkout',
  };
