import { inject, Injectable } from '@angular/core';
import { SharedTranslateKeys } from '@fiyu/api';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { FiyuError } from '../errors/fiyu-error';
import { FiyuValidationError } from '../errors/validation/fiyu-validation-error';
import { ValidationError } from '../errors/validation/validation-error';
import { Severity } from './severity.enum';
import { ToastKeys } from './toast-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly translateService = inject(TranslateService);
  private readonly messageService = inject(MessageService);

  notify(
    key: string,
    severity: string,
    summary: string,
    detail: string,
    sticky?: boolean,
    life?: number,
    closable?: boolean
  ) {
    this.messageService.add({
      key,
      severity,
      summary,
      detail,
      sticky,
      life,
      closable,
    });
  }

  notifySuccess(toastKey: ToastKeys, detail: string, summary = '', isSticky?: boolean) {
    const messages = this.translateMessage(detail, summary);
    this.notify(toastKey, Severity.Success, messages.summary, messages.detail, isSticky);
  }

  notifyInfo(toastKey: ToastKeys, detail: string, summary = '', isSticky?: boolean) {
    const messages = this.translateMessage(detail, summary);
    this.notify(toastKey, Severity.Info, messages.summary, messages.detail, isSticky);
  }

  notifyWarning(toastKey: ToastKeys, detail: string, summary = '', isSticky?: boolean) {
    const messages = this.translateMessage(detail, summary);
    this.notify(toastKey, Severity.Warning, messages.summary, messages.detail, isSticky);
  }

  notifyError(toastKey: ToastKeys, detail: string, summary = '', isSticky?: boolean) {
    const messages = this.translateMessage(detail, summary);
    this.notify(toastKey, Severity.Error, messages.summary, messages.detail, isSticky);
  }

  notifyFiyuError(error: FiyuError) {
    const translatedMessage = error.message
      ? this.translateService.instant(error.message, error.parameters)
      : this.translateService.instant(SharedTranslateKeys.ErrorToastMessage);
    this.notify(error.toastKey as string, error.severity as string, error.name, translatedMessage, true);
  }

  notifyFiyuValidationError(error: FiyuValidationError) {
    error.validationErrors?.forEach((validationError) => {
      const translatedMessage = this.translateValidationErrorMessage(validationError);
      this.notify(
        error.toastKey as string,
        error.severity as string,
        translatedMessage.summary,
        translatedMessage.detail,
        true
      );
    });
  }

  notifyPushNotification(notification: any, data: any) {
    const title = this.translateService.instant(notification.notificationTitle);
    const message = notification.notificationMessage
      ? this.translateService.instant(notification.notificationMessage, data)
      : notification.notificationMessage;

    this.notify(ToastKeys.FiyuKey, Severity.Info, title, message);
  }

  private translateMessage(detail: string, summary: string) {
    detail = detail ? this.translateService.instant(detail) : detail;
    summary = summary ? this.translateService.instant(summary) : summary;

    return {
      detail: detail,
      summary: summary,
    };
  }

  private translateValidationErrorMessage(validationError: ValidationError) {
    const summary = this.translateService.instant(SharedTranslateKeys.ErrorValidationMessage);

    let parameters: Record<string, any> = {};
    if (validationError.error.parameters) {
      parameters = validationError.error.parameters;
    }
    parameters.field = validationError.field;
    parameters.value = validationError.value;

    const detail: string = validationError.message
      ? this.translateService.instant(validationError.message, parameters)
      : this.translateService.instant(SharedTranslateKeys.ErrorToastMessage);

    return {
      detail: detail,
      summary: summary,
    };
  }
}
