import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { DocumentsModuleMetadataService } from './documents-module-metadata.service';

export function provideDocumentsModuleProviders(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: 'DmmModuleMetadataService',
      useClass: DocumentsModuleMetadataService,
    },
  ]);
}
