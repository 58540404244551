/**
 * Check if string is null or empty, for use in inputTransform
 * @param value string
 * @returns string | null
 */
export function inputTransformStringNullCheck(value: string): string | null {
  const isNull = value === undefined || value === null || value === '';
  return isNull ? null : value;
}

// higher order function
export const higherOrderFn =
  <T>(defaultVal: T) =>
  (v: string) =>
    v || defaultVal;

/**
 * array coerce
 * @param value T | T[]
 * @usage Input transform for array
 * ```ts
 *  skills = input.required({
 *    transform: coerceArray<string>,
 *  })
 * ```
 */
export function coerceArray<T>(value: T | T[]): T[];
export function coerceArray<T>(value: T | readonly T[]): readonly T[];
export function coerceArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}
