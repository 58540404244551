import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

_('common.paymentMethod');
_('common.paymentMethods');
_('common.plan');
_('common.plans');
_('common.account');
_('common.accountCode');
_('common.accountName');
_('common.accounts');
_('common.paymentMethodId');
_('common.managerId');
_('common.managerName');
_('common.resourceType');
_('common.billingName');
_('common.billingAddress');
_('common.billingVatId');
_('common.billingEmail');
_('common.notificationEmail');
_('common.verificationCodes');
_('common.resource');
_('common.resources');
_('common.resourcesNote');
_('common.resourcesNoteContinuation');
_('common.provisioning');
_('common.provisionings');
_('common.deprovisioning');
_('common.deprovisionings');
_('common.planFeature');
_('common.planFeatures');
_('common.bill');
_('common.billAssociatedItems');
_('common.billName');
_('common.bills');
_('common.billItem');
_('common.billItems');
_('common.billingSummary');
_('common.summary');
_('common.dueDate');
_('common.amount');
_('common.period');
_('common.creditCard');
_('common.expirationDate');
_('common.downloadReceipt');
_('doc.base');
_('doc.boardMember');
_('doc.contract');
_('doc.customer');
_('doc.dateOfCurrency');
_('doc.dateOfPayment');
_('doc.identification');
_('doc.invoice');
_('doc.invoiceFooterMessage');
_('doc.internalNumber');
_('doc.OIB');
_('doc.operatorCode');
_('doc.orderForm');
_('doc.paymentInfoMessage');
_('doc.placeAndDate');
_('doc.productName');
_('doc.rebate');
_('doc.responsiblePerson');
_('doc.signature');
_('doc.quantity');
_('doc.taxFooterMessage');
_('doc.taxNumber');
_('doc.taxRates');
_('doc.timeOfCreation');
_('doc.total');
_('doc.totalToPay');
_('doc.unit');
_('doc.value');
_('doc.valueNoVATAdded');
_('doc.VAT');
_('doc.VATNumber');

export enum TranslateKeys {
  PaymentMethod = 'common.paymentMethod',
  PaymentMethods = 'common.paymentMethods',
  Plan = 'common.plan',
  Plans = 'common.plans',
  Account = 'common.account',
  AccountName = 'common.accountName',
  Accounts = 'common.accounts',
  PaymentMethodId = 'common.paymentMethodId',
  ManagerId = 'common.managerId',
  ManagerName = 'common.managerName',
  AccountCode = 'common.accountCode',
  BillingName = 'common.billingName',
  BillingAddress = 'common.billingAddress',
  BillingVatId = 'common.billingVatId',
  BillingEmail = 'common.billingEmail',
  NotificationEmail = 'common.notificationEmail',
  VerificationCodes = 'common.verificationCodes',
  Resource = 'common.resource',
  ResourceType = 'common.resourceType',
  Resources = 'common.resources',
  ResourcesNote = 'common.resourcesNote',
  ResourcesNoteContinuation = 'common.resourcesNoteContinuation',
  Provisioning = 'common.provisioning',
  Provisionings = 'common.provisionings',
  Deprovisioning = 'common.deprovisioning',
  Deprovisionings = 'common.deprovisionings',
  PlanFeature = 'common.planFeature',
  PlanFeatures = 'common.planFeatures',
  Bill = 'common.bill',
  BillAssociatedItems = 'common.billAssociatedItems',
  BillName = 'common.billName',
  Bills = 'common.bills',
  BillItem = 'common.billItem',
  BillItems = 'common.billItems',
  BillingSummary = 'common.billingSummary',
  Summary = 'common.summary',
  DueDate = 'common.dueDate',
  Amount = 'common.amount',
  Period = 'common.period',
  CreditCard = 'common.creditCard',
  DownloadReceipt = 'common.downloadReceipt',
  ExpirationDate = 'common.expirationDate',
  Base = 'doc.base',
  BoardMember = 'doc.boardMember',
  Contract = 'doc.contract',
  Customer = 'doc.customer',
  DateOfCurrency = 'doc.dateOfCurrency',
  DateOfPayment = 'doc.dateOfPayment',
  Identification = 'doc.identification',
  Invoice = 'doc.invoice',
  InvoiceFooterMessage = 'doc.invoiceFooterMessage',
  InternalNumber = 'doc.internalNumber',
  OIB = 'doc.OIB',
  OperatorCode = 'doc.operatorCode',
  OrderForm = 'doc.orderForm',
  PaymentInfoMessage = 'doc.paymentInfoMessage',
  PlaceAndDate = 'doc.placeAndDate',
  ProductName = 'doc.productName',
  Rebate = 'doc.rebate',
  ResponsiblePersonForInvoice = 'doc.responsiblePersonForInvoice',
  Signature = 'doc.signature',
  Quantity = 'doc.quantity',
  TaxFooterMessage = 'doc.taxFooterMessage',
  TaxNumber = 'doc.taxNumber',
  TaxRates = 'doc.taxRates',
  CreatedAt = 'doc.createdAt',
  Total = 'doc.total',
  TotalToPay = 'doc.totalToPay',
  Unit = 'doc.unit',
  Value = 'doc.value',
  ValueNoVATAdded = 'doc.valueNoVATAdded',
  VAT = 'doc.VAT',
  VATNumber = 'doc.VATNumber',
}
