import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SKIP_CACHE_HEADER } from '@fiyu/api';
import { AbstractCRUDService } from '@fiyu/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleMetadata } from '../module-metadata';
import { OrganizationAdminRole } from './organization-admin-role.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationAdminService extends AbstractCRUDService {
  constructor() {
    super('/organizations', ModuleMetadata.getInstance());
  }

  getRoles(id: string): Observable<OrganizationAdminRole[]> {
    return this.httpClient
      .get<OrganizationAdminRole[]>(`${this.apiUrl}/${id}/roles`, {
        context: new HttpContext().set(SKIP_CACHE_HEADER, true),
      })
      .pipe(map((response) => response as OrganizationAdminRole[]));
  }
}
