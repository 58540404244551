import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LC_THEME } from './../../models/storage/local-storage-keys';
import {
  ColorScheme,
  InputStyle,
  MenuMode,
  PrimeNgThemeEnum,
  type ThemeConfig,
} from './../../models/theme/theme.model';
import { defaultThemeConfig } from './../../tokens/theme-config-token';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly themeSubject = new BehaviorSubject<string>(PrimeNgThemeEnum.LIGHT_TEAL);
  public theme$ = this.themeSubject.asObservable();

  setTheme(theme: string) {
    this.themeSubject.next(theme);
  }

  setupTheme(theme: string): ThemeConfig {
    if (theme) {
      // check if user has defined a theme in user preferences, if not use org theme
      if (this.isUserThemeDefined()) {
        theme = localStorage.getItem(LC_THEME);
      }
      const schema: string = theme.split(' ')[0].toLowerCase();
      const color: string = theme.split(' ')[1].toLowerCase();
      const colorSchema: ColorScheme =
        schema === ColorScheme.Light
          ? ColorScheme.Light
          : schema === ColorScheme.Dark
            ? ColorScheme.Dark
            : ColorScheme.Dim;
      const menuTheme = `layout-sidebar-${color}`;
      const newTheme: ThemeConfig = {
        menuMode: MenuMode.Static,
        colorScheme: colorSchema,
        menuTheme: menuTheme,
        inputStyle: InputStyle.Outlined,
      };
      const themeImport = document.getElementById('theme-css') as HTMLAnchorElement;
      const layoutImport = document.getElementById('layout-css') as HTMLAnchorElement;
      themeImport.href = `/assets/theme/${color}/theme-${colorSchema}.css`;
      layoutImport.href = `/assets/layout/css/layout-${colorSchema}.css`;
      return newTheme;
    }
    return defaultThemeConfig;
  }
  isUserThemeDefined(): boolean {
    return localStorage.getItem(LC_THEME) !== null;
  }
}
