import type { Type } from '@angular/core';
import type { AbstractCRUD } from '../crud/abstract-crud.interface';
import type { AbstractEntity } from '../entity/abstract-entity.model';
import type { Nullable } from '../types/nullable-type';

export interface DisplayConfiguration {
  propertyName: string;
  propertyDisplayName: string;
  isTableColumn: boolean;
  isInQuickView?: boolean;
  isInQuickViewPanelSection?: boolean;
  renderer?: string | null;
  rendererValueRecord?: boolean;
  rendererFunction?: Nullable<() => void>;
  rendererAsyncFunction?: Nullable<() => void>;
  useCustomComponent?: boolean;
  componentRenderType?: string;
  useCustomTemplate?: boolean;
  type?: string;
  disableFilter?: boolean;
  disableSort?: boolean;
  enumModel?: Type<any>;
  columnWidthPercentage?: number;
  lookupModel?: Type<AbstractEntity> | null;
  lookupService?: Type<AbstractCRUD> | null;
  criterionField?: string;
  customDateFormat?: Nullable<string>;
  isColumnFrozen?: boolean;
  columnFrozenAlignment?: 'left' | 'right';
}
