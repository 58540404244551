import { HttpErrorResponse } from '@angular/common/http';
import { computed, DestroyRef, inject, Injectable, signal, type Signal, type WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, firstValueFrom, ReplaySubject, tap, throwError } from 'rxjs';
import type { FeatureFlag, FeatureFlags } from './../../types/feature-flags';
import type { FeatureFlagsState } from './../state/feature-flags-state.model';
import { FeatureFlagsDataService } from './feature-flags-data.service';

const initialState: Readonly<FeatureFlagsState> = {
  featureFlags: null,
};

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsFacadeService {
  readonly #featureFlagsDataService: FeatureFlagsDataService = inject(FeatureFlagsDataService);
  readonly #destroyRef: DestroyRef = inject(DestroyRef);
  readonly #state: WritableSignal<Readonly<FeatureFlagsState>> = signal(initialState);
  readonly #configLoadedSubject: ReplaySubject<boolean> = new ReplaySubject();
  readonly configLoaded$ = this.#configLoadedSubject.asObservable();

  readonly featureFlags: Signal<FeatureFlags> = computed(() => this.#state().featureFlags);

  async loadFeatureFlags(): Promise<FeatureFlags> {
    return firstValueFrom(
      this.#featureFlagsDataService.getFeatureFlags().pipe(
        tap({
          next: (featureFlags: FeatureFlags) => {
            this.#state.set({
              featureFlags,
            });
            this.#configLoadedSubject.next(true);
            this.#configLoadedSubject.complete();
          },
        }),
        catchError((error: unknown) => throwError(() => new HttpErrorResponse({ error: error, status: 422 }))),
        takeUntilDestroyed(this.#destroyRef),
      ),
    );
  }

  featureFlagEnabled(featureFlag: FeatureFlag): boolean {
    return this.featureFlags()?.[featureFlag] ?? false;
  }
}
