export enum PresentationOptionsEnum {
  Primary = 'primary',
  DisplayName = 'displayName',
  TableDisplay = 'tableDisplay',
  QuickViewDisplay = 'quickViewDisplay',
  QuickViewPanelSectionDisplay = 'quickViewPanelSectionDisplay',
  ColumnWidth = 'columnWidth',
  Hidden = 'hidden',
  Type = 'type',
  Renderer = 'renderer',
  RendererValueRecord = 'rendererValueRecord',
  RendererFunction = 'rendererFunction',
  RendererAsyncFunction = 'rendererAsyncFunction',
  DisableFilter = 'disableFilter',
  DisableSort = 'disableSort',
  EnumModel = 'enumModel',
  DisableRowActionsProperty = 'disableRowActionsProperty',
  SuggestionSearch = 'suggestionSearch',
  RequiredPermissions = 'requiredPermissions',
  LookupModel = 'lookupModel',
  LookupService = 'lookupService',
  CustomDateFormat = 'customDateFormat',
  CriterionField = 'criterionField',
}
