export interface ValidatorOptions {
  required?: boolean;
  requiredTrue?: boolean;
  min?: number;
  max?: number;
  email?: boolean;
  phoneNumber?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  password?: boolean;
  equalFields?: string;
  hoursMinutes?: { clock: 12 | 24 | false };
  compareDate?: {
    operator: string;
    compareWith: string;
  };
  timeLessThan?: string;
  timeGreaterThan?: string;
  dateLessThan?: string;
  dateGreaterThan?: string;
  dateGreaterOrEqualThan?: string;
  dateLessOrEqualThan?: string;
  notOnlySpaces?: boolean;
}
