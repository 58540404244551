/**
 *
 * @description Function to play audio from api response.
 * @param {string} srcPath path or base64 string
 * @param {HTMLAudioElement} audioTrack audio element
 */
const playAudio = (srcPath: string, audioTrack: HTMLAudioElement) => {
  audioTrack.src = srcPath; // http://localhost:4200/assets/audios/test.mp3
  audioTrack.load();
  audioTrack.play();
};

const AudioUtils = { playAudio };
export { AudioUtils };
