import { InjectionToken } from '@angular/core';
import type { ModuleMetadataInterface } from './../models/module/module-metadata.interface';

/**
 * Token is used when providing module metadata.
 * Create module-metadata.ts file for module metadata and provide as token via module route providers
 * @usageNotes
 * ```typescript
 * import { ModuleMetadata } from './module-metadata';
 * import { MODULE_METADATA_TOKEN } from '@fiyu/api';
 * { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() }
 * ```
 */
export const MODULE_METADATA_TOKEN = new InjectionToken<ModuleMetadataInterface>('moduleMetadata');
