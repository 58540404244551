import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Injector, NgZone, type ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { SharedTranslateKeys, type ValidationErrorsConfig } from '@fiyu/api';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackService } from '../core/feedback.service';
import { EnvironmentService } from '../environment/environment.service';
import { SecurityService } from '../security/security.service';
import { FiyuError } from './fiyu-error';
import { FiyuException } from './fiyu-exception';
import { FiyuValidationError } from './validation/fiyu-validation-error';
import type { ValidationError } from './validation/validation-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  private readonly injector: Injector = inject(Injector);
  private readonly ngZone: NgZone = inject(NgZone);

  // using injector because ErrorHandler is created before the providers
  get environmentService(): EnvironmentService {
    return this.injector.get(EnvironmentService);
  }

  get feedbackService(): FeedbackService {
    return this.injector.get(FeedbackService);
  }

  get translateService(): TranslateService {
    return this.injector.get(TranslateService);
  }

  get securityService(): SecurityService {
    return this.injector.get(SecurityService);
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get fiyuException(): FiyuException {
    return this.injector.get(FiyuException);
  }

  /** central fiyu error handler
   * @param error
   */

  handleError(error: any): void {
    this.ngZone.run(() => {
      // show errors on console only on local
      if (!this.environmentService.logToLoki) {
        console.error(error);
      }
      if (error instanceof FiyuValidationError) {
        this.handleFiyuValidationError(error);
      } else if (error instanceof FiyuError) {
        this.handleFiyuError(error);
      } else if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 400: {
            this.handle400Error(error);
            break;
          }
          case 401: {
            this.handle401Error(error);
            break;
          }
          default: {
            // handle other HTTP error response
            const messages = this.translateMessage(this.fiyuException.Fiyu0().message, this.fiyuException.Fiyu0().name);

            this.feedbackService.errorToast(messages.detail, messages.summary);
          }
        }
      } else {
        // FIXME: implement list of errors which will not be displayed in toast
        if (error.message && !error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError')) {
          const messages = this.translateMessage(this.fiyuException.Fiyu0().message, this.fiyuException.Fiyu0().name);
          console.error(messages);
          //    this.feedbackService.errorToast(messages.detail, messages.summary);
        }
      }
    });
  }

  /**
   * Handle 400 error - do logout user
   * @param error
   */
  private handle400Error(error: any) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.logoutUser();
    }
  }

  /**
   * Handle 401 error - do logout user
   * @param error
   */
  private handle401Error(error: any) {
    if (error && error.status === 401 && error.error && error.error.error === 'invalid_token') {
      // If we get a 401 and the error message is 'invalid_token', the refresh token is no longer valid so logout.
      this.logoutUser();
    } else {
      this.feedbackService.warningToast(
        SharedTranslateKeys.ErrorMissingPrivileges,
        SharedTranslateKeys.ErrorUnauthorized,
      );
    }
  }

  private handleFiyuError(error: FiyuError) {
    const translatedMessage = this.translateMessage(error.message, error.name);
    this.feedbackService.errorToast(translatedMessage.detail, translatedMessage.summary);
  }

  private handleFiyuValidationError(error: any) {
    error.validationErrors.forEach((validationError: any) => {
      const translatedMessage = this.translateValidationErrorMessage(validationError);
      this.feedbackService.errorValidationMessage(translatedMessage.detail);
    });
  }

  private translateValidationErrorMessage(validationError: ValidationError) {
    const summary = this.translateService.instant(SharedTranslateKeys.ErrorValidationMessage);

    let parameters: Record<string, any> = {};
    if (validationError.error.parameters) {
      parameters = validationError.error.parameters;
    }
    parameters.field = validationError.field;
    parameters.value = validationError.value;

    if (validationError.error.key) {
      const veByKey: ValidationError | undefined = this.fiyuException.findValidationErrorByKey(
        validationError.error.key,
      );
      validationError.message = veByKey?.message || '';
    }

    const detail = validationError.message
      ? this.translateService.instant(validationError.message, parameters)
      : this.translateService.instant(SharedTranslateKeys.ErrorToastMessage);

    return {
      detail: detail,
      summary: summary,
    };
  }

  private translateMessage(detail: string, summary: string) {
    detail = detail ? this.translateService.instant(detail) : detail;
    summary = summary ? this.translateService.instant(summary) : summary;

    return {
      detail: detail,
      summary: summary,
    };
  }

  /**
   * Logout user and redirect to login page
   */
  private logoutUser() {
    this.securityService.logout();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigateByUrl('/login');
  }

  public showServerValidationErrors(
    error: FiyuValidationError | unknown,
    validationErrorsConfig: ValidationErrorsConfig,
  ) {
    if (
      (error as FiyuValidationError)?.validationErrors === null ||
      (error as FiyuValidationError)?.validationErrors === undefined
    ) {
      const validationError: ValidationError = validationErrorsConfig.validationErrors.find(
        (v: ValidationError) => v.error.key === (error as FiyuValidationError).name,
      );
      this.feedbackService.errorBarMessage(
        this.translateService.instant(
          (<FiyuValidationError>error)?.message ??
            validationError?.message ??
            SharedTranslateKeys.ErrorValidationMessage,
        ),
      );
    } else if (
      (error as FiyuValidationError)?.validationErrors &&
      (error as FiyuValidationError)?.validationErrors.length > 0
    ) {
      (error as FiyuValidationError).validationErrors.forEach((validationError: ValidationError) => {
        this.feedbackService.errorBarMessage(this.translateValidationErrorMessage(validationError).detail);
      });
    } else this.feedbackService.errorBarMessage(this.translateService.instant(SharedTranslateKeys.ErrorGeneralError));
  }
}
