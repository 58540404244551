import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';
import { OrganizationUserType } from '../organization-user-types/organization-user-type.model';
import { OrganizationUserTypeService } from '../organization-user-types/organization-user-type.service';
import { EmployeeService } from './employee.service';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.OrganizationEmployee,
  pluralName: SharedTranslateKeys.OrganizationEmployees,
  singularUrlName: 'employee',
  pluralUrlName: 'employees',
  contentType: 'application/user.organization.list.v1+json',
  labelField: 'fullName',
  defaultSort: { field: 'fullName', order: SortDirection.Ascending, type: 'string' },
  permissionPrefix: ModulePermissions.ORGANIZATION,
  quickExportEnabled: true,
})
export class EmployeeListItem extends AbstractEntity {
  @PresentationEntity({
    displayName: SharedTranslateKeys.FullName,
  })
  fullName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Email,
  })
  email: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.InternalUsername,
  })
  internalUsername: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.UserType,
    lookupService: OrganizationUserTypeService,
    lookupModel: OrganizationUserType,
  })
  userType: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Superior,
    lookupService: EmployeeService,
    lookupModel: EmployeeListItem,
  })
  superior: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.ValidFrom,
    renderer: 'dateFormat',
  })
  validFrom: Date;

  @PresentationEntity({
    displayName: SharedTranslateKeys.ValidTo,
    renderer: 'dateFormat',
  })
  validTo: Date;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Active,
    renderer: 'checkCross',
  })
  active: boolean;
}
