import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity, PresentationEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

export enum OrganizationUserTypeClassificationEnum {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.UserType,
  pluralName: SharedTranslateKeys.UserTypes,
  contentType: 'application/organization.user.type.v1+json',
  singularUrlName: 'organization-user-type',
  pluralUrlName: 'organization-user-types',
  permissionPrefix: ModulePermissions.ORGANIZATION_USER_TYPE,
  modLog: 'C',
})
export class OrganizationUserType extends AbstractEntity {
  @FormEntity({
    label: SharedTranslateKeys.Name,
    required: true,
  })
  @PresentationEntity({
    displayName: SharedTranslateKeys.Name,
  })
  name: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    label: SharedTranslateKeys.Classification,
    lookupOptions: [
      {
        label: OrganizationUserTypeClassificationEnum.INTERNAL,
        value: OrganizationUserTypeClassificationEnum.INTERNAL,
      },
      {
        label: OrganizationUserTypeClassificationEnum.EXTERNAL,
        value: OrganizationUserTypeClassificationEnum.EXTERNAL,
      },
    ],
    lookupEmpty: false,
    required: true,
  })
  @PresentationEntity({
    displayName: SharedTranslateKeys.Classification,
  })
  classification: string;
}
