import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

_('EQUAL');
_('IEQUAL');
_('GREATER_EQUAL');
_('GREATER');
_('IN');
_('NOT_IN');
_('NOT_NULL');
_('IS_NULL');
_('LESS_EQUAL');
_('LESS');
_('NOT_EQUAL');
_('BEGINS');
_('IBEGINS');
_('ENDS');
_('IENDS');
_('CONTAINS');
_('ICONTAINS');
_('common.about');
_('common.aboutUs');
_('common.academy');
_('common.acceptLicenceAgreement');
_('common.acceptStoringTermsInfoMessage');
_('common.account');
_('common.accountInformation');
_('common.accountLogin');
_('common.actions');
_('common.verificationCode');
_('common.active');
_('common.activate');
_('common.activeOnPlatform');
_('common.add');
_('common.addNew');
_('common.alias');
_('common.allow');
_('common.and');
_('common.application');
_('common.applications');
_('common.apply');
_('common.areYouSureYouWantToDeleteThisRecord');
_('common.ascending');
_('common.availableApplications');
_('common.availableModules');
_('common.backendDocs');
_('common.billingPeriod');
_('common.billingFrequency');
_('common.billingType');
_('common.birthdate');
_('common.businessModules');
_('common.cancel');
_('common.changePassword');
_('common.characters');
_('common.chooseResponsiblePerson');
_('common.chooseUserType');
_('common.chosenPlanType');
_('common.classification');
_('common.clear');
_('common.collapse');
_('common.coreModules');
_('common.confirm');
_('common.create');
_('common.created');
_('common.createdBy');
_('common.createdTime');
_('common.currency');
_('common.dateOfCreation');
_('common.deactivate');
_('common.defaultModule');
_('common.delete');
_('common.deleted');
_('common.deletedBy');
_('common.deletedGlobal');
_('common.deletedOrganization');
_('common.deploymentForDevelopment');
_('common.deploymentForProduction');
_('common.description');
_('common.descending');
_('common.devOpsPortal');
_('common.discard');
_('common.displayName');
_('common.document');
_('common.documents');
_('common.documentation');
_('common.domain');
_('common.domainName');
_('common.download');
_('common.edit');
_('common.elk');
_('common.email');
_('common.emailAddress');
_('common.emailUs');
_('common.emailUsForSupport');
_('common.employee');
_('common.employees');
_('common.employmentEndsOn');
_('common.employmentStartsFrom');
_('common.enabled');
_('common.enableNotificationsMessage');
_('common.enableNotificationsNote');
_('common.enterDescription');
_('common.enterDomain');
_('common.enterEmail');
_('common.enterEmailAddress');
_('common.enterFirstName');
_('common.enterIdentificationNumber');
_('common.enterInternalCode');
_('common.enterInternalUsername');
_('common.enterLastName');
_('common.enterNotificationEmailAddress');
_('common.enterOrganizationName');
_('common.enterOrganizationType');
_('common.enterPassword');
_('common.enterPhoneNumber');
_('common.enterRegistrationNumber');
_('common.enterUsername');
_('common.common.environments');
_('common.exit');
_('common.export');
_('common.exportFormat');
_('common.exportIncludeColumns');
_('common.extraActions');
_('common.failed');
_('common.feature');
_('common.file');
_('common.files');
_('common.filename');
_('common.finish');
_('common.firstName');
_('common.fiyuCoreModules');
_('common.fiyuPushNotifications');
_('common.fiyuPushNotificationsNote');
_('common.fiyuPushNotificationsNoteButtonText');
_('common.fiyuPushNotificationsNoteMessage');
_('common.fiyuPushNotificationsNoteMessageEnd');
_('common.forgotPasswordVerificationCodeErrorMessage');
_('common.forgotPasswordPatternErrorMessage');
_('common.forgotPasswordRequiredErrorMessage');
_('common.forgotPasswordSuccessMessage');
_('common.forgotPasswordTitle');
_('common.frontendDocs');
_('common.fullName');
_('common.gender');
_('common.gotIt');
_('common.goToPage');
_('common.grantPermission');
_('common.grafana');
_('common.identificationNumber');
_('common.info');
_('common.inPersonTrainingAvailability');
_('common.install');
_('common.internalCode');
_('common.internalUsername');
_('common.isActiveEmployment');
_('common.isCore');
_('common.isDeleted');
_('common.isMandatory');
_('common.isSuperadmin');
_('common.isVerified');
_('common.jobPosition');
_('common.label');
_('common.language');
_('common.lastChanged');
_('common.lastModification');
_('common.lastModifiedBy');
_('common.lastName');
_('common.licenseAgreement');
_('common.listOfOrganizations');
_('common.loadingPleaseWait');
_('common.login');
_('common.logo');
_('common.loginToYourAccount');
_('common.loginWithMicrosoftAccount');
_('common.logout');
_('common.mainEmail');
_('common.middlename');
_('common.modified');
_('common.modifiedBy');
_('common.module');
_('common.modules');
_('common.name');
_('common.newPassword');
_('common.newPasswordConfirm');
_('common.next');
_('common.numberOfDevelopers');
_('common.numberOfEndUsers');
_('common.noRecordsFound');
_('common.none');
_('common.notAvailableInfoMessage');
_('common.note');
_('common.nothingSelected');
_('common.notificationEmail');
_('common.notificationMessage');
_('common.notificationMessages');
_('common.notification');
_('common.notifications');
_('common.objectId');
_('common.oldPassword');
_('common.onlineTutorials');
_('common.optional');
_('common.orderSummary');
_('common.organization');
_('common.organizationAndRoles');
_('common.organizationEmployee');
_('common.organizationEmployees');
_('common.organizationInformation');
_('common.organizationInfoMessage');
_('common.organizationName');
_('common.organizationType');
_('common.organizationTypes');
_('common.organizations');
_('common.pageNotFoundReachedOutOfUniverse');
_('common.pageNotFoundReturnMessage');
_('common.pageNotFoundReturnBackToHome');
_('common.payment');
_('common.payments');
_('common.paymentMethodType');
_('common.paymentMethod');
_('common.permissions');
_('common.phoneNumber');
_('common.planType');
_('common.planName');
_('common.platform');
_('common.play');
_('common.pause');
_('common.previous');
_('common.preview');
_('common.price');
_('common.pricingInfo');
_('common.privateDomain');
_('common.primeNgTheme');
_('common.privacyPolicy');
_('common.privilege');
_('common.privileges');
_('common.privilegesOn');
_('common.proceed');
_('common.profile');
_('common.profileSettings');
_('common.project');
_('common.projects');
_('common.projectDescription');
_('common.projectDomainName');
_('common.projectName');
_('common.projectNameInfo');
_('common.provisioningVerifySuccessMessage');
_('common.provisioningRegisterSuccessMessage');
_('common.provisioningRegisterUsernameMinLengthErrorMessage');
_('common.provisioningRegisterUsernamePatternErrorMessage');
_('common.provisioningPaymentIsCanceled');
_('common.provisioningPaymentSuccessMessage');
_('common.provisioningPaymentSetupSuccessMessage');
_('common.provisioningPaymentSetupSupportedPaymentMethodsErrorMessage');
_('common.provisioningPaymentTitle');
_('common.provisioningProcessStarted');
_('common.provisioningSummaryTitle');
_('common.provisioningTrialSetupSuccessMessage');
_('common.refundPolicy');
_('common.registeredByUser');
_('common.registrationNumber');
_('common.registration');
_('common.registrationName');
_('common.registrations');
_('common.rememberMe');
_('common.remove');
_('common.repositories');
_('common.requiredFieldErrorMessage');
_('common.reset');
_('common.resetPasswordCapitalCasePatternErrorMessage');
_('common.resetPasswordMatchErrorMessage');
_('common.resetPasswordMinLengthErrorMessage');
_('common.resetPasswordNumberPatternErrorMessage');
_('common.resetPasswordRequiredErrorMessage');
_('common.resetPasswordSmallCasePatternErrorMessage');
_('common.resetPasswordSpecialCharPatternErrorMessage');
_('common.resetPasswordSuccessMessage');
_('common.resetPasswordTitle');
_('common.responsiblePerson');
_('common.review');
_('common.role');
_('common.roleAlias');
_('common.roleDescription');
_('common.roleInformation');
_('common.roleName');
_('common.rolePermissions');
_('common.roleTemplate');
_('common.roleTemplateName');
_('common.roleTemplates');
_('common.roles');
_('common.save');
_('common.search');
_('common.securePayment');
_('common.select');
_('common.selectAll');
_('common.deselectAll');
_('common.send');
_('common.sendNewCredentials');
_('common.settings');
_('common.shortDescription');
_('common.startDate');
_('common.startDeprovisioning');
_('common.startProvisioning');
_('common.startRecording');
_('common.startTyping');
_('common.startSpeechRecognition');
_('common.status');
_('common.stopRecording');
_('common.success');
_('common.subdomain');
_('common.submit');
_('common.summary');
_('common.superadmin');
_('common.superior');
_('common.support');
_('common.switchOrganization');
_('common.technicalAdministrator');
_('common.text');
_('common.type');
_('common.update');
_('common.user');
_('common.userInPlatform');
_('common.userInformation');
_('common.userPassword');
_('common.userType');
_('common.userTypes');
_('common.username');
_('common.users');
_('common.usersAndRoles');
_('common.userNotAuthorizedMessage');
_('common.userNotAuthorizedBackToHomeMessage');
_('common.validFrom');
_('common.validTo');
_('common.valueInPackage');
_('common.totalAmount');
_('common.vatAmount');
_('common.vatPercent');
_('common.verified');
_('common.verify');
_('common.version');
_('common.viewAll');
_('common.viewMoreAbout');
_('common.viewMorePrivacyPolicy');
_('common.yes');
_('common.welcomeMessage');
_('common.welcomeIntroMessage');
_('common.workspace');
_('crm.moduleDescription');
_('error.missingPrivileges');
_('error.unauthorized');
_('errorFiyu00.generalError');
_('errorFiyu01.createIdNotNull');
_('errorFiyu02.updateIdNull');
_('errorFiyu03.accessDenied');
_('errorFiyu04.bodyMissing');
_('errorFiyu05.requestParameterMissing');
_('errorFiyu06.typeMismatch');
_('errorFiyu07.entityNotFound');
_('errorFiyu08.aclNotFound');
_('errorFiyuV.validationError');
_('errorFiyuV.noWhitespace');
_('errorFiyuV.maxLengthLimit');
_('errorFiyuV.alphabeticPattern');
_('errorFiyuV.noDotPattern');
_('errorFiyuV01.decimalMax');
_('errorFiyuV02.decimalMin');
_('errorFiyuV03.digits');
_('errorFiyuV04.email');
_('errorFiyuV05.future');
_('errorFiyuV06.futureOrPresent');
_('errorFiyuV07.max');
_('errorFiyuV08.min');
_('errorFiyuV09.negative');
_('errorFiyuV10.negativeOrZero');
_('errorFiyuV11.notBlank');
_('errorFiyuV12.notEmpty');
_('errorFiyuV13.notNull');
_('errorFiyuV14.null');
_('errorFiyuV15.past');
_('errorFiyuV16.pastOrPresent');
_('errorFiyuV17.pattern');
_('errorFiyuV18.positive');
_('errorFiyuV19.positiveOrZero');
_('errorFiyuV20.size');
_('errorFiyuV21.startEndDate');
_('import.browseFile');
_('import.continueImportProcessIfErrorOccurs');
_('import.here');
_('import.import');
_('import.orDownloadTemplate');
_('import.selectImportFileToUpload');
_('import.startImportProcessFromBeginningIfErrorOccurs');
_('notifications.moduleDescription');
_('organization.moduleDescription');
_('organization.moduleLabel');
_('passwordInfo.requirementsHeader');
_('passwordInfo.requirementsIntro');
_('passwordInfo.requirementsRule1');
_('passwordInfo.requirementsRule2');
_('passwordInfo.requirementsRule3');
_('passwordInfo.requirementsRule4');
_('passwordInfo.requirementsRule5');
_('platform.moduleDescription');
_('platform.moduleLabel');
_('preferences.locked');
_('preferences.organizationPreferences');
_('preferences.preferences');
_('preferences.saved');
_('preferences.theme');
_('preferences.userPreferences');
_('shared.crud.error');
_('shared.crud.recordCreated');
_('shared.crud.recordDeleted');
_('shared.crud.recordDiscard');
_('shared.crud.recordUpdate');
_('shared.dataImportSuccessful');
_('shared.detailsSidebar.nothingSelected');
_('shared.dialog.deleteRecord');
_('shared.dialog.noKeep');
_('shared.dialog.yesDelete');
_('shared.filter.enterFilterTerm');
_('shared.filter.selectCondition');
_('shared.filter.selectDataField');
_('shared.filter.selectOption');
_('shared.table.add');
_('shared.table.filter');
_('shared.table.fullExport');
_('shared.table.quickExport');
_('shared.table.quickView');
_('shared.table.sort');
_('shared.toast.error');
_('ui.fileUpload.fileDeleteError');
_('ui.fileUpload.fileDeleted');
_('ui.fileUpload.fileUploadError');
_('ui.fileUpload.fileUploaded');
_('ui.fileUpload.fileDownlodError');
_('ui.fileUpload.fileDownloaded');
_('users.moduleDescription');
_('users.moduleLabel');
_('tour.addNewUser');
_('tour.addNewUserMessage');
_('tour.addFilters');
_('tour.addFiltersDesc');
_('tour.fiyuTableFilters');
_('tour.fiyuTableFiltersDesc');
_('tour.fiyuTableSorting');
_('tour.fiyuTableSortingDesc');
_('tour.getCode');
_('tour.getCodeGitlab');
_('tour.navigateToPlatformModule');
_('tour.navigateToUsers');
_('tour.navigationToPlatformModule');
_('tour.organizationAndRolesStep');
_('tour.platformModule');
_('tour.platformModuleIntro');
_('tour.submitForm');
_('tour.quickViewOpened');
_('tour.tourIntroMessage');
_('tour.usersNavigationIntro');
_('tour.usersModule');
_('tour.usersModuleIntroduction');
_('tour.userForm');
_('tour.usersPage');
_('tour.usersPageMessage');
_('tour.quickExportDesc');
_('tour.quickViewDesc');
_('tour.welcomeToTour');
_('tour.welcomeToFiyu');

export enum SharedTranslateKeys {
  EQUAL = 'EQUAL',
  IEQUAL = 'IEQUAL',
  GREATER_EQUAL = 'GREATER_EQUAL',
  GREATER = 'GREATER',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_NULL = 'NOT_NULL',
  IS_NULL = 'IS_NULL',
  LESS_EQUAL = 'LESS_EQUAL',
  LESS = 'LESS',
  NOT_EQUAL = 'NOT_EQUAL',
  BEGINS = 'BEGINS',
  IBEGINS = 'IBEGINS',
  ENDS = 'ENDS',
  IENDS = 'IENDS',
  CONTAINS = 'CONTAINS',
  ICONTAINS = 'ICONTAINS',
  About = 'common.about',
  AboutUs = 'common.aboutUs',
  Academy = 'common.academy',
  AcceptLicenceAgreement = 'common.acceptLicenceAgreement',
  AcceptStoringTermsInfoMessage = 'common.acceptStoringTermsInfoMessage',
  AccessTo = 'common.accessTo',
  Account = 'common.account',
  AccountInformation = 'common.accountInformation',
  AccountLogin = 'common.accountLogin',
  Actions = 'common.actions',
  VerificationCode = 'common.verificationCode',
  Active = 'common.active',
  Activate = 'common.activate',
  ActiveOnPlatform = 'common.activeOnPlatform',
  Add = 'common.add',
  AddNew = 'common.addNew',
  Alias = 'common.alias',
  Allow = 'common.allow',
  And = 'common.and',
  Application = 'common.application',
  Applications = 'common.applications',
  Apply = 'common.apply',
  Ascending = 'common.ascending',
  DeleteConfirmMessage = 'common.areYouSureYouWantToDeleteThisRecord',
  AvailableApplications = 'common.availableApplications',
  AvailableModules = 'common.availableModules',
  BillingPeriod = 'common.billingPeriod',
  BillingFrequency = 'common.billingFrequency',
  BillingType = 'common.billingType',
  Birthdate = 'common.birthdate',
  BackendDocs = 'common.backendDocs',
  BusinessModules = 'common.businessModules',
  Cancel = 'common.cancel',
  ChangePassword = 'common.changePassword',
  Characters = 'common.characters',
  ChooseResponsiblePerson = 'common.chooseResponsiblePerson',
  ChooseUserType = 'common.chooseUserType',
  ChosenPlanType = 'common.chosenPlanType',
  Classification = 'common.classification',
  Clear = 'common.clear',
  Collapse = 'common.collapse',
  Confirm = 'common.confirm',
  CoreModules = 'common.coreModules',
  Create = 'common.create',
  Created = 'common.created',
  CreatedBy = 'common.createdBy',
  CreatedTime = 'common.createdTime',
  CreatedDate = 'common.dateOfCreation',
  CrmModuleDescription = 'crm.moduleDescription',
  Currency = 'common.currency',
  Deactivate = 'common.deactivate',
  DefaultModule = 'common.defaultModule',
  Delete = 'common.delete',
  Deleted = 'common.deleted',
  DeletedBy = 'common.deletedBy',
  DeletedGlobal = 'common.deletedGlobal',
  DeletedOrganization = 'common.deletedOrganization',
  DeploymentForDevelopment = 'common.deploymentForDevelopment',
  DeploymentForProduction = 'common.deploymentForProduction',
  Description = 'common.description',
  Descending = 'common.descending',
  DevOpsPortal = 'common.devOpsPortal',
  Discard = 'common.discard',
  DisplayName = 'common.displayName',
  Document = 'common.document',
  Documents = 'common.documents',
  Documentation = 'common.documentation',
  Domain = 'common.domain',
  DomainName = 'common.domainName',
  Download = 'common.download',
  Edit = 'common.edit',
  ELK = 'common.elk',
  Email = 'common.email',
  EmailAddress = 'common.emailAddress',
  EmailUs = 'common.emailUs',
  EmailUsForSupport = 'common.emailUsForSupport',
  Employee = 'common.employee',
  Employees = 'common.employees',
  EmploymentEndsOn = 'common.employmentEndsOn',
  EmploymentStartsFrom = 'common.employmentStartsFrom',
  Enabled = 'common.enabled',
  EnableNotificationsMessage = 'common.enableNotificationsMessage',
  EnableNotificationsNote = 'common.enableNotificationsNote',
  EnterDescription = 'common.enterDescription',
  EnterDomain = 'common.enterDomain',
  EnterEmail = 'common.enterEmail',
  EnterEmailAddress = 'common.enterEmailAddress',
  EnterFirstName = 'common.enterFirstName',
  EnterIdentificationNzmber = 'common.enterIdentificationNumber',
  EnterInternalCode = 'common.enterInternalCode',
  EnterInternalUsername = 'common.enterInternalUsername',
  EnterLastName = 'common.enterLastName',
  EnterNotificationEmailAddress = 'common.enterNotificationEmailAddress',
  EnterOrganizationName = 'common.enterOrganizationName',
  EnterOrganizationType = 'common.enterOrganizationType',
  EnterPassword = 'common.enterPassword',
  EnterPhoneNumber = 'common.enterPhoneNumber',
  EnterRegistrationNumber = 'common.enterRegistrationNumber',
  EnterUsername = 'common.enterUsername',
  Environments = 'common.environments',
  Exit = 'common.exit',
  Export = 'common.export',
  ExportFormat = 'common.exportFormat',
  ExportIncludeColumns = 'common.exportIncludeColumns',
  ExtraActions = 'common.extraActions',
  Failed = 'common.failed',
  ExpirationDate = 'common.expirationDate',
  Feature = 'common.feature',
  File = 'common.file',
  Files = 'common.files',
  Filename = 'common.filename',
  Finish = 'common.finish',
  FirstName = 'common.firstName',
  FiyuCoreModules = 'common.fiyuCoreModules',
  FiyuPushNotifications = 'common.fiyuPushNotifications',
  FiyuPushNotificationsNote = 'common.fiyuPushNotificationsNote',
  FiyuPushNotificationsNoteButtonText = 'common.fiyuPushNotificationsNoteButtonText',
  FiyuPushNotificationsNoteMessage = 'common.fiyuPushNotificationsNoteMessage',
  FiyuPushNotificationsNoteMessageEnd = 'common.fiyuPushNotificationsNoteMessageEnd',
  ForgotPasswordVerificationCodeErrorMessage = 'common.forgotPasswordVerificationCodeErrorMessage',
  ForgotPasswordPatternErrorMessage = 'common.forgotPasswordPatternErrorMessage',
  ForgotPasswordRequiredErrorMessage = 'common.forgotPasswordRequiredErrorMessage',
  ForgotPasswordSuccessMessage = 'common.forgotPasswordSuccessMessage',
  ForgotPasswordTitle = 'common.forgotPasswordTitle',
  FrontendDocs = 'common.frontendDocs',
  FullName = 'common.fullName',
  Gender = 'common.gender',
  GotIt = 'common.gotIt',
  GoToPage = 'common.goToPage',
  GrantPermission = 'common.grantPermission',
  Grafana = 'common.grafana',
  IdentificationNumber = 'common.identificationNumber',
  Info = 'common.info',
  InPersonTrainingAvailability = 'common.inPersonTrainingAvailability',
  Install = 'common.install',
  InternalCode = 'common.internalCode',
  InternalUsername = 'common.internalUsername',
  IsActiveEmployment = 'common.isActiveEmployment',
  IsCore = 'common.isCore',
  IsDeleted = 'common.isDeleted',
  IsMandatory = 'common.isMandatory',
  IsSuperadmin = 'common.isSuperadmin',
  IsVerified = 'common.isVerified',
  JobPosition = 'common.jobPosition',
  Label = 'common.label',
  Language = 'common.language',
  LastChanged = 'common.lastChanged',
  LastModification = 'common.lastModification',
  LastModifiedBy = 'common.lastModifiedBy',
  LastName = 'common.lastName',
  LicenseAgreement = 'common.licenseAgreement',
  ListOfOrganizations = 'common.listOfOrganizations',
  LoadingPleaseWait = 'common.loadingPleaseWait',
  Login = 'common.login',
  Logo = 'common.logo',
  LoginToYourAccount = 'common.loginToYourAccount',
  LoginWithMicrosoftAccount = 'common.loginWithMicrosoftAccount',
  Logout = 'common.logout',
  MainEmail = 'common.mainEmail',
  Middlename = 'common.middlename',
  Modified = 'common.modified',
  ModifiedBy = 'common.modifiedBy',
  Module = 'common.module',
  Modules = 'common.modules',
  Name = 'common.name',
  NewPassword = 'common.newPassword',
  NewPasswordConfirm = 'common.newPasswordConfirm',
  Next = 'common.next',
  NumberOfDevelopers = 'common.numberOfDevelopers',
  NumberOfEndUsers = 'common.numberOfEndUsers',
  NoRecordsFound = 'common.noRecordsFound',
  None = 'common.none',
  NotAvailableInfoMessage = 'common.notAvailableInfoMessage',
  Note = 'common.note',
  NothingSelected = 'common.nothingSelected',
  NotificationEmail = 'common.notificationEmail',
  NotificationMessage = 'common.notificationMessage',
  NotificationMessages = 'common.notificationMessages',
  Notification = 'common.notification',
  Notifications = 'common.notifications',
  NotificationsModuleDescription = 'notifications.moduleDescription',
  ObjectId = 'common.objectId',
  OldPassword = 'common.oldPassword',
  OnlineTutorials = 'common.onlineTutorials',
  Optional = 'common.optional',
  OrderSummary = 'common.orderSummary',
  Organization = 'common.organization',
  OrganizationAndRoles = 'common.organizationAndRoles',
  OrganizationEmployee = 'common.organizationEmployee',
  OrganizationEmployees = 'common.organizationEmployees',
  OrganizationInformation = 'common.organizationInformation',
  OrganizationInfoMessage = 'common.organizationInfoMessage',
  OrganizationName = 'common.organizationName',
  OrganizationType = 'common.organizationType',
  OrganizationTypes = 'common.organizationTypes',
  Organizations = 'common.organizations',
  PageNotFoundReachedOutOfUniverse = 'common.pageNotFoundReachedOutOfUniverse',
  PageNotFoundReturnBackToHome = 'common.pageNotFoundReturnBackToHome',
  PageNotFoundReturnMessage = 'common.pageNotFoundReturnMessage',
  PasswordInfoRequirementsHeader = 'passwordInfo.requirementsHeader',
  PasswordInfoRequirementsIntro = 'passwordInfo.requirementsIntro',
  PasswordInfoRequirementsRule1 = 'passwordInfo.requirementsRule1',
  PasswordInfoRequirementsRule2 = 'passwordInfo.requirementsRule2',
  PasswordInfoRequirementsRule3 = 'passwordInfo.requirementsRule3',
  PasswordInfoRequirementsRule4 = 'passwordInfo.requirementsRule4',
  PasswordInfoRequirementsRule5 = 'passwordInfo.requirementsRule5',
  Payment = 'common.payment',
  Payments = 'common.payments',
  PaymentMethod = 'common.paymentMethod',
  PaymentMethodType = 'common.paymentMethodType',
  Permissions = 'common.permissions',
  PhoneNumber = 'common.phoneNumber',
  PlanType = 'common.planType',
  PlanName = 'common.planName',
  Platform = 'common.platform',
  Play = 'common.play',
  Pause = 'common.pause',
  Previous = 'common.previous',
  Preview = 'common.preview',
  Price = 'common.price',
  PricingInfo = 'common.pricingInfo',
  PrivateDomain = 'common.privateDomain',
  PrimeNgTheme = 'common.primeNgTheme',
  Privilege = 'common.privilege',
  Privileges = 'common.privileges',
  PrivilegesOn = 'common.privilegesOn',
  PrivacyPolicy = 'common.privacyPolicy',
  Proceed = 'common.proceed',
  Profile = 'common.profile',
  ProfileSettings = 'common.profileSettings',
  Project = 'common.project',
  Projects = 'common.projects',
  ProjectDescription = 'common.projectDescription',
  ProjectDomainName = 'common.projectDomainName',
  ProjectName = 'common.projectName',
  ProjectNameInfo = 'common.projectNameInfo',
  ProvisioningPaymentTitle = 'common.provisioningPaymentTitle',
  ProvisioningProcessStarted = 'common.provisioningProcessStarted',
  ProvisioningVerifySuccessMessage = 'common.provisioningVerifySuccessMessage',
  ProvisioningRegisterSuccessMessage = 'common.provisioningRegisterSuccessMessage',
  ProvisioningRegisterUsernameMinLengthErrorMessage = 'common.provisioningRegisterUsernameMinLengthErrorMessage',
  ProvisioningRegisterUsernamePatternErrorMessage = 'common.provisioningRegisterUsernamePatternErrorMessage',
  ProvisioningPaymentIsCanceled = 'common.provisioningPaymentIsCanceled',
  ProvisioningPaymentSuccessMessage = 'common.provisioningPaymentSuccessMessage',
  ProvisioningPaymentSetupSuccessMessage = 'common.provisioningPaymentSetupSuccessMessage',
  ProvisioningPaymentSetupSupportedPaymentMethodsErrorMessage = 'common.provisioningPaymentSetupSupportedPaymentMethodsErrorMessage',
  ProvisioningSummaryTitle = 'common.provisioningSummaryTitle',
  ProvisioningTrialSetupSuccessMessage = 'common.provisioningTrialSetupSuccessMessage',
  RefundPolicy = 'common.refundPolicy',
  RegisteredByUser = 'common.registeredByUser',
  RegistrationNumber = 'common.registrationNumber',
  Registration = 'common.registration',
  RegistrationName = 'common.registrationName',
  Registrations = 'common.registrations',
  RememberMe = 'common.rememberMe',
  Remove = 'common.remove',
  Repositories = 'common.repositories',
  Reset = 'common.reset',
  ResetPasswordCapitalCasePatternErrorMessage = 'common.resetPasswordCapitalCasePatternErrorMessage',
  ResetPasswordMatchErrorMessage = 'common.resetPasswordMatchErrorMessage',
  ResetPasswordMinLengthErrorMessage = 'common.resetPasswordMinLengthErrorMessage',
  ResetPasswordNumberPatternErrorMessage = 'common.resetPasswordNumberPatternErrorMessage',
  ResetPasswordRequiredErrorMessage = 'common.resetPasswordRequiredErrorMessage',
  ResetPasswordSmallCasePatternErrorMessage = 'common.resetPasswordSmallCasePatternErrorMessage',
  ResetPasswordSpecialCharPatternErrorMessage = 'common.resetPasswordSpecialCharPatternErrorMessage',
  ResetPasswordSuccessMessage = 'common.resetPasswordSuccessMessage',
  ResetPasswordTitle = 'common.resetPasswordTitle',
  ResponsiblePerson = 'common.responsiblePerson',
  RequiredFieldErrorMessage = 'common.requiredFieldErrorMessage',
  Review = 'common.review',
  Role = 'common.role',
  RoleAlias = 'common.roleAlias',
  RoleDescription = 'common.roleDescription',
  RoleInformation = 'common.roleInformation',
  RoleName = 'common.roleName',
  RolePermissions = 'common.rolePermissions',
  RoleTemplate = 'common.roleTemplate',
  RoleTemplateName = 'common.roleTemplateName',
  RoleTemplates = 'common.roleTemplates',
  Roles = 'common.roles',
  Save = 'common.save',
  Search = 'common.search',
  SecurePayment = 'common.securePayment',
  Select = 'common.select',
  SelectAll = 'common.selectAll',
  DeselectAll = 'common.deselectAll',
  Send = 'common.send',
  SendNewCredentials = 'common.sendNewCredentials',
  Settings = 'common.settings',
  ShortDescription = 'common.shortDescription',
  StartSpeechRecognition = 'common.startSpeechRecognition',
  StartDate = 'common.startDate',
  StartTyping = 'common.startTyping',
  StartDeprovisioning = 'common.startDeprovisioning',
  StartProvisioning = 'common.startProvisioning',
  StartRecording = 'common.startRecording',
  Status = 'common.status',
  StopRecording = 'common.stopRecording',
  Success = 'common.success',
  Subdomain = 'common.subdomain',
  Submit = 'common.submit',
  Summary = 'common.summary',
  Superadmin = 'common.superadmin',
  Superior = 'common.superior',
  Support = 'common.support',
  SwitchOrganization = 'common.switchOrganization',
  TechnicalAdministrator = 'common.technicalAdministrator',
  Text = 'common.text',
  Type = 'common.type',
  Update = 'common.update',
  User = 'common.user',
  UserInPlatform = 'common.userInPlatform',
  UserInformation = 'common.userInformation',
  UserPassword = 'common.userPassword',
  UserType = 'common.userType',
  UserTypes = 'common.userTypes',
  Username = 'common.username',
  Users = 'common.users',
  UsersAndRoles = 'common.usersAndRoles',
  UserNotAuthorizedMessage = 'common.userNotAuthorizedMessage',
  UserNotAuthorizedBackToHomeMessage = 'common.userNotAuthorizedBackToHomeMessage',
  ValidFrom = 'common.validFrom',
  ValidTo = 'common.validTo',
  ValueInPackage = 'common.valueInPackage',
  TotalAmount = 'common.totalAmount',
  VatAmount = 'common.vatAmount',
  VatPercent = 'common.vatPercent',
  Verified = 'common.verified',
  Verify = 'common.verify',
  Version = 'common.version',
  ViewAll = 'common.viewAll',
  ViewMoreAbout = 'common.viewMoreAbout',
  ViewMorePrivacyPolicy = 'common.viewMorePrivacyPolicy',
  Yes = 'common.yes',
  WelcomeMessage = 'common.welcomeMessage',
  WelcomeIntroMessage = 'common.welcomeIntroMessage',
  Workspace = 'common.workspace',
  ErrorMissingPrivileges = 'error.missingPrivileges',
  ErrorUnauthorized = 'error.unauthorized',
  ErrorGeneralError = 'errorFiyu00.generalError',
  ErrorCreateIdNotNull = 'errorFiyu01.createIdNotNull',
  ErrorUpdateIdNull = 'errorFiyu02.updateIdNull',
  ErrorAccessDenied = 'errorFiyu03.accessDenied',
  ErrorBodyMissing = 'errorFiyu04.bodyMissing',
  ErrorRequestParameterMissing = 'errorFiyu05.requestParameterMissing',
  ErrorTypeMismatch = 'errorFiyu06.typeMismatch',
  ErrorEntityNotFound = 'errorFiyu07.entityNotFound',
  ErrorAclNotFound = 'errorFiyu08.aclNotFound',
  ErrorValidationMessage = 'errorFiyuV.validationError',
  ErrorNoWhitespace = 'errorFiyuV.noWhitespace',
  ErrorMaxLengthLimit = 'errorFiyuV.maxLengthLimit',
  ErrorAlphabeticPattern = 'errorFiyuV.alphabeticPattern',
  ErrorNoDot = 'errorFiyuV.noDotPattern',
  ErrorDecimalMax = 'errorFiyuV01.decimalMax',
  ErrorDecimalMin = 'errorFiyuV02.decimalMin',
  ErrorDigits = 'errorFiyuV03.digits',
  ErrorEmail = 'errorFiyuV04.email',
  ErrorFuture = 'errorFiyuV05.future',
  ErrorFutureOrPresent = 'errorFiyuV06.futureOrPresent',
  ErrorMax = 'errorFiyuV07.max',
  ErrorMin = 'errorFiyuV08.min',
  ErrorNegative = 'errorFiyuV09.negative',
  ErrorNegativeOrZero = 'errorFiyuV10.negativeOrZero',
  ErrorNotBlank = 'errorFiyuV11.notBlank',
  ErrorNotEmpty = 'errorFiyuV12.notEmpty',
  ErrorNotNull = 'errorFiyuV13.notNull',
  ErrorNull = 'errorFiyuV14.null',
  ErrorPast = 'errorFiyuV15.past',
  ErrorPastOrPresent = 'errorFiyuV16.pastOrPresent',
  ErrorPattern = 'errorFiyuV17.pattern',
  ErrorPositive = 'errorFiyuV18.positive',
  ErrorPositiveOrZero = 'errorFiyuV19.positiveOrZero',
  ErrorSize = 'errorFiyuV20.size',
  ErrorStartEndDate = 'errorFiyuV21.startEndDate',
  BrowseFile = 'import.browseFile',
  ImportContinueImportProcessIfErrorOccurs = 'import.continueImportProcessIfErrorOccurs',
  ImportHere = 'import.here',
  Import = 'import.import',
  ImportOrDownloadTemplate = 'import.orDownloadTemplate',
  ImportSelectImportFileToUpload = 'import.selectImportFileToUpload',
  ImportStartImportProcessFromBeginningIfErrorOccurs = 'import.startImportProcessFromBeginningIfErrorOccurs',
  OrganizationModuleDescription = 'organization.moduleDescription',
  OrganizationModuleLabel = 'organization.moduleLabel',
  PlatformModuleDescription = 'platform.moduleDescription',
  PlatformModuleLabel = 'platform.moduleLabel',
  PreferencesLocked = 'preferences.locked',
  PreferencesOrganizationPreferences = 'preferences.organizationPreferences',
  Preferences = 'preferences.preferences',
  PreferencesSaved = 'preferences.saved',
  PreferencesTheme = 'preferences.theme',
  PreferencesUserPreferences = 'preferences.userPreferences',
  ErrorCrud = 'shared.crud.error',
  CrudRecordCreated = 'shared.crud.recordCreated',
  CrudRecordDeleted = 'shared.crud.recordDeleted',
  CrudRecordDiscard = 'shared.crud.recordDiscard',
  CrudRecordUpdate = 'shared.crud.recordUpdate',
  DataImportSuccessful = 'shared.dataImportSuccessful',
  SidebarNothingSelected = 'shared.detailsSidebar.nothingSelected',
  DialogDeleteRecord = 'shared.dialog.deleteRecord',
  DialogNoKeep = 'shared.dialog.noKeep',
  DialogYesDelete = 'shared.dialog.yesDelete',
  FilterEnterTerm = 'shared.filter.enterFilterTerm',
  FilterSelectCondition = 'shared.filter.selectCondition',
  FilterSelectField = 'shared.filter.selectDataField',
  FilterSelectOption = 'shared.filter.selectOption',
  TableAdd = 'shared.table.add',
  TableFilter = 'shared.table.filter',
  TableFullExport = 'shared.table.fullExport',
  TableQuickExport = 'shared.table.quickExport',
  TableQuickView = 'shared.table.quickView',
  TableSort = 'shared.table.sort',
  ErrorToastMessage = 'shared.toast.error',
  FileUploadFileDeleteError = 'ui.fileUpload.fileDeleteError',
  FileUploadFileDeleted = 'ui.fileUpload.fileDeleted',
  FileUploadFileUploadError = 'ui.fileUpload.fileUploadError',
  FileUploadFileUploaded = 'ui.fileUpload.fileUploaded',
  FileUploadFileDownloadError = 'ui.fileUpload.fileDownlodError',
  FileUploadFileDownloaded = 'ui.fileUpload.fileDownloaded',
  UsersModuleDescription = 'users.moduleDescription',
  UsersModuleLabel = 'users.moduleLabel',
  AddFilters = 'tour.addFilters',
  AddFiltersDesc = 'tour.addFiltersDesc',
  AddNewUser = 'tour.addNewUser',
  AddNewUserMessage = 'tour.addNewUserMessage',
  FiyuTableFilters = 'tour.fiyuTableFilters',
  FiyuTableFiltersDesc = 'tour.fiyuTableFiltersDesc',
  FiyuTableSorting = 'tour.fiyuTableSorting',
  FiyuTableSortingDesc = 'tour.fiyuTableSortingDesc',
  GetCode = 'tour.getCode',
  GetCodeGitlab = 'tour.getCodeGitlab',
  NavigateToPlatformModule = 'tour.navigateToPlatformModule',
  NavigationToPlatformModule = 'tour.navigationToPlatformModule',
  NavigateToUsers = 'tour.navigateToUsers',
  OrganizationAndRolesStep = 'tour.organizationAndRolesStep',
  PlatformModule = 'tour.platformModule',
  PlatformModuleIntro = 'tour.platformModuleIntro',
  SubmitForm = 'tour.submitForm',
  TableQuickViewOpened = 'tour.quickViewOpened',
  TourIntroMessage = 'tour.tourIntroMessage',
  UsersNavigationIntro = 'tour.usersNavigationIntro',
  UsersModule = 'tour.usersModule',
  UsersModuleIntroduction = 'tour.usersModuleIntroduction',
  UserForm = 'tour.userForm',
  UsersPage = 'tour.usersPage',
  UsersPageMessage = 'tour.usersPageMessage',
  QuickExportDesc = 'tour.quickExportDesc',
  QuickViewDesc = 'tour.quickViewDesc',
  WelcomeToFiyu = 'tour.welcomeToFiyu',
  WelcomeToTour = 'tour.welcomeToTour',
}
