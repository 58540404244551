import { inject, Injectable } from '@angular/core';
import { AbstractModuleMetadataService, ErrorLoadingService } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { validationErrors } from './module-validation-errors';

@Injectable({ providedIn: 'root' })
export class OrganizationsModuleMetadataService extends AbstractModuleMetadataService {
  private readonly errorLoadingService: ErrorLoadingService = inject(ErrorLoadingService);

  constructor() {
    super(ModuleMetadata.getInstance());
    this.errorLoadingService.loadValidationErrorsForModule(validationErrors);
  }
}
