import type { Nullable } from '../types/nullable-type';

export class EnvironmentSettings {
  production = false;
  shouldEncrypt = false;
  appHostUrl = '';
  userModuleApiUrl: Nullable<string> = null;
  organizationModuleApiUrl: Nullable<string> = null;
  platformModuleApiUrl: Nullable<string> = null;
  nmmModuleApiUrl: Nullable<string> = null;
  dmmModuleApiUrl: Nullable<string> = null;
  crmModuleApiUrl: Nullable<string> = null;
  emmModuleApiUrl: Nullable<string> = null;
  jwtClientId = '';
  jwtClientSecret = '';
  corvusPayStoreId: number = null;
  corvusPaySecretKey = '';
  corvusPayPaymentGatewayUrl = '';
  firebase: Record<string, any> = null;
  firebaseVapidKey = '';
  firebaseRecaptchaSiteKey = '';
  firebaseRecaptchaSecretKey = '';
  azureAuthEnabled = false;
  azureAuthClientId = '';
  azureAuthTenantId = '';
  googleTagManagerTrackingId = '';
  clarityProjectId = '';
  logToLoki = false;
  logstashHostUrl = '';
  lokiLogUrl = '';
}
