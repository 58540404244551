import { Injectable } from '@angular/core';
import { ModulePrefixes } from '@fiyu/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractCRUDService } from '../crud/abstract-crud.service';
import { CriterionType } from '../crud/search/criterion-type.enum';
import { SearchType } from '../crud/search/search-type.enum';
import { AbstractEntity } from '../entity/abstract-entity.model';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class CoreUserService extends AbstractCRUDService {
  constructor(envService: EnvironmentService) {
    super(`${envService.getModuleApiURL(ModulePrefixes.USER)}/users`);
  }

  override getOne<T extends AbstractEntity>(id: string): Observable<T> {
    const searchCriteria = {
      criterion: {
        type: SearchType.Simple,
        propertyPath: 'id',
        propertyValue: id,
        criterionType: CriterionType.Equal,
      },
    };

    return this.get<T>(searchCriteria).pipe(
      map((results) => {
        if (results.length > 0) {
          return results[0];
        } else {
          throw new Error(`User with id: ${id} doesn't exist.`);
        }
      }),
    );
  }
}
