import { ENVIRONMENT_INITIALIZER, type Provider } from '@angular/core';

/**
 * The function `provideInitValue` returns a provider object that can be used in Angular to initialize the ENVIRONMENT_INITIALIZER token with a given function.
 * @param {VoidFunction} fn - The `fn` parameter is a function that does not take any arguments and does not return any value.
 * @returns {Provider} a provider object.
 * @example
 * ```
 * export const OFFLINE_INIT = provideInitValue(() => {
 *   const toast = inject(ToastService);
 *   const window = inject(WINDOW);
 *   window.addEventListener('offline', () => {
 *     toast.warning('There is no internet connection', {
 *       duration: 10_000,
 *     })
 *   })
 * })
 * // register the provider
 * bootstrapApplication(AppComponent, {
 *   providers: [
 *     OFFLINE_INIT
 *   ]
 * })
 * ```
 */
export function provideInitValue(fn: VoidFunction): Provider {
  return {
    // The multi: true option is needed for Angular to recognize this as a multi-value provider
    multi: true,
    provide: ENVIRONMENT_INITIALIZER,
    useValue: fn,
  };
}
