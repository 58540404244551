import {
  HttpErrorResponse,
  type HttpHandler,
  type HttpHeaderResponse,
  type HttpInterceptor,
  type HttpProgressEvent,
  type HttpRequest,
  type HttpResponse,
  type HttpSentEvent,
  type HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BYPASS_ORGANIZATION_HEADER } from '@fiyu/api';
import { BehaviorSubject, throwError, type Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { CoreService } from './../../core/core.service';
import { SecurityService } from './../../security/security.service';

/**
 * @deprecated use OrganizationInterceptor instead
 */
@Injectable()
export class OrganizationClassInterceptor implements HttpInterceptor {
  constructor(
    private readonly secService: SecurityService,
    private readonly coreService: CoreService,
    private readonly router: Router,
  ) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  /**
   * Intercepts every httpRequest and adds token to header
   * if for any request token is expired, new token is fetched and inserted in header
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    /*  const uri = new URL(req.url);
    if (!config.trustedHostNames.includes(uri.hostname)) {
      return next.handle(req);
    } */
    // assets don't need a token to be loaded (e.g. if this is omitted, core translations won't load before user logs in)
    if (req.url.includes('assets')) {
      return next.handle(req);
    }

    if (
      req.url.endsWith(this.secService.loginUrl) ||
      req.url.endsWith(this.secService.azureLoginUrl) ||
      req.context.get(BYPASS_ORGANIZATION_HEADER) === true
    ) {
      return next.handle(req);
    }

    try {
      return this.addTokenToHeader(req, next);
    } catch (error) {
      return this.logoutUser();
    }
  }

  /**
   * Adds token to request header, if request does not succeed and
   * the reason is 401 with message 'invalid_token', new token is
   * fetched and inserted in header
   * @param req
   * @param next
   */
  addTokenToHeader(req: HttpRequest<any>, next: HttpHandler) {
    const requestWithToken = this.addToken(req, this.secService.getAccessToken());
    return next.handle(requestWithToken).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.refreshToken(req, next);
        }
        return throwError(() => error);
      }),
    );
  }

  /**
   * Add Bearer token to header
   */
  private addToken(req: HttpRequest<any>, token: string | null = ''): HttpRequest<any> {
    const organizationId = this.coreService.getCurrentOrganizationId();

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Handle 401 Error - do refresh token
   */
  private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.secService.doRefreshToken().pipe(
        switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(req, newToken));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token: any) => {
          return next.handle(this.addToken(req, token));
        }),
      );
    }
  }

  /**
   * Do logout user and redirect to login page
   */
  private logoutUser(): Observable<any> {
    this.secService.logout();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.router.navigateByUrl('/login');
    return throwError(() => '');
  }
}
