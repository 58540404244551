import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModulePrefixes, WINDOW } from '@fiyu/api';
import { initializeApp, type FirebaseApp, type FirebaseOptions } from 'firebase/app';
import { initializeAppCheck, onTokenChanged, ReCaptchaV3Provider, type AppCheck } from 'firebase/app-check';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
  type MessagePayload,
  type Messaging,
} from 'firebase/messaging';
import { BehaviorSubject, catchError, of } from 'rxjs';
import { CoreService } from '../core/core.service';
import { StorageService } from '../core/storage.service';
import { EnvironmentService } from '../environment/environment.service';
import { LogService } from '../logging/log-service';
import { DeviceTypeEnum } from './device-type-enum';
import type { FiyuNotification } from './fiyu-notification';
import type { RegisterUserDto } from './register-user-dto';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  /**
   * environmentService - Subscribing to environmentService observable is needed in order to
   * guarantee that the data from environment.json is fully loaded into the EnvironmentService class
   * httpClient - used to send tokens to server
   */
  private readonly environmentService: EnvironmentService = inject(EnvironmentService);
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly coreService: CoreService = inject(CoreService);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly logService: LogService = inject(LogService);
  private readonly currentToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private messaging: Messaging = null;
  private readonly currentMessage = new BehaviorSubject<FiyuNotification>(null);
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  private firebaseConfig: FirebaseOptions = {};
  private firebaseVapidKey: string = null;
  private firebaseApp: FirebaseApp = null;
  private firebaseAppCheck: AppCheck = null;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly window: Window = inject(WINDOW);
  constructor() {
    this.environmentService.readyObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((ready) => {
      if (!ready) {
        return;
      }
      this.firebaseConfig = this.environmentService.firebase;
      this.firebaseVapidKey = this.environmentService.firebaseVapidKey;
      // Lets check if the browser supports notifications
      isSupported()
        .then(() => {
          this.firebaseApp = initializeApp(this.firebaseConfig);
          console.log('Notifications supported');
          if (this.environmentService.production === false) {
            (<any>self)['FIREBASE_APPCHECK_DEBUG_TOKEN'] = true;
          }
          this.firebaseAppCheck = initializeAppCheck(this.firebaseApp, {
            provider: new ReCaptchaV3Provider(this.environmentService.firebaseRecaptchaSiteKey),
            isTokenAutoRefreshEnabled: true,
          });
          console.log('Firebase app', this.firebaseApp, this.firebaseAppCheck);
          // Initialization of Firebase Cloud Messaging functionality
          this.initializeFirebaseMessaging();
        })
        .catch(() => console.warn('This browser does not support notifications'));
    });
  }

  /**
   * This method initializes Firebase Cloud Messaging and starts up listeners
   * Also, last call in this method is to request permission for receiving notifications
   * from the user
   */
  private initializeFirebaseMessaging() {
    // Required call to enable messaging functionality
    this.messaging = getMessaging(this.firebaseApp);
    // If a token is for some reason changed while the application is
    // running, this listener will catch that event and update the token
    this.tokenRefreshListener();
    // This method initiates a listener for new messages
    this.receiveMessage();
    // Fetch token only if notification permission has been granted
    if (this.getPermissionStatus() === 'granted') {
      this.fetchToken();
    }
  }

  /**
   * Handles incoming messages. Called when:
   * - a message is received while the app has focus
   * - the user clicks on an app notification created by a service worker
   * `messaging.setBackgroundMessageHandler` handler.
   * When a message is received, variable 'currentMessage' of type BehaviorSubject
   * is called and updated with the new message
   */
  private receiveMessage() {
    onMessage(this.messaging, (payload: MessagePayload) => {
      this.currentMessage.next(payload as unknown as FiyuNotification);
    });
  }

  /**
   * This method retrieves the token from FCM
   */
  private fetchToken() {
    if (this.isSupported()) {
      if (Notification.permission === 'granted') {
        // Configuration of the FCM to use VAPID key
        getToken(this.messaging, { vapidKey: this.firebaseVapidKey })
          .then((currentToken) => {
            if (currentToken) {
              this.sendTokenToServer(currentToken);
              this.currentToken.next(currentToken);
              // console.log(currentToken);
            } else {
              this.setTokenSentToServer(false);
            }
          })
          .catch(() => {
            this.logService.logError('An error occurred while retrieving token.');
            this.setTokenSentToServer(false);
          });
      }
    } else {
      console.warn('This browser does not support notifications');
    }
  }

  /**
   * If token gets changed for some reason, this 'onTokenChanged' will be triggered
   */
  private tokenRefreshListener() {
    onTokenChanged(this.firebaseAppCheck, (_newToken) => {
      getToken(this.messaging, { vapidKey: this.firebaseVapidKey })
        .then((refreshedToken) => {
          this.setTokenSentToServer(false);
          this.sendTokenToServer(refreshedToken);
          this.currentToken.next(refreshedToken);
        })
        .catch(() => {
          this.logService.logError('Unable to retrieve refreshed token ');
        });
    });
  }

  /**
   * in order to reduce number of requests to server, this flag is used to indicate
   * whether the token is already sent to server. This method changes aforementioned
   * flag in the local storage
   * @param tokenSent - value that will be written in local storage
   */
  private setTokenSentToServer(tokenSent: boolean) {
    this.storageService.setItem('sentToServer', tokenSent ? 'true' : 'false');
  }

  /**
   * Sends registerUserDto object to server
   * @param token - current user token
   */
  private sendTokenToServer(token: string) {
    const sentToServer = this.storageService.getItem('sentToServer');
    if (sentToServer === 'false' || !sentToServer) {
      const userTokenDto = this.buildUserRegistrationDto(token);
      this.httpClient
        .post(this.environmentService.getModuleApiURL(ModulePrefixes.NOTIFICATIONS) + '/register', userTokenDto)
        .pipe(
          catchError((_error: unknown) => of(null)),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.setTokenSentToServer(true);
        });
    }
  }

  /**
   * Builds RegisterUserDto from token. That DTO is used by sendTokenToServer to
   * save that user information on server
   * @param token - token that is used to build RegisterUserDTO
   * TODO: Extract device name and application name from the user agent.
   * Currently, those variables both have the same value (User Agent string).
   */
  private buildUserRegistrationDto(token: string): RegisterUserDto {
    let userId = this.coreService.getUserId();
    if (!userId) {
      userId = 'undefined';
    }
    const ua = navigator.userAgent;
    const deviceType: DeviceTypeEnum = this.isMobile(ua.toLowerCase()) ? DeviceTypeEnum.MOBILE : DeviceTypeEnum.DESKTOP;

    return {
      userId: userId,
      registerToken: token,
      deviceName: ua,
      deviceType: deviceType,
      applicationName: ua,
    };
  }

  /**
   * This method uses a regex to determine whether the device used is mobile phone or not.
   * Regex was obtained from http://detectmobilebrowsers.com/ and is supposed to be accurate.
   * TODO: implement a better and cleaner way to detect mobile devices.
   * @param ua - User Agent string
   */
  private isMobile(ua: string): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        ua,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        ua.substring(0, 4),
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Exposes currentMessage object to users of this service
   * so that they can subscribe to new incoming messages
   */
  public subscribeToMessages(): BehaviorSubject<any> {
    return this.currentMessage;
  }

  /**
   * Returns permission status.
   * Permission status can be:
   * 	- granted -> User has given permission
   * 	- default -> User hasn't answered permission prompt yet
   * 	- denied -> User denied permission
   */
  public getPermissionStatus(): string {
    if (this.isSupported()) {
      return Notification.permission;
    } else return '';
  }

  /**
   * Requests permission from user to enable notifications.
   */
  public requestPermission() {
    if (this.isSupported()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.fetchToken();
        }
      });
    } else {
      console.warn('This browser does not support notifications');
    }
  }

  public isSupported = () => 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

  public isMobileDevice() {
    const isMobileDevice = this.window.matchMedia('only screen and (max-width: 760px)').matches;
    return isMobileDevice;
  }
}
