import { BreakpointObserver } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  readonly #platform: Platform = inject(Platform);
  readonly #breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  isMobile(): boolean {
    return this.#platform.ANDROID || this.#platform.IOS;
  }

  isSmallScreen(): boolean {
    return this.#breakpointObserver.isMatched('(max-width: 768px)');
  }
}
