export class FileUtility {
  /**
   * Checks if file is image type
   * @static
   * @param {File} file
   * @return {*}  {boolean}
   */
  public static isFileImage(file: File): boolean {
    if (!file) return false;
    return file && file['type'].split('/')[0] === 'image';
  }

  public static isImageMimeType(mimeType: string): boolean {
    return mimeType.split('/')[0] === 'image';
  }

  /**
   * for async file operations
   *
   * @static
   * @param {*} blob
   * @memberof FileUtility
   */
  public static convertBlobToBase64 = async (blob: Blob) => {
    return FileUtility.blobToBase64(blob);
  };

  /**
   * for async file operations
   *
   * @static
   * @param {*} blob
   * @memberof FileUtility
   */
  public static blobToBase64 = async (blob: Blob) =>
    new Promise((resolve: (value: string | ArrayBuffer) => void, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
    });

  /**
   *https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   *
   * @static
   * @param {string} fileType
   * @return {*}  {string}
   * @memberof FileUtility
   */
  public static getIconBasedOnFileType(fileType: string): string {
    let iconString = 'pi pi-file';
    switch (fileType) {
      // spreadsheet files (xls, xlsx, ods)
      case 'application/vnd.ms-excel': {
        iconString = 'pi pi-file-excel';
        break;
      }
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
        iconString = 'pi pi-file-excel';
        break;
      }
      case 'application/vnd.oasis.opendocument.spreadsheet': {
        iconString = 'pi pi-file-excel';
        break;
      }
      // pdf files (pdf)
      case 'application/pdf': {
        iconString = 'pi pi-file-pdf';
        break;
      }
      default: {
        iconString = 'pi pi-file';
        break;
      }
    }
    return iconString;
  }
}
