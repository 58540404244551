import { sharedValidationErrors, ValidationError } from '@fiyu/core';

export const validationErrors: ValidationError[] = [
  ...sharedValidationErrors,
  {
    error: {
      key: 'ORGANIZATION_TYPE_NAME_EXISTS',
    },
    message: 'Organization Type name already exists!',
  },
];
