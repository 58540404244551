import type { Type } from '@angular/core';
import type { Permissions } from '@fiyu/api';
import type { SelectItem } from 'primeng/api';
import type { ReviewStepDisplay } from '../component-types/review-step-display.enum';
import type { AbstractCRUD } from '../crud';
import type { ValidatorOptions } from '../validator';
import type { AbstractEntity } from './abstract-entity.model';

export interface FormOptions {
  /**
   * Defines field as primary key, by default it's ID in abstract form component
   */
  primary?: boolean;
  /**
   * Is this field required?
   * Same as validation: {required: true}
   */
  required?: boolean;
  /**
   * Label of field
   */
  label?: string;
  /**
   * Should label on field be rendered (displayed)
   */
  renderLabel?: boolean;
  /**
   * Type of field, this isn't form type it's type of value
   */
  type?: string;
  /**
   * Form type, it's form control name
   */
  formType?: string;
  /**
   * What type are items in array, if entity type is array
   */
  arrayValueType?: Type<any>;
  /**
   * Is this field hidden field, rendered but nov visible in form
   */
  hidden?: boolean;
  /**
   * Is this field readonly input field
   */
  readonly?: boolean;
  /**
   * Is this field disabled?
   */
  disabled?: boolean;
  /**
   * Validator object or just string with name of validator
   */
  validators?: string | ValidatorOptions;
  /**
   * Where this property should be displayed, in which form group (box)?
   */
  groupNumber?: number;
  initialValue?: any;
  placeholder?: string;
  description?: string;
  render?: boolean;
  /**
   * When creating new entity, should this field be rendered?
   */
  renderOnCreate?: boolean;
  /**
   * When editing existing entity, should this field be rendered?
   */
  renderOnEdit?: boolean;
  lookupModel?: Type<AbstractEntity>;
  lookupService?: Type<AbstractCRUD>;
  lookupOptions?: SelectItem[];
  lookupEmpty?: boolean;
  lookupSearchField?: string; // NOT IN USE ATM
  lookupMy?: boolean;
  lookupMyMethod?: string;
  renderer?: string; // NOT IN USE ATM
  rendererFunction?: () => void; // NOT IN USE ATM
  rendererAsyncFunction?: () => void; // NOT IN USE ATM
  requiredPermissions?: string | Permissions;
  fieldOptions?: {
    multiple?: boolean;
    min?: number;
    max?: number;
    step?: number;
    readonly?: boolean;
    maxlength?: number;
    keyFilter?: string | RegExp;
    validateOnly?: boolean;
    stepMinute?: number;
    disabledDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
    showOtherMonths?: boolean;
    minFractionDigits?: number;
    useGrouping?: boolean;
    currency?: string; // 3 char code eg. 'USD' or 'EUR'
    inputMask?: string; // input Mask pattern eg. 999-999-99 alpha numeric combinations with pattern (default: A-Z,a-z),(0-9)
    // upload options
    accept?: string;
    maxFileSize?: number;
    auto?: boolean;
    mode?: string;
    hiddenInputName?: string;
  };
  wizardStepIndex?: number;
  editable?: boolean;
  reviewStepDisplay?: ReviewStepDisplay;
  transient?: boolean;
  cascadeTo?: string;
  cascadedBy?: string;
}
