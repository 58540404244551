import { SortDirection } from './../crud/search/sort-direction.enum';
import { ObjectUtility } from './object.utility';

export class ArrayUtil {
  static copyArray(array: any[]) {
    return ObjectUtility.copy(array);
  }

  static copyArrayAndAdd(array: any[], itemToAdd: any) {
    if (array == null) {
      return [itemToAdd];
    }

    const arrayCopy: any[] = this.copyArray(array);
    arrayCopy.push(itemToAdd);

    return arrayCopy;
  }

  static deepCloneArray(array: any[]) {
    const clonedArray: any = [];
    array.map((item) => {
      clonedArray.push(Object.assign({}, item));
    });
    return clonedArray;
  }

  static copyArrayAndRemove(array: any[], itemToRemove: any, comparisonPropertyName?: string): any[] {
    if (array == null) {
      return array;
    }
    const arrayCopy: any[] = this.copyArray(array);
    this.removeFromArray(arrayCopy, itemToRemove, comparisonPropertyName);

    return arrayCopy;
  }

  static copyArrayAndRemoveByIndex(array: any[], indexOfItemToRemove: number): any[] {
    if (array == null) {
      return array;
    }

    const arrayCopy: any[] = this.copyArray(array);
    arrayCopy.splice(indexOfItemToRemove, 1);

    return arrayCopy;
  }

  /**
   * Removes item from array of objects or primitives.
   *
   * @param array source array from which an object or primitive should be removed
   * @param itemToRemove represents entire object that should be removed
   *  - a value of one of its fields or a primitive value
   * @param comparisonPropertyName name of the property which should be used to compare values
   *  - if the source array contains only objects
   */
  static removeFromArray(array: any[], itemToRemove: any, comparisonPropertyName?: string): void {
    let itemIndex: number;

    if (comparisonPropertyName) {
      if (typeof itemToRemove === 'object' && itemToRemove.constructor === Object) {
        itemIndex = array.findIndex((item) => item[comparisonPropertyName] === itemToRemove[comparisonPropertyName]);
      } else {
        itemIndex = array.findIndex((item) => item[comparisonPropertyName] === itemToRemove);
      }
    } else {
      itemIndex = array.findIndex((item) => item === itemToRemove);
    }

    if (itemIndex !== -1) {
      array.splice(itemIndex, 1);
    }
  }

  /**
   * Flatten Array.
   *
   * @param resource source array to flatten
   * @param depth The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
   */
  static arrayFlatDeep(resource: Array<any>, depth = 1): any {
    return depth > 0
      ? resource.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.arrayFlatDeep(val, depth - 1) : val), [])
      : resource.slice();
  }

  /**
   * Sort array by key with specified order
   *
   * @static
   * @param {string} key
   * @param {string} [sortOrder='ASC']
   * @param {string} [propType='string']
   * @return {*}  {(a: T, b: T) => number}
   * @memberof ArrayUtil
   */
  static sortByKey<T>(
    key: string,
    sortOrder: string = SortDirection.Ascending,
    propType = 'string',
  ): (a: T, b: T) => number {
    // const order = descending ? -1 : 1;
    const order = sortOrder === SortDirection.Ascending ? 1 : -1;
    return (a, b): number => {
      let valA = null;
      let valB = null;
      if (propType === 'date') {
        valA = new Date(a as unknown as string).getTime();
        valB = new Date(b as unknown as string).getTime();
      } else if (propType === 'string') {
        // switch to this if needed in future
        // Intl.Collator object enables language-sensitive string comparison.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
        valA = ((<T>a) as any)[key]?.toString()?.toLowerCase();
        valB = ((<T>b) as any)[key]?.toString()?.toLowerCase();
      }
      return valA < valB ? -order : valA > valB ? order : 0;
    };
  }

  /**
   * Sort array by nested key with specified order
   *
   * @static
   * @param {string} prop1
   * @param {string} [prop2=null]
   * @param {string} [sortOrder='ASC']
   * @param {string} [propType='string']
   * @memberof ArrayUtil
   */
  static sortByNestedKey =
    (prop1: string, prop2: string = null, sortOrder: string = SortDirection.Ascending, propType = 'string') =>
    (e1: { [x: string]: any }, e2: { [x: string]: any }): number => {
      let a = prop2 ? e1[prop1][prop2] : e1[prop1];

      let b = prop2 ? e2[prop1][prop2] : e2[prop1];
      const order = sortOrder === SortDirection.Ascending ? 1 : -1;
      if (propType === 'date') {
        a = new Date(a as string).getTime();
        b = new Date(b as string).getTime();
      } else if (propType === 'string') {
        // switch to this if needed in future
        // Intl.Collator object enables language-sensitive string comparison.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator
        a = (<string>a)?.toString().toLowerCase();
        b = (<string>b)?.toString().toLowerCase();
      }
      return a < b ? -order : a > b ? order : 0;
    };

  /**
   * Remove duplicate objects from array
   *
   * @static
   * @param {T[]} items
   * @return {*}  {T[]}
   * @memberof ArrayUtil
   */
  static deduplicate<T>(items: T[]): T[] {
    const uniqueItems: T[] = [...new Set(items.map((o): string => JSON.stringify(o)))].map((string: string): any =>
      JSON.parse(string),
    ) as T[];
    return [...uniqueItems] as T[];
  }

  /**
   * Remove duplicate objects from array by key
   *
   * @static
   * @param {T[]} items
   * @param {string} key
   * @return {*}  {T[]}
   * @memberof ArrayUtil
   */
  static deduplicateByKey<T>(items: T[], key: string): T[] {
    const uniqueItems: T[] = [];
    const map = new Map();
    for (const item of items) {
      if (!map.has((<any>item)[key])) {
        map.set((<any>item)[key], true); // set any value to Map
        uniqueItems.push(item);
      }
    }
    return uniqueItems;
  }

  /**

     * Function that maps a string array to enum values and returns a concatenated result

     * @param array - string array of enum keys that need mapping

     * @param enumModel - enum whose values are being mapped from

     */

  static enumStringArrayToReadable(array: string[], enumModel: any) {
    let result = '';

    array.forEach((value: string, index) => {
      result += enumModel[value];

      if (index !== array.length - 1) {
        result += ', ';
      }
    });

    return result;
  }

  static sortListByLang(options: Intl.ListFormatOptions, list: string[], lang: string | string[]) {
    if (Array.isArray(list)) {
      const formatter = new Intl.ListFormat(lang, options);
      return formatter.format(list);
    }
    return list;
  }
}
