import 'reflect-metadata';
import { DecoratorKeysEnum } from './decorator-keys.enum';
import type { PresentationOptions } from './presentation-options.interface';

/**
 * Decorator for entity property
 *
 * Insert entity and presentation options
 *
 * @param options
 */
export function PresentationEntity(options?: PresentationOptions) {
  return (target: any, key: string) => {
    const t = Reflect.getMetadata('design:type', target, key);
    const classConstructor = target.constructor;
    const decoratorKey = DecoratorKeysEnum.Presentation + classConstructor.name;

    let presentationMap = Reflect.getMetadata(decoratorKey, classConstructor);

    if (!presentationMap) {
      presentationMap = new Map<string, PresentationOptions>();
    }

    const _options = options || {};
    _options.type = t.name;

    presentationMap.set(key, _options);

    Reflect.defineMetadata(decoratorKey, presentationMap, classConstructor);
  };
}
