import type { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { SpinnerService } from '@fiyu/api';
import { finalize } from 'rxjs/operators';

/**
 * Spinner interceptor for api calls
 * @usage
 * provideHttpClient(
      withInterceptors([ spinnerInterceptor() ])
    ),
 * @returns HttpInterceptorFn
 */
export const spinnerInterceptor = () => {
  const interceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const spinnerService: SpinnerService = inject(SpinnerService);
    spinnerService.set(true);
    return next(req).pipe(
      finalize(() => {
        spinnerService.set(false);
      }),
    );
  };
  return interceptor;
};
