import { inject } from '@angular/core';
import {
  Router,
  type ActivatedRouteSnapshot,
  type CanActivateFn,
  type GuardResult,
  type MaybeAsync,
  type RouterStateSnapshot,
} from '@angular/router';
import { SecurityService } from './../../security/security.service';

/**
 * guard as a const
 * @usageNotes  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
 * @param _route
 * @param _state
 * @returns MaybeAsync<GuardResult>
 */
export const AuthGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): MaybeAsync<GuardResult> => {
  const securityService = inject(SecurityService);
  const router = inject(Router);
  return securityService.isLoggedIn() ? true : router.parseUrl('/login');
};

/**
 * Guard as a function
 * @usageNotes { path: 'home', component: HomeComponent, canActivate: [authGuard()]},
 * @param _route
 * @param _state
 * @returns MaybeAsync<GuardResult>
 */
export function authGuard(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): CanActivateFn {
  return (): MaybeAsync<GuardResult> => {
    const securityService = inject(SecurityService);
    const router = inject(Router);
    return securityService.isLoggedIn() ? true : router.parseUrl('/login');
  };
}
