import { Injectable } from '@angular/core';
import type { FiyuMenuItem } from '@fiyu/api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private readonly menuSource = new Subject<string>();
  private readonly menuItemsSource = new BehaviorSubject<FiyuMenuItem[]>([]);
  private readonly resetSource = new Subject<boolean>();

  menuSource$ = this.menuSource.asObservable();
  menuItemsSource$ = this.menuItemsSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  onMenuStateChange(key: string) {
    this.menuSource.next(key);
  }

  reset() {
    this.resetSource.next(true);
  }

  setMenuItems(menuItems: FiyuMenuItem[]) {
    this.menuItemsSource.next(menuItems);
  }
}
