import { ErrorHandler, makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ErrorHandlerService } from './errors/error-handler.service';
import { FiyuException } from './errors/fiyu-exception';

export function provideCoreModuleServices(): EnvironmentProviders {
  return makeEnvironmentProviders([
    ConfirmationService,
    MessageService,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    FiyuException,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ]);
}
