export class StringUtility {
  /**
   * Sets string to first letter uppercase
   * @param obj
   */
  static toFirstLetterUpper(obj: string): string {
    return obj.charAt(0).toUpperCase() + obj.slice(1);
  }

  /**
   * Sets string to first letter lowercase
   * @param obj
   */
  static toFirstLetterLower(obj: string): string {
    return obj.charAt(0).toLowerCase() + obj.slice(1);
  }

  /**
   * Ignore case
   * @param obj1
   * @param obj2
   * @returns boolean
   */
  static ignoreCase(obj1: string, obj2: string): boolean {
    return obj1.toLowerCase() === obj2.toLowerCase();
  }

  /**
   *
   * @param obj
   * @returns boolean
   */

  static isEmpty(obj: string): boolean {
    return obj === undefined || obj === null || obj === '';
  }
}
