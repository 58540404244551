import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createStore, select, Store, withProps } from '@ngneat/elf';
import { Observable } from 'rxjs';

export interface CacheProps {
  cacheMap: string;
}

@Injectable({ providedIn: 'root' })
export class CacheRepository {
  private store: Store;
  public persist: any;
  constructor() {
    const initialValue: Map<string, HttpResponse<any>> = new Map();
    initialValue.set('init', new HttpResponse());
    this.store = createStore(
      { name: 'cacheMap' },
      withProps<CacheProps>({ cacheMap: JSON.stringify([...new Map()]) as any })
    );

    /*   this.persist = persistState(this.store, {
      key: 'cacheMap',
      storage: localStorageStrategy,
      //  preStoreInit: (value) => {
      //  console.log('preStoreInit : ', value);
      //  return value;
      // },
      //  source: () => this.store.pipe(debounceTime(1000)),
    }); */
    // this.updateCacheMap(initialValue);
  }

  getCacheMap(): Observable<string> {
    const cacheMap$ = this.store.pipe(select((state: CacheProps) => state.cacheMap));
    return cacheMap$;
  }

  getCacheMapValue(): Map<string, HttpResponse<any>> {
    const cacheMap = new Map<string, HttpResponse<any>>(JSON.parse(this.store.getValue().cacheMap));
    return cacheMap;
  }

  getStoreValue(): CacheProps {
    return this.store.getValue();
  }

  updateCacheMap(cacheMap: Map<string, HttpResponse<any>>) {
    const stringifiedCacheMap = JSON.stringify([...cacheMap]);
    this.store.update((state: CacheProps) => ({
      ...state,
      cacheMap: stringifiedCacheMap,
    }));
  }
}
