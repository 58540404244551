import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

export enum NotificationTypeEnum {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  MODULE = 'MODULE',
  GENERAL = 'GENERAL',
}

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.NotificationMessage,
  pluralName: SharedTranslateKeys.NotificationMessages,
  contentType: 'application/project.v1+json',
  permissionPrefix: ModulePermissions.ORGANIZATION,
  labelField: 'message',
  editDisabled: true,
  defaultSort: { field: 'message', order: SortDirection.Ascending, type: 'string' },
})
export class NotificationMessages extends AbstractEntity {
  @PresentationEntity()
  message: string;

  @PresentationEntity()
  generator: string;

  @PresentationEntity()
  createdTime: Date;

  @PresentationEntity({
    // tableDisplay: false,
    renderer: 'yesNo',
  })
  read: boolean;

  @PresentationEntity({
    tableDisplay: false,
    renderer: 'yesNo',
  })
  seen: boolean;

  @PresentationEntity({
    // tableDisplay: false,
  })
  readTime: Date;

  @PresentationEntity({
    tableDisplay: false,
  })
  seenTime: Date;

  icon: string;
  color: string;
}
