import { EnumUtility } from '../utilities/enum.utility';
import { DecoratorKeysEnum } from './decorator-keys.enum';
import { EntityOptionsEnum } from './entity-options.enum';
import type { EntityOptions } from './entity-options.interface';
import { ModLogOptions } from './mod-log-options.enum';
import type { PresentationOptions } from './presentation-options.interface';

/**
 * Decorator for entity
 *
 * @param options
 */
export function Entity(options?: EntityOptions | any) {
  return function (target: any) {
    // save a reference to the original constructor
    const original = target;
    const c = new target();

    const classConstructor = target;
    const decoratorKey = DecoratorKeysEnum.Entity + classConstructor.name;
    const decoratorKeyOriginal = DecoratorKeysEnum.EntityOriginal + classConstructor.name;

    if (options) {
      const entityOptionsEnum = EntityOptionsEnum as any;
      for (const item in entityOptionsEnum) {
        if (entityOptionsEnum[item]) {
          const key = entityOptionsEnum[item];
          const value = options[key] !== undefined ? options[key] : c[key];
          if (key === entityOptionsEnum.ModLog) {
            resolveModLog(original, value, classConstructor);
          }

          Reflect.defineMetadata(decoratorKey + key, value, classConstructor);
          Reflect.defineMetadata(decoratorKeyOriginal + key, options[key], classConstructor);
        }
      }
    }

    return original;
  };
}

const resolveModLog = (target: any, value: string, classConstructor: string) => {
  const presentationKey = DecoratorKeysEnum.Presentation + target.name;
  let presentationMap = Reflect.getMetadata(presentationKey, classConstructor);

  if (!value) {
    value = 'CcLlDd';
  }

  if (value && value.length) {
    value.split('').forEach((mod) => {
      let fieldName: string;
      try {
        fieldName = EnumUtility.getEnumKey(ModLogOptions, mod);
      } catch {
        throw new SyntaxError(`Unknown modLog option: ${mod}`);
      }

      if (fieldName) {
        const defaultPresentationOptions: PresentationOptions = {
          displayName: 'common.' + fieldName,
          type: fieldName?.includes('By') ? 'String' : 'Date',
          quickViewDisplay: false,
          quickViewPanelSectionDisplay: true,
          tableDisplay: false,
        };
        const existingPresentationOptions = presentationMap ? presentationMap.get(fieldName) : null;
        const presentationOptions = existingPresentationOptions
          ? { ...defaultPresentationOptions, ...existingPresentationOptions }
          : defaultPresentationOptions;
        if (!presentationMap) {
          presentationMap = new Map<string, PresentationOptions>();
          //  presentationMap.set(fieldName, existingPresentationOptions);
        }
        presentationMap.set(fieldName, presentationOptions);

        Reflect.defineMetadata(presentationKey, presentationMap, classConstructor);
      }
    });
  }
};
