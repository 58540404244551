export enum ModulePermissions {
  CRM_PLAN_RUN = 'CRM_PLAN_RUN',
  CRM_PLAN_CREATE = 'CRM_PLAN_CREATE',
  CRM_PLAN_EDIT = 'CRM_PLAN_EDIT',
  CRM_PLAN_DELETE = 'CRM_PLAN_DELETE',
  CRM_PLAN_VIEW_ALL = 'CRM_PLAN_VIEW_ALL',
  CRM_PLAN_VIEW_MY = 'CRM_PLAN_VIEW_MY',

  CRM_PAYMENT_RUN = 'CRM_PAYMENT_RUN',
  CRM_PAYMENT_VIEW_ALL = 'CRM_PAYMENT_VIEW_ALL',
  CRM_PAYMENT_VIEW_MY = 'CRM_PAYMENT_VIEW_MY',
  CRM_PAYMENT_CREATE = 'CRM_PAYMENT_CREATE',
  CRM_PAYMENT_EDIT = 'CRM_PAYMENT_EDIT',
  CRM_PAYMENT_DELETE = 'CRM_PAYMENT_DELETE',

  CRM_PAYMENT_METHOD_RUN = 'CRM_PAYMENT_METHOD_RUN',
  CRM_PAYMENT_METHOD_VIEW = 'CRM_PAYMENT_METHOD_VIEW',
  CRM_PAYMENT_METHOD_CREATE = 'CRM_PAYMENT_METHOD_CREATE',
  CRM_PAYMENT_METHOD_EDIT = 'CRM_PAYMENT_METHOD_EDIT',
  CRM_PAYMENT_METHOD_DELETE = 'CRM_PAYMENT_METHOD_DELETE',

  CRM_ACCOUNT_RUN = 'CRM_ACCOUNT_RUN',
  CRM_ACCOUNT_VIEW_ALL = 'CRM_ACCOUNT_VIEW_ALL',
  CRM_ACCOUNT_CREATE_ALL = 'CRM_ACCOUNT_CREATE_ALL',
  CRM_ACCOUNT_EDIT_ALL = 'CRM_ACCOUNT_EDIT_ALL',
  CRM_ACCOUNT_DELETE_ALL = 'CRM_ACCOUNT_DELETE_ALL',
  CRM_ACCOUNT_VIEW_MY = 'CRM_ACCOUNT_VIEW_MY',
  CRM_ACCOUNT_CREATE_MY = 'CRM_ACCOUNT_CREATE_MY',
  CRM_ACCOUNT_EDIT_MY = 'CRM_ACCOUNT_EDIT_MY',
  CRM_ACCOUNT_DELETE_MY = 'CRM_ACCOUNT_DELETE_MY',

  CRM_PROJECT_RUN = 'CRM_PROJECT_RUN',
  CRM_PROJECT_VIEW_ALL = 'CRM_PROJECT_VIEW_ALL',
  CRM_PROJECT_CREATE_ALL = 'CRM_PROJECT_CREATE_ALL',
  CRM_PROJECT_EDIT_ALL = 'CRM_PROJECT_EDIT_ALL',
  CRM_PROJECT_DELETE_ALL = 'CRM_PROJECT_DELETE_ALL',
  CRM_PROJECT_VIEW_MY = 'CRM_PROJECT_VIEW_MY',
  CRM_PROJECT_CREATE_MY = 'CRM_PROJECT_CREATE_MY',
  CRM_PROJECT_EDIT_MY = 'CRM_PROJECT_EDIT_MY',
  CRM_PROJECT_DELETE_MY = 'CRM_PROJECT_DELETE_MY',

  CRM_REGISTRATION_RUN = 'CRM_REGISTRATION_RUN',
  CRM_REGISTRATION_VIEW = 'CRM_REGISTRATION_VIEW',
  CRM_REGISTRATION_CREATE = 'CRM_REGISTRATION_CREATE',
  CRM_REGISTRATION_EDIT = 'CRM_REGISTRATION_EDIT',
  CRM_REGISTRATION_DELETE = 'CRM_REGISTRATION_DELETE',

  CRM_RESOURCE_RUN = 'CRM_RESOURCE_RUN',
  CRM_RESOURCE_VIEW = 'CRM_RESOURCE_VIEW',
  CRM_RESOURCE_CREATE = 'CRM_RESOURCE_CREATE',
  CRM_RESOURCE_EDIT = 'CRM_RESOURCE_EDIT',
  CRM_RESOURCE_DELETE = 'CRM_RESOURCE_DELETE',

  CRM_PROVISIONING_RUN = 'CRM_PROVISIONING_RUN',
  CRM_PROVISIONING_VIEW = 'CRM_PROVISIONING_VIEW',
  CRM_PROVISIONING_CREATE = 'CRM_PROVISIONING_CREATE',
  CRM_PROVISIONING_EDIT = 'CRM_PROVISIONING_EDIT',
  CRM_PROVISIONING_DELETE = 'CRM_PROVISIONING_DELETE',

  CRM_BILL_RUN = 'CRM_BILL_RUN',
  CRM_BILL_VIEW_ALL = 'CRM_BILL_VIEW_ALL',
  CRM_BILL_VIEW_MY = 'CRM_BILL_VIEW_MY',
  CRM_BILL_CREATE = 'CRM_BILL_CREATE',
  CRM_BILL_EDIT = 'CRM_BILL_EDIT',
  CRM_BILL_DELETE = 'CRM_BILL_DELETE',

  CRM_BILLITEM_RUN = 'CRM_BILLITEM_RUN',
  CRM_BILLITEM_VIEW = 'CRM_BILLITEM_VIEW',
  CRM_BILLITEM_CREATE = 'CRM_BILLITEM_CREATE',
  CRM_BILLITEM_EDIT = 'CRM_BILLITEM_EDIT',
  CRM_BILLITEM_DELETE = 'CRM_BILLITEM_DELETE',

  CRM_FEATURE_RUN = 'CRM_FEATURE_RUN',
  CRM_FEATURE_VIEW = 'CRM_FEATURE_VIEW',
  CRM_FEATURE_CREATE = 'CRM_FEATURE_CREATE',
  CRM_FEATURE_EDIT = 'CRM_FEATURE_EDIT',
  CRM_FEATURE_DELETE = 'CRM_FEATURE_DELETE',

  // Permission prefixes
  CRM = 'CRM',
  CRM_RUN = 'CRM_RUN',
  CRM_PLAN = 'CRM_PLAN',
  CRM_PAYMENT = 'CRM_PAYMENT',
  CRM_PAYMENT_METHOD = 'CRM_PAYMENT_METHOD',
  CRM_ACCOUNT = 'CRM_ACCOUNT',
  CRM_PROJECT = 'CRM_PROJECT',
  CRM_REGISTRATION = 'CRM_REGISTRATION',
  CRM_RESOURCE = 'CRM_RESOURCE',
  CRM_PROVISIONING = 'CRM_PROVISIONING', // deprovisioning is using same permission
  CRM_FEATURE = 'CRM_FEATURE',
  CRM_BILL = 'CRM_BILL',
  CRM_BILLITEM = 'CRM_BILLITEM',
}
