import { CompositionType } from './composition-type.enum';
import { CriterionType } from './criterion-type.enum';
import { SearchType } from './search-type.enum';

export interface Criterion {
  type: SearchType;
  propertyPath?: string;
  propertyValue?: any;
  criterionType?: CriterionType;
  compositionType?: CompositionType;
  criterions?: Criterion[];
}
