import { Injectable } from '@angular/core';
import { LC_STORAGE_TYPE } from '@fiyu/api';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   *
   * @param value
   */
  public setLocal(value: boolean) {
    localStorage.setItem(LC_STORAGE_TYPE, value ? 'local' : 'session');
  }

  /**
   *
   */
  public isLocal(): boolean {
    return localStorage.getItem(LC_STORAGE_TYPE) === 'local';
  }

  /**
   *
   * @param key
   * @param value
   */
  public setItem(key: string, value: any) {
    if (this.isLocal()) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  /**
   *
   * @param key
   */
  public getItem(key: string): any {
    if (this.isLocal()) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }

  /**
   *
   * @param key
   */
  public removeItem(key: string) {
    if (this.isLocal()) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }
}
