import { SharedTranslateKeys } from '@fiyu/api';
import { FiyuError } from './fiyu-error';

const fiyuErrors: FiyuError[] = [
  {
    name: 'FIYU-0',
    message: SharedTranslateKeys.ErrorGeneralError,
  },
  {
    name: 'FIYU-1',
    message: SharedTranslateKeys.ErrorCreateIdNotNull,
  },
  {
    name: 'FIYU-2',
    message: SharedTranslateKeys.ErrorUpdateIdNull,
  },
  {
    name: 'FIYU-3',
    message: SharedTranslateKeys.ErrorAccessDenied,
  },
  {
    name: 'FIYU-4',
    message: SharedTranslateKeys.ErrorBodyMissing,
  },
  {
    name: 'FIYU-5',
    message: SharedTranslateKeys.ErrorRequestParameterMissing,
  },
  {
    name: 'FIYU-6',
    message: SharedTranslateKeys.ErrorTypeMismatch,
  },
  {
    name: 'FIYU-7',
    message: SharedTranslateKeys.ErrorEntityNotFound,
  },
  {
    name: 'FIYU-8',
    message: SharedTranslateKeys.ErrorAclNotFound,
  },
  {
    name: 'FIYU-V',
    message: SharedTranslateKeys.ErrorValidationMessage,
  },
];

export default fiyuErrors;
