import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T;

type RemoveNull<T> = ExpandRecursively<{ [K in keyof T]: Exclude<RemoveNull<T[K]>, null> }>;
/**
 * Redirect utils
 * @export
 * @class RedirectService
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly document: Document = inject(DOCUMENT);

  /**
   * method to trigger post redirect on click
   * @example (click)="redirect()"
   * public redirect = () => this.redirectService.postRedirect(params);
   * @param {Record<string, any>} params - query params as a object for post redirect
   * @param {string} actionUrl - post action url
   * @memberof RedirectService
   */
  public postRedirect(params: Record<string, any>, actionUrl: string) {
    const form = this.document.createElement('form');
    form.method = 'POST';
    form.target = '_self';
    form.action = actionUrl;
    // form.setAttribute("target", "_top");
    params = this.removeEmpty(params);
    // console.log(params);
    for (const prop in params) {
      const input = this.document.createElement('input');
      input.type = 'hidden';
      input.name = prop;
      input.value = params[prop];
      form.append(input);
    }
    this.document.body.appendChild(form);
    form.submit();
  }

  private removeEmpty<T>(obj: T): RemoveNull<T> {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null)
        .map(([k, v]) => [k, v === Object(v) ? this.removeEmpty(v) : v]),
    ) as RemoveNull<T>;
  }
}
