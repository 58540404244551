import type { Type } from '@angular/core';
import type { Permissions } from '@fiyu/api';
import type { SelectItem } from 'primeng/api';
import type { AbstractCRUD } from '../crud/abstract-crud.interface';
import type { AbstractEntity } from '../entity/abstract-entity.model';

export interface FormField {
  id?: string;
  required?: boolean;
  label?: string;
  renderLabel?: boolean;
  type?: string;
  formType?: string;
  hidden?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  render?: boolean;
  renderOnCreate?: boolean;
  renderOnEdit?: boolean;
  initialValue?: any;
  placeholder?: string;
  description?: string;
  lookupOptions?: SelectItem[];
  lookupEmpty?: boolean;
  lookupSearchField?: string;
  lookupModel?: Type<AbstractEntity>;
  lookupService?: Type<AbstractCRUD>;
  lookupMy?: boolean;
  lookupMyMethod?: string;
  renderer?: string;
  rendererFunction?: () => void;
  requiredPermissions?: string | Permissions;
  fieldOptions?: {
    multiple?: boolean;
    min?: number;
    max?: number;
    step?: number;
    readonly?: boolean;
    maxlength?: number;
    keyFilter?: string | RegExp;
    validateOnly?: boolean;
    stepMinute?: number;
    disabledDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
    showOtherMonths?: boolean;
    minFractionDigits?: number;
    useGrouping?: boolean;
    currency?: string; // 3 char code eg. 'USD' or 'EUR'
    inputMask?: string; // input Mask pattern eg. 999-999-99 alpha numeric combinations with pattern (default: A-Z,a-z),(0-9)
    // upload options
    accept?: string;
    maxFileSize?: number;
    auto?: boolean;
    mode?: string;
    hiddenInputName?: string;
  };
  cascadeTo?: string;
  cascadedBy?: string;
}
