import {
  MODULE_METADATA_TOKEN,
  Operations,
  RequiredModulesConfig,
  SharedTranslateKeys,
  VALIDATION_ERRORS_TOKEN,
} from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { TranslateKeys } from './module-translate-keys';
import { validationErrors } from './module-validation-errors';

export const CrmRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () => (await import('./crm-layout/crm-layout.component')).CrmLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'plan/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.Plan}`,
        loadComponent: async () => (await import('./plan/plan-form/plan-form.component')).PlanFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PLAN_RUN, ModulePermissions.CRM_PLAN_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'plan',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.Plan}`,
        loadComponent: async () => (await import('./plan/plan-form/plan-form.component')).PlanFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PLAN_RUN, ModulePermissions.CRM_PLAN_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'plans',
        title: TranslateKeys.Plans,
        loadComponent: async () => (await import('./plan/plan-list/plan-list.component')).PlanListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PLAN_VIEW_ALL, ModulePermissions.CRM_PLAN_VIEW_MY],
        operation: Operations.OR,
      },
      {
        path: 'plan-feature/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.PlanFeature}`,
        loadComponent: async () =>
          (await import('./plan-feature/plan-feature-form/plan-feature-form.component')).PlanFeatureFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_FEATURE_RUN, ModulePermissions.CRM_FEATURE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'plan-feature',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.PlanFeature}`,
        loadComponent: async () =>
          (await import('./plan-feature/plan-feature-form/plan-feature-form.component')).PlanFeatureFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_FEATURE_RUN, ModulePermissions.CRM_FEATURE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'plan-features',
        title: TranslateKeys.PlanFeatures,
        loadComponent: async () =>
          (await import('./plan-feature/plan-feature-list/plan-feature-list.component')).PlanFeatureListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_FEATURE_RUN, ModulePermissions.CRM_FEATURE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'payment/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Payment}`,
        loadComponent: async () => (await import('./payment/payment-form/payment-form.component')).PaymentFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_RUN, ModulePermissions.CRM_PAYMENT_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'payment',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Payment}`,
        loadComponent: async () => (await import('./payment/payment-form/payment-form.component')).PaymentFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_RUN, ModulePermissions.CRM_PAYMENT_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'payments',
        title: SharedTranslateKeys.Payments,
        loadComponent: async () => (await import('./payment/payment-list/payment-list.component')).PaymentListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_VIEW_ALL, ModulePermissions.CRM_PAYMENT_VIEW_MY],
        operation: Operations.OR,
      },
      {
        path: 'payment-method',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.PaymentMethod}`,
        loadComponent: async () =>
          (await import('./payment-method/payment-method-form/payment-method-form.component'))
            .PaymentMethodFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_METHOD_RUN, ModulePermissions.CRM_PAYMENT_METHOD_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'payment-method/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.PaymentMethod}`,
        loadComponent: async () =>
          (await import('./payment-method/payment-method-form/payment-method-form.component'))
            .PaymentMethodFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_METHOD_RUN, ModulePermissions.CRM_PAYMENT_METHOD_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'payment-methods',
        title: TranslateKeys.PaymentMethods,
        loadComponent: async () =>
          (await import('./payment-method/payment-method-list/payment-method-list.component'))
            .PaymentMethodListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PAYMENT_METHOD_RUN, ModulePermissions.CRM_PAYMENT_METHOD_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'account',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.Account}`,
        loadComponent: async () => (await import('./account/account-form/account-form.component')).AccountFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_ACCOUNT_CREATE_MY, ModulePermissions.CRM_ACCOUNT_CREATE_ALL],
        operation: Operations.OR,
      },
      {
        path: 'account/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.Account}`,
        loadComponent: async () => (await import('./account/account-form/account-form.component')).AccountFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_ACCOUNT_EDIT_ALL, ModulePermissions.CRM_ACCOUNT_EDIT_MY],
        operation: Operations.OR,
      },
      {
        path: 'accounts',
        title: TranslateKeys.Accounts,
        loadComponent: async () => (await import('./account/account-list/account-list.component')).AccountListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_ACCOUNT_VIEW_ALL, ModulePermissions.CRM_ACCOUNT_VIEW_MY],
        operation: Operations.OR,
      },
      {
        path: 'bill/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.Bill}`,
        loadComponent: async () => (await import('./bill/bill-form/bill-form.component')).BillFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILL_RUN, ModulePermissions.CRM_BILL_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'bill',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.Bill}`,
        loadComponent: async () => (await import('./bill/bill-form/bill-form.component')).BillFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILL_RUN, ModulePermissions.CRM_BILL_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'bills',
        title: TranslateKeys.Bills,
        loadComponent: async () => (await import('./bill/bill-list/bill-list.component')).BillListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILL_VIEW_ALL, ModulePermissions.CRM_BILL_VIEW_MY],
        operation: Operations.OR,
      },
      {
        path: 'bill-item/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.BillItem}`,
        loadComponent: async () =>
          (await import('./bill-item/bill-item-form/bill-item-form.component')).BillItemFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILLITEM_RUN, ModulePermissions.CRM_BILLITEM_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'bill-item',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.BillItem}`,
        loadComponent: async () =>
          (await import('./bill-item/bill-item-form/bill-item-form.component')).BillItemFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILLITEM_RUN, ModulePermissions.CRM_BILLITEM_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'bill-items',
        title: TranslateKeys.BillItems,
        loadComponent: async () =>
          (await import('./bill-item/bill-item-list/bill-item-list.component')).BillItemListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_BILLITEM_RUN, ModulePermissions.CRM_BILLITEM_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'project',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Project}`,
        loadComponent: async () => (await import('./project/project-form/project-form.component')).ProjectFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROJECT_CREATE_MY, ModulePermissions.CRM_PROJECT_CREATE_ALL],
        operation: Operations.OR,
      },
      {
        path: 'project/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Project}`,
        loadComponent: async () => (await import('./project/project-form/project-form.component')).ProjectFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROJECT_EDIT_MY, ModulePermissions.CRM_PROJECT_EDIT_ALL],
        operation: Operations.OR,
      },
      {
        path: 'projects',
        title: SharedTranslateKeys.Projects,
        loadComponent: async () => (await import('./project/project-list/project-list.component')).ProjectListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROJECT_VIEW_ALL, ModulePermissions.CRM_PROJECT_VIEW_MY],
        operation: Operations.OR,
      },
      {
        path: 'provisioning',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.Provisioning}`,
        loadComponent: async () =>
          (await import('./provisioning/provisioning-form/provisioning-form.component')).ProvisioningFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROVISIONING_RUN, ModulePermissions.CRM_PROVISIONING_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'provisioning/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.Provisioning}`,
        loadComponent: async () =>
          (await import('./provisioning/provisioning-form/provisioning-form.component')).ProvisioningFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROVISIONING_RUN, ModulePermissions.CRM_PROVISIONING_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'provisionings',
        title: TranslateKeys.Provisionings,
        loadComponent: async () =>
          (await import('./provisioning/provisionings-list/provisionings-list.component')).ProvisioningsListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PROVISIONING_RUN, ModulePermissions.CRM_PROVISIONING_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'registration/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Registration}`,
        loadComponent: async () =>
          (await import('./registration/registration-form/registration-form.component')).RegistrationFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_REGISTRATION_RUN, ModulePermissions.CRM_REGISTRATION_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'registrations',
        title: SharedTranslateKeys.Registrations,
        loadComponent: async () =>
          (await import('./registration/registration-list/registration-list.component')).RegistrationListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_REGISTRATION_RUN, ModulePermissions.CRM_REGISTRATION_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'resource',
        title: `${SharedTranslateKeys.Create} ${TranslateKeys.Resource}`,
        loadComponent: async () =>
          (await import('./resource/resource-form/resource-form.component')).ResourceFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_RESOURCE_RUN, ModulePermissions.CRM_RESOURCE_CREATE],
        operation: Operations.AND,
      },
      {
        path: 'resource/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${TranslateKeys.Resource}`,
        loadComponent: async () =>
          (await import('./resource/resource-form/resource-form.component')).ResourceFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_RESOURCE_RUN, ModulePermissions.CRM_RESOURCE_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'resources',
        title: TranslateKeys.Resources,
        loadComponent: async () =>
          (await import('./resource/resources-list/resources-list.component')).ResourcesListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_RESOURCE_RUN, ModulePermissions.CRM_RESOURCE_VIEW],
        operation: Operations.AND,
      },
      {
        path: 'summary',
        title: TranslateKeys.BillingSummary,
        loadComponent: async () => (await import('./summary/summary-list/summary-list.component')).SummaryListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_PLAN_RUN, ModulePermissions.CRM_PLAN_VIEW_MY],
        operation: Operations.AND,
      },
      {
        path: 'landing',
        title: 'CRM',
        loadComponent: async () => (await import('./crm-landing/crm-landing.component')).CrmLandingComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.CRM,
        requiredPermissions: [ModulePermissions.CRM_RUN],
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
