import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@fiyu/core';
import { Observable } from 'rxjs';
import { ModuleMetadata } from './../module-metadata';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractCRUDService {
  constructor() {
    super('/users', ModuleMetadata.getInstance());
  }

  public sendNewUserCredentials<UserAdminListItem>(user: UserAdminListItem | any): Observable<UserAdminListItem> {
    return this.httpClient.post<UserAdminListItem>(`${this.apiUrl}/sendNewCredentials/${user.id}`, null);
  }
}
