import { AbstractEntity } from '../entity/abstract-entity.model';
import { Entity } from '../entity/entity.decorator';
import { FormEntity } from '../entity/form-entity.decorator';

@Entity()
export class CoreOrganization extends AbstractEntity {
  @FormEntity() name = '';
  @FormEntity() shortDescription = '';
  @FormEntity() status = '';
  @FormEntity() verified = false;
  @FormEntity() active = false;
}
