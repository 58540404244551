export enum CriterionType {
  Equal = 'EQUAL',
  IEqual = 'IEQUAL',
  GreaterEqual = 'GREATER_EQUAL',
  Greater = 'GREATER',
  In = 'IN',
  NotIn = 'NOT_IN',
  NotNull = 'NOT_NULL',
  IsNull = 'IS_NULL',
  LessEqual = 'LESS_EQUAL',
  Less = 'LESS',
  NotEqual = 'NOT_EQUAL',
  Begins = 'BEGINS',
  IBegins = 'IBEGINS',
  Ends = 'ENDS',
  IEnds = 'IENDS',
  Contains = 'CONTAINS',
  IContains = 'ICONTAINS',
}

export const criterionDataTypes: Map<string, CriterionType[]> = new Map([
  ['Boolean', [CriterionType.Equal]],
  [
    'String',
    [
      CriterionType.Equal,
      CriterionType.IEqual,
      CriterionType.Begins,
      CriterionType.IBegins,
      CriterionType.Ends,
      CriterionType.IEnds,
      CriterionType.Contains,
      CriterionType.IContains,
    ],
  ],
  [
    'Number',
    [
      CriterionType.Less,
      CriterionType.LessEqual,
      CriterionType.Equal,
      CriterionType.NotEqual,
      CriterionType.GreaterEqual,
      CriterionType.Greater,
    ],
  ],
  [
    'Date',
    [
      CriterionType.Less,
      CriterionType.LessEqual,
      CriterionType.Equal,
      CriterionType.NotEqual,
      CriterionType.GreaterEqual,
      CriterionType.Greater,
    ],
  ],
  ['Enum', [CriterionType.Equal, CriterionType.NotEqual]],
  ['Dropdown', [CriterionType.Equal]],
]);
