import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity, PresentationEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.OrganizationType,
  pluralName: SharedTranslateKeys.OrganizationTypes,
  contentType: 'application/organization.type.admin.v1+json',
  singularUrlName: 'organization-type',
  pluralUrlName: 'organization-types',
  permissionPrefix: ModulePermissions.ORGANIZATION_TYPE,
})
export class OrganizationType extends AbstractEntity {
  @FormEntity({
    label: SharedTranslateKeys.Name,
    required: true,
  })
  @PresentationEntity({
    displayName: SharedTranslateKeys.Name,
  })
  name: string;

  @PresentationEntity()
  created: Date = new Date();
}
