import type { DefaultSort } from './default-sort.interface';

export interface EntityOptions {
  singularName?: string;
  pluralName?: string;
  labelField?: string;
  defaultSort?: DefaultSort;
  singularUrlName?: string;
  pluralUrlName?: string;
  isAdmin?: boolean;
  formBlockTitles?: { [key: number]: string };
  contentType?: string;
  permissionPrefix?: string;
  createPermission?: string;
  readPermission?: string;
  updatePermission?: string;
  deletePermission?: string;
  modLog?: string;
  acl?: boolean;
  isPanelSectionVisible?: boolean;
  exportEnabled?: boolean;
  importEnabled?: boolean;
  deleteDisabled?: boolean;
  editDisabled?: boolean;
  quickExportEnabled?: boolean;
  fullExportEnabled?: boolean;
  disabledStateProp?: string;
}
