import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity } from '@fiyu/core';
import {
  ModuleAdmin,
  ModuleService,
  OrganizationAdminNestedUser,
  OrganizationType,
  OrganizationTypeService,
} from '@fiyu/platform';
import { UserLookup } from '@fiyu/users';
import { ModulePermissions } from '../module-permissions';
import { UserService } from '../user.service';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.Organization,
  pluralName: SharedTranslateKeys.Organizations,
  singularUrlName: 'organization',
  pluralUrlName: 'organizations',
  labelField: 'name',
  formBlockTitles: {
    1: SharedTranslateKeys.OrganizationInformation,
    2: SharedTranslateKeys.AvailableModules,
  },
  contentType: 'application/organization.v1+json',
  permissionPrefix: ModulePermissions.ORGANIZATION,
  editDisabled: true,
})
export class Organization extends AbstractEntity {
  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.OrganizationName,
    placeholder: SharedTranslateKeys.EnterOrganizationName,
    required: true,
  })
  name: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    groupNumber: 1,
    label: SharedTranslateKeys.OrganizationType,
    lookupModel: OrganizationType,
    lookupService: OrganizationTypeService,
    lookupEmpty: false,
    placeholder: SharedTranslateKeys.EnterOrganizationType,
    required: true,
  })
  organizationTypeId: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Domain,
    placeholder: SharedTranslateKeys.EnterDomain,
    required: true,
  })
  domain: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.EmailAddress,
    placeholder: SharedTranslateKeys.EnterEmailAddress,
    required: true,
    validators: {
      email: true,
    },
  })
  email: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.NotificationEmail,
    placeholder: SharedTranslateKeys.EnterNotificationEmailAddress,
    validators: {
      email: true,
    },
  })
  notificationEmail: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.IdentificationNumber,
    placeholder: SharedTranslateKeys.EnterIdentificationNzmber,
  })
  identificationNumber: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.RegistrationNumber,
    placeholder: SharedTranslateKeys.EnterRegistrationNumber,
  })
  registrationNumber: string;

  @FormEntity({
    formType: FormControlType.Autocomplete,
    groupNumber: 1,
    label: SharedTranslateKeys.ResponsiblePerson,
    lookupModel: UserLookup,
    lookupService: UserService,
    lookupSearchField: 'fullName',
    placeholder: SharedTranslateKeys.ChooseResponsiblePerson,
    required: true,
  })
  responsiblePersonId: string;

  /* DISABLED TEMPORARY UNTIL DESC IS MAPPED */
  /*   @FormEntity({
    formType: FormControlType.TextArea,
    groupNumber: 1,
    label:  SharedTranslateKeys.Description,
  })
  description: string;

  @FormEntity({
    groupNumber: 1,
    label:  SharedTranslateKeys.ShortDescription,
  })
  shortDescription: string; */

  @FormEntity({
    arrayValueType: OrganizationAdminNestedUser,
    groupNumber: 2,
    render: false,
  })
  users: OrganizationAdminNestedUser[];

  @FormEntity({
    arrayValueType: String,
    formType: FormControlType.Checkbox,
    groupNumber: 2,
    renderLabel: false,
    lookupModel: ModuleAdmin,
    lookupService: ModuleService,
  })
  modules: string[] = [];
}
