import type { Observable } from 'rxjs';
import type { SuggestionSearchParameters } from '../component-types/suggestion-search-parameters.type';
import type { AbstractEntity } from '../entity/abstract-entity.model';
import type { Criterion } from './search/criterion.type';
import type { SearchCriteria } from './search/search-criteria.type';
import type { SearchResult } from './search/search-result.type';

export interface AbstractCRUD {
  apiUrl: string;

  get<T extends AbstractEntity>(searchCriteria: SearchCriteria): Observable<T[]>;
  getWithParams<T extends AbstractEntity>(
    searchCriteria: SearchCriteria,
    filterParams: Record<string, any>,
  ): Observable<SearchResult<T>>;
  getAll<T extends AbstractEntity>(): Observable<T[]>;
  getOne<T extends AbstractEntity>(id: string, acceptType?: string): Observable<T>;
  getPage<T extends AbstractEntity>(searchCriteria: SearchCriteria): Observable<SearchResult<T>>;
  create<T extends AbstractEntity>(entity: T, contentType: string): Observable<T>;
  update<T extends AbstractEntity>(entity: T, contentType: string): Observable<T>;
  /* @ts-ignore */
  delete<T extends AbstractEntity>(id: string): Observable<any>;
  getBaseUrl(): string;
  getExportConfiguration(): Observable<any>;
  exportTableData(exportFields: any): Observable<any>;
  getImportTemplate(): Observable<any>;
  importTableData(files: any): void;

  /**
   * Get suggestions
   * @deprecated
   * @template T
   * @param {new () => T} entityType
   * @param {SuggestionSearchParameters} searchParameters
   * @param {boolean} [skipMappingToSelectItem]
   * @param {Criterion} [additionalCriterion]
   * @return {*}  {Observable<SearchResult<any>>}
   * @memberof AbstractCRUD
   */
  getSuggestions<T extends AbstractEntity>(
    entityType: new () => T,
    searchParameters: SuggestionSearchParameters,
    skipMappingToSelectItem?: boolean,
    additionalCriterion?: Criterion,
  ): Observable<SearchResult<any>>;
  getSuggestionsByList<T extends AbstractEntity>(
    entityType: new () => T,
    searchParameters: SuggestionSearchParameters,
    skipMappingToSelectItem?: boolean,
    additionalCriterion?: Criterion,
  ): Observable<SearchResult<any>>;
  getMy<T extends AbstractEntity>(): Observable<T[]>;
  fullExportData(searchCriteria: SearchCriteria): Observable<any>;
}
