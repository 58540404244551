import { inject, Injectable } from '@angular/core';
import { LC_ACTIVE_MODULE } from '@fiyu/api';
import { StorageService } from './../core/storage.service';
import { FiyuError } from './fiyu-error';
import fiyuErrors from './fiyu-errors';
import { ValidationError } from './validation/validation-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoadingService {
  private errors: Map<string, FiyuError[]> = new Map<string, FiyuError[]>();
  private validationErrors: Map<string, ValidationError[]> = new Map<string, ValidationError[]>();
  private readonly storage: StorageService = inject(StorageService);

  constructor() {
    if (!this.errors.has('core')) {
      this.errors = this.errors.set('core', fiyuErrors);
    }
  }

  loadErrorsForModule(errors: FiyuError[]) {
    const module = this.storage.getItem(LC_ACTIVE_MODULE);
    if (module && !this.errors.has(module)) {
      this.errors = this.errors.set(module, errors);
    }
  }

  loadValidationErrorsForModule(errors: ValidationError[]) {
    const module = this.storage.getItem(LC_ACTIVE_MODULE);
    if (module && !this.validationErrors.has(module)) {
      this.validationErrors = this.validationErrors.set(module, errors);
    }
  }

  getErrors(): Map<string, FiyuError[]> {
    return this.errors;
  }

  getValidationErrors(): Map<string, ValidationError[]> {
    return this.validationErrors;
  }
}
