import { inject } from '@angular/core';
import type { CanMatchFn } from '@angular/router';
import { map, type Observable } from 'rxjs';
import { FeatureFlagsFacadeService } from './../services/feature-flags/feature-flags-facade.service';
import type { FeatureFlag } from './../types/feature-flags';

/**
 * Guard to check if a feature flag is enabled
 * @param {FeatureFlag} featureFlag
 * @returns {CanMatchFn}
 * @example
 * ```ts
 * {
 *   path: 'some-path',
 *   title: 'Some title',
 *   canMatch: [featureFlagGuard('flag-name')],
 *   loadComponent: async () => (await import('./some-path/some-form/some-form.component')).SomeFormComponent,
 * },
 * ```
 */
export function featureFlagGuard(featureFlag: FeatureFlag): CanMatchFn {
  return (): Observable<boolean> => {
    const featureFlagsFacade: FeatureFlagsFacadeService = inject(FeatureFlagsFacadeService);
    return featureFlagsFacade.configLoaded$.pipe(
      map(() => {
        return featureFlagsFacade.featureFlagEnabled(featureFlag);
      }),
    );
  };
}
