import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

interface Script {
  name: string;
  src: string;
}

/**
 * store for lazy loading of scripts on demand
 * src is name of lazy chunk bundle defined in app project.json or external cdn url
 */
export const ScriptStore: Script[] = [
  /* {
    name: 'google-maps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBxvhxWGEHLxXY6YUpoKwrwOw2prLYyEY8&libraries=visualization,places,drawing',
  },
  {
    name: 'marker-clusterer',
    src: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js',
  }, */
  { name: 'exceljs', src: 'exceljs.js' },
  // { name: 'chartjs', src: 'chartjs.js' },
];

@Injectable({ providedIn: 'root' })
export class ScriptLoaderService {
  private scripts: Record<string, any> = {};
  private readonly document: Document = inject(DOCUMENT);

  constructor() {
    this.setScriptDefaults();
  }

  setScriptDefaults() {
    ScriptStore.forEach((script: Script) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  async load(...scripts: string[]): Promise<any[]> {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  async loadScript(scriptName: string): Promise<Record<string, unknown>> {
    return new Promise((resolve, reject) => {
      if (this.scripts[scriptName].loaded) {
        this.resolveAlreadyLoaded(resolve, scriptName);
        return;
      }
      const script = this.createScriptElement(scriptName);
      this.resolveOnLoad(script, resolve, scriptName);
      this.rejectOnError(script, reject, scriptName);
    });
  }

  createScriptElement(scriptName: any) {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.scripts[scriptName].src;
    script.async = true;
    script.setAttribute('defer', 'defer');
    script.setAttribute('async', 'async');

    return script;
  }

  resolveOnLoad(script: any, resolve: any, scriptName: any) {
    if (script.readyState) {
      this.resolveForIE(script, resolve, scriptName);
    } else {
      this.resolve(script, resolve, scriptName);
    }
  }

  rejectOnError(script: any, reject: any, scriptName: any): void {
    script.onerror = (error: any) => reject({ script: scriptName, loaded: false, status: error });
    this.document.getElementsByTagName('head')[0].appendChild(script);
  }

  resolveAlreadyLoaded(resolve: any, scriptName: any): void {
    resolve({ script: scriptName, loaded: true, status: 'Already Loaded' });
  }

  resolve(script: any, resolve: any, scriptName: any) {
    script.onload = () => {
      this.scripts[scriptName].loaded = true;
      resolve({ script: scriptName, loaded: true, status: 'Loaded' });
    };
  }

  resolveForIE(script: any, resolve: any, scriptName: any) {
    script.onreadystatechange = () => {
      if (!(script.readyState === 'loaded' || script.readyState === 'complete')) return;
      script.onreadystatechange = null;
      this.scripts[scriptName].loaded = true;
      resolve({ script: scriptName, loaded: true, status: 'Loaded' });
    };
  }
}
