import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SignalsStoreService } from '@fiyu/api';
import { catchError, map, of, switchMap, type Observable } from 'rxjs';
import { FilesService, type DownloadedFile } from './../files/files.service';

@Injectable({ providedIn: 'root' })
export class LogoService extends SignalsStoreService<DownloadedFile> {
  private readonly filesService = inject(FilesService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    super();
  }

  setUpLogo(fileUuid: string) {
    if (!fileUuid) {
      return;
    }
    this.downloadFile(fileUuid)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(() => of({ file: null, base64ImageString: '', blob: null })),
      )
      .subscribe((data) => {
        this.setState(data);
      });
  }

  downloadFile(fileUuid: string): Observable<DownloadedFile> {
    return this.filesService.downloadFile(fileUuid).pipe(
      switchMap((blob: Blob) =>
        this.filesService.blobToBase64(blob).pipe(map((base64ImageString) => ({ base64ImageString, blob }))),
      ),
      map((response) => {
        const file = new File([response.blob], fileUuid, { type: response.blob.type });
        return { ...response, file } as DownloadedFile;
      }),
      catchError(() => of(null)),
    );
  }
}
