export interface Profile {
  id: string;
  imageId: string;
  userName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  birthdate: Date;
  gender: string;
  email: string;
  notificationEmail: string;
}

export interface ProfileOrganization {
  id: string;
  name: string;
  theme: string;
  imageId: string;
}
