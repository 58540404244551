export class StringTransformUtils {
  /**
   * Transform camel cased text to underscore separated text
   *
   * @param value
   */
  public static camelToSpace(value: string): string {
    if (value == null) {
      return '';
    }
    return value
      .split(/(?=[A-Z])/)
      .join(' ')
      .toLowerCase();
  }

  /**
   * Transform camel cased text to dashed separated text
   *
   * @param value
   */
  public static camelToDashed(value: string): string {
    if (value == null) {
      return '';
    }
    return value
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase();
  }

  /**
   * Transform camel cased text to underscore separated text
   *
   * @param value
   */
  public static camelToSnake(value: string): string {
    if (value == null) {
      return '';
    }
    return value
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }

  /**
   * Capitalize first letter of string
   *
   * @param value
   */
  public static capitalizeFirst(value: string): string {
    if (value == null) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Transform to Title Case: uppercase the first letter of the words in a string
   * Taken from the official title case angular pipe implementation.
   */
  public static titleCase(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/\w\S*/g, (txt) => txt[0].toUpperCase() + txt.substring(1).toLowerCase());
  }

  /**
   * Transform camel cased text to underscore separated text
   *
   * @param value
   */
  public static dashToSpace(value: string): string {
    if (value == null) {
      return '';
    }
    return value.split(/_/).join(' ').toLowerCase();
  }

  /**
   * Transform underscore separated text to lowercase text
   * separated by spaces
   *
   * @param value
   */
  public static enumToString(value: string): string {
    if (!value) {
      return '';
    }

    const words = value.split('_').map((word) => word.toLowerCase());
    return words.join(' ');
  }

  /**
   * Create initials from any string
   * (separate by space, use first letters and upper case them)
   *
   * @param value
   */
  public static getInitials(value: string): string {
    const names = value.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}
