export enum ModulePermissions {
  DMM_RUN = 'DMM_RUN', // not in use atm
  DMM_UPLOAD = 'DMM_UPLOAD',
  DMM_EDIT = 'DMM_EDIT',
  DMM_DOWNLOAD = 'DMM_DOWNLOAD',
  DMM_PREVIEW = 'DMM_PREVIEW',
  DMM_DELETE = 'DMM_DELETE',

  // Permission prefixes
  DMM = 'DMM',
}
