import { StringTransformUtils, type FiyuSelectItem } from '@fiyu/api';
import type { DisplayConfiguration } from '../component-types/display-configuration.type';
import type { DefaultPermissions } from '../core/default-permissions.interface';
import { PermissionSufixes } from '../core/permission-sufixes.enum';
import { SortDirection } from '../crud/search/sort-direction.enum';
import type { DefaultSort } from './../entity/default-sort.interface';
import { DecoratorKeysEnum } from './decorator-keys.enum';
import { EntityOptionsEnum } from './entity-options.enum';
import { FormEntity } from './form-entity.decorator';
import type { FormOptionsEnum } from './form-options.enum';
import type { FormOptions } from './form-options.interface';
import { PresentationEntity } from './presentation-entity.decorator';
import type { PresentationOptionsEnum } from './presentation-options.enum';
import type { PresentationOptions } from './presentation-options.interface';

/**
 * Abstract entity class of Entity Framework
 */
export abstract class AbstractEntity {
  /**
   * Name of property which is used for entity label
   */
  public labelField = 'name';

  /**
   * Default sort options of entity
   */
  public defaultSort: DefaultSort = { field: 'name', order: SortDirection.Ascending, type: 'string' };

  /**
   * Name of prop that is used for disabled state
   */
  public disabledStateProp: string = null;

  /**
   * deleted timestamp
   */
  public deleted: Date = null;

  /**
   * Is entity used as DTO in admin module
   */
  public isAdmin = false;

  /**
   * Singular name of entity,
   * used as label in some use cases
   */
  public singularName: string = StringTransformUtils.camelToSpace(this.constructor.name);

  /**
   * Plural name of entity,
   * used as label in some use cases
   */
  public pluralName: string = `${StringTransformUtils.camelToSpace(this.constructor.name)}s`;

  /**
   * Entity singular name of url,
   * used for default navigation and convention
   */
  public singularUrlName: string = StringTransformUtils.camelToDashed(this.constructor.name);

  /**
   * Entity plural name of url,
   * used for default navigation and convention
   */
  public pluralUrlName: string = `${StringTransformUtils.camelToDashed(this.constructor.name)}s`;

  /**
   * List of form block titles
   * Key of object is number which identifies form block component
   */
  public formBlockTitles?: { [key: number]: string } = {};

  /**
   * Content type used for DTO versioning
   */
  public contentType = '';

  /**
   * is acl enabled
   */
  public acl = false;

  /**
   * is audit panel section visible
   */
  public isPanelSectionVisible = true;

  /**
   * Permission prefix for entity
   * Defaults to snaked constructor name
   * Override as needed
   */

  public permissionPrefix?: string = StringTransformUtils.camelToSnake(this.constructor.name).toUpperCase();
  public createPermission?: string;
  public readPermission?: string;
  public updatePermission?: string;
  public deletePermission?: string;

  /**
   * ################################### Default entity property  #####################################
   */
  @FormEntity({
    primary: true,
  })
  @PresentationEntity({
    displayName: 'ID',
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    primary: true,
    disableFilter: true,
    disableSort: true,
  })
  id = '';

  /**
   * ################################### Static methods  #####################################
   */

  /**
   * Get display configuration for table component
   */
  public static getTableDisplayConfig(): DisplayConfiguration[] {
    return this.getTableDisplayConfig();
  }

  /**
   * Get presentation configuration of entity
   *
   * @returns Map<string, PresentationOptions>
   */
  public static getPresentationConfiguration(target?: any, targetName?: string): Map<string, PresentationOptions> {
    target = target ? target : this;
    targetName = targetName ? targetName : this.name;

    return this.getPresentationConfiguration(target, targetName);
  }

  /**
   * Get form configuration of entity
   *
   * @returns Map<string, FormOptions>
   */
  public static getFormConfiguration(target?: any, targetName?: string): Map<string, FormOptions> {
    target = target ? target : this;
    targetName = targetName ? targetName : this.name;

    return this.getFormConfiguration(target, targetName);
  }

  /**
   * Get form configuration of entity
   *
   * @param target
   * @param targetName
   * @returns Map<string, FormOptions>
   */
  public static getEntityConfiguration(target?: any, targetName?: string): Map<string, any> {
    target = target ? target : this;
    targetName = targetName ? targetName : this.name;

    return this.getEntityConfiguration(target, targetName);
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns PresentationOptions
   */
  public static getFormOption(propertyName: string, option?: FormOptionsEnum): FormOptions {
    return this.getFormOption(propertyName, option);
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns PresentationOptions
   */
  public static getPresentationOption(propertyName: string, option?: PresentationOptionsEnum): PresentationOptions {
    return this.getPresentationOption(propertyName, option);
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns PresentationOptions
   */
  public static getEntityOption(propertyName: string, option?: EntityOptionsEnum): any {
    return this.getEntityOption(propertyName, option);
  }

  /**
   * Returns name of primary keys
   * if primary key is more than one field, it is separetd with colon :
   *
   * @returns string
   */
  public static getPrimaryName(): string {
    const primary = new Array<string>();

    this.getPresentationConfiguration().forEach((value: PresentationOptions, key: string) => {
      if (value.primary && value.primary === true) {
        primary.push(key);
      }
    });

    return primary.join(':');
  }

  /**
   * In instance construction loops trough EntityOptionsEnum
   * and checks if there is Entity decorator
   * and set it as default properties
   *
   * @param init
   */
  constructor(init?: any) {
    const decoratorKey = DecoratorKeysEnum.Entity + this.constructor.name;

    for (const key in EntityOptionsEnum) {
      if (isNaN(Number(key))) {
        const item = (EntityOptionsEnum as any)[key];

        if (Reflect.getMetadata(decoratorKey + item, this.constructor)) {
          (this as any)[item] = Reflect.getMetadata(decoratorKey + item, this.constructor);
        }
      }
    }

    // if init parameter is set,
    // loop and set default object values in construction
    for (const key in init) {
      // if (init.hasOwnProperty(key)) { // Old code kept for debugging purposes
      // https://eslint.org/docs/rules/no-prototype-builtins
      if (Object.prototype.hasOwnProperty.call(init, key)) {
        // Better way of writing the same thing
        if (this.hasFormProperty(key) || this.hasPresentationProperty(key)) {
          (this as any)[key] = init[key];
        }
      }
    }

    this.initializeDefaultPermissions();
  }

  private getAbstractPresentationDecoratorValues() {
    const map = new Map<string, FormOptions>();

    const _options = {
      displayName: 'ID',
      tableDisplay: false,
      quickViewDisplay: false,
      quickViewPanelSectionDisplay: true,
      primary: true,
      disableSort: true,
      disableFilter: true,
      renderOnCreate: false,
      renderOnEdit: false,
      type: 'String',
    };

    map.set('id', _options);

    return map;
  }

  private getAbstractFormDecoratorValues() {
    const map = new Map<string, FormOptions>();

    const _options = {
      primary: true,
      renderOnCreate: false,
      renderOnEdit: false,
      disableFilter: true,
      disableSort: true,
      type: 'string',
    };

    map.set('id', _options);

    return map;
  }

  private initializeDefaultPermissions(): void {
    const decoratorKeyOriginal = DecoratorKeysEnum.EntityOriginal + this.constructor.name;

    if (!Reflect.getMetadata(decoratorKeyOriginal + EntityOptionsEnum.CreatePermission, this.constructor)) {
      this.createPermission = this.permissionPrefix + PermissionSufixes.CREATE;
    }

    if (!Reflect.getMetadata(decoratorKeyOriginal + EntityOptionsEnum.ReadPermission, this.constructor)) {
      this.readPermission = this.permissionPrefix + PermissionSufixes.READ;
    }

    if (!Reflect.getMetadata(decoratorKeyOriginal + EntityOptionsEnum.UpdatePermission, this.constructor)) {
      this.updatePermission = this.permissionPrefix + PermissionSufixes.UPDATE;
    }

    if (!Reflect.getMetadata(decoratorKeyOriginal + EntityOptionsEnum.DeletePermission, this.constructor)) {
      this.deletePermission = this.permissionPrefix + PermissionSufixes.DELETE;
    }
  }

  /**
   * Returns AbstractEntity class name, used for compiling and code obfuscation
   *
   * @returns string
   */
  public getAbstractEntityClassName(): string {
    return Object.getPrototypeOf(Object.getPrototypeOf(this)).constructor.name;
    // return 'AbstractEntity';
  }

  /**
   * Get presentation configuration of entity
   *
   * @returns Map<string, PresentationOptions>
   */
  public getPresentationConfiguration(target?: any, targetName?: string): Map<string, PresentationOptions> {
    target = target ? target : this.constructor;
    targetName = targetName ? targetName : this.constructor.name;

    // we couldn't use hardcoded name AbstractEntity because in --aot production compile, class name are obfuscated
    // const abstractEntityClassName = this.getAbstractEntityClassName();

    // const abstractPresentationOpts = Reflect.getMetadata(DecoratorKeysEnum.Presentation + 'AbstractEntity', target);
    // const abstractPresentationOpts = Reflect.getMetadata(
    // 	DecoratorKeysEnum.Presentation + abstractEntityClassName,
    // 	target
    // );

    const abstractPresentationOpts = this.getAbstractPresentationDecoratorValues();

    let presentationOpts = Reflect.getMetadata(DecoratorKeysEnum.Presentation + targetName, target);

    if (!presentationOpts) {
      presentationOpts = new Map<string, PresentationOptions>();
    }

    abstractPresentationOpts.forEach((value: PresentationOptions, key: string) => {
      presentationOpts.set(key, value);
    });

    return presentationOpts;
  }

  /**
   * Get form configuration of entity
   *
   * @returns Map<string, FormOptions>
   */
  public getFormConfiguration(target?: any, targetName?: string): Map<string, FormOptions> {
    target = target ? target : this.constructor;
    targetName = targetName ? targetName : this.constructor.name;

    // const abstractEntityClassName = this.getAbstractEntityClassName();

    // const abstractFormOpts = Reflect.getMetadata(DecoratorKeysEnum.Form + abstractEntityClassName, target);
    let formOpts = Reflect.getMetadata(DecoratorKeysEnum.Form + targetName, target);

    const abstractFormOpts = this.getAbstractFormDecoratorValues();

    if (!formOpts) {
      formOpts = new Map<string, FormOptions>();
    }

    abstractFormOpts.forEach((value: FormOptions, key: string) => {
      formOpts.set(key, value);
    });

    return formOpts;
  }

  /**
   * Get form configuration of entity
   *
   * @param target
   * @param targetName
   * @returns Map<string, FormOptions>
   */
  public getEntityConfiguration(target?: any, targetName?: string): Map<string, any> {
    target = target ? target : this.constructor;
    targetName = targetName ? targetName : this.constructor.name;

    let entityOpts = Reflect.getMetadata(DecoratorKeysEnum.Entity + targetName, target);

    if (!entityOpts) {
      entityOpts = new Map<string, any>();
    }

    const decoratorKey = DecoratorKeysEnum.Entity + targetName;

    for (const key in EntityOptionsEnum) {
      if (isNaN(Number(key))) {
        const item = (EntityOptionsEnum as any)[key];

        if (Reflect.getMetadata(decoratorKey + item, target) !== undefined) {
          const value = Reflect.getMetadata(decoratorKey + item, target);
          entityOpts.set(item, value);
        }
      }
    }

    return entityOpts;
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns PresentationOptions
   */
  public getPresentationOption(propertyName: string, option?: PresentationOptionsEnum): PresentationOptions {
    const properties: any = this.getPresentationConfiguration().get(propertyName);

    return option ? <any>properties[option] : properties;
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns FormOptions
   */
  public getFormOption(propertyName: string, option?: FormOptionsEnum): FormOptions {
    const properties = this.getFormConfiguration().get(propertyName);

    return option ? (properties as any)[option] : properties;
  }

  /**
   * Get options for specific property name
   * or get specific option from property
   *
   * @param propertyName
   * @param option
   *
   * @returns PresentationOptions
   */
  public getEntityOption(propertyName: string, option?: EntityOptionsEnum) {
    const properties = this.getEntityConfiguration().get(propertyName);

    return option ? <any>properties[option] : properties;
  }

  /**
   * Check if presentation entity has property
   *
   * @param property
   */
  public hasPresentationProperty(property: string): boolean {
    let hasProperty = false;

    this.getPresentationConfiguration().forEach((_value: PresentationOptions, key: string) => {
      if (!hasProperty) {
        hasProperty = property === key;
      } else {
        return;
      }
    });

    return hasProperty;
  }

  /**
   * Check if form entity has property
   *
   * @param property
   */
  public hasFormProperty(property: string): boolean {
    let hasProperty = false;

    this.getFormConfiguration().forEach((_value: PresentationOptions, key: string) => {
      if (!hasProperty) {
        hasProperty = property === key;
      } else {
        return;
      }
    });

    return hasProperty;
  }

  /**
   * Get display configuration for table component
   *
   * @returns DisplayConfiguration[]
   */
  public getTableDisplayConfig(): DisplayConfiguration[] {
    const displayConfig = new Array<DisplayConfiguration>();

    this.getPresentationConfiguration().forEach((value: PresentationOptions, key: string) => {
      if (!value.renderer) {
        if (value.type === 'Date' && value.customDateFormat === undefined) {
          value.renderer = 'dateTimeFormat';
        }
        if (value.type === 'Time') {
          value.renderer = 'timeFormat';
        }
      }

      if (value.enumModel) {
        value.type = 'Dropdown';
      }

      displayConfig.push({
        propertyName: key,
        propertyDisplayName:
          value.displayName || StringTransformUtils.capitalizeFirst(StringTransformUtils.camelToSpace(key)),
        isTableColumn: value.tableDisplay === undefined ? true : value.tableDisplay,
        isInQuickView: value.quickViewDisplay === undefined ? true : value.quickViewDisplay,
        isInQuickViewPanelSection:
          value.quickViewPanelSectionDisplay === undefined ? false : value.quickViewPanelSectionDisplay,
        renderer: value.renderer ? value.renderer : null,
        rendererValueRecord: value.rendererValueRecord === undefined ? false : value.rendererValueRecord,
        rendererFunction: value.rendererFunction ? value.rendererFunction : null,
        rendererAsyncFunction: value.rendererAsyncFunction ? value.rendererAsyncFunction : null,
        type: value.type,
        disableFilter: value.disableFilter,
        disableSort: value.disableSort,
        enumModel: value.enumModel ? value.enumModel : null,
        useCustomTemplate: value.useCustomTemplate,
        useCustomComponent: value.useCustomComponent,
        componentRenderType: value.componentRenderType ? value.componentRenderType : null,
        columnWidthPercentage: value.columnWidthPercentage,
        lookupModel: value.lookupModel ? value.lookupModel : null,
        lookupService: value.lookupService ? value.lookupService : null,
        criterionField: value.criterionField ?? null,
        customDateFormat: value.customDateFormat ? value.customDateFormat : null,
        isColumnFrozen: value.isColumnFrozen ? value.isColumnFrozen : false,
        columnFrozenAlignment: value.columnFrozenAlignment ? value.columnFrozenAlignment : 'left',
      });
    });

    return displayConfig;
  }

  /**
   * Get isAclEnabled
   * @returns boolean
   */
  public getIsAclEnabled(): boolean {
    return this.acl;
  }

  getEnableRowActionsPropertyName() {
    let enableRowActionsPropertyName = '';

    this.getPresentationConfiguration().forEach((value: PresentationOptions, key: string) => {
      if (value.enableRowActionsProperty) {
        enableRowActionsPropertyName = key;
      }
    });

    return enableRowActionsPropertyName;
  }

  /**
   * Get permissions configuration for table component
   *
   * @returns Default Entity Permissions
   */
  public getDefaultPermissions(): DefaultPermissions {
    const permissions = {} as DefaultPermissions;
    permissions.createPermission = this.createPermission;
    permissions.readPermission = this.readPermission;
    permissions.updatePermission = this.updatePermission;
    permissions.deletePermission = this.deletePermission;
    return permissions;
  }

  /**
   * ################################### Only instance methods #####################################
   */

  /**
   * Get identificator value of entity
   * @returns string
   */
  public getId(): string {
    return this.id;
  }

  /**
   * Get label
   * @returns string
   */
  public getLabel(): string {
    if ((this as any)[this.labelField]) {
      return (this as any)[this.labelField];
    } else {
      console.warn('Defined label property does not exists in object.');
    }
    return '';
  }

  /**
   * Get label field
   * @returns string
   */
  public getLabelField(): string {
    if (this.labelField) {
      return this.labelField;
    } else {
      throw new Error('Label field is not for model ' + this.constructor.name + ' defined.');
    }
  }

  /**
   * Get sort field
   * @returns string
   */
  public getSortField(): string {
    if (!this.defaultSort) {
      this.defaultSort.field = this.getLabelField();
    }

    if (this.defaultSort.field) {
      return this.defaultSort.field;
    } else {
      throw new Error(`Sort field is not defined for model ${this.constructor.name}`);
    }
  }

  /**
   * Get sort order
   * @returns string
   */
  public getSortOrder(): string {
    if (this.defaultSort.order) {
      return this.defaultSort.order;
    } else {
      throw new Error(`Sort order is not defined for model ${this.constructor.name}`);
    }
  }

  public getDisabledState(): string {
    return this.disabledStateProp;
  }

  /**
   * Get SelectItem for autocomplete or dropdown
   */
  public getSelectItem(): FiyuSelectItem {
    return {
      label: this.getLabel(),
      value: this.getId(),
      deleted: false,
    };
  }
}
