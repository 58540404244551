export enum ValidatorsEnum {
  Required = 'required',
  RequiredTrue = 'requiredTrue',
  Min = 'min',
  Max = 'Max',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  MinLength = 'minLength',
  MaxLength = 'maxLength',
  Pattern = 'pattern',
  Password = 'password',
  CompareDate = 'compareDate',
  TimeLessThan = 'timeLessThan',
  TimeGreaterThan = 'timeGreaterThan',
  DateLessThan = 'dateLessThan',
  DateGreaterThan = 'dateGreaterThan',
  DateGreaterOrEqualThan = 'dateGreaterOrEqualThan',
  DateLessOrEqualThan = 'dateLessOrEqualThan',
  NotOnlySpaces = 'notOnlySpaces',
}
