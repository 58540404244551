import type { ValidationErrors } from '@angular/forms';
import type { StrictFormGroup } from '@fiyu/api';
import { ValidationMessage } from './validation-message';

/**
 * Custom FIYU form group validators and wrappers for internal Angular validators
 */
export class FiyuGroupValidators {
  /**
   *
   * Compares values of two controls from the same form group by their name
   *
   * @param first
   * @param second
   */
  static secondGreater(first: string, second: string): any {
    return (formGroup: StrictFormGroup<unknown>): { [key: string]: any } | null => {
      if (!formGroup.get(first) || !formGroup.get(second)) {
        return null;
      }
      if (formGroup === null) {
        return null;
      }
      const firstControlValue: number = +formGroup.get(first)?.value;
      const secondControlValue: number = +formGroup.get(second)?.value;

      if (isNaN(firstControlValue) || isNaN(secondControlValue)) {
        return null;
      }

      if (firstControlValue > secondControlValue) {
        const v: ValidationErrors = { firstFieldGreater: true };
        return new ValidationMessage('The value of the first input field must be greater then the second.', v);
      }
      return null;
    };
  }
}
