import { HttpHeaders } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractCRUDService, LogService, SearchCriteria } from '@fiyu/core';
import { ModuleMetadata } from '../module-metadata';

@Injectable({
  providedIn: 'root',
})
export class NotificationMessagesService extends AbstractCRUDService {
  private readonly logService: LogService = inject(LogService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  constructor() {
    super('', ModuleMetadata.getInstance());
  }

  /**
   * This method is used by Notification submenu in the header/topbar (bell icon).
   * It queries the server for last 10 notifications and the number of unseen notifications.
   */
  getLatest() {
    const searchCriteria: SearchCriteria = new SearchCriteria();
    return this.httpClient.post<any>(
      `${this.apiUrl}/latest`,
      searchCriteria,
      this.getHttpOptions(SearchCriteria.contentType)
    );
  }

  /**
   * Sends list of notification ids to the server and marks them as seen.
   * @param listOfIds - ids of notifications that are to be marked as seen
   */
  markSeen(listOfIds: string[]) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.httpClient
      .post(this.apiUrl + '/seen', JSON.stringify(listOfIds), {
        headers: headers,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.logService.logInfo('Marking notifications as seen on server...'));
  }

  /**
   * Sends list of notification ids to the server and marks them as read.
   * @param listOfIds - ids of notifications that are to be marked as read
   */
  markRead(listOfIds: string[]) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.httpClient
      .post(this.apiUrl + '/read', JSON.stringify(listOfIds), {
        headers: headers,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.logService.logInfo('Marking notifications as read on server...'));
  }
}
