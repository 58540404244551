import { Severity } from '../toast/severity.enum';
import { ToastKeys } from '../toast/toast-keys.enum';

export class FiyuError extends Error {
  parameters?: any;
  toastKey?: ToastKeys;
  severity?: Severity;
  log?: boolean;
  status?: number;

  constructor(error: FiyuError) {
    super(error.message);
    this.name = error.name;
    this.parameters = error.parameters;
    this.toastKey = error.toastKey || ToastKeys.FiyuErrorKey;
    this.severity = error.severity || Severity.Error;
    this.log = error.log;
    this.status = error.status;

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FiyuError.prototype);
  }
}
