import { inject } from '@angular/core';
import { Router, type CanMatchFn, type UrlSegment, type UrlTree } from '@angular/router';
import type { FiyuRoute } from './../routing/fiyu-route';
import { PermissionsService } from './permissions.service';

/**
 * Route guard based on user permissions
 * @usageNotes  { path: 'home', component: HomeComponent, canMatch: [PermissionsMatchGuard]},
 * @param _route
 * @param _segments
 * @returns boolean
 */
export const PermissionsMatchGuard: CanMatchFn = (route: FiyuRoute, _segments: UrlSegment[]): boolean | UrlTree => {
  const permissionsService = inject(PermissionsService);
  const router = inject(Router);
  /**
   * check user permissions for this route
   */
  if (route?.requiredPermissions && route?.requiredModule) {
    const permissions = route?.requiredPermissions;
    const module = route?.requiredModule;
    const operation = route?.operation;
    return permissionsService.checkPermission(permissions, operation, module) || router.parseUrl('/not-authorized');
  }
  return router.parseUrl('/not-authorized');
};
