import { AbstractEntity } from '../entity/abstract-entity.model';
import { Entity } from '../entity/entity.decorator';
import { FormEntity } from '../entity/form-entity.decorator';

@Entity({
  labelField: 'fullName',
})
export class CoreUser extends AbstractEntity {
  @FormEntity() fullName = '';
  @FormEntity() userName = '';
  @FormEntity() userTypeId = '';
  @FormEntity() verified = false;
  @FormEntity() activeGlobal = false;
  @FormEntity() activeOrganization = false;
  @FormEntity() deletedGlobal = false;
  @FormEntity() deletedOrganization = false;
  @FormEntity() created: Date | null = null;
}
