import { AbstractEntity } from '../entity/abstract-entity.model';
import { Entity } from '../entity/entity.decorator';
import { FormEntity } from '../entity/form-entity.decorator';
import type { Nullable } from '../types/nullable-type';

@Entity()
export class SecurityToken extends AbstractEntity {
  @FormEntity() access_token: Nullable<string> = null;
  @FormEntity() token_type: Nullable<string> = null;
  @FormEntity() refresh_token: Nullable<string> = null;
}
