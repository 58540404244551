import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, EnumUtility, FormEntity } from '@fiyu/core';
import { ModulePermissions } from './../module-permissions';
import { TranslateKeys } from './../module-translate-keys';

export enum PlanTypeEnum {
  COMMUNITY = 'Community',
  STANDARD = 'Standard',
  ADVANCED = 'Advanced',
  ENTERPRISE = 'Enterprise',
}

export enum CurrencyTypeEnum {
  EUR = 'EUR',
  USD = 'USD',
}

@Entity({
  singularName: TranslateKeys.Plan,
  pluralName: TranslateKeys.Plans,
  singularUrlName: 'plan',
  pluralUrlName: 'plans',
  contentType: 'application/plan.v1+json',
  permissionPrefix: ModulePermissions.CRM_PLAN,
  readPermission: ModulePermissions.CRM_PLAN_VIEW_ALL,
  quickExportEnabled: false,
})
export class Plan extends AbstractEntity {
  @FormEntity({
    required: true,
  })
  name: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    label: SharedTranslateKeys.PlanType,
    required: true,
    lookupEmpty: false,
    lookupOptions: EnumUtility.mapEnumToSelectItems(PlanTypeEnum),
  })
  planType: string;

  @FormEntity({
    required: true,
    label: SharedTranslateKeys.Price,
    formType: FormControlType.InputNumber,
    fieldOptions: {
      minFractionDigits: 2,
    },
  })
  price: number;

  @FormEntity({
    formType: FormControlType.Dropdown,
    label: SharedTranslateKeys.Currency,
    required: true,
    lookupEmpty: false,
    lookupOptions: EnumUtility.mapEnumToSelectItems(CurrencyTypeEnum),
  })
  currency: string;

  @FormEntity({
    formType: FormControlType.Date,
    label: SharedTranslateKeys.StartDate,
    validators: {
      dateLessThan: 'expirationDate',
      required: true,
    },
  })
  startDate: Date;

  @FormEntity({
    formType: FormControlType.Date,
    label: TranslateKeys.ExpirationDate,
    validators: {
      dateGreaterThan: 'startDate',
      required: true,
    },
  })
  expirationDate: Date;

  @FormEntity({
    required: true,
    label: SharedTranslateKeys.VatPercent,
  })
  vatPercent: string;

  @FormEntity({
    formType: FormControlType.TextArea,
    label: SharedTranslateKeys.Description,
    validators: {
      maxLength: 100,
    },
  })
  description: string;
}
