import type { MenuItem } from 'primeng/api';
import type { Permissions } from './../permissions/permissions';

export interface FiyuMenuItem extends MenuItem {
  class?: string;
  permissions?: Permissions;
  permission?: string;
  separator?: boolean;
  moduleAlias?: string;
  items?: FiyuMenuItem[];
}
