import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type FiyuMenuItem,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';
import { TranslateKeys } from './module-translate-keys';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Is module local module only, server doesn't know about it
   */
  public isCore = false;
  public isAdmin = false;
  /**
   * Module name
   */
  public moduleName = 'CRM';

  /**
   * Short name
   */
  public moduleNameShort = 'CRM';

  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.CRM;

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.CRM;

  /**
   * Module Icon
   */
  public moduleIcon = 'pi pi-fw pi-box';

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#3c3c3c';

  /**
   *  Module darker color
   */
  public moduleLogoDarkerColor = '#000';

  /**
   * Module description
   */
  public moduleDescription = SharedTranslateKeys.CrmModuleDescription;

  /**
   * All possible permissions for this module
   */
  public modulePermissions = ModulePermissions;

  /**
   * Sidebar permissions
   */

  crmPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_RUN],
    operation: Operations.OR,
  };
  planPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PLAN_RUN],
    operation: Operations.OR,
  };

  planFeaturePermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_FEATURE_RUN],
    operation: Operations.OR,
  };

  paymentPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PAYMENT_RUN],
    operation: Operations.OR,
  };

  paymentMethodPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PAYMENT_METHOD_RUN],
    operation: Operations.OR,
  };

  accountPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_ACCOUNT_RUN],
    operation: Operations.OR,
  };

  billPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_BILL_RUN],
    operation: Operations.OR,
  };

  billItemPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_BILLITEM_RUN],
    operation: Operations.OR,
  };

  projectPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PROJECT_RUN],
    operation: Operations.OR,
  };

  registrationPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_REGISTRATION_RUN],
    operation: Operations.OR,
  };

  resourcePermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_RESOURCE_RUN],
    operation: Operations.OR,
  };

  provisioningPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PROVISIONING_RUN],
    operation: Operations.OR,
  };

  billingSummaryPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.CRM_PLAN_RUN, ModulePermissions.CRM_PLAN_VIEW_MY],
    operation: Operations.AND,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems: FiyuMenuItem[] = [
    {
      label: 'CRM',
      icon: 'pi pi-fw pi-bell-o',
      routerLink: '/crm',
      permissions: this.crmPermissions,
      items: [
        {
          label: TranslateKeys.Plans,
          icon: 'pi pi-fw pi-file',
          routerLink: '/crm/plans',
          permissions: this.planPermissions,
        },
        {
          label: TranslateKeys.PlanFeatures,
          icon: 'pi pi-fw pi-cloud',
          routerLink: '/crm/plan-features',
          permissions: this.planFeaturePermissions,
        },
        {
          label: SharedTranslateKeys.Payments,
          icon: 'pi pi-fw pi-credit-card',
          routerLink: '/crm/payments',
          permissions: this.paymentPermissions,
        },
        {
          label: TranslateKeys.PaymentMethods,
          icon: 'pi pi-fw pi-wallet',
          routerLink: '/crm/payment-methods',
          permissions: this.paymentMethodPermissions,
        },
        {
          label: TranslateKeys.Accounts,
          icon: 'pi pi-fw pi-users',
          routerLink: '/crm/accounts',
          permissions: this.accountPermissions,
        },
        {
          label: TranslateKeys.Bills,
          icon: 'pi pi-fw pi-euro',
          routerLink: '/crm/bills',
          permissions: this.billPermissions,
        },
        {
          label: TranslateKeys.BillItems,
          icon: 'pi pi-fw pi-money-bill',
          routerLink: '/crm/bill-items',
          permissions: this.billItemPermissions,
        },
        {
          label: SharedTranslateKeys.Projects,
          icon: 'pi pi-fw pi-box',
          routerLink: '/crm/projects',
          permissions: this.projectPermissions,
        },
        {
          label: TranslateKeys.Provisionings,
          icon: 'pi pi-fw pi-server',
          routerLink: '/crm/provisionings',
          permissions: this.provisioningPermissions,
        },
        {
          label: SharedTranslateKeys.Registrations,
          icon: 'pi pi-fw pi-id-card',
          routerLink: '/crm/registrations',
          permissions: this.registrationPermissions,
        },
        {
          label: TranslateKeys.Resources,
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/crm/resources',
          permissions: this.resourcePermissions,
        },
        {
          label: TranslateKeys.BillingSummary,
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/crm/summary',
          permissions: this.billingSummaryPermissions,
        },
      ],
    },
  ];
}
