import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { CrmModuleMetadataService } from './crm-module-metadata.service';

export function provideCrmModuleProviders(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: 'CrmModuleMetadataService',
      useClass: CrmModuleMetadataService,
    },
  ]);
}
