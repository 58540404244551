import { AbstractEntity } from '../entity/abstract-entity.model';
import { Entity } from '../entity/entity.decorator';
import { FormEntity } from '../entity/form-entity.decorator';
import type { Nullable } from '../types/nullable-type';

@Entity({
  labelField: 'fullName',
  contentType: 'application/core.employee.v1+json',
})
export class CoreEmployee extends AbstractEntity {
  @FormEntity() userId: Nullable<string> = null;
  @FormEntity() fullName: Nullable<string> = null;
  @FormEntity() internalCode?: Nullable<string> = null;
  @FormEntity() internalUsername: Nullable<string> = null;
  @FormEntity() superior?: Nullable<string> = null;
  @FormEntity() superiorId?: Nullable<string> = null;
  @FormEntity() userType?: Nullable<string> = null;
  @FormEntity() userTypeClassification: Nullable<string> = null;
  @FormEntity() validFrom?: Nullable<Date> = null;
  @FormEntity() validTo?: Nullable<Date> = null;
  @FormEntity() active: Nullable<boolean> = null;
}
