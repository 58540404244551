import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

export class UserAdminNestedOrganization extends AbstractEntity {
  @FormEntity()
  name: string;
  @FormEntity()
  active: boolean;
  @FormEntity()
  organizationUserId: string;
  @FormEntity({
    arrayValueType: String,
  })
  roles: string[] = [];
}

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.User,
  pluralName: SharedTranslateKeys.Users,
  singularUrlName: 'user',
  pluralUrlName: 'users',
  formBlockTitles: {
    1: SharedTranslateKeys.UserInformation,
    2: SharedTranslateKeys.OrganizationAndRoles,
  },
  contentType: 'application/user.admin.v1+json',
  labelField: 'fullName',
  defaultSort: { field: 'userName', order: SortDirection.Ascending, type: 'string' },
  permissionPrefix: ModulePermissions.USER,
})
export class UserAdmin extends AbstractEntity {
  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.FirstName,
    required: true,
  })
  firstName: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.LastName,
    required: true,
  })
  lastName: string;

  @PresentationEntity()
  fullName: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Username,
    required: true,
    validators: {
      pattern: '[A-Za-z0-9_.]*',
    },
  })
  userName: string;

  @FormEntity({
    groupNumber: 1,
    required: true,
    label: SharedTranslateKeys.Email,
    validators: {
      email: true,
    },
  })
  email: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.IsSuperadmin,
  })
  superAdmin: boolean;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.IsVerified,
  })
  verified: boolean;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.ActiveOnPlatform,
    required: true,
  })
  enabled: boolean;

  @FormEntity({
    arrayValueType: UserAdminNestedOrganization,
    label: SharedTranslateKeys.ListOfOrganizations,
    render: false,
  })
  organizations: UserAdminNestedOrganization[] = [];

  @FormEntity({
    render: false,
  })
  override deleted: Date = null;

  public override getLabel(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
