import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@fiyu/api';

/**
 * For usage with Google Tag Manager to push dataLayer events
 *
 * @export
 * @class DataLayerService
 */
@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private readonly window: Window = inject(WINDOW);

  private pingHome(obj: any): void {
    if (obj) (<any>this.window)['dataLayer']?.push(obj);
  }

  //list of all our dataLayer methods
  logPageView(item: Record<string, any>): void {
    const hit = {
      ...item,
      event: 'page',
      pageName: item.pageName,
    };
    this.pingHome(hit);
  }

  logEvent(item: Record<string, any>): void {
    const hit = {
      ...item,
      event: item.event,
      category: item.category,
      action: item.action,
      label: item.label,
    };
    this.pingHome(hit);
  }

  logCustomDimensionTest(value: any): void {
    const hit = {
      event: 'custom-dimension',
      value: value,
    };
    this.pingHome(hit);
  }
}
