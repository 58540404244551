import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ModulePrefixes, SKIP_CACHE_HEADER } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import type { UserPreferenceDTO } from './user-preference-model';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  headers = new HttpHeaders().set('Content-Type', 'application/user.preferences.v1+json');
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly envService: EnvironmentService = inject(EnvironmentService);

  getUserPreference(): Observable<UserPreferenceDTO> {
    return this.httpClient.get<UserPreferenceDTO>(
      `${this.envService.getModuleApiURL(ModulePrefixes.USER)}/profile/preferences`,
      { context: new HttpContext().set(SKIP_CACHE_HEADER, true) },
    );
  }

  updateUserPreference(userPreference: UserPreferenceDTO): Observable<UserPreferenceDTO> {
    return this.httpClient.put<UserPreferenceDTO>(
      `${this.envService.getModuleApiURL(ModulePrefixes.USER)}/profile/preferences`,
      JSON.stringify(userPreference),
      {
        headers: this.headers,
      },
    );
  }
}
