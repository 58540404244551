import type { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { Observable } from 'rxjs';
import { StorageService } from '../../core/storage.service';
import { EnvironmentService } from '../../environment/environment.service';
import { LogService } from '../../logging/log-service';

/**
 * Intercepts every httpRequest and adds a request header with UUID as its value.
 * The message is then logged using the LogService.
 * @param req
 * @param next
 */
export const LogInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const environmentService: EnvironmentService = inject(EnvironmentService);
  const logService: LogService = inject(LogService);
  const storageService: StorageService = inject(StorageService);
  const destroyRef = inject(DestroyRef);
  let requestUUID = self.crypto.randomUUID();
  // If developer provides its own value under devReqId key, that value will be used as request id.
  // This is used so that the developer can make it easier to track its requests in the logs.
  const devRequestId = storageService.getItem('devReqId');
  if (devRequestId) {
    requestUUID = devRequestId;
  }
  const updatedRequest = req.clone({
    headers: req.headers.set('RequestID', requestUUID),
  });
  // Since some http messages are sent early in the app loading phase, it is necessary to check whether
  // the environment has been loaded properly. Only when the BehaviorSubject we are subscribing to
  // emits "true" can we be sure that the environment has been set.
  // BehaviorSubject that is used to check whether the environment initialization is done
  environmentService.readyObservable.pipe(takeUntilDestroyed(destroyRef)).subscribe((ready) => {
    if (ready) {
      logService.logInfo(JSON.stringify(updatedRequest));
    }
  });
  return next(updatedRequest);
};
