import { inject } from '@angular/core';
import type { CanMatchFn, UrlSegment } from '@angular/router';
import type { Observable } from 'rxjs';
import { CoreService } from './../../core/core.service';
import type { FiyuRoute } from './../../routing/fiyu-route';

/**
 * Module activation guard for main fiyu modules
 * Used to insert activated module into storage
 * @usageNotes  { path: 'home', component: HomeComponent, canMatch: [ActivationGuard]},
 * @param _route
 * @param _segments
 * @returns boolean | Observable<boolean>
 */
export const ActivationGuard: CanMatchFn = (
  route: FiyuRoute,
  _segments: UrlSegment[],
): boolean | Observable<boolean> => {
  const coreService = inject(CoreService);
  const moduleName = route?.requiredModule as string;

  if (moduleName) {
    coreService.setActiveModule(moduleName);
    return true;
  }
  console.error('Module data not found.');
  return false;
};
