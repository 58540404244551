import type { Nullable } from '../types/nullable-type';

export class FiyuNotificationData {
  id = '';
  message = '';
  generator = ''; // module prefix
  read = false;
  seen = false;
  icon = ''; // module icon
  color = ''; // module logo color
  createdTime: Date;
  readTime: Date;
  seenTime: Date;
}

export class FiyuNotification {
  notificationType: Nullable<string> = null;
  notificationTitle?: Nullable<string> = null;
  notificationMessage?: Nullable<string> = null;
  data: FiyuNotificationData;
}
