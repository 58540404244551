import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, PresentationEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.Module,
  pluralName: SharedTranslateKeys.Modules,
  singularUrlName: 'module',
  pluralUrlName: 'modules',
  contentType: 'application/module.admin.v1+json',
  permissionPrefix: ModulePermissions.MODULE,
})
export class ModuleAdminListItem extends AbstractEntity {
  @PresentationEntity({
    displayName: SharedTranslateKeys.Name,
  })
  name: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Description,
  })
  description: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Version,
  })
  version: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Alias,
  })
  alias: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.IsCore,
    renderer: 'checkCross',
  })
  core: boolean;

  @PresentationEntity({
    displayName: SharedTranslateKeys.DefaultModule,
    renderer: 'checkCross',
  })
  defaultModule: boolean;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Enabled,
    renderer: 'checkCross',
  })
  enabled: boolean;

  /* @PresentationEntity({
    useCustomTemplate: true,
  })
  update!: string; */

  @PresentationEntity({
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  createdBy: string;

  @PresentationEntity({
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  created: Date;

  @PresentationEntity({
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  lastModifiedBy: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Deleted,
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  override deleted: Date = null;

  @PresentationEntity({
    displayName: SharedTranslateKeys.DeletedBy,
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  deletedBy: string;

  /* @PresentationEntity({
    tableDisplay: false,
    quickViewDisplay: false,
    quickViewPanelSectionDisplay: true,
    disableSort: true,
    disableFilter: true,
  })
  lastModified!: Date; */
}
