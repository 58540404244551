import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ScriptLoaderService } from './../scripts/script-loader.service';

/**
 * pass scriptName as data on route definition to load script
 * @usageNotes
 * ```typescript
 * {
 *   path: '',
 *   component: AppMainComponent,
 *   children: appChildRoutes,
 *   canActivate: [AuthGuard],
 *   data: { scriptName: 'chartjs' },
 *   resolve: { loadScript: loadScriptResolver },
 * }
 * ```
 * @param ActivatedRouteSnapshot route
 * @param RouterStateSnapshot _state
 * @returns ResolveFn<Promise<Record<string, unknown>>>
 */
export const loadScriptResolver: ResolveFn<Promise<Record<string, unknown>>> = async (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
) => {
  const scriptLoaderService = inject(ScriptLoaderService);
  const loaded = await scriptLoaderService.loadScript(route.data.scriptName);
  return loaded;
  //return of(true);
};
