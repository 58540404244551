import { FormControlType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';
import { ModuleAdmin } from '../modules/module-admin.model';
import { ModuleService } from '../modules/module.service';
import { OrganizationType } from '../organization-type/organization-type.model';
import { OrganizationTypeService } from '../organization-type/organization-type.service';
import { UserAdminListItem } from '../users/user-admin-list-item.model';
import { UserService } from '../users/user.service';

export class OrganizationAdminNestedUser extends AbstractEntity {
  @FormEntity()
  active!: boolean;
  @FormEntity()
  firstName!: string;
  @FormEntity()
  lastName!: string;
  @FormEntity()
  userName!: string;
  @FormEntity()
  organizationUserId!: string;
  @FormEntity({ arrayValueType: String })
  roles!: String[];
}

@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.Organization,
  pluralName: SharedTranslateKeys.Organizations,
  singularUrlName: 'organization',
  pluralUrlName: 'organizations',
  labelField: 'name',
  formBlockTitles: {
    1: SharedTranslateKeys.Organization,
    2: SharedTranslateKeys.UsersAndRoles,
    3: SharedTranslateKeys.Modules,
  },
  contentType: 'application/organization.admin.v1+json',
  permissionPrefix: ModulePermissions.ORGANIZATION,
})
export class OrganizationAdmin extends AbstractEntity {
  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Name,
    required: true,
  })
  name: string;

  @FormEntity({
    formType: FormControlType.Dropdown,
    groupNumber: 1,
    label: SharedTranslateKeys.OrganizationType,
    lookupEmpty: false,
    lookupModel: OrganizationType,
    lookupService: OrganizationTypeService,
    placeholder: SharedTranslateKeys.EnterOrganizationType,
    required: true,
  })
  organizationTypeId: string | undefined;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Domain,
    required: true,
  })
  domain: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.MainEmail,
    required: true,
    validators: {
      email: true,
    },
  })
  email: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.NotificationEmail,
    validators: {
      email: true,
    },
  })
  notificationEmail: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.IdentificationNumber,
  })
  identificationNumber: string;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.RegistrationNumber,
  })
  registrationNumber: string;

  @FormEntity({
    formType: FormControlType.Autocomplete,
    groupNumber: 1,
    label: SharedTranslateKeys.TechnicalAdministrator,
    lookupModel: UserAdminListItem,
    lookupService: UserService,
    lookupSearchField: 'fullName',
    placeholder: SharedTranslateKeys.ChooseResponsiblePerson,
    required: true,
  })
  responsiblePersonId: string | undefined;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Active,
  })
  active: boolean;

  @FormEntity({
    groupNumber: 1,
    label: SharedTranslateKeys.Deleted,
  })
  override deleted: Date = null;

  verified: boolean;

  @FormEntity({
    renderLabel: false,
    formType: FormControlType.InputFile,
    render: false,
    hidden: true,
    disabled: false,
    required: false,
  })
  imageId: string;

  @FormEntity({
    formType: FormControlType.Upload,
    label: SharedTranslateKeys.Logo,
    disabled: false,
    required: false,
    fieldOptions: {
      multiple: false,
      accept: 'image/*',
      maxFileSize: 20000000,
      auto: true,
      mode: 'basic',
      hiddenInputName: 'imageId',
    },
  })
  image: string;

  @FormEntity({
    arrayValueType: OrganizationAdminNestedUser,
    groupNumber: 2,
    render: true,
  })
  users: OrganizationAdminNestedUser[] = [];

  @FormEntity({
    arrayValueType: String,
    formType: FormControlType.Checkbox,
    groupNumber: 3,
    renderLabel: false,
    lookupModel: ModuleAdmin,
    lookupService: ModuleService,
  })
  modules: string[] = [];
}
