import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity } from '../entity/abstract-entity.model';
import { Entity } from '../entity/entity.decorator';
import { FormEntity } from '../entity/form-entity.decorator';
import type { Nullable } from '../types/nullable-type';

export class AclUserPrivilege extends AbstractEntity {
  @FormEntity({})
  label = '';

  @FormEntity({
    arrayValueType: String,
  })
  privileges: string[] = [];
}

@Entity({
  singularName: 'Context',
  pluralName: 'Contexts',
  singularUrlName: 'context',
  pluralUrlName: 'contexts',
  contentType: 'application/acl.list.v1+json',
})
export class Acl extends AbstractEntity {
  @FormEntity({
    label: SharedTranslateKeys.ObjectId,
    required: true,
  })
  objectId: Nullable<string> = null;

  @FormEntity({
    label: SharedTranslateKeys.Users,
    required: true,
    arrayValueType: AclUserPrivilege,
  })
  users: AclUserPrivilege[] = [];
}
