export enum ModLogOptions {
  created = 'C',
  createdBy = 'c',
  modified = 'M',
  modifiedBy = 'm',
  deleted = 'D',
  deletedBy = 'd',
  lastModification = 'L',
  lastModifiedBy = 'l',
}
