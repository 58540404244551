export enum ModulePermissions {
  USER_RUN = 'USER_RUN',
  USER_CREATE = 'USER_CREATE',
  USER_VIEW = 'USER_VIEW',
  USER_EDIT = 'USER_EDIT',
  USER_DELETE = 'USER_DELETE',
  ORGANIZATION_USER_TYPE_CREATE = 'ORGANIZATION_USER_TYPE_CREATE',
  ORGANIZATION_USER_TYPE_VIEW = 'ORGANIZATION_USER_TYPE_VIEW',
  ORGANIZATION_USER_TYPE_EDIT = 'ORGANIZATION_USER_TYPE_EDIT',
  ORGANIZATION_USER_TYPE_DELETE = 'ORGANIZATION_USER_TYPE_DELETE',
  ORGANIZATION_SETTING_CREATE = 'ORGANIZATION_SETTING_CREATE',
  ORGANIZATION_SETTING_VIEW = 'ORGANIZATION_SETTING_VIEW',
  ORGANIZATION_SETTING_EDIT = 'ORGANIZATION_SETTING_EDIT',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_EDIT = 'ROLE_EDIT',
  ROLE_DELETE = 'ROLE_DELETE',
  PROFILE_EDIT = 'PROFILE_EDIT',
  PROFILE_VIEW = 'PROFILE_VIEW',

  // Permission prefixes
  USER = 'USER',
  ROLE = 'ROLE',
  ORGANIZATION_USER_TYPE = 'ORGANIZATION_USER_TYPE',
}
