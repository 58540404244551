import {
  HttpResponse,
  type HttpEvent,
  type HttpHandlerFn,
  type HttpInterceptorFn,
  type HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { SKIP_CACHE_HEADER } from '@fiyu/api';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCacheService } from './cache.service';

/**
 * Http interceptor for caching GET requests
 * Provide as bootstrap app providers in main.ts like shown below in snippet
 * @usageNotes
 * ```typescript
 * provideHttpClient(withInterceptors([CacheInterceptor])),
 * ```
 */
export const CacheInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const cacheService = inject(HttpCacheService);
  // const cacheTimerService = inject(HttpCacheTimerService);
  // time based cache
  /*   cacheTimerService.startTimer();
    const remainingTimeInMilliseconds: number = cacheTimerService.getRemainingTime();

    if (remainingTimeInMilliseconds <= 0) {
      cacheTimerService.resetTimer();
      // console.log(`Invalidating cache due to cache time limit: ${req.method} ${req.url}`);
      cacheService.invalidateCache();
    } */
  // time based cache END

  //check if the outgoing calls are GET APIs or http context token is set to skip cache
  if (req.method !== 'GET' || req.context.get(SKIP_CACHE_HEADER) === true) {
    // pass along non-cacheable requests
    return next(req);
  }
  // check if header has flag to reset cache
  if (req.headers.get('resetCache')) {
    // cacheService.delete(req);
    cacheService.invalidateCache();
  }
  // attempt to retrieve a cached response
  const cachedResponse: HttpResponse<any> | undefined = cacheService.get(req);
  if (cachedResponse) {
    // console.log(`Returning a cached response: ${cachedResponse.url}`);
    // console.log(cachedResponse);
    // In case of parallel requests to same URI, return the request already in progress
    // otherwise return the last cached data
    return cachedResponse instanceof Observable ? cachedResponse : of(cachedResponse.clone());
  } else {
    // send request to server and add response to cache
    return next(req).pipe(
      tap((event: HttpEvent<any>): void => {
        if (event instanceof HttpResponse && event.ok) {
          // console.log(`Adding item to cache: ${req.url}`);
          cacheService.set(req, event.clone());
        }
      }),
    );
  }
};
