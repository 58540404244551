import { effect, untracked, type CreateEffectOptions, type EffectCleanupRegisterFn, type Signal } from '@angular/core';

type SignalsArrayValues<T extends Signal<any>[]> = {
  [Index in keyof T]: T[Index] extends Signal<infer P> ? P : never;
};

export function explicitEffect<T extends [Signal<any>, ...Signal<any>[]]>(
  signalDeps: T,
  effectFn: (...params: [...deps: SignalsArrayValues<T>, onCleanup: EffectCleanupRegisterFn]) => void,
  options?: CreateEffectOptions | undefined,
) {
  return effect((onCleanup) => {
    const deps = signalDeps.map((signalDep) => signalDep()) as SignalsArrayValues<T>;

    untracked(() => effectFn(...deps, onCleanup));
  }, options);
}

// Usage:
/*
const a = signal(2);
const b = signal('s');
const c = signal(true);

explicitEffect([a, b], (a, b) => console.log(a, b, c()));

setTimeout(() => {
  // Triggers
  a.set(7);
}, 2000);

setTimeout(() => {
  // Won't trigger
  c.set(false);
}, 4000);
*/
