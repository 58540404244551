import type { SelectItem } from 'primeng/api';

export class EnumUtility {
  /**
   * Get enum name from enum value
   */
  static getEnumKey(enumerator: any, value: any): string {
    const found = Object.keys(enumerator).find((key) => enumerator[key] === value);

    if (found) {
      return found;
    } else {
      throw new Error(`Enum entry with value ${value} not found.`);
    }
  }
  /**
   * Extract array of k-v pairs from enum
   *
   * Maps differently for enum { Value1, Value2 } and enum
   * { Value1 = 'value1', Value2 = 'value2' } because of how they compile to JS
   * @param enumerator
   */
  static getEnumArray(enumerator: any): {
    key: string;
    value: any;
  }[] {
    const isOneWay = Object.keys(enumerator).every((key) => isNaN(Number(key)));
    const result = isOneWay
      ? Object.keys(enumerator).map((key) => ({ key: key, value: enumerator[key] }))
      : Object.keys(enumerator)
          .filter((key) => !isNaN(Number(enumerator[key])))
          .map((key) => ({ key: key, value: enumerator[key] }));

    return result;
  }

  /**
   * Map enumerator to select items object SelectItem for dropdowns and autocomplete etc.
   *
   * @param enumerator
   */
  static mapEnumToSelectItems(enumerator: any): SelectItem[] {
    const enumArray = EnumUtility.getEnumArray(enumerator);
    return enumArray.map((element) => ({ label: element.value, value: element.key }));
  }
}
