import { inject, Injectable } from '@angular/core';
import { ErrorLoadingService } from './error-loading.service';
import { FiyuError } from './fiyu-error';
import { FiyuValidationError } from './validation/fiyu-validation-error';
import { ValidationError } from './validation/validation-error';
import { sharedValidationErrors } from './validation/validation-errors';

@Injectable()
export class FiyuException {
  protected readonly errorLoadingService: ErrorLoadingService = inject(ErrorLoadingService);
  Fiyu0(): FiyuError {
    return this.getByErrorName('FIYU-0');
  }

  /**
   * Get error by name.
   *
   * @param name Error name or code
   * @param message Optional fallback message if no error is found for given code
   */
  getByErrorName(name = '', message = ''): FiyuError {
    let error: FiyuError | undefined = new FiyuError(new Error());
    const values: any = this.getErrors()?.values();
    for (const moduleErrors of Array.from(values)) {
      error = (moduleErrors as any).find((err: any) => {
        return err.name === name;
      });
      if (error) {
        break;
      }
    }

    if (error && this.isFiyuValidationError(error)) {
      return new FiyuValidationError(error);
    }
    return error ? new FiyuError(error) : new FiyuError({ name, message });
  }

  getValidationErrors(receivedErrors: ValidationError[]): ValidationError[] | undefined {
    const fiyuValidationErrors: any = [];

    const ve = this.errorLoadingService?.getValidationErrors();

    let allValidationErrors = [...sharedValidationErrors];
    ve?.forEach((value: ValidationError[]) => {
      allValidationErrors = [...allValidationErrors, ...value];
    });
    if (typeof receivedErrors !== 'string' && Array.isArray(receivedErrors)) {
      for (const err of receivedErrors) {
        const error = {
          ...sharedValidationErrors.find((element) => {
            return element.error.key === err.error.key;
          }),
        };

        if (!err.error.key) {
          err.error.key = 'FIYU-V-0';
        }

        error.field = err.field;
        error.error = err.error;
        error.value = err.value;

        fiyuValidationErrors.push(error);
      }
    }

    return fiyuValidationErrors;
  }

  public findValidationErrorByKey(key: string): ValidationError | undefined {
    let allValidationErrors = [...sharedValidationErrors];

    const ve = this.errorLoadingService?.getValidationErrors();

    ve?.forEach((value: ValidationError[]) => {
      allValidationErrors = [...allValidationErrors, ...value];
    });

    return allValidationErrors.find((error) => {
      return error.error.key === key;
    });
  }

  isFiyuValidationError(error: FiyuError | undefined): boolean {
    if (!error) {
      return false;
    }
    return error.name === 'FIYU-V';
  }

  protected getErrors(): Map<string, FiyuError[]> | undefined {
    return this.errorLoadingService?.getErrors();
  }
}
