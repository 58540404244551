import { inject } from '@angular/core';
import { ModulePrefixes, RequiredModulesConfig, SharedTranslateKeys } from '@fiyu/api';
import {
  ActivationGuard,
  AuthGuard,
  EnvironmentService,
  loadScriptResolver,
  PermissionsMatchGuard,
  type FiyuRoutes,
} from '@fiyu/core';
import { ModulePermissions } from './workspace/module-permissions';

const appChildRoutes: FiyuRoutes = [
  {
    path: 'documentation',
    title: SharedTranslateKeys.Documentation,
    loadComponent: async () => (await import('./documentation/documentation.component')).DocumentationComponent,
  },
  {
    path: 'be-documentation',
    title: SharedTranslateKeys.Documentation,
    loadComponent: async () =>
      (await import('./documentation/documentation-backend.component')).DocumentationBackendComponent,
  },
  {
    path: ModulePrefixes.PLATFORM,
    title: SharedTranslateKeys.Platform,
    loadChildren: async () => (await import(`@fiyu/platform`)).PlatformRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.PLATFORM,
    requiredPermissions: [ModulePermissions.PLATFORM_RUN],
  },
  {
    path: ModulePrefixes.CRM,
    title: 'CRM',
    loadChildren: async () => (await import(`@fiyu/crm`)).CrmRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.CRM,
    requiredPermissions: [ModulePermissions.CRM_RUN],
  },
  {
    path: ModulePrefixes.USER,
    title: SharedTranslateKeys.Users,
    loadChildren: async () => (await import(`@fiyu/users`)).UsersRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.USER,
    requiredPermissions: [ModulePermissions.USER_RUN],
  },
  {
    path: ModulePrefixes.ORGANIZATION,
    title: SharedTranslateKeys.Organizations,
    loadChildren: async () => (await import(`@fiyu/organizations`)).OrganizationsRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.ORGANIZATION,
    requiredPermissions: [ModulePermissions.ORGANIZATION_RUN],
  },
  {
    path: ModulePrefixes.NOTIFICATIONS,
    title: SharedTranslateKeys.Notifications,
    loadChildren: async () => (await import('@fiyu/notifications')).NotificationsRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.NOTIFICATIONS,
    requiredPermissions: [ModulePermissions.NMM_RUN],
  },
  {
    path: ModulePrefixes.DOCUMENTS,
    title: SharedTranslateKeys.Documents,
    loadChildren: async () => (await import('@fiyu/documents')).DocumentsRoutes,
    canMatch: [ActivationGuard, PermissionsMatchGuard],
    requiredModule: RequiredModulesConfig.DOCUMENTS,
    requiredPermissions: [ModulePermissions.DMM_RUN],
  },
  {
    path: '',
    title: SharedTranslateKeys.Workspace,
    loadComponent: async () => (await import('./workspace/workspace/workspace.component')).WorkspaceComponent,
  },
  // { path: '', component: WorkspaceComponent },
  // { path: '**', component: PageNotFoundComponent },
];

export const AppRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () => (await import('./layout/main/app.main.component')).AppMainComponent,
    children: appChildRoutes,
    canActivate: [AuthGuard],
    data: { scriptName: 'exceljs' },
    resolve: { loadScript: loadScriptResolver },
  },
  {
    path: 'login',
    title: SharedTranslateKeys.Login,
    loadComponent: async () => (await import('./account/login/app.login.component')).AppLoginComponent,
  },
  {
    path: 'forgot-password',
    title: SharedTranslateKeys.ForgotPasswordTitle,
    loadComponent: async () =>
      (await import('./account/forgot-password-form/forgot-password-form.component')).ForgotPasswordFormComponent,
  },
  {
    path: 'reset-password',
    title: SharedTranslateKeys.ResetPasswordTitle,
    loadComponent: async () =>
      (await import('./account/reset-password-form/reset-password-form.component')).ResetPasswordFormComponent,
  },
  {
    path: 'provisioning-register',
    title: SharedTranslateKeys.Registration,
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    loadComponent: async () =>
      (await import('./provisioning/provisioning-register-form/provisioning-register-form.component'))
        .ProvisioningRegisterFormComponent,
  },
  {
    path: 'provisioning-verify',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.Verify,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-verify-form/provisioning-verify-form.component'))
        .ProvisioningVerifyFormComponent,
  },
  {
    path: 'provisioning-start',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.StartProvisioning,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-start/provisioning-start.component')).ProvisioningStartComponent,
  },
  {
    path: 'provisioning-payment',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.ProvisioningPaymentTitle,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-payment-form/provisioning-payment-form.component'))
        .ProvisioningPaymentFormComponent,
  },
  {
    path: 'provisioning-payment-success',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.ProvisioningPaymentSetupSuccessMessage,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-payment-success/provisioning-payment-success.component'))
        .ProvisioningPaymentSuccessComponent,
  },
  {
    path: 'provisioning-payment-cancel',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.Cancel,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-payment-cancel/provisioning-payment-cancel.component'))
        .ProvisioningPaymentCancelComponent,
  },
  {
    path: 'provisioning-payment-summary',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.ProvisioningSummaryTitle,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-payment-summary/provisioning-payment-summary.component'))
        .ProvisioningPaymentSummaryComponent,
  },
  {
    path: 'provisioning-plan-upgrade-summary',
    canMatch: [() => !inject(EnvironmentService).isDemo()],
    title: SharedTranslateKeys.ProvisioningSummaryTitle,
    loadComponent: async () =>
      (await import('./provisioning/provisioning-upgrade-summary/provisioning-upgrade-summary.component'))
        .ProvisioningUpgradeSummaryComponent,
  },
  {
    path: 'privacy-policy',
    title: SharedTranslateKeys.PrivacyPolicy,
    loadComponent: async () =>
      (await import('./privacy/privacy-policy/privacy-policy.component')).PrivacyPolicyComponent,
  },
  {
    path: 'refund-policy',
    title: SharedTranslateKeys.RefundPolicy,
    loadComponent: async () => (await import('./privacy/refund-policy/refund-policy.component')).RefundPolicyComponent,
  },
  {
    path: 'license-agreement',
    title: SharedTranslateKeys.LicenseAgreement,
    loadComponent: async () =>
      (await import('./privacy/license-agreement/license-agreement.component')).LicenseAgreementComponent,
  },
  {
    path: 'secure-payment-info',
    title: SharedTranslateKeys.SecurePayment,
    loadComponent: async () =>
      (await import('./privacy/secure-payment-info/secure-payment-info.component')).SecurePaymentInfoComponent,
  },
  {
    path: 'not-found',
    title: 'Not found',
    loadComponent: async () => (await import('./layout/not-found/not-found.component')).NotFoundComponent,
  },
  {
    path: 'not-authorized',
    title: 'Not authorized',
    loadComponent: async () =>
      (await import('./layout/not-authorized/not-authorized.component')).NotAuthorizedComponent,
  },
  { path: '**', redirectTo: '/not-found' },
  /*
  { path: 'error', component: AppErrorComponent },
  */
];
