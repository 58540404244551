import {
  HttpErrorResponse,
  type HttpHandlerFn,
  type HttpHeaderResponse,
  type HttpInterceptorFn,
  type HttpProgressEvent,
  type HttpRequest,
  type HttpResponse,
  type HttpSentEvent,
  type HttpUserEvent,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BYPASS_ORGANIZATION_HEADER } from '@fiyu/api';
import { BehaviorSubject, throwError, type Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { CoreService } from './../../core/core.service';
import { SecurityService } from './../../security/security.service';

/**
 * Intercepts every httpRequest and adds token to header
 * if for any request token is expired, new token is fetched and inserted in header
 * @param req
 * @param next
 * @returns Observable<any>
 */
export const OrganizationInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> => {
  const secService: SecurityService = inject(SecurityService);
  const coreService: CoreService = inject(CoreService);
  const router: Router = inject(Router);

  let isRefreshingToken = false;
  const tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  /*
  const uri = new URL(req.url);
    if (!config.trustedHostNames.includes(uri.hostname)) {
      return next(req);
    }
  */
  // assets don't need a token to be loaded (e.g. if this is omitted, core translations won't load before user logs in)
  if (req.url.includes('assets')) {
    return next(req);
  }

  if (
    req.url.endsWith(secService.loginUrl) ||
    req.url.endsWith(secService.azureLoginUrl) ||
    req.context.get(BYPASS_ORGANIZATION_HEADER) === true
  ) {
    return next(req);
  }

  try {
    return addTokenToHeader(req, next, isRefreshingToken, tokenSubject, secService, coreService, router);
  } catch (error) {
    return logoutUser(secService, router);
  }
};

/**
 * Adds token to request header, if request does not succeed and
 * the reason is 401 with message 'invalid_token', new token is
 * fetched and inserted in header
 * @param req
 * @param next
 * @param isRefreshingToken
 * @param tokenSubject
 * @param secService
 * @param coreService
 * @param router
 * @returns Observable<any>
 */
const addTokenToHeader = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  isRefreshingToken: boolean,
  tokenSubject: BehaviorSubject<string | null>,
  secService: SecurityService,
  coreService: CoreService,
  router: Router,
): Observable<any> => {
  const requestWithToken = addToken(req, secService.getAccessToken(), coreService);
  return next(requestWithToken).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return refreshToken(req, next, isRefreshingToken, tokenSubject, secService, coreService, router);
      }
      return throwError(() => error);
    }),
  );
};

/**
 * Add Bearer token to header
 * @param req
 * @param token
 * @param coreService
 * @returns HttpRequest<any>
 */
const addToken = (req: HttpRequest<any>, token: string | null = '', coreService: CoreService): HttpRequest<any> => {
  const organizationId = coreService.getCurrentOrganizationId();

  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
      organizationId: organizationId,
    },
  });
};

/**
 * Handle 401 Error - do refresh token
 */
const refreshToken = (
  req: HttpRequest<any>,
  next: HttpHandlerFn,
  isRefreshingToken: boolean,
  tokenSubject: BehaviorSubject<string | null>,
  secService: SecurityService,
  coreService: CoreService,
  router: Router,
): Observable<any> => {
  if (!isRefreshingToken) {
    isRefreshingToken = true;

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    tokenSubject.next(null);

    return secService.doRefreshToken().pipe(
      switchMap((newToken: string) => {
        if (newToken) {
          tokenSubject.next(newToken);
          return next(addToken(req, newToken, coreService));
        }

        // If we don't get a new token, we are in trouble so logout.
        return logoutUser(secService, router);
      }),
      finalize(() => {
        isRefreshingToken = false;
      }),
    );
  } else {
    return tokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((token: any) => {
        return next(addToken(req, token, coreService));
      }),
    );
  }
};

/**
 * Do logout user and redirect to login page
 */
const logoutUser = (secService: SecurityService, router: Router): Observable<any> => {
  secService.logout();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  router.navigateByUrl('/login');
  return throwError(() => '');
};
