import { SharedTranslateKeys } from '@fiyu/api';
import { ValidationError } from './validation-error';

export const sharedValidationErrors: ValidationError[] = [
  {
    error: {
      key: 'FIYU-V-0',
    },
    message: SharedTranslateKeys.ErrorValidationMessage,
  },
  {
    error: {
      key: 'FIYU-V-1',
    },
    message: SharedTranslateKeys.ErrorDecimalMax,
  },
  {
    error: {
      key: 'FIYU-V-2',
    },
    message: SharedTranslateKeys.ErrorDecimalMin,
  },
  {
    error: {
      key: 'FIYU-V-3',
    },
    message: SharedTranslateKeys.ErrorDigits,
  },
  {
    error: {
      key: 'FIYU-V-4',
    },
    message: SharedTranslateKeys.ErrorEmail,
  },
  {
    error: {
      key: 'FIYU-V-5',
    },
    message: SharedTranslateKeys.ErrorFuture,
  },
  {
    error: {
      key: 'FIYU-V-6',
    },
    message: SharedTranslateKeys.ErrorFutureOrPresent,
  },
  {
    error: {
      key: 'FIYU-V-7',
    },
    message: SharedTranslateKeys.ErrorMax,
  },
  {
    error: {
      key: 'FIYU-V-8',
    },
    message: SharedTranslateKeys.ErrorMin,
  },
  {
    error: {
      key: 'FIYU-V-9',
    },
    message: SharedTranslateKeys.ErrorNegative,
  },
  {
    error: {
      key: 'FIYU-V-10',
    },
    message: SharedTranslateKeys.ErrorNegativeOrZero,
  },
  {
    error: {
      key: 'FIYU-V-11',
    },
    message: SharedTranslateKeys.ErrorNotBlank,
  },
  {
    error: {
      key: 'FIYU-V-12',
    },
    message: SharedTranslateKeys.ErrorNotEmpty,
  },
  {
    error: {
      key: 'FIYU-V-13',
    },
    message: SharedTranslateKeys.ErrorNotNull,
  },
  {
    error: {
      key: 'FIYU-V-14',
    },
    message: SharedTranslateKeys.ErrorNull,
  },
  {
    error: {
      key: 'FIYU-V-15',
    },
    message: SharedTranslateKeys.ErrorPast,
  },
  {
    error: {
      key: 'FIYU-V-16',
    },
    message: SharedTranslateKeys.ErrorPastOrPresent,
  },
  {
    error: {
      key: 'FIYU-V-17',
    },
    message: SharedTranslateKeys.ErrorPattern,
  },
  {
    error: {
      key: 'FIYU-V-18',
    },
    message: SharedTranslateKeys.ErrorPositive,
  },
  {
    error: {
      key: 'FIYU-V-19',
    },
    message: SharedTranslateKeys.ErrorPositiveOrZero,
  },
  {
    error: {
      key: 'FIYU-V-20',
    },
    message: SharedTranslateKeys.ErrorSize,
  },
  {
    error: {
      key: 'FIYU-V-21',
    },
    message: SharedTranslateKeys.ErrorStartEndDate,
  },
  {
    error: {
      key: 'FIYU_V_NOT_FOUND',
    },
    message: 'Data not found.',
  },
  {
    error: {
      key: 'FIYU_V_INVALID_PARAMETER',
    },
    message: 'Input parameter is not valid.',
  },
  {
    error: {
      key: 'FIYU_V_INVALID_ID',
    },
    message: 'Id is not valid.',
  },
  {
    error: {
      key: 'USERNAME_EXISTS',
    },
    message: 'Username already exists!',
  },
  { error: { key: 'EMAIL_EXISTS' }, message: 'Email already exists!' },
  {
    /** Exception thrown when the User can not be found by the email address. */
    error: {
      key: '' + 'FIYU_USER_NOT_FOUND',
    },
    message: 'User not found with given email.',
  },
  { error: { key: 'USER_NOT_FOUND' }, message: 'User not found with a given email.' },
  {
    /** Exception thrown when the Verification code generation has failed. */
    error: {
      key: 'VERIFICATION_CODE_GENERATION_FAILED',
    },
    message: 'Unable to generate the Verification code.',
  },
  {
    /** Exception thrown when the email sending has failed. */
    error: {
      key: 'EMAIL_SENDING_FAILED',
    },
    message: 'Unable to send the email.',
  },
  {
    /** Exception thrown when the Verification code is not valid. */
    error: {
      key: 'INVALID_VERIFICATION_CODE',
    },
    message: 'Verification code is not valid.',
  },
  {
    /** Exception thrown when password update has failed. */
    error: {
      key: 'PASSWORD_UPDATE_FAILED',
    },
    message: 'Unable to update the password.',
  },
  {
    error: {
      key: 'ORGANIZATION_USERTYPE_NAME_EXISTS',
    },
    message: 'Organization user type name already exists!',
  },
  {
    error: {
      key: 'ORGANIZATION_NAME_EXISTS',
    },
    message: 'Organization name already exists.',
  },
  {
    error: {
      key: 'PROJECT_NAME_EXISTS',
    },
    message: 'Project name is already taken. Please choose another one.',
  },
  {
    error: {
      key: 'ORGANIZATION_DOMAIN_EXISTS',
    },
    message: 'Organization domain already exists.',
  },
  {
    error: {
      key: 'CRM_PROJECT_NAME_TAKEN',
    },
    message: 'Project name is already taken. Please choose another one.',
  },
];
