import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@fiyu/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModuleMetadata } from '../module-metadata';
import { OrganizationRole } from './organization-role.model';

@Injectable({ providedIn: 'root' })
export class OrganizationService extends AbstractCRUDService {
  constructor() {
    super('/organizations', ModuleMetadata.getInstance());
  }

  getRoles(id: string): Observable<OrganizationRole[]> {
    return this.httpClient
      .get<OrganizationRole[]>(`${this.apiUrl}/${id}/roles`)
      .pipe(map((response) => response as OrganizationRole[]));
  }
}
