import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isNonNullable, Operations, type Permissions } from '@fiyu/api';
import type { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CoreService } from '../core/core.service';
import type { TokenModule, TokenOrganization } from './access-token.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  public userPermissions: TokenModule[] = [];
  private readonly coreService: CoreService = inject(CoreService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  constructor() {
    this.getUser();
  }

  getUser(): void {
    this.getPermissionsForOrganization()
      .pipe(isNonNullable(), takeUntilDestroyed(this.destroyRef))
      .subscribe((perms: TokenModule[]) => {
        this.userPermissions = perms;
      });
  }

  getUserPermissions(): TokenModule[] {
    return this.userPermissions;
  }

  /**
   * Checks if user has chosen permission
   * @param permission permission which we wan't to check if user has
   * @param prefix which set of permissions we need to check (TSH, DMM, NMM...)
   */
  hasPermission(permission: string, prefix: string): boolean {
    const modules = this.getUserPermissions();
    const module = modules.find((module: TokenModule) => module.alias === prefix);
    const modulePermissions = module.permissions;
    if (modulePermissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }

  private getPermissionsForOrganization(): Observable<TokenModule[] | null> {
    return this.coreService.getCurrentOrganizationIdSource().pipe(
      switchMap((orgId: string) => {
        return this.coreService.getAllUserPermissionsSource().pipe(
          map((organizations: TokenOrganization[]) => {
            if (organizations) {
              const organization = organizations.find((org: TokenOrganization) => org.organizationId === orgId);
              return organization?.modules;
            }
            return null;
          }),
        );
      }),
    );
  }

  public checkModulePermission(modules: string[]): boolean {
    const reqModule = modules[0];
    if (reqModule && this.userPermissions) {
      return !!this.userPermissions.find((module: TokenModule) => module.alias === reqModule.toUpperCase())
        ?.permissions;
    } else {
      return false;
    }
  }

  public checkPermissions(permissions: Permissions): boolean {
    return this.checkPermission(permissions.requiredPermissions, permissions.operation, permissions.moduleAlias);
  }

  public checkPermission(
    requiredPermissions: string[],
    operation: string = Operations.AND,
    moduleName?: any,
    prefix: string | null = null,
  ): boolean {
    moduleName = moduleName ? moduleName : this.coreService.getActiveModule();
    if (moduleName) moduleName = moduleName.toUpperCase();
    if (!this.userPermissions) {
      return false;
    }

    if (requiredPermissions && requiredPermissions.length) {
      if (requiredPermissions instanceof Array) {
        if (operation === Operations.AND) {
          return this.hasAllPermissions(requiredPermissions, moduleName, prefix as any);
        } else if (operation === Operations.OR) {
          return this.hasSomePermissions(requiredPermissions, moduleName, prefix as any);
        }
      } else {
        return this.hasModulePermissions(requiredPermissions);
      }
    } else {
      return true;
    }
    return false;
  }

  private hasAllPermissions(requiredPermissions: string[], moduleName: string, prefix = ''): boolean {
    for (let i = 0; i < requiredPermissions.length; i++) {
      const permissionFound = this.permissionFound(requiredPermissions[i], moduleName, prefix);
      if (!permissionFound) {
        return false;
      }
    }
    return true;
  }

  private hasSomePermissions(requiredPermissions: string[], moduleName: string, prefix = ''): boolean {
    for (let i = 0; i < requiredPermissions.length; i++) {
      const permissionFound = this.permissionFound(requiredPermissions[i], moduleName, prefix);
      if (permissionFound) {
        return true;
      }
    }
    return false;
  }

  private hasModulePermissions(requiredPermissions: any): boolean {
    return !!this.userPermissions.find((module: TokenModule) => module.alias === requiredPermissions.moduleName)
      ?.permissions;
  }

  private permissionFound(perm: string, moduleName: any, prefix?: string): boolean {
    const reqPerm = prefix ? prefix + perm.toUpperCase() : perm.toUpperCase();
    const modulePermissions = this.userPermissions.find(
      (module: TokenModule) => module.alias === moduleName,
    )?.permissions;
    if (!modulePermissions) {
      return false;
    }
    const permissionFound = modulePermissions.find((userPerm) => userPerm.toUpperCase() === reqPerm);
    return !!permissionFound;
  }
}
