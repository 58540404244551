import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { SharedTranslateKeys, type ModuleMetadataInterface } from '@fiyu/api';
import { of, type Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import type { SuggestionSearchParameters } from '../component-types/suggestion-search-parameters.type';
import type { AbstractEntity } from '../entity/abstract-entity.model';
import { EnvironmentService } from '../environment/environment.service';
import { ToastKeys, ToastService } from '../toast';
import { ArrayUtil } from '../utilities';
import type { AbstractCRUD } from './abstract-crud.interface';
import { SearchCriteria } from './search/search-criteria.type';
import type { SearchResult } from './search/search-result.type';

export abstract class AbstractCRUDMockService implements AbstractCRUD {
  protected readonly httpClient: HttpClient = inject(HttpClient);
  protected readonly environmentService: EnvironmentService = inject(EnvironmentService);
  protected readonly toastService: ToastService = inject(ToastService);

  constructor(
    public apiUrl: string,
    protected moduleMetadata?: ModuleMetadataInterface,
    protected baseUrl?: string,
  ) {
    if (moduleMetadata) {
      this.baseUrl = this.environmentService.getModuleApiURL(moduleMetadata.modulePrefix);
      this.apiUrl = this.baseUrl + this.apiUrl;
    }
  }

  /**
   * Mock data
   */
  mockEntity: any;
  getResult: any[] = [];

  initMockData() {
    this.getResult = [this.mockEntity];
  }

  getBaseUrl(): string {
    return this.baseUrl as string;
  }

  get<T extends AbstractEntity>(_searchCriteria: SearchCriteria): Observable<T[]> {
    return of(this.getResult);
  }

  getWithParams<T extends AbstractEntity>(
    _searchCriteria: SearchCriteria,
    _filterParams: Record<string, any>,
  ): Observable<SearchResult<T>> {
    return of(this.getResult) as any;
  }

  getPage<T extends AbstractEntity>(_searchCriteria: SearchCriteria): Observable<SearchResult<T>> {
    const pageResult = {
      results: this.getResult,
      totalResults: this.getResult.length,
    } as SearchResult<T>;
    return of(pageResult);
  }

  getAll<T extends AbstractEntity>(): Observable<T[]> {
    return this.get<T>(new SearchCriteria());
  }

  getMy<T extends AbstractEntity>(): Observable<T[]> {
    return this.get<T>(new SearchCriteria());
  }

  getOne<T extends AbstractEntity>(id: string, _acceptType?: string): Observable<any> {
    let result: T | null = null;

    this.getResult.forEach((element) => {
      if (element.id === id) {
        result = element;
      }
    });

    return of(result);
  }

  create<T extends AbstractEntity>(entity: T, _contentType: string): Observable<T> {
    entity.id = new Date().valueOf().toString();
    this.getResult.push(entity);

    return of(entity).pipe(
      tap(() => {
        this.showCreateSuccessMessage();
      }),
    );
  }

  update<T extends AbstractEntity>(entity: T, _contentType: string): Observable<T> {
    // update entry in list
    this.getResult.forEach((element) => {
      if (element.id === entity.id) {
        ArrayUtil.removeFromArray(this.getResult, element);
        this.getResult.push(entity);
      }
    });

    return of(entity).pipe(
      tap(() => {
        this.showUpdateSuccessMessage();
      }),
    );
  }

  /* @ts-ignore */
  delete<T extends AbstractEntity>(id: string): Observable<any> {
    this.getResult.forEach((element) => {
      if (element.id === id) {
        ArrayUtil.removeFromArray(this.getResult, element);
      }
    });
    return of().pipe(
      tap(() => {
        this.showDeleteSuccessMessage();
      }),
    );
  }

  /**
   * Get suggestions SelectItem[], search based on entity
   * @deprecated
   * @param entityType
   * @param _searchParameters
   * @param skipMappingToSelectItem optional
   */
  getSuggestions<T extends AbstractEntity>(
    entityType: new () => T,
    _searchParameters: SuggestionSearchParameters,
    skipMappingToSelectItem?: boolean,
  ): Observable<SearchResult<any>> {
    return this.getPage<T>(new SearchCriteria()).pipe(
      map((searchResult) => {
        if (!skipMappingToSelectItem) {
          searchResult.results = searchResult.results.map((item) => new entityType().constructor(item).getSelectItem());
        }

        return searchResult;
      }),
    );
  }

  /**
   * Get suggestions by list SelectItem[], search based on entity
   *
   * @param entityType
   * @param _searchParameters
   * @param skipMappingToSelectItem optional
   */
  getSuggestionsByList<T extends AbstractEntity>(
    entityType: new () => T,
    _searchParameters: SuggestionSearchParameters,
    skipMappingToSelectItem?: boolean,
  ): Observable<SearchResult<any>> {
    return this.getPage<T>(new SearchCriteria()).pipe(
      map((searchResult) => {
        if (!skipMappingToSelectItem) {
          searchResult.results = searchResult.results.map((item) => new entityType().constructor(item).getSelectItem());
        }

        return searchResult;
      }),
    );
  }

  getExportConfiguration(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  exportTableData(_exportFields: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getImportTemplate(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  importTableData(_files: any): void {
    throw new Error('Method not implemented.');
  }

  fullExportData(_searchCriteria: SearchCriteria): Observable<any> {
    throw new Error('Method not implemented.');
  }

  protected showCreateSuccessMessage() {
    if (this.toastService) {
      this.toastService.notifySuccess(ToastKeys.FiyuKey, SharedTranslateKeys.CrudRecordCreated);
    }
  }

  protected showUpdateSuccessMessage() {
    if (this.toastService) {
      this.toastService.notifySuccess(ToastKeys.FiyuKey, SharedTranslateKeys.CrudRecordUpdate);
    }
  }

  protected showDeleteSuccessMessage() {
    if (this.toastService) {
      this.toastService.notifySuccess(ToastKeys.FiyuKey, SharedTranslateKeys.CrudRecordDeleted);
    }
  }

  protected showErrorMessage() {
    if (this.toastService) {
      this.toastService.notifyError(ToastKeys.FiyuKey, SharedTranslateKeys.ErrorCrud);
    }
  }
}
