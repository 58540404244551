import type { FiyuMenuItem } from './../menu/fiyu-menu-item';
import type { Preference } from './module-preferences.interface';

export interface ModuleMetadataInterface {
  isAdmin?: boolean;
  isCore?: boolean;
  isLocal?: boolean;
  moduleName: string;
  moduleNameShort: string;
  moduleDescription: string;
  modulePrefix: string;
  menuItems: FiyuMenuItem[];
  moduleLogoColor: string;
  moduleIcon: string;
  moduleLogoDarkerColor: string;
  moduleAlias: string;
  modulePermissions?: any;
  preferences?: Preference[];
  settings?: unknown;
}
