import { ComponentRenderType, SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, FormEntity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../module-permissions';
import { OrganizationAdminService } from './../organization/organization-admin.service';
import { OrganizationModel } from './organization.model';

export class OrganizationAdminNested extends AbstractEntity {
  @FormEntity()
  active!: boolean;
  @FormEntity()
  firstName!: string;
  @FormEntity()
  lastName!: string;
  @FormEntity()
  userName!: string;
  @FormEntity()
  organizationUserId!: string;
  organizationId: string;
  @FormEntity({ arrayValueType: String })
  roles!: String[];
}

@Entity({
  isAdmin: false,
  singularName: SharedTranslateKeys.User,
  pluralName: SharedTranslateKeys.Users,
  singularUrlName: 'user',
  pluralUrlName: 'users',
  contentType: 'application/user.admin.v1+json',
  permissionPrefix: ModulePermissions.USER,
  labelField: 'fullName',
  defaultSort: { field: 'userName', order: SortDirection.Ascending, type: 'string' },
  exportEnabled: true,
  fullExportEnabled: false,
  importEnabled: true,
  quickExportEnabled: true,
})
export class UserAdminListItem extends AbstractEntity {
  @PresentationEntity({
    useCustomComponent: true,
    componentRenderType: ComponentRenderType.FilePreview,
    displayName: 'Photo',
    disableFilter: true,
    disableSort: true,
  })
  imageId: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Username,
    tableDisplay: true,
    suggestionSearch: true,
  })
  userName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.FirstName,
    tableDisplay: true,
    suggestionSearch: true,
  })
  firstName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.LastName,
    tableDisplay: true,
    suggestionSearch: true,
  })
  lastName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.FullName,
    tableDisplay: true,
    disableSort: true,
    suggestionSearch: false,
  })
  fullName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Organization,
    tableDisplay: false,
    disableSort: true,
    quickViewDisplay: false,
    lookupModel: OrganizationModel,
    lookupService: OrganizationAdminService,
  })
  organizationId: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Organizations,
    disableFilter: true,
    disableSort: true,
    useCustomComponent: true,
    componentRenderType: ComponentRenderType.Chips,
  })
  organizationNames: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Enabled,
    renderer: 'checkCross',
    tableDisplay: true,
  })
  enabled: boolean;

  @PresentationEntity({
    displayName: SharedTranslateKeys.IsVerified,
    renderer: 'yesNo',
    tableDisplay: true,
  })
  verified: boolean;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Superadmin,
    renderer: 'onOff',
    tableDisplay: true,
  })
  superAdmin: boolean;

  @PresentationEntity({
    displayName: SharedTranslateKeys.RegisteredByUser,
    disableSort: true,
    tableDisplay: false,
  })
  registeredBy: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Deleted,
    tableDisplay: false,
  })
  override deleted: Date = null;

  /* public override getLabel(): string {
    return this.firstName + ' ' + this.lastName;
  } */
}
