import {
  MODULE_METADATA_TOKEN,
  Operations,
  RequiredModulesConfig,
  SharedTranslateKeys,
  VALIDATION_ERRORS_TOKEN,
} from '@fiyu/api';
import { PermissionsMatchGuard, type FiyuRoutes } from '@fiyu/core';
import { ModuleMetadata } from './module-metadata';
import { ModulePermissions } from './module-permissions';
import { validationErrors } from './module-validation-errors';

export const DocumentsRoutes: FiyuRoutes = [
  {
    path: '',
    loadComponent: async () => (await import('./document-layout/document-layout.component')).DocumentLayoutComponent,
    providers: [
      { provide: VALIDATION_ERRORS_TOKEN, useValue: { validationErrors } },
      { provide: MODULE_METADATA_TOKEN, useValue: ModuleMetadata.getInstance() },
    ],
    children: [
      {
        path: 'documents',
        title: SharedTranslateKeys.Documents,
        loadComponent: async () =>
          (await import('./document/document-list/document-list.component')).DocumentListComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.DOCUMENTS,
        requiredPermissions: [ModulePermissions.DMM_RUN, ModulePermissions.DMM_PREVIEW],
        operation: Operations.AND,
      },
      {
        path: 'document/:id/edit',
        title: `${SharedTranslateKeys.Edit} ${SharedTranslateKeys.Document}`,
        loadComponent: async () =>
          (await import('./document/document-form/document-form.component')).DocumentFormComponent,
        canMatch: [PermissionsMatchGuard],
        requiredModule: RequiredModulesConfig.DOCUMENTS,
        requiredPermissions: [ModulePermissions.DMM_RUN, ModulePermissions.DMM_EDIT],
        operation: Operations.AND,
      },
      {
        path: 'document',
        title: `${SharedTranslateKeys.Create} ${SharedTranslateKeys.Document}`,
        loadComponent: async () =>
          (await import('./document/document-form/document-form.component')).DocumentFormComponent,
        requiredModule: RequiredModulesConfig.DOCUMENTS,
        canMatch: [PermissionsMatchGuard],
        requiredPermissions: [ModulePermissions.DMM_RUN, ModulePermissions.DMM_UPLOAD],
        operation: Operations.AND,
      },
      {
        path: 'landing',
        title: SharedTranslateKeys.Documents,
        loadComponent: async () =>
          (await import('./document-landing/document-landing.component')).DocumentLandingComponent,
        requiredModule: RequiredModulesConfig.DOCUMENTS,
      },
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
    ],
  },
];
