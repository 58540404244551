export * from './error.interceptor';
export * from './log.interceptor';
export * from './organization-interceptor.service';
export * from './organization.interceptor';
export * from './retry.interceptor';
export * from './spinner.interceptor';

/* export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: OrganizationClassInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
]; */
