import { sharedValidationErrors, ValidationError } from '@fiyu/core';

export const validationErrors: ValidationError[] = [
  ...sharedValidationErrors,
  {
    error: {
      key: 'CRM_PROJECT_ACTIVATION_FAILED',
    },
    message: 'Project cannot be activated reagrding payment issues. Check your payment settings.',
  },
  {
    error: {
      key: 'CRM_ACCOUNT_NOD_FOUND',
    },
    message: 'Account can not be found',
  },
  { error: { key: 'CRM_ACCOUNT_CODE_TAKEN' }, message: 'Account code already taken' },
  {
    error: {
      key: 'CRM_ACCOUNT_REMOVED',
    },
    message: 'Account is removed you can not do anything with this account.',
  },
  { error: { key: 'CRM_PAYMENT_METHOD_NAME_TAKEN' }, message: 'Payment Method name already taken' },
  { error: { key: 'REGISTRATION_NOT_FOUND' }, message: 'Registration not found.' },
  { error: { key: 'RESOURCE_NOT_FOUND' }, message: 'Resource not found.' },
  { error: { key: 'ORGANIZATION_NOT_FOUND' }, message: 'Organization not found.' },
  { error: { key: 'PROVISIONING_NOT_FOUND' }, message: 'Provisioning not found.' },
  { error: { key: 'PROJECT_NOT_FOUND' }, message: 'Project not found.' },
  { error: { key: 'STACKSTORM_NOT_FOUND' }, message: 'StackStorm unavailable.' },
  { error: { key: 'STACKSTORM_SERVER_ERROR' }, message: 'StackStorm server error.' },
  { error: { key: 'FEATURE_NOT_FOUND' }, message: 'Feature not found.' },
  { error: { key: 'BILL_NOT_FOUND' }, message: 'Bill not found.' },
  { error: { key: 'BILLITEM_NOT_FOUND' }, message: 'Bill item not found.' },
  { error: { key: 'PAYMENT_NOT_FOUND' }, message: 'Payment not found.' },
];
