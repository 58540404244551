import type { ValidatorFn } from '@angular/forms';

export interface Organization {
  modules: Module[];
  name: string;
}

export interface Module {
  name: string;
  preferences: Preference[];
  alias: string;
}

export interface Preference {
  name: string;
  label: string;
  type: 'input' | 'checkbox' | 'select';
  options?: { label: string; value: string }[];
  validators?: ValidatorFn[];
  // disabled?: boolean;
  // id?: string;
}
