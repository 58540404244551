export enum PrimeNgThemeEnum {
  LIGHT_BLUE = 'Light Blue',
  DIM_BLUE = 'Dim Blue',
  DARK_BLUE = 'Dark Blue',
  LIGHT_CYAN = 'Light Cyan',
  DIM_CYAN = 'Dim Cyan',
  DARK_CYAN = 'Dark Cyan',
  LIGHT_DEEPPURPLE = 'Light Deeppurple',
  DIM_DEEPPURPLE = 'Dim Deeppurple',
  DARK_DEEPPURPLE = 'Dark Deeppurple',
  LIGHT_GREEN = 'Light Green',
  DIM_GREEN = 'Dim Green',
  DARK_GREEN = 'Dark Green',
  LIGHT_INDIGO = 'Light Indigo',
  DIM_INDIGO = 'Dim Indigo',
  DARK_INDIGO = 'Dark Indigo',
  LIGHT_LIGHTGREEN = 'Light Lightgreen',
  DIM_LIGHTGREEN = 'Dim Lightgreen',
  DARK_LIGHTGREEN = 'Dark Lightgreen',
  LIGHT_ORANGE = 'Light Orange',
  DIM_ORANGE = 'Dim Orange',
  DARK_ORANGE = 'Dark Orange',
  LIGHT_PINK = 'Light Pink',
  DIM_PINK = 'Dim Pink',
  DARK_PINK = 'Dark Pink',
  LIGHT_PURPLE = 'Light Purple',
  DIM_PURPLE = 'Dim Purple',
  DARK_PURPLE = 'Dark Purple',
  LIGHT_TEAL = 'Light Teal',
  DIM_TEAL = 'Dim Teal',
  DARK_TEAL = 'Dark Teal',
}

export enum MenuMode {
  Horizontal = 'horizontal',
  Overlay = 'overlay',
  Slim = 'slim',
  Static = 'static',
}
export enum ColorScheme {
  Dim = 'dim',
  Dark = 'dark',
  Green = 'green',
  Light = 'light',
}

export enum InputStyle {
  Filled = 'filled',
  Outlined = 'outlined',
}

export interface ThemeConfig {
  menuMode: MenuMode;
  colorScheme: ColorScheme;
  menuTheme: string;
  inputStyle: InputStyle;
}
