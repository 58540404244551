import { HttpContext, type HttpClient } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { catchError, distinctUntilChanged, map, of, type Observable } from 'rxjs';
import type { ProfileOrganization } from './../models/profile/profile.model';
import { LC_ACTIVE_ORGANIZATION_ID, LC_ORGANIZATIONS } from './../models/storage/local-storage-keys';
import { ColorScheme, InputStyle, MenuMode, type ThemeConfig } from './../models/theme/theme.model';
import { ThemeService } from './../services/theme/theme.service';
import { SKIP_CACHE_HEADER } from './interceptor-context-tokens';

export const defaultThemeConfig: ThemeConfig = {
  menuMode: MenuMode.Static,
  colorScheme: ColorScheme.Light,
  menuTheme: 'layout-sidebar-teal',
  inputStyle: InputStyle.Outlined,
};

export const THEME_CONFIG_TOKEN = new InjectionToken<Observable<ThemeConfig>>('themeConfig', {
  providedIn: 'root',
  factory: (): Observable<ThemeConfig> => {
    // const httpClient: HttpClient = inject(HttpClient);
    const themeService: ThemeService = inject(ThemeService);
    return themeService.theme$.pipe(
      distinctUntilChanged(),
      map((orgTheme: string) => {
        return themeService.setupTheme(orgTheme);
      }),
    );
  },
});

export function getOrganization(httpClient: HttpClient): Observable<ProfileOrganization> {
  const existingOrganizations: ProfileOrganization[] = JSON.parse(localStorage.getItem(LC_ORGANIZATIONS) ?? '[]');
  const activeOrganizationId: string = localStorage.getItem(LC_ACTIVE_ORGANIZATION_ID);
  const existingOrganization: ProfileOrganization = existingOrganizations.find(
    (x: ProfileOrganization) => x.id === activeOrganizationId,
  );
  if (existingOrganization) {
    return of(existingOrganization);
  }
  const organizations: Observable<ProfileOrganization[]> = httpClient.get<ProfileOrganization[]>(
    `user/profile/organizations`,
    {
      context: new HttpContext().set(SKIP_CACHE_HEADER, true),
    },
  );
  const organization: Observable<ProfileOrganization> = organizations.pipe(
    map((organizations: ProfileOrganization[]) => {
      return organizations?.find((x: ProfileOrganization) => x.id === activeOrganizationId);
    }),
    catchError((error: unknown) => {
      console.error('Organizations can not be fetched', error);
      return of(null);
    }),
  );
  return organization;
}
