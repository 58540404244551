export interface ForgotPassword {
  email: string;
  clientUri?: string;
}

export interface ResetPassword {
  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
}

export class LoginUser {
  username: string;
  password: string;
  rememberMe: boolean;
}
