export enum ModulePermissions {
  ORGANIZATION_RUN = 'ORGANIZATION_RUN',
  ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
  ORGANIZATION_CREATE = 'ORGANIZATION_CREATE',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
  ORGANIZATION_DELETE = 'ORGANIZATION_DELETE',
  EMPLOYEE_CREATE = 'EMPLOYEE_CREATE',
  EMPLOYEE_EDIT = 'EMPLOYEE_EDIT',
  EMPLOYEE_DELETE = 'EMPLOYEE_DELETE',
  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  ORGANIZATION_TYPE_EDIT = 'ORGANIZATION_TYPE_EDIT',
  ORGANIZATION_TYPE_VIEW = 'ORGANIZATION_TYPE_VIEW',
  ORGANIZATION_USER_TYPE_VIEW = 'ORGANIZATION_USER_TYPE_VIEW',
  ORGANIZATION_USER_TYPE_EDIT = 'ORGANIZATION_USER_TYPE_EDIT',
  ORGANIZATION_USER_TYPE_CREATE = 'ORGANIZATION_USER_TYPE_CREATE',
  ORGANIZATION_USER_TYPE_DELETE = 'ORGANIZATION_USER_TYPE_DELETE',
  ORGANIZATION_PREFERENCE_EDIT = 'ORGANIZATION_PREFERENCE_EDIT',
  ORGANIZATION_PREFERENCE_DELETE = 'ORGANIZATION_PREFERENCE_DELETE',
  ORGANIZATION_PREFERENCE_CREATE = 'ORGANIZATION_PREFERENCE_CREATE',
  ORGANIZATION_PREFERENCE_VIEW = 'ORGANIZATION_PREFERENCE_VIEW',
  ORGANIZATION_SETTING_DELETE = 'ORGANIZATION_SETTING_DELETE',
  ORGANIZATION_SETTING_EDIT = 'ORGANIZATION_SETTING_EDIT',
  ORGANIZATION_SETTING_VIEW = 'ORGANIZATION_SETTING_VIEW',
  ORGANIZATION_SETTING_CREATE = 'ORGANIZATION_SETTING_CREATE',
  MODULE_EDIT = 'MODULE_EDIT',
  MODULE_VIEW = 'MODULE_VIEW',
  // Permission prefixes
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
  ORGANIZATION_USER_TYPE = 'ORGANIZATION_USER_TYPE',
}
