import type { ValidatorFn } from '@angular/forms';
import type {
  Module as FrontModule,
  Organization as FrontOrganization,
  Preference as FrontPreference,
} from '@fiyu/api';
import type { Nullable } from '../types/nullable-type';

export interface UserPreferenceDTO {
  id?: string | null | undefined;
  globalPreferences: PreferenceDTO[];
  organizations: OrganizationDTO[];
}

export interface OrganizationDTO {
  id: string;
  name: string;
  modules: ModuleDTO[];
}

export interface ModuleDTO {
  id: string;
  alias: string;
  name: string;
  preferences: PreferenceDTO[];
}

export interface PreferenceDTO {
  id: string;
  name: string;
  value: string;
  locked: boolean;
}

export class UserPreferences {
  id: any;
  globalPreferences: UserPreference[];
  organizations: UserOrganization[];

  constructor(frontGlobalPrefs: FrontPreference[], frontOrgPrefs: FrontOrganization[], backPrefs: UserPreferenceDTO) {
    this.id = backPrefs.id;
    this.globalPreferences = frontGlobalPrefs.map(
      (front) =>
        new UserPreference(
          front,
          backPrefs.globalPreferences.find((back) => back.name === front.name),
        ),
    );

    this.organizations = frontOrgPrefs.map(
      (front) =>
        new UserOrganization(
          front,
          backPrefs.organizations.find((back) => front.name === back.name),
        ),
    );
  }
}

export class UserOrganization {
  id: Nullable<string> = null;
  name: Nullable<string> = null;
  modules: UserModule[] = [];

  constructor(frontOrganization?: FrontOrganization, backOrganization?: OrganizationDTO) {
    if (backOrganization) {
      this.id = backOrganization.id;
    }

    if (frontOrganization) {
      this.name = frontOrganization.name;
    }

    if (backOrganization && frontOrganization) {
      this.modules = frontOrganization.modules.map(
        (front) =>
          new UserModule(
            front,
            backOrganization.modules.find((back) => back.alias === front.alias),
          ),
      );
    }
  }
}

export class UserModule {
  id: Nullable<string> = null;
  alias: Nullable<string> = null;
  name: Nullable<string> = null;
  preferences: UserPreference[] = [];

  constructor(frontModule?: FrontModule, backModule?: ModuleDTO) {
    if (backModule) {
      this.id = backModule.id;
    }

    if (frontModule) {
      this.name = frontModule.name;
      this.alias = frontModule.alias;
    }

    if (backModule && frontModule) {
      this.preferences = frontModule.preferences.map(
        (front) =>
          new UserPreference(
            front,
            backModule.preferences.find((back) => back.name === front.name),
          ),
      );
    }
  }
}

export class UserPreference {
  id: Nullable<string> = null;
  name: Nullable<string> = null;
  value: Nullable<string> = null;
  locked: Nullable<boolean> = null;
  label: Nullable<string> = null;
  type: 'input' | 'checkbox' | 'select' | null = null;
  options: { label: string; value: string }[] | undefined = [];
  validators: ValidatorFn[] | undefined = [];

  constructor(frontPreference?: FrontPreference, backPreference?: PreferenceDTO) {
    if (backPreference) {
      this.id = backPreference.id;
      this.locked = backPreference.locked;
      this.value = backPreference.value;
    }

    if (frontPreference) {
      this.name = frontPreference.name;
      this.type = frontPreference.type;
      this.label = frontPreference.label;
      this.options = frontPreference.options;
      this.validators = frontPreference.validators;
    }
  }
}
