import type { Criterion } from './criterion.type';
import type { Page } from './page.type';

export class SearchCriteria {
  static contentType = 'application/search.criteria.v1+json';

  criterion?: Criterion;
  paging?: Page;
  sortProperties?: any;
  window?: Window;
  filtered?: boolean;
}

export class Window {
  firstResult = 0;
  maxResults = 0;
}
