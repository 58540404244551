import { HttpClient, HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DomSanitizer, type SafeUrl } from '@angular/platform-browser';
import { SKIP_CACHE_HEADER, WINDOW } from '@fiyu/api';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';

export type DownloadedFile = { file: File; base64ImageString: string; blob: Blob };

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private readonly envService: EnvironmentService = inject(EnvironmentService);
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);
  private readonly window: Window = inject(WINDOW);

  downloadFile(fileUuid: string): Observable<Blob> {
    return this.httpClient.get(`${this.envService.dmmModuleApiUrl}/documents/${fileUuid}`, {
      responseType: 'blob',
      context: new HttpContext().set(SKIP_CACHE_HEADER, false),
    });
  }

  uploadFile(formData: FormData): Observable<any> {
    return this.httpClient.post(`${this.envService.dmmModuleApiUrl}/documents/upload`, formData, {
      context: new HttpContext().set(SKIP_CACHE_HEADER, false),
    });
  }

  /**
   * Rxjs method that converts file to base64 string
   * @static
   * @param {Blob} blob
   * @return {*}  {Observable<string>}
   */

  public blobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>((subscriber) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };
      reader.onerror = () => subscriber.error(reader.error);
      return () => reader.abort(); // cancel function in case you unsubscribe from the obs
    });
  }

  public saveFile(fileUuid: string): Observable<Blob> {
    return this.downloadFile(fileUuid);
  }

  public saveFileToFs(file: File): void {
    saveAs(file);
    //  saveAs(file, file.name);
  }

  public deleteFile(fileUuid: string): Observable<any> {
    return this.httpClient.delete(`${this.envService.dmmModuleApiUrl}/documents/${fileUuid}`);
  }

  public getFileForPreview(url: string): Observable<SafeUrl> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(map((val: Blob) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }

  public downloadExportFile(file: Blob, fileName: string): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.download = `${fileName}.csv`;
    link.href = (<any>this.window).URL.createObjectURL(file);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
