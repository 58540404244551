import { SharedTranslateKeys } from '@fiyu/api';
import { AbstractEntity, Entity, PresentationEntity, SortDirection } from '@fiyu/core';
import { ModulePermissions } from '../../module-permissions';
@Entity({
  isAdmin: true,
  singularName: SharedTranslateKeys.User,
  pluralName: SharedTranslateKeys.Users,
  singularUrlName: 'user',
  pluralUrlName: 'users',
  contentType: 'application/user.v1+json',
  labelField: 'fullName',
  defaultSort: { field: 'userName', order: SortDirection.Ascending, type: 'string' },
  permissionPrefix: ModulePermissions.USER,
})
export class UserLookup extends AbstractEntity {
  @PresentationEntity({
    displayName: SharedTranslateKeys.LastName,
    suggestionSearch: true,
  })
  lastName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.FirstName,
    tableDisplay: false,
    suggestionSearch: true,
  })
  firstName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.Username,
    tableDisplay: false,
    suggestionSearch: true,
  })
  userName: string;

  @PresentationEntity({
    displayName: SharedTranslateKeys.FullName,
    suggestionSearch: false,
    disableSort: true,
  })
  fullName: string;

  public override getLabel(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
