import {
  assertInInjectionContext,
  effect,
  inject,
  Injector,
  signal,
  type CreateEffectOptions,
  type EffectCleanupRegisterFn,
  type Signal,
  type WritableSignal,
} from '@angular/core';
import type { Observable, Subscription } from 'rxjs';

interface Options {
  injector?: Injector;
  initialValue?: any;
}

/**
 * This function accepts an array of signal dependencies and a function that returns an observable.
 * We subscribe to this observable whenever any dependency changes
 * Function verifies the injection context and takes an optional injector for scenarios outside this context.
 * It then creates a signal and an effect
 * It also sets the allowSignalWrites option to true in case our observable emits synchronously
 * @example How to use fromEffect
 * ```ts
 *  #service = inject(ProjectsService);
 *  id = input.required<string>();
 *  project = fromEffect([this.id], id => this.#service.getProject(id))
 * ```
 */
export function fromEffect<
  T,
  const Deps extends Signal<any>[],
  Values extends {
    [K in keyof Deps]: Deps[K] extends Signal<infer T> ? T : never;
  },
>(deps: Deps, source: (...values: Values) => Observable<T>, options?: Options): Signal<T> {
  if (!options?.injector) assertInInjectionContext(fromEffect);
  const injector = options?.injector ?? inject(Injector);
  const sig: WritableSignal<T> = signal<T | undefined>(options.initialValue ?? undefined);

  effect(
    (onCleanup: EffectCleanupRegisterFn) => {
      const values = deps.map((dep) => dep()) as Values;
      const sub: Subscription = source(...values).subscribe((value) => {
        sig.set(value);
      });

      onCleanup(() => sub.unsubscribe());
    },
    { injector, allowSignalWrites: true } as CreateEffectOptions,
  );

  return sig.asReadonly();
}
