/**
 * List of available entity options
 *
 */
export enum EntityOptionsEnum {
  SingularName = 'singularName',
  PluralName = 'pluralName',
  LabelField = 'labelField',
  DefaultSort = 'defaultSort',
  SinguralUrlName = 'singularUrlName',
  PluralUrlName = 'pluralUrlName',
  IsAdmin = 'isAdmin',
  FormBlockTitles = 'formBlockTitles',
  ContentType = 'contentType',
  PermissionPrefix = 'permissionPrefix',
  CreatePermission = 'createPermission',
  ReadPermission = 'readPermission',
  UpdatePermission = 'updatePermission',
  DeletePermission = 'deletePermission',
  ModLog = 'modLog',
  Acl = 'acl',
  IsPanelSectionVisible = 'isPanelSectionVisible',
  ExportEnabled = 'exportEnabled',
  ImportEnabled = 'importEnabled',
  DeleteDisabled = 'deleteDisabled',
  EditDisabled = 'editDisabled',
  QuickExportEnabled = 'quickExportEnabled',
  FullExportEnabled = 'fullExportEnabled',
  DisabledStateProp = 'disabledStateProp',
}
