import { Injectable } from '@angular/core';
import { AbstractCRUDService } from '@fiyu/core';
import { ModuleMetadata } from './../../module-metadata';

@Injectable({
  providedIn: 'root',
})
export class UserPasswordService extends AbstractCRUDService {
  constructor() {
    super('/profile/password', ModuleMetadata.getInstance());
  }
}
