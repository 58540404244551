import type { InputSignal } from '@angular/core';
/**
 * @example
 * ```ts
 * type UserInputs = ComponentInputs<UserComponent>;
 * ```
 */
export type ComponentInputs<C> = {
  [K in keyof Pick<
    C,
    {
      [K in keyof C]: C[K] extends InputSignal<any> ? K : never;
    }[keyof C]
  >]: C[K] extends InputSignal<infer Write> ? Write : never;
};
