import {
  ModuleAliases,
  ModulePrefixes,
  Operations,
  SharedTranslateKeys,
  type ModuleMetadataInterface,
  type Permissions,
} from '@fiyu/api';
import { AbstractModuleMetadata } from '@fiyu/core';
import { ModulePermissions } from './module-permissions';

/**
 * Class with metadata for module
 */
export class ModuleMetadata extends AbstractModuleMetadata implements ModuleMetadataInterface {
  /**
   * Module name
   */
  public moduleName = SharedTranslateKeys.Users;

  /**
   * Module description
   */
  public moduleDescription = SharedTranslateKeys.UsersModuleDescription;

  /**
   * Core module flag
   */
  public isCore = true;

  /**
   * Short name
   */
  public moduleNameShort = SharedTranslateKeys.Users;

  /**
   *  Module prefix used in navigation and url
   */
  public modulePrefix = ModulePrefixes.USER;

  /**
   *  Module icon used in navigation and url
   */
  public moduleIcon = 'pi pi-fw pi-users';

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoColor = '#A6CFD5';

  /**
   *  Module prefix used in navigation and url
   */
  public moduleLogoDarkerColor = '#64b4bf';

  /**
   * Module name used for accessing user module permissions
   */
  public moduleAlias = ModuleAliases.USER;

  /**
   * Sidebar permissions
   */
  userManagementPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.USER_VIEW],
    operation: Operations.AND,
  };
  userTypesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ORGANIZATION_USER_TYPE_VIEW],
    operation: Operations.AND,
  };
  rolesPermissions: Permissions = {
    requiredPermissions: [ModulePermissions.USER_RUN, ModulePermissions.ROLE_VIEW],
    operation: Operations.AND,
  };

  /**
   * Module sidebar navigation
   */
  public menuItems = [
    {
      label: SharedTranslateKeys.UsersModuleLabel,
      icon: 'pi pi-fw pi-users',
      permissions: this.userManagementPermissions,
      items: [
        {
          label: SharedTranslateKeys.Users,
          icon: 'pi pi-fw pi-users',
          routerLink: '/user/users',
          permissions: this.userManagementPermissions,
        },
        {
          label: SharedTranslateKeys.UserTypes,
          icon: 'pi pi-fw pi-user-edit',
          routerLink: '/user/organization-user-types',
          permissions: this.userTypesPermissions,
        },
        {
          label: SharedTranslateKeys.Roles,
          icon: 'pi pi-fw pi-plus-circle',
          routerLink: '/user/roles',
          permissions: this.rolesPermissions,
        },
      ],
    },
  ];

  public modulePermissions = ModulePermissions;
}
