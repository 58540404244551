import { InjectionToken } from '@angular/core';

interface ValidationErrorType {
  key: string;
  parameters?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
interface ValidationError {
  error: ValidationErrorType;
  message: string;
  field?: string;
  value?: string;
}

/**
 * Token is used when providing module validation errors.
 * Create module-validation-errors.ts file for module and provide as token via module providers
 * @usageNotes
 * ```typescript
 * import { validationErrors } from './module-validation-errors';
 * import { VALIDATION_ERRORS_TOKEN } from '@fiyu/api';
 * { provide: VALIDATION_ERRORS_TOKEN, useValue: {validationErrors}; }
 * ```
 */
export const VALIDATION_ERRORS_TOKEN = new InjectionToken<ValidationErrorsConfig>('validationErrorsConfig');
export interface ValidationErrorsConfig {
  validationErrors: ValidationError[];
}
