import { differenceInHours } from 'date-fns/differenceInHours';
import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { differenceInSeconds } from 'date-fns/differenceInSeconds';
import { intervalToDuration } from 'date-fns/intervalToDuration';
import type { Duration } from 'date-fns/types';

export enum DiffType {
  Hours = 'h',

  Minutes = 'min',

  Seconds = 's',

  Milliseconds = 'ms',
}

export class TimeUtility {
  /**
   * Format ISO 8061 Duration object to HH:MM formatted string
   * @param value
   */
  static formatISOtoReadable(value: number): string {
    const duration = this.milliSecondsToDuration(value);
    const calculatedHours = duration.days > 0 ? duration.hours + duration.days * 24 : duration.hours;
    return `${calculatedHours}h ${duration.minutes}m ${duration.seconds}s`;

    // IMPLEMENTATION NOTE using formatDuration returns readable strings (hours, minutes, seconds) in long format
    /*  formatDuration(
     {
       hours: millisecondsToDuration.hours,
       minutes: millisecondsToDuration.minutes,
       seconds: millisecondsToDuration.seconds,
     },
     { zero: false }
   ); */
  }

  /**
   *
   * @param milliSeconds
   * @returns Duration
   */

  static milliSecondsToDuration(milliSeconds: number): Duration {
    milliSeconds = milliSeconds ? milliSeconds : 0;
    const epoch = new Date(0);
    const secondsAfterEpoch = new Date(milliSeconds);
    return intervalToDuration({
      start: epoch,
      end: secondsAfterEpoch,
    });
  }

  /**
   * Receives two times in string in HH:MM format formatted
   * returns if first time is greater or less than second depending on
   * @param date1
   * @param date2
   * @param operator
   * @param diffType
   * @returns boolean
   */
  static compareTime(
    date1: Date,
    date2: Date,
    operator: string,
    diffType: string = DiffType.Minutes,
  ): boolean | undefined {
    if (operator === 'lt') {
      return this.isBefore(date1, date2, diffType);
    }

    if (operator === 'gt') {
      return this.isAfter(date1, date2, diffType);
    }

    return undefined;
  }

  /**
   *
   * @param date1
   * @param date2
   * @param diffType
   * @returns boolean
   */
  static isBefore(date1: Date, date2: Date, diffType: string = DiffType.Hours): boolean {
    switch (diffType) {
      case DiffType.Hours: {
        return differenceInHours(date1, date2) <= 0;
      }
      case DiffType.Minutes: {
        let diffMin = differenceInMinutes(date1, date2);
        if (Object.is(diffMin, -0)) {
          diffMin = 1;
        }
        return diffMin < 0;
      }
      case DiffType.Seconds: {
        return differenceInSeconds(date1, date2) <= 0;
      }
      case DiffType.Milliseconds: {
        return differenceInMilliseconds(date1, date2) <= 0;
      }
      default: {
        return differenceInHours(date1, date2) <= 0;
      }
    }
  }

  /**
   *
   * @param date1
   * @param date2
   * @param diffType
   * @returns boolean
   */
  static isAfter(date1: Date, date2: Date, diffType: string = DiffType.Hours): boolean {
    switch (diffType) {
      case DiffType.Hours: {
        return differenceInHours(date1, date2) >= 0;
      }
      case DiffType.Minutes: {
        let diffMin = differenceInMinutes(date1, date2);
        if (Object.is(diffMin, -0)) {
          diffMin = -1;
        }
        return diffMin > 0;
      }
      case DiffType.Seconds: {
        return differenceInSeconds(date1, date2) >= 0;
      }
      case DiffType.Milliseconds: {
        return differenceInMilliseconds(date1, date2) >= 0;
      }
      default: {
        return differenceInHours(date1, date2) >= 0;
      }
    }
  }
}
