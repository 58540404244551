import { CompositionType } from '../crud/search/composition-type.enum';
import type { Criterion } from '../crud/search/criterion.type';
import type { SearchCriteria } from '../crud/search/search-criteria.type';
import { SearchType } from '../crud/search/search-type.enum';

export class SearchCriteriaUtility {
  /**
   * Checks if search criteria already has a single criterion, multiple criterions or no criterions set
   * and adds new criterion accordingly.
   *
   * @param criterionToAdd new criterion which will be added to provided search criteria
   * @param searchCriteria search criteria which already contains a single, multiple or no criterion
   *
   */
  static addCriterion(criterionToAdd: Criterion, searchCriteria: SearchCriteria): SearchCriteria | undefined {
    if (!searchCriteria) {
      /* @ts-ignore */
      return;
    }

    if (this.isMultipleCriterionsSet(searchCriteria)) {
      searchCriteria?.criterion?.criterions?.push(criterionToAdd);
    } else if (this.isSingleCriterionSet(searchCriteria)) {
      const criterion: any = searchCriteria.criterion;

      searchCriteria.criterion = {
        type: SearchType.Composite,
        compositionType: CompositionType.And,
        criterions: [criterion, criterionToAdd],
      };
    } else {
      searchCriteria.criterion = criterionToAdd;
    }

    return searchCriteria;
  }

  /**
   * Adds array of criterions to provided search criteria.
   *
   * @param criterionsToAdd array of criterions which will be added to provided search criteria
   * @param searchCriteria search criteria which already contains a single, multiple or no criterion
   */
  static addCriterions(searchCriteria: SearchCriteria, ...criterionsToAdd: Criterion[]): SearchCriteria {
    criterionsToAdd.forEach((criterionToAdd: Criterion) => {
      if (criterionToAdd) {
        this.addCriterion(criterionToAdd, searchCriteria);
      }
    });

    return searchCriteria;
  }

  private static isSingleCriterionSet(searchCriteria: SearchCriteria) {
    return searchCriteria.criterion;
  }

  private static isMultipleCriterionsSet(searchCriteria: SearchCriteria) {
    return searchCriteria.criterion && searchCriteria.criterion.criterions;
  }
}
