/**
 * List of available form options
 */
export enum FormOptionsEnum {
  Primary = 'primary',
  Required = 'required',
  Label = 'label',
  RenderLabel = 'renderLabel',
  Readonly = 'readonly',
  Disabled = 'disabled',
  Hidden = 'hidden',
  Type = 'type',
  FormType = 'formType',
  ArrayValueType = 'arrayValuetype',
  Validators = 'validators',
  GroupNumber = 'groupNumber',
  InitialValue = 'initialValue',
  Placeholder = 'placeholder',
  Description = 'description',
  Render = 'render',
  RenderOnCreate = 'renderOnCreate',
  RenderOnEdit = 'renderOnEdit',
  LookupModel = 'lookupModel',
  LookupService = 'lookupService',
  LookupOptions = 'lookupOptions',
  LookupSearchField = 'lookupSearchField',
  LookupMy = 'LookupMy',
  LookupMyMethod = 'lookupMyMethod',
  Renderer = 'renderer',
  RendererFunction = 'rendererFunction',
  RendererAsyncFunction = 'rendererAsyncFunction',
  RequiredPermissions = 'requiredPermissions',
  FieldOptions = 'fieldOptions',
  WizardStepIndex = 'wizardStepIndex',
  Editable = 'editable',
  ReviewStepDisplay = 'reviewStepDisplay',
  Transient = 'transient',
  CascadeTo = 'cascadeTo',
  CascadedBy = 'cascadedBy',
}
